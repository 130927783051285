import React, {useEffect} from "react";
import '../../styles/pages/KnowledgeGraph/LargeLanguageModel.scss';
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const LargeLanguageModel = ({setSearchTerm, getLLMData}) => {
  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        getLLMData()
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [getLLMData]);

  return (
    <div className={"llm-container"}>
      <textarea
        className="input-field text llm-text-field"
        placeholder={"Ask our AI about your environment."}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <a className={"llm-tooltip"} target="_blank" rel="noreferrer" href="https://docs.actzero.ai/zeroin/">
        <IconButton className={"search-i-icon"}><InfoOutlinedIcon fontSize="small"/></IconButton>
      </a>
    </div>
  );
}

export default LargeLanguageModel;
