import { getNetworkData, getNetworkTrends } from "../../api/ApiClient";
import countryCentroids from "./config/countryCentroids.json";
import * as d3 from "d3";
import { scaleRadial, scaleLog } from "d3-scale";
import { mapChartData } from "../../util/mapChartData";
import { downloadFile } from "../../util/export";
import _ from "lodash";
import { sortObjects } from "../../util/sortObjects";
import { filterMapping, defaultChartLines } from "./config/config";

const generateScale = (data) => {
  const combinedData = [...data.inbound?.summaries, ...data.outbound?.summaries]
    .map((country) => country.total)
    .sort((a, b) => a - b);

  const high = combinedData[combinedData.length - 1];
  const low = combinedData[0];
  const mean = _.mean(combinedData);
  const topPercentile = d3.quantile(combinedData, 0.95);
  const scaleDivide = topPercentile > mean ? topPercentile : mean;

  const lowerScale = scaleRadial()
    .domain([low, scaleDivide])
    .range([2, 14]) // min size for circle on map is 2
    .clamp(true);

  const upperScale = scaleLog()
    .domain([scaleDivide, high])
    .range([15, 30]) // max size for circle on map is 30
    .clamp(true);

  return (value) =>
    value > scaleDivide ? upperScale(value) : lowerScale(value);
};

const formatMapData = (data, scale) =>
  data.summaries
    .map((country) => {
      const countryData = countryCentroids.find(
        (c) => c.alpha2 === country.country
      );
      return {
        ...country,
        longitude: countryData?.longitude,
        latitude: countryData?.latitude,
        name: countryData?.name,
        value: scale(country.total),
      };
    })
    .filter((country) => country.longitude);

export const setMapData = async ({ userData, setMapStatistics, filters }) => {
  const { results } = await getNetworkData(userData?.tenant, filters);
  const scale = generateScale(results);
  setMapStatistics({
    incoming: formatMapData(results.inbound, scale),
    outgoing: formatMapData(results.outbound, scale),
  });
};

export const exportCSV = async ({ userData, filters }) => {
  const results = await getNetworkData(userData?.tenant, filters, true);
  downloadFile(results);
};

const generateChartLines = (filters) => {
  const filtersToUse = _.intersection(
    filterMapping[filters.action] || filterMapping.default,
    filterMapping[filters.source] || filterMapping.default
  );
  const chartLines = _.cloneDeep(defaultChartLines).filter((line) =>
    filtersToUse.includes(line.name)
  );
  return chartLines;
};

const formatTopCountries = (allCountries) =>
  allCountries.splice(0, 9).map((country) => {
    const countryData = countryCentroids.find(
      (item) => item.alpha2 === country.country
    );
    return {
      ...country,
      name: countryData?.name,
    };
  });

export const sortTopCountries = (allCountries, sortBy, sortOrder) =>
  formatTopCountries(sortObjects(allCountries, sortBy, sortOrder));

export const setTrendsData = async ({
  userData,
  filters,
  setTopCountriesData,
  setTrafficData,
  setTrafficAxisData,
  setAllCountries,
}) => {
  const results = await getNetworkTrends(userData?.tenant, filters);

  setAllCountries([...results.top_countries]);
  setTopCountriesData(formatTopCountries(results.top_countries));

  results.network_trend.forEach((item) => {
    item.source = `${item.source}${item.action === "deny" ? " blocked" : ""}`;
  });
  let chartLines = generateChartLines(filters);

  mapChartData(
    results.network_trend,
    setTrafficAxisData,
    setTrafficData,
    chartLines,
    []
  );
};
