/**
* Sorts an array of objects by specified key values
* @param  arr  the array of objects to sort
* @param  order sort order, "asc" or "desc"
* @return      a sorted copy of the array
*/
export const sortObjects = (arr, sortBy, order) =>
  [...arr].sort((a, b) => {
    if (order === "desc") {
      let temp = a;
      a = b;
      b = temp;
    }
    if(!a[sortBy]) return -1;
    if(!b[sortBy]) return 1;
    return a[sortBy]
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "")
      .localeCompare(
        b[sortBy].toString().toLowerCase().replace(/\s+/g, ""),
        undefined,
        { numeric: true, sensitivity: "base" }
      );
  });
