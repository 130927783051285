const TenantDropDownStyles = {
    option: (provided, state) => ({
      ...provided,
      color: '#C0D0D0',
      padding: 10,
      width: 220,
      cursor: 'pointer',
      backgroundColor: '#14373f',
      border: state.isFocused ? "1px solid #018D8D" : "1px solid #002C34",
      fontSize: "16px"
    }),
    control: () => ({
        letterSpacing: ".5px",
        width: 230,
        color: '#D0F31B',
        display: 'flex',
        border: '3px solid #616B6B',
        cursor: 'pointer',
        height: "40px"
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#C2CECF',
      lineHeight: "30px",
      marginBottom: "5px", 
      fontSize: "17px"
    }),
    input: (provided) => {
      const color = '#fff';
      return {...provided, color};
    },
    menuList: base => ({
      ...base,
      padding: 0,
      marginTop: '-8px',
      overflowX: 'hidden',
    }), 
    menu: provided => ({ 
        ...provided, 
        zIndex: 20 })
  }
  
  export default TenantDropDownStyles