import React, { useState, useEffect, useContext } from "react";
import {
  getCloudConnections,
  getMobileConnections,
  getEndPointConnections,
  getIdentityConnections,
  getLogConnections,
  getChildTenantOfferings,
} from "../../api/ApiClient";
import { captureSentryError } from "../../util/sentry";
import UserData from "../../store/User/UserData";
import ServicePanel from "./components/ServicePanel";
import "../../styles/pages/MultiTenantDashboard/ServicePanels.scss";
import _ from "lodash";
import { convertDataSize, getDataUnit } from "../../util/format";

const ServicePanels = () => {
  const [userData] = useContext(UserData);
  const [cloudData, setCloudData] = useState({});
  const [cloudDataLoading, setCloudDataLoading] = useState(false);
  const [mobileData, setMobileData] = useState({});
  const [mobileDataLoading, setMobileDataLoading] = useState(false);
  const [endpointData, setEndpointData] = useState({});
  const [endpointDataLoading, setEndpointDataLoading] = useState(false);
  const [identityData, setIdentityData] = useState({});
  const [identityDataLoading, setIdentityDataLoading] = useState(false);
  const [logData, setLogData] = useState({});
  const [logDataLoading, setLogDataLoading] = useState(false);
  const [childOfferings, setChildOfferings] = useState({});
  const [childOfferingsLoading, setChildOfferingsLoading] = useState(false);

  const servicePanelConfig = [
    {
      title: "Endpoints",
      data: endpointData,
      loading: endpointDataLoading,
      type: "endpoint",
    },
    {
      title: "Cloud",
      data: cloudData,
      loading: cloudDataLoading,
      type: "cloud",
      options: ["aws", "gsuite", "o365", "salesforce"],
    },
    {
      title: "Mobile",
      data: mobileData,
      loading: mobileDataLoading,
      type: "mobile",
      options: ["Android", "iOS"],
    },
    {
      title: "Identity",
      data: identityData,
      loading: identityDataLoading,
      type: "identity",
      options: ["OKTA"],
    },
    {
      title: "Log",
      data: logData,
      loading: logDataLoading,
      type: "log",
      options: ["log"],
    },
    {
      title: "Add Ons",
      data: childOfferings,
      loading: childOfferingsLoading,
      type: "child_offerings",
      options: ["evm", "device_control"],
      iconLabels: {"evm" : "Enhanced Vulnerability Management", "device_control": "Device Control"}
    },
  ];

  const setCloudConnections = async () => {
    try {
      setCloudDataLoading(true);
      const results = await getCloudConnections(userData?.tenant);
      setCloudData({
        services: Object.keys(results.clouds).reduce((dataObject, cloud) => {
          dataObject[cloud] = results.clouds[cloud].active_connections;
          return dataObject;
        }, {}),
        total: _.sumBy(
          Object.keys(results.clouds),
          (cloud) => results.clouds[cloud].active_connections
        ),
      });
    } catch (e) {
      captureSentryError(
        e,
        userData,
        "getCloudConnections API in ServicePanels.js"
      );
    } finally {
      setCloudDataLoading(false);
    }
  };

  const formatConnectionsData = (data) => {
    const total = _.sumBy(
      Object.keys(data.active_endpoints),
      (platform) => data.active_endpoints[platform]
    );
    return {
      services: data.active_endpoints,
      contracted: data.contracted_endpoints,
      total: total,
      percentUsed: Math.round((total / data.contracted_endpoints) * 100),
    };
  };

  const setMobileConnections = async () => {
    try {
      setMobileDataLoading(true);
      const results = await getMobileConnections(userData?.tenant);
      setMobileData(formatConnectionsData(results));
    } catch (e) {
      captureSentryError(
        e,
        userData,
        "getMobileConnections API in ServicePanels.js"
      );
    } finally {
      setMobileDataLoading(false);
    }
  };

  const setEndpointConnections = async () => {
    try {
      setEndpointDataLoading(true);
      const results = await getEndPointConnections(userData?.tenant);
      setEndpointData(formatConnectionsData(results));
    } catch (e) {
      captureSentryError(
        e,
        userData,
        "getEndpointConnections API in ServicePanels.js"
      );
    } finally {
      setEndpointDataLoading(false);
    }
  };

  const setIdentityConnections = async () => {
    try {
      setIdentityDataLoading(true);
      const results = await getIdentityConnections(userData?.tenant);
      const formatted = {};
      if(results?.identity) Object.keys(results.identity).forEach((id) => {
        formatted[id] = results?.identity[id]?.active_connections;
      });
      const total = _.sumBy(Object.keys(formatted), (id) => formatted[id]);
      setIdentityData({ services: formatted, total: total });
    } catch (e) {
      captureSentryError(
        e,
        userData,
        "getIdentityConnections API in ServicePanels.js"
      );
    } finally {
      setIdentityDataLoading(false);
    }
  };

  const setLogConnections = async () => {
    try {
      setLogDataLoading(true);
      const results = await getLogConnections(userData?.tenant);
      const size = results?.log_management?.size || 0;
      const total = (
        <>
          <span>{convertDataSize(size, 0)}</span>
          <span className="data-unit">{getDataUnit(size)}</span>
        </>
      );
      setLogData({
        total: total,
        services: { log: `${convertDataSize(size, 3)} ${getDataUnit(size)}` },
      });
    } catch (e) {
      captureSentryError(
        e,
        userData,
        "getLogConnections API in ServicePanels.js"
      );
    } finally {
      setLogDataLoading(false);
    }
  };

  const setChildTenantOfferings = async () => {
    try {
      setChildOfferingsLoading(true);
      const results = await getChildTenantOfferings(userData?.tenant);
      setChildOfferings({
        services: results,
        total: _.sumBy(Object.keys(results), (service) => results[service]),
      });
    } catch (e) {
      captureSentryError(
        e,
        userData,
        "getChildTenantOfferings API in ServicePanels.js"
      );
    } finally {
      setChildOfferingsLoading(false);
    }
  };

  useEffect(() => {
    if (userData.tenant) {
      setCloudConnections();
      setMobileConnections();
      setEndpointConnections();
      setIdentityConnections();
      setLogConnections();
      setChildTenantOfferings();
    }
    //eslint-disable-next-line
  }, [userData]);

  return (
    <div className={"service-panels-container"}>
      {servicePanelConfig.map((config, index) => {
        return <ServicePanel key={index} {...config} />;
      })}
    </div>
  );
};
export default ServicePanels;
