import React from 'react';
import {capitalize} from "../../../util/format";
import '../../../styles/components/D3/LineChart/Legend.scss';

const LineChartLegend = ({colors, position = 'left', data}) => {
  return (
    <div className={`legend-container ${position}`}>
      {data.map((value, i) =>
        <div className={'legend-value'} key={i}>
          <div className={'svg-rect-container'}>
            <svg width={10} height={10}>
              <rect width="10" height="10" style={{"fill": colors[value.name]}}/>
            </svg>
            <span className={"legend-text-value"}>{capitalize(value.name).replace(/_/g, ' ')}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default LineChartLegend;
