import React from 'react';
import '../../styles/components/Dropdown/GenericCheckbox.scss';
import CaretDown from '../../assets/icons/caret-down.svg';
import CaretDownRed from '../../assets/icons/caret-down-red.svg';
import CaretDownDisabled from '../../assets/icons/caret-down-disabled.svg';
import {useOnClickOutside} from "../../util/clickOutside";

const Dropdown = ({
                    className,
                    name,
                    dropdownList,
                    selectItem,
                    selectedItem,
                    showDropdown,
                    setShowDropdown,
                    hangRight=false,
                    useList=false,
                    caretColor='default'
                  }) => {

  let domNode = useOnClickOutside((e) => setShowDropdown(false, e));

  const caretColors = {
    'disabled': CaretDownDisabled,
    'error': CaretDownRed,
    'default': CaretDown
  }

  return (
    <div className={className + " dropdown-container " + (showDropdown ? 'open' : '')} ref={domNode}>
      <div className={"dropdown-title"} onClick={(e) => setShowDropdown(!showDropdown, e)}>
        <span className={"title-container"}>{name}</span>
        <div className={`caret-container ${hangRight}`}>
          <img className={"caret"} src={caretColors[caretColor] || CaretDown} alt={name}/>
        </div>
      </div>
      {showDropdown && !useList && dropdownList.map((item, index) => (
        <div className={"list-container"} onClick={(e) => selectItem(Object.keys(item)[0], e)} key={index}>
          {Object.keys(item)[0] === '' ? <hr/> :
            <p className={"list-item " + (selectedItem === Object.keys(item)[0] ? 'selected' : '')}>
              {Object.keys(item)}
            </p>}
        </div>)
      )}
      {showDropdown && useList && dropdownList.map((item, index) => (
        <div className={"list-container"} onClick={(e) => selectItem(item, e)} key={index}>
          <p className={"list-item " + (selectedItem === item ? 'selected' : '')}>
            {item}
          </p>
        </div>)
      )}
    </div>
  )
}

export default Dropdown;
