import React, {useEffect, useRef, useState} from 'react';
import * as d3 from "d3";
import '../../../styles/components/D3/LineChart/LineChart.scss';

const LineChart = ({
                     data,
                     tickValues,
                     className,
                     axis,
                     height,
                     widthOffset,
                     marginLeft,
                     colors,
                     lineData,
                     yAxisTicks,
                     xAxisTicks,
                     plotRadius,
                     width,
                     position = 'left',
                     timeFormat = "%b",
                     numberFormat,
                     contractedAmount,
                     lockWidth = false,
                     useLogScale = false
                   }) => {
  const svgRef = useRef()
  const [chartWidth, setChartWidth] = useState(width)

  useEffect(() => {
    const resize = () => {
      let chartWidth = width
      let clientWidth = document.body.clientWidth;
      //handle min width of 1680px
      if (clientWidth > 1680) chartWidth = clientWidth - (clientWidth * widthOffset)
      setChartWidth(chartWidth)
    }
    resize()
    !lockWidth && window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
    // eslint-disable-next-line
  }, []);

  const createSVG = () => {
    //remove duplicates
    d3.selectAll(`.${className}`).remove();

    /* Scale */
    let yScale = useLogScale
      ? d3
        .scaleLog()
        .base(1.2)
        .clamp(true)
        .domain([1, axis.y.max])
        .range([height - marginLeft, 0])
      : d3
        .scaleLinear()
        .domain([axis.y.min, axis.y.max])
        .range([height - marginLeft, 0]);


    let xScale = d3
      .scaleTime()
      .domain([axis.x.min, axis.x.max])
      .range([0, chartWidth - marginLeft]);

    /* Add SVG */
    let svg = d3
      .select(svgRef.current)
      .append("svg")
      .attr("class", `${className} line-chart`)
      .attr("width", chartWidth + marginLeft + "px")
      .attr("height", height + marginLeft + "px")
      .append("g")
      .attr("transform", `translate(${marginLeft}, ${marginLeft})`);

    /* Add area into SVG */
    data.forEach((item) => {
      svg
        .append("path")
        .datum(item.values)
        .attr("class", "area")
        .attr("fill", "rgba(255, 255, 255, 0.1)")
        .attr("stroke", "red")
        .attr("stroke-width", 0)
        .attr("d", d3
          .area()
          .x((d) => xScale(d.date))
          .y0(yScale(0))
          .y1((d) => yScale(d.value))
        )
        .style("fill", () => item.name !== 'contracted' ? "url(#gradientFill)" : 'transparent');
    });

    /* Add linear gradient */
    let lg = svg
      .append("defs")
      .append("linearGradient")
      .attr("id", "gradientFill")
      .attr("x1", "0%")
      .attr("x2", "0%")
      .attr("y1", "0%")
      .attr("y2", "100%");
    lg.append("stop")
      .attr("offset", "0%")
      .style("stop-color", "rgba(255,255,255,0.5)")
      .style("stop-opacity", 1);

    lg.append("stop")
      .attr("offset", "100%")
      .style("stop-color", "transparent")
      .style("stop-opacity", 1);

    /* Add line into SVG */
    let line = d3
      .line()
      .x((d) => xScale(d.date))
      .y((d) => yScale(d.value));

    let lines;

    let gradientColor = (p, name) => {
      if (name === 'contracted') return 'grey'
      if (Array.isArray(p)) {
        return p[0].value >= contractedAmount ? 'red' : p[0].value < contractedAmount ? '#99E100' : "grey";
      } else {
        return parseInt(p) >= contractedAmount ? 'red' : '#99E100';
      }
    }

    if (contractedAmount !== undefined) {
      data.forEach(item => {
        lines = svg.append("g").attr("class", `lines ${item.name}`)
        let formattedData = item.values.map((p, index) => index === item.values.length - 1 ? [p] : [p, item.values[index + 1]])
        lines
          .selectAll(".line-group")
          .data(formattedData)
          .enter()
          .append("g")
          .attr("class", `line-group ${item.name}`)
          .append("path")
          .attr("class", "line")
          .attr("d", d => line(d))
          .style("opacity", lineData.lineOpacity)
          .style("stroke", d => gradientColor(d, item.name))
      })
    } else {
      lines = svg.append("g").attr("class", "lines");
      lines
        .selectAll(".line-group")
        .data(data)
        .enter()
        .append("g")
        .attr("class", event => `line-group ${event.name}`)
        .append("path")
        .attr("class", "line")
        .attr("d", d => line(d.values))
        .style("stroke", d => colors[d.name])
        .style("opacity", lineData.lineOpacity)
    }

    /* Add tooltip */
    svg
      .selectAll("line-plot")
      .data(data)
      .enter()
      .append('g')
      .style("fill", d => contractedAmount === undefined && colors[d.name])
      .style("cursor", "pointer")
      .attr("class", d => `line-plot ${d.name}`)
      .on("mouseover", (event, d) => {
        let coords = d3.pointer(event);
        let displayValue = event?.target?.__data__[0]?.value || event?.target?.__data__?.value || 0
        d3.selectAll(".line-plot").style("opacity", lineData.otherLinesOpacityHover);
        d3.select(`.line-plot.${d.name}`)
          .style("opacity", lineData.lineOpacityHover)
          .attr("r", plotRadius + 1)
          .style("stroke-width", lineData.lineStrokeHover + 1)
          .style("cursor", "pointer");

        if (numberFormat) {
          let formatValue = d3.format(numberFormat)
          displayValue = formatValue(displayValue)
        }

        d3.selectAll(".line-group").style("opacity", lineData.otherLinesOpacityHover);
        d3.selectAll(`.line-group.${d.name}`)
          .style("opacity", lineData.lineOpacityHover)
          .style("stroke-width", lineData.lineStrokeHover)
        svg
          .append("text")
          .attr("class", "title-text")
          .style("fill", contractedAmount !== undefined ? gradientColor(displayValue, d.name) : colors[d.name])
          .text(`${displayValue}`)
          .attr("text-anchor", "middle")
          .attr("x", coords[0])
          .attr("y", coords[1] - 20);
      })
      .on("mouseout", (event, d) => {
        d3.selectAll(".line-plot").style("opacity", lineData.lineOpacity);
        d3.select(this)
          .style("stroke-width", lineData.lineStroke)
          .style("cursor", "none");

        d3.selectAll(".line-group").style("opacity", lineData.lineOpacity);
        d3.select(`.line-group.${d.name}`).style("stroke-width", lineData.lineStroke)
        svg.select(".title-text").remove();
      })
      .selectAll(`.line-plot`)
      .data(d => d.values)
      .enter()
      .append("circle")
      .attr("cx", d => xScale(d.date))
      .attr("cy", d => yScale(d.value))
      .attr("r", plotRadius)
      .attr("fill", d => contractedAmount !== undefined && gradientColor(d.value, ''))

    let contractedTest = svg.selectAll('.line-plot.contracted').selectChildren()
    contractedTest.attr("fill", "grey")

    /* Add Axis into SVG */
    let xAxisTickValues = data[0].values.map(d => d.date)
    let xAxis = d3
      .axisBottom(xScale)
      .tickValues(tickValues !== undefined ? tickValues : xAxisTickValues)
      .ticks(xAxisTicks)
      .tickFormat(d3.timeFormat(timeFormat));

    let formatter = Intl.NumberFormat('en', {notation: 'compact'});
    // Calculate tick values
    let min = axis.y.min
    let max = Math.ceil(axis.y.max / 10) * 10;
    let stepValue = (max - min) / (yAxisTicks - 1)
    let defaultTickValues = useLogScale
      ? [0, ...yScale.ticks(yAxisTicks).slice(1)]
      : d3.range(min, max + stepValue, stepValue);

    let yAxis = d3
      .axisLeft(yScale)
      .tickValues(defaultTickValues)
      .ticks(yAxisTicks)
      .tickFormat((d) => formatter.format(d))

    svg
      .append("g")
      .attr("class", "x axis")
      .attr("transform", `translate(0, ${height - marginLeft})`)
      .call(xAxis);

    svg
      .append("g")
      .attr("class", "y axis")
      .call(yAxis)
      .append("text")
      .attr("y", 15)
      .attr("transform", "rotate(-90)")
      .attr("fill", "#fff");
  }

  useEffect(() => {
    createSVG()
    // eslint-disable-next-line
  }, [data, chartWidth])

  return (
    <div className={`trend-graph ${position}`}>
      <div id="svg" ref={svgRef}/>
    </div>
  )
}

export default LineChart;