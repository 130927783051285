import React from 'react';
import '../../styles/pages/Vulnerabilities/DataList.scss';
import GreyArrowUp from '../../assets/icons/grey-arrow-up.svg';
import GreyArrowDown from '../../assets/icons/grey-arrow-down.svg';
import GreenArrowDown from '../../assets/icons/green-arrow-down.svg';
import YellowArrowUp from '../../assets/icons/yellow-arrow-up.svg';
import {formatPercentage, getTrendDirection, displayValue} from "../../util/format";

const DataList = ({data, metricsExpanded=false}) => {
  const percentChange = formatPercentage(data.percent_change_from_last_scan)

  return (
    <div className={`data-list-container ${metricsExpanded}`}>
      <span className={`table-title ${metricsExpanded}`}>TRENDS</span>
      <table className="table-container">
        <tbody>
          <tr className={"data-table-row"}>
            <td className={`data-table-cell ${metricsExpanded}`}>Change from previous scan</td>
            <td
              className={"amount " + getTrendDirection(data.previous_scan_total, data.current_scan_total, 'green', 'yellow') + ` ${metricsExpanded}`}>
              {percentChange !== null ? `${percentChange}%` : 'No data'}
              {(percentChange !== null && percentChange !== 0) && <img
                className={"arrow"}
                alt={"critical"}
                src={getTrendDirection(data.previous_scan_total, data.current_scan_total, GreenArrowDown, YellowArrowUp)}
              />}
            </td>
          </tr>
          <tr className={"data-table-row"}>
            <td className={`data-table-cell ${metricsExpanded}`}>Average vulnerabilities per IP</td>
            <td
              className={"amount " + getTrendDirection(data.previous_scan_average, data.current_scan_average, 'green', 'yellow') + ` ${metricsExpanded}`}>
              {displayValue(data.current_scan_average)}
              {(data.previous_scan_average !== null && Math.round(data.current_scan_average) !== 0) && <img
                className={"arrow"}
                alt={"average"}
                src={getTrendDirection(data.previous_scan_average, data.current_scan_average, GreenArrowDown, YellowArrowUp)}
              />}
            </td>
        </tr>
        {data?.severities && Object.entries(data.severities).map(([name, value], index) => {
          return <tr key={index} className={"data-table-row"}>
            <td>Total <span className={name.toLowerCase()}>{name}</span> in the last scan</td>
            <td className={"amount severity"}> {displayValue(value.current)}
              {(value.previous !== null && value.previous !== value.current) &&
                <img
                className={"arrow"}
                alt={"critical"}
                src={getTrendDirection(value.previous, value.current, GreyArrowDown, GreyArrowUp)}
              />}
            </td>
          </tr>
        })}
        </tbody>
      </table>
    </div>
  )
}

export default DataList;