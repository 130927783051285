import "../../../styles/pages/Onboarding/Firewall/FirewallTable.scss";
import React, {useContext, useEffect, useState} from "react";
import DenseTable from "../../../components/Table/DenseTable";
import {sortObjects} from "../../../util/sortObjects";
import NotFound from "../../../navigation/NotFound";
import FirewallTableRow from "./FirewallTableRow";
import FirewallTableHeader from "./FirewallTableHeader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UserData from "../../../store/User/UserData";

const FirewallTable = ({loading, tableData, setTableData, fetchError, getData, setCurrentModal, deleteAccountHandler}) => {
  const [userData] = useContext(UserData);
  const [sortBy, setSortBy] = useState();
  const [sortOrder, setSortOrder] = useState("asc");
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [, setRowError] = useState();
  const [pendingRow, setPendingRow] = useState(() => ({
    row: {},
    action: null,
  }));
  const actionMenuConfig = [
    {label: "Delete", function: () => deleteAccountHandler(selectedRows)},
  ];

  useEffect(() => {
    setTableData(sortObjects(tableData, sortBy, sortOrder));
    // eslint-disable-next-line
  }, [sortBy, sortOrder]);

  const resetPendingStage = () => {
    setRowError();
    setPendingRow({row: {}, action: null});
  };

  const handleActionMenu = (setModal) => {
    resetPendingStage();
    selectedRows.length && setModal(true);
    setShowActionMenu(false);
  };

  const handlePendingRow = (row, action) => {
    setPendingRow({ row: row, action: action });
  };

  useEffect(() => {
    userData?.tenant && getData()
    // eslint-disable-next-line
  }, [userData?.tenant])

  return (
    <>
      {!loading && fetchError ?
        <NotFound isError dataError className="panel modal"/> :
        <>
          <div className="actions-container">
          <span
            className="selection"
            onClick={() => setShowActionMenu(!showActionMenu)}
          >
            Actions <KeyboardArrowDownIcon className="down-arrow"/>
          </span>
            {showActionMenu && (
              <ul className="options" onMouseLeave={() => setShowActionMenu(false)}>
                {actionMenuConfig.map((a) =>
                  <li className="option" onClick={() => handleActionMenu(a.function)} key={a.label}>{a.label}</li>
                )}
              </ul>
            )}
          </div>
        <DenseTable
          className={"firewall"}
          loading={loading}
          header={<FirewallTableHeader
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
          />}
          noDataMessage={"No firewalls added yet."}
          body={<FirewallTableRow
            data={tableData}
            setPendingRow={handlePendingRow}
            pendingRow={pendingRow}
            resetPendingStage={resetPendingStage}
            setCurrentModal={setCurrentModal}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
          />}
        />
        </>}
    </>
  );
}

export default FirewallTable;
