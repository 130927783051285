import "../../../styles/pages/Onboarding/VirtualMachine/CopyModal.scss";
import React from "react";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const CopyModal = ({stringCopied, setStringCopied, value, title}) => {
  return (
    <p className="copy-container">
      <p className="title">{title}</p>
      <span>{`${value}`}</span>
      <IconButton
        onClick={() => {
          navigator.clipboard.writeText(value);
          setStringCopied(true);
        }}
        className="copy-btn"
      >
        <ContentCopyIcon
          className={`copy-icon ${stringCopied && "copied"}`}
        />
      </IconButton>
    </p>
  );
}

export default CopyModal;
