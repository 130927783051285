import React from 'react';
import '../../styles/pages/Hygiene/DataList.scss';
import GreenArrowUp from '../../assets/icons/green-arrow-up.svg';
import GreyArrowUp from '../../assets/icons/grey-arrow-down.svg';
import YellowArrowDown from '../../assets/icons/yellow-arrow-down.svg';
import GreyArrowDown from '../../assets/icons/grey-arrow-up.svg';
import {formatPercentage, getTrendDirection, displayValue, formatTime, formatDate} from "../../util/format";

const DataList = ({data, metricsExpanded=false}) => {
  const percentChange = formatPercentage(data.score.current)

  function getStatusMapping() {
    return {
      'PASS': 'Passed',
      'FAIL' : 'Failed',
      'WARNING': 'Warning',
      'INFO': 'Informational'
    }
  }


  return (
    <div className={`data-list-container-hygiene ${metricsExpanded}`}>
      <span className={`table-title ${metricsExpanded}`}>TRENDS</span>
      <table className="table-container">
        <tbody>
        <tr className={"data-table-row"}>
            <td className={`data-table-cell ${metricsExpanded}`}>Score</td>
            <td
              className={"amount " + getTrendDirection(data.score.previous, data.score.current, 'yellow', 'green') + ` ${metricsExpanded}`}>
              {percentChange !== null ? `${percentChange}%` : 'No data'}
              {(percentChange !== null && percentChange !== 0) && <img
                className={"arrow"}
                alt={"critical"}
                src={getTrendDirection(data.score.previous, data.score.current, YellowArrowDown, GreenArrowUp)}
              />}
            </td>
          </tr>
          <tr className={"data-table-row"}>
            <td className={`data-table-cell ${metricsExpanded}`}>Total Resources</td>
            <td
              className={"amount"}>
              {data.total_resources ? `${displayValue(data.total_resources.current)}` : 'No data'}
            </td>
          </tr>
          {data?.total_status && Object.entries(getStatusMapping()).map(([name, value], index) => {
            let status = data?.total_status[name]
            return <tr key={index} className={"data-table-row"}>
                <td>Total<span className={value.toLowerCase()}> {value}</span> in the last scan</td>
                <td className={"amount status"}> {displayValue(status.current)}
                {(status.previous !== null && status.previous !== status.current) &&
                    <img
                    className={"arrow"}
                    alt={"critical"}
                    src={getTrendDirection(status.previous, status.current, GreyArrowDown, GreyArrowUp)}
                />}
                </td>
              </tr>
        })}
        <tr className={"data-table-row"}>
            <td className={`data-table-cell ${metricsExpanded}`}>Checks Executed</td>
            <td
              className={"amount"}>
              {data.total_checks_executed !== null ? `${displayValue(data.total_checks_executed.current)}` : 'No data'}
            </td>
          </tr>
          <tr className={"data-table-row"}>
            <td className={`data-table-cell ${metricsExpanded}`}>Scan Date</td>
            <td
              className={"amount"}>
              {data.scan_date ? `${formatDate(formatTime(data.scan_date))}` : 'No data'}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default DataList;