export const leftColumn = {}
export const rightColumn = {}

export const mainRow = {
	"Time": {"field": "created_date", "header": "TIME", "sort": true, "defaultSortValue": false},
	"Name": {"field": "case_number", "header": "SUBJECT", "sort": true, "defaultSortValue": false},
	"Priority": {"field": "priority", "header": "PRIORITY", "sort": true, "defaultSortValue": false},
	"Submitted by": {"field": "email", "header": "SUBMITTED BY", "sort": true, "defaultSortValue": false},
	"Status": {"field": "status", "header": "STATUS", "sort": true, "defaultSortValue": false}
}
