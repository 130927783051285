import Windows from '../../Connections/config/icons/windows-logo.svg';
import Apple from '../../Connections/config/icons/apple-logo.svg';
import Linux from '../../Connections/config/icons/linux-logo.svg';
import Firewall from '../../Connections/config/icons/firewall1-logo.svg';
import Log from '../../Connections/config/icons/log-logo.svg';
import AWS from '../../../assets/logos/aws.svg';
import Identity from '../../../assets/logos/identity.svg';
import Okta from '../../../assets/logos/okta.svg';
import GSuite from '../../../assets/logos/gsuite.svg';
import O365 from '../../../assets/logos/o365.svg';
import Salesforce from '../../../assets/logos/salesforce.svg';
import Android from '../../Connections/config/icons/android-logo.svg';
import IOS from '../../Connections/config/icons/ios-logo.svg';
import Cloud from '../../Connections/config/icons/cloud-logo.svg';
import EVM from '../../Connections/config/icons/evm-icon.svg';
import DeviceControl from '../../Connections/config/icons/device-control-icon.svg';

export const iconMap = {
  "windows": Windows,
  "mac": Apple,
  "linux": Linux,
  "firewall": Firewall,
  "log": Log,
  "aws": AWS,
  "gsuite": GSuite,
  "o365": O365,
  "salesforce": Salesforce,
  "android": Android,
  "ios": IOS,
  "cloud": Cloud,
  "okta": Okta,
  "identity": Identity,
  "evm": EVM,
  "device_control": DeviceControl
}

export const mapIcons = (name) => {
  return iconMap[name]
}
