import React, {useEffect} from "react";
import '../../styles/pages/Vulnerabilities2/CommentModal.scss';
import {Modal} from "../../components";
import {Loader} from "../../components/Loader/Loader";

const CommentModal = ({cancel, type, setGlobalComment, globalComment, submit, loading, existingComment, setOpenCommentModal}) => {
  const commentMessage = type === 'Edit Comments'
    ? "EDITS TO THIS COMMENT WILL BE APPLIED TO ALL SELECTED VULNERABILITIES"
    : "THIS COMMENT WILL BE ADDED TO ALL SELECTED VULNERABILITIES";
  const buttonText = type === 'Edit Comments' ? "UPDATE" : "CONFIRM AND APPLY";

  useEffect(()=>{
    type === 'Edit Comments' ? setGlobalComment(existingComment) : setGlobalComment('')
  },[existingComment, type, setGlobalComment])

  return (
    <Modal className="bulk-comment-container new" close={cancel}>
      <p className="input-label">Bulk {type}</p>
      {loading && <Loader/>}
      <textarea
        value={globalComment}
        className="input-box notes"
        onChange={(e) => setGlobalComment(e.target.value)}
        placeholder="Enter comment here"
      />
      <div className="flex comment-text">
        <p>{commentMessage}</p>
        <div className="add-btn back" onClick={() => setOpenCommentModal(false)}>BACK</div>
        <div className="add-btn" onClick={async () => await submit(globalComment)}>{buttonText}</div>
      </div>
    </Modal>
  );
};

export default CommentModal;
