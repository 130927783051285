import React, {useContext, useEffect, useState} from 'react';
import '../../styles/pages/Connections/Firewall.scss';
import {abbreviateNumber, formatTimeAgo, formatTime} from "../../util/format";
import {mapIcons} from "./config/iconMap";
import UserData from "../../store/User/UserData";
import {getFirewallConnections} from "../../api/ApiClient";
import {tooltipMapping} from "../../config/tooltipTextMapping";
import Tooltip from "../../components/Tooltip/Tooltip";
import ConnectionsLineChart from "./ConnectionsLineChart";
import {Loader} from "../../components/Loader/Loader";
import ConnectionsModal from "../../components/Modal/ConnectionsModal";
import { hasAdminAccess } from "../../util/userControl"
import DisabledChart from "./DisabledChart";
import MailButton from "../../components/MailButton/MailButton";
import NotFound from "../../navigation/NotFound";
import { captureSentryError } from '../../util/sentry';

const FirewallConnection = ({onInvestigationClick, isMDRLite, isMobileOnly, createEmail}) => {
  const [userData] = useContext(UserData);
  const [connectionsData, setConnectionsData] = useState({trend: {data: []}})
  const [firewallLoading, setFirewallLoading] = useState(false);
  const [colors, setColors] = useState({})
  const [modalOpen, setModalOpen] = useState('')
  const [count, setCount] = useState(0);
  const mapValues = {
    "Last Seen": {field: 'last_seen'},
    "Events": {field: 'total_events'},
    "Investigations": {field: 'investigation_count'},
    "Last Monthly Scan": {field: "last_monthly_scan"},
    "Most Recent Scan": {field: "most_recent_scan"}
  }
  const [fetchError, setFetchError] = useState(false);

  useEffect(() => {
    const getConnections = async () => {
      setFetchError(false);
      setFirewallLoading(true)
      try {
        let connectionsResponse = await getFirewallConnections(userData?.tenant)
        let name = 'Network_event_count_per_day'
        let color = connectionsResponse?.status && connectionsResponse?.status === 'green' ? "#99E100" : "grey"
        setColors({[name]: color})
        connectionsResponse.trend.data[name] = connectionsResponse.trend.data[Object.keys(connectionsResponse.trend.data)[0]]
        delete connectionsResponse.trend.data[Object.keys(connectionsResponse.trend.data)[0]];
        connectionsResponse.firewalls && setCount(abbreviateNumber(connectionsResponse.firewalls.length, 0))
        setConnectionsData(connectionsResponse)
      } catch (e) {
        captureSentryError(e, userData, "getFirewallConnections API in Firewall.js");
        setFetchError(true);
      } finally {
        setFirewallLoading(false)
      }
    }
    userData?.tenant && (!isMDRLite && !isMobileOnly) && getConnections()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.tenant, isMobileOnly, isMDRLite]);

  const formatScanDate = (value) => value ? formatTime(value, false) : "-";

  return (
    <tr className={"connections-row firewall"}>
      {!firewallLoading ? 
      fetchError ? <NotFound isError dataError className="panel service" /> : 
      <>
        <td className={"connection-type"}>
          <Tooltip content={tooltipMapping.serviceStatus.firewall} className={"title-tooltip"} direction={"right"}>
            <p className={"is-connected" + (isMobileOnly || isMDRLite ? ' disabled' : '')}>NETWORK</p>
          </Tooltip>
          {connectionsData?.firewalls &&
          <p className={"count" + (isMobileOnly || isMDRLite ? ' disabled' : '')}>
            {(isMDRLite || isMobileOnly) ? '' : count}</p>}
          {(isMobileOnly || isMDRLite) && hasAdminAccess(userData?.role) &&
          <MailButton label={"ADD SERVICE »"} mailto={createEmail('firewall')}/>}
        </td>
        <td>
          <span className={"circle " + (!count ? "grey" : connectionsData?.status)}/>
        </td>
        <td className={"service-icons" + (isMobileOnly || isMDRLite ? ' disabled' : '')}>
          <img alt={'firewall'} src={mapIcons('firewall'.toLowerCase())}/>
          <div className={"new-firewalls-container"}>
            {connectionsData?.new_firewalls > 0 &&
            <p className={"value new-firewalls"}>{connectionsData?.new_firewalls} added in the last month</p>}
            {connectionsData?.firewalls?.length > 0 &&
            <p className={"name modal-btn new-firewalls"} onClick={() => setModalOpen('firewall-list')}>
              NETWORK DEVICE LIST »</p>}
            {modalOpen === 'firewall-list' &&
            <ConnectionsModal close={setModalOpen} title={"Network Device List"}/>}
          </div>
        </td>
        <td className={"connection-data"}>
          {Object.entries(mapValues).map((elem, index) => {
            let field = elem[1].field
            if(field === "most_recent_scan" && connectionsData["most_recent_scan"] === connectionsData["last_monthly_scan"]) return <></>;
            let count = connectionsData[field]
            let value = field === 'last_seen' ? formatTimeAgo(connectionsData[field]) : field === "last_monthly_scan" || field === "most_recent_scan" ? formatScanDate(connectionsData[field]) : abbreviateNumber(connectionsData[field], 0)
            let hasInvestigationLink = elem[0] === "Investigations" && count > 0
            return <div key={index} className={"display-values " + elem[0] + (hasInvestigationLink ? ' pointer' : '')}>
              <p className={"name"}>{elem[0]}</p>
              <p
                className={"value " + (hasInvestigationLink ? ' link' : '')}
                onClick={() => hasInvestigationLink && onInvestigationClick('Network')}
              >{(!isMobileOnly && !isMDRLite) ? value : ''}</p>
            </div>
          })}
        </td>
        <td className={"chart-container" + (isMobileOnly || isMDRLite ? ' disabled' : '')}>
          {connectionsData?.trend?.data && (!isMobileOnly && !isMDRLite) ?
            <ConnectionsLineChart
              numberFormat={".2s"}
              displayName={'firewall'}
              data={connectionsData.trend.data}
              colors={colors}
            /> : (isMobileOnly || isMDRLite) &&
            <DisabledChart
              data={[{name: 'Network_IP_Count', values: []}]}
              colors={{Network_IP_Count: 'green'}}
            />
          }
        </td>
      </> : <td><Loader/></td>}
    </tr>
  )
}

export default FirewallConnection;
