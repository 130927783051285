import "../../styles/pages/FileStorage/FileStorage.scss";
import React, { useState, useEffect, useContext } from "react";
import DocumentIcon from "../../assets/icons/document-icon.svg";
import DownloadIcon from "../../assets/icons/download-icon.svg";
import TrashIcon from "../../assets/icons/trash-icon.svg";
import IconButton from "@mui/material/IconButton";
import UploadModal from "./UploadModal";
import { getFiles, deleteFile } from "../../api/ApiClient";
import UserData from "../../store/User/UserData";
import GlobalState from "../../store/GlobalState/GlobalState";
import _ from "lodash";
import { trackClick, trackPageView } from "../../util/analytics";
import AscSorting from "../../assets/icons/triangle-up.svg";
import DescSorting from "../../assets/icons/triangle-down.svg";
import { formatTime } from "../../util/format";
import { hasWriteAccess } from "../../util/userControl";
import DenseTable from "../../components/Table/DenseTable";
import ConfirmDialogCell from "../../components/Table/ConfirmDialogCell";
import NotFound from "../../navigation/NotFound";
import GenerateReportButton from "./GenerateReportButton";
import { isAZUser } from "../../util/userControl";
import { captureSentryError } from "../../util/sentry";

const tableHeaders = ["name", "date", "creator_email", "tags"];

const FileStorage = () => {
  const [azState] = useContext(GlobalState);
  const [userData] = useContext(UserData);
  const [fileData, setFileData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [sortBy, setSortBy] = useState("date");
  const [sortOrder, setSortOrder] = useState("asc");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [tagFilter, setTagFilter] = useState();
  const [loading, setLoading] = useState(true);
  const [pendingDelete, setPendingDelete] = useState();
  const [fetchError, setFetchError] = useState(false);
  const isAZ = isAZUser(userData);

  useEffect(
    () => trackPageView("File Storage", userData, azState),
    // eslint-disable-next-line
    [userData?.userId, azState.reactGaInitializedState]
  );

  useEffect(() => {
    async function getFileData() {
      try {
        setFetchError(false);
        setLoading(true);
        const { results } = await getFiles(userData?.tenant);
        setFileData(results);
        setLoading(false);
      } catch (e) {
        captureSentryError(e, userData, "GET files API in FileStorage.js");
        setFetchError(true);
      }
    }
    !showUploadModal && getFileData();
    // eslint-disable-next-line
  }, [userData?.tenant, showUploadModal]);

  useEffect(() => {
    const sortFiles = (sortBy, order, data) => {
      if (sortBy === "name") sortBy = "file_name";
      data.sort((a, b) => {
        if (order === "asc") {
          let temp = a;
          a = b;
          b = temp;
        }
        return a[sortBy]
          .toString()
          .toLowerCase()
          .replace(/\s+/g, "")
          .localeCompare(
            b[sortBy].toString().toLowerCase().replace(/\s+/g, ""),
            undefined,
            { numeric: true, sensitivity: "base" }
          );
      });

      return data;
    };

    let updateData = [...fileData];
    if (fileData.length) {
      if (tagFilter)
        updateData = updateData.filter((file) => file.tags.includes(tagFilter));
      updateData = sortFiles(sortBy, sortOrder, updateData);
      setFilteredData(updateData);
    }
  }, [sortBy, sortOrder, tagFilter, fileData]);

  const handleDeleteFile = async (file) => {
    try {
      setFileData((prevState) =>
        prevState.filter(
          (f) => !(f.file_name === file.file_name && f.date === file.date)
        )
      );
      await deleteFile(userData?.tenant, {
        file_name: file.file_name,
        path: file.path,
      });
      trackClick(userData, `deleted file ${file.file_name}`, "click");
    } catch (e) {
      captureSentryError(e, userData, "DELETE file API in FileStorage.js");
    }
  };

  const toggleSortOrder = () =>
    sortOrder === "asc" ? setSortOrder("desc") : setSortOrder("asc");

  const generateContent = (col, row) => {
    let retFunctions = {
      tags: () =>
        row[col].map((tag) => (
          <span
            className="tag-filter"
            onClick={() => setTagFilter(tag)}
            key={_.uniqueId()}
          >
            {tag}
          </span>
        )),
      date: () => formatTime(row[col]),
      creator_email: () => row["creator_email"],
      name: () => row["file_name"],
    };

    return retFunctions[col]();
  };

  const generateHeaderRow = () => 
    <>
      <td className="table-column icon" />
      {tableHeaders.map((col) => {
        return (
          <td className={`table-column ${col}`} key={_.uniqueId()}>
            <div
              onClick={() => {
                sortBy === col ? toggleSortOrder() : setSortBy(col);
              }}
              className={
                "clickable" + (sortBy === col ? " active" : "")
              }
            >
              {col.replace("_", " ").toUpperCase()}
              <span className="sort-icon">
                {sortBy === col && sortOrder === "asc" ? (
                  <img className={"asc"} src={AscSorting} alt="" />
                ) : sortBy === col && sortOrder === "desc" ? (
                  <img src={DescSorting} alt="" />
                ) : null}
              </span>
            </div>
          </td>
        );
      })}
      <td className="table-column buttons" />
    </>

  const generateBodyRows = () => 
    filteredData.map((row) => {
      return (
        <tr key={`${row.name}${row.date}`}>
          <td className="file-icon-cell">
            <div className="file-icon">
              <img src={DocumentIcon} alt="File" />
            </div>
          </td>
          {tableHeaders.map((col) => {
            return (
              <td key={_.uniqueId()}>{generateContent(col, row)}</td>
            );
          })}
          {pendingDelete?.name === row.file_name &&
          pendingDelete?.date === row.date ? (
              <ConfirmDialogCell 
                message={"Confirm?"}
                confirmFunc={() => handleDeleteFile(row)}
                cancelFunc={setPendingDelete}
              />
          ) : (
            <td className="icon-container">
              <IconButton
                target="_blank"
                rel="noreferrer"
                href={row.data}
                className="dl-icon"
                onClick={() =>
                  trackClick(userData, 
                    `downloaded file ${row.file_name}`,
                    "click"
                  )
                }
              >
                <img src={DownloadIcon} alt="Download" className="download-btn"/>
              </IconButton>
              {hasWriteAccess(userData?.role) && <IconButton
                onClick={() =>
                  setPendingDelete({
                    name: row.file_name,
                    date: row.date,
                  })
                }
              >
                <img src={TrashIcon} alt="Delete" className="delete-btn"/>
              </IconButton>}
            </td>
          )}
        </tr>
      );
    })



  return (
    <div className={"file-storage-container"}>
      <UploadModal
        isOpen={showUploadModal}
        setOpen={setShowUploadModal}
        userData={userData}
      />
      <div className={"header-row"}>
        <p className={"page-title"}>FILE STORAGE</p>
      </div>
      <div className="page-content">
        {hasWriteAccess(userData?.role) && <IconButton
          className="upload-btn-container"
          onClick={() => setShowUploadModal(true)}
        >
          <div className="files-button">{"UPLOAD >>"}</div>
        </IconButton>}
        <div className="folder-path">
          <span className="root-title">DRIVE</span>
        </div>
        {isAZ && <GenerateReportButton/>}
        {tagFilter && (
          <div className="tag-filter-container">
            <span className="tag-filter">
              {tagFilter}
              <button onClick={() => setTagFilter()}>x</button>
            </span>
          </div>
        )}
        {!loading && fetchError ? (
          <NotFound isError dataError className="panel"/>
        ) : (
          <DenseTable
            loading={loading}
            header={generateHeaderRow()}
            body={generateBodyRows()}
            noDataMessage={"No files to view."}
          />
        )}
      </div>
    </div>
  );
};

export default FileStorage;
