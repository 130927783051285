import React from "react";
import "../../styles/pages/Dashboard/NoAlertsPanel.scss";
import {abbreviateNumber} from "../../util/format";

const NoAlertsPanel = ({data}) => {
  return (
    <div className={"no-alerts-container"}>
      <div className={"triangle"}/>
      <div className={"no-alerts-text"}>
        <p className={"yellow-text medium-text"}>Good news, no alerts in the last 30 days</p>
        <p className={"small-text"}>
          STATUS: ActZero technology is active and protecting your system
        </p>
      </div>
      <div className={"flex data-container"}>
        {data.map((item, i) => {
            let itemValue = item.value > 999 ? abbreviateNumber(item.value, 2) : item.value
            return (<div key={i} className={"data-item"}>
              <p
                className={`${item.name.toLowerCase()}-count large-value`}>{itemValue || 0}</p>
              <p className={"value-title"}>{item.name}</p>
            </div>)
          }
        )}
      </div>
    </div>
  )
};

export default NoAlertsPanel;
