import React from "react";
import TimeSlider from "../../components/D3/TimeSlider/TimeSlider";
import '../../styles/pages/KnowledgeGraph/GraphControls.scss';

const GraphControls = ({setEndTime, setStartTime, tenant}) => {
  return (
    <div className={"graph-controls-container"}>
      <TimeSlider width={550} marginLeft={50} title={"Start time:"} setTime={setStartTime} name={'start'} tenant={tenant}/>
      <TimeSlider width={550} marginLeft={50} title={"End time:"} setTime={setEndTime} name={'end'} tenant={tenant}/>
    </div>
  );
}

export default GraphControls;
