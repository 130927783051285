import React from "react";
import {Modal} from "../../../../components";
import '../../../../styles/pages/Onboarding/Cloud/AddingAccountModal.scss';
import parse from "html-react-parser";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from "@mui/icons-material/Close";
import RadioSelection from "./RadioSelection.js";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import IconButton from "@mui/material/IconButton";
import {cloudInputConfigs} from "../config";

const mapIcons = {
  success: ['Added a new integration ', 'Tested newly added integration ', 'Automatic security incident response has been enabled'],
  error: ['Testing newly added integration. ', 'Adding a new integration. ', 'Second test of the newly added integration failed. ', 'Retried to add new integration. ']
}

const responseData = {
  title: "Incident Response Options for Your O365 Infrastructure",
  description: "ActZero offers an automatic security incident response for certain O365 detections.",
  options: cloudInputConfigs['O365']['responseConfigOptions']
}

const AddAccountModal = ({messages, error, cancelFunc, submitFunc, showResponseData=false, handleRadioSelection, selectedResponse, inputValues, setInputValues, isSubmitted, setIsSubmitted, userData }) => (
  <Modal close={() => cancelFunc()} className={'adding-account-modal'}>
      {!isSubmitted && showResponseData &&
           <div className={"response-modal"}>
             <div className={"response-modal-title"}> {responseData.title}</div>
             <div className={"response-modal-note"}>Response options can be enabled <p className="bold-note">ONLY</p> if your <p className="bold-note">Azure and O365 environments</p> are hosted in the Cloud. (Hybrid and On-Prem deployment models are <p className={"bold-note"}>NOT</p> supported for this feature).
             <div className={"response-modal-note-italics"}><p className="bold-note">Note:</p> To activate the response option, please complete the necessary steps in your Azure account as detailed in the<a
                className={"clickable-link"}
                target="_blank"
                rel="noopener noreferrer"
                href={
                  "https://docs.actzero.ai/cloud-connections/connect-O365/#enable-office-365-response-actions"
                }>documentation</a></div>
           </div>
           <table>
               <tbody>
               <tr key={"response-data-row"}>
                 <td className={"response-data-row"}>
                   <RadioSelection options={responseData.options}
                      handleChange={handleRadioSelection}
                      selected={selectedResponse}
                      className="response-selection add"
                      icon={<RadioButtonUncheckedIcon/>}
                      checkedIcon={<RadioButtonCheckedIcon className={"checked-box-icon"}/>}
                   />
                 </td>
               </tr>
               </tbody>
             </table>
             <IconButton className="border-btn-container" onClick={async () => submitFunc()}>
                  <div className="border-btn">{"SUBMIT"}</div>
             </IconButton></div>}

    {(isSubmitted || !showResponseData) && messages.length > 0 &&  messages.map((message, i) =>
      <div key={i} className={"loading-text"}>{parse(message)}
        {mapIcons.success.includes(message) && <CheckIcon fontSize="small" sx={{color: '#D0F31A'}}/>}
        {mapIcons.error.includes(message) && <CloseIcon fontSize="small" sx={{color: '#D64426'}}/>}
      </div>
    )}

  </Modal>
);

export default AddAccountModal;