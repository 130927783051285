import React, {useEffect} from 'react';
import '../../../styles/components/D3/CircularGraph/CircularGraph.scss';
import * as d3 from "d3";

const CircularGraph = ({
                         data,
                         color,
                         containerName,
                         onClick = () => {},
                         xAxis,
                         percentText,
                         height = 135,
                         width = 135,
                         innerRadius = 52,
                         outerRadius = 55
                       }) => {
  let name = data.name.replace(/ .*/, '')

  useEffect(() => {
    let twoPi = 2 * Math.PI,
      progress = 0

    let arc = d3.arc()
      .startAngle(0)
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);

    //remove any duplicates
    d3.select('#' + name.replace(/\s/g, '')).selectAll("*").remove();

    let svg = d3.select('#' + name.replace(/\s/g, '')).append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    let meter = svg.append("g")
      .attr("class", "funds-allocated-meter");

    meter.append("path")
      .attr("class", "background")
      .attr("d", arc.endAngle(twoPi));

    let fillColor = meter.append("path")
      .attr("class", "fill-color")
      .style("fill", color)

    let percentComplete = meter.append("text")
      .attr("text-anchor", "middle")
      .attr("x", xAxis ? xAxis : "5")
      .attr("class", "percent-complete")
      .attr("dy", "0.3em")
      .style("fill", color)
      .on("click", () => {
        onClick()
      })

    let progressCircleValue = (data.name === 'Noise Reduction' && data.value < 0) ? 0 : data.value
    let i = d3.interpolate(progress, progressCircleValue);

    meter.transition().duration(2000).tween("progress", () => {
      return (t) => {
        progress = i(t);
        fillColor.attr("d", arc.endAngle(twoPi * progress));
        percentComplete.text(percentText ? percentText : data.stringValue);
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, color])

  return (
    <div className={`${containerName}-container`} id={`${name.replace(/\s/g, '')}`}/>
  )
}

export default CircularGraph;
