export const zeroinModalMap = {
  "aid": ["Origin", "Hostname", "id"],
  "IP": ["IP Address", "City", "Region", "Country",],
  "O365_userid": [],
  "okta_userid": [],
  "gw_userid": [],
  "Detection": [
    "Cloud Subsource",
    "Service",
    "Detection Time",
    "Detection ID",
    "Detection Type",
    "Username",
    "24 Hour Exchange Analytics",
    "24 Hour Exchange Analytics Summary",
    "24 Hour Login Analytics Summary",
    "Severity",
    "Source",
    "Origin",
    "id"
  ],
  "rdp_IP": [],
  "untracked_endpoint": [],
  "Vulnerability": [],
}

export const hiddenProperties = ["case_id", "az_event_id", "vulnerability_id", "last_observed_scan_result_id", "highly_connected_ip",
  "id", "ID", "Name", "name", "STIG Severity", "case_id", "az_event_id", "CVE", "report source", "plugin"];

export const tableTitleMap = {
  "Endpoint": ["aid", "untracked_endpoint"],
  "IP": ["IP", "highly_connected_ip"],
  "Highly connected IP": ["highly_connected_ip"],
  "RDP IP": ["rdp_IP"],
  "O365 Account": ["O365_userid"],
  "G-Workspace Account": ["gw_userid"],
  "Okta Account": ["okta_userid"],
  "Detection": ["Detection"],
  "Vulnerability": ["Vulnerability"],
}
