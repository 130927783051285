import {useEffect, useState} from "react";
export const useDebounce = (value, delay, setIsLoading) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    setIsLoading(true)
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
      setIsLoading(false)
    };
  }, [value, delay, setIsLoading]);

  return debouncedValue;
};
