import React, {useEffect, useState} from 'react';
import Tooltip from "../../components/Tooltip/Tooltip";
import '../../styles/pages/Hygiene/TopHygieneIssues.scss';

const TopHygieneIssues = ({title, data, type, handleClick}) => {
  const [tooltips, setTooltips] = useState({})
  const maxWidth = 449

  useEffect(() => {
    const expandText = () => {
      let tempTooltips = {}
      data.map((item, index) => {
        let elemValue = document.getElementsByClassName(`tooltip-issues-value ${type} ${index}`)
        // Compare length of container with length of compliance check value 
        if (elemValue.length > 0 && elemValue[0].offsetWidth > maxWidth) {
          tempTooltips[index] = true
        } else {
          tempTooltips[index] = false
        }
        return item
      })
      setTooltips(tempTooltips)
    }
    expandText()
  }, [data, type])


  return (
    <div className={"top-hygiene-issues"}>
      <p className={"title"}>{title}</p>
      <table>
        <tbody>
        {
          data && data.map((item, index) => {
            let name = Object.keys(item)[0]
            return (
              <tr className={"top-item"} key={index}>
                <td className={"top-amount"}>{item[name]}</td>
                <td className={`top-name ${type}`} onClick={() => handleClick(name, title)}>
                  {tooltips[index] ? 
                    <Tooltip content={name} direction={"top"} className={'large-tooltip'}><span className={`tooltip-issues-value ${type} ${index}`}>{name}</span></Tooltip>
                    : <span className={`tooltip-issues-value ${type} ${index}`}>{name}</span>
                  }
                </td>
              </tr>)
          })
        }
        </tbody>
      </table>
    </div>
  )
}

export default TopHygieneIssues;