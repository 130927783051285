export const filterConfig = [
  {
    Severity: {
      filter_name: "severity",
      isOpen: true,
      filters: {
        Critical: false, High: false, Medium: false, Low: false
      }
    },
  },
  {
    'Action Status': {
      filter_name: "action",
      isOpen: true,
      filters: {
        'New Risk': false,
        'Accept Risk': false,
        'Defer Risk': false,
        'Ignore': false,
        'In-Progress': false,
        'Risk Mitigated': false
      }
    }
  },
  {
    'Source': {
      filter_name: "source",
      isOpen: true,
      filters: {'Internal': false, 'External': false}
    }
  },
  {
    Exploit: {
      filter_name: "exploit",
      isOpen: true,
      filters: {'Yes': false, 'No': false}
    }
  },
  {
    New_Vulnerabilities: {
      filter_name: "new_vulnerabilities",
      isOpen: true,
      filters: {'Yes': false},
      title: 'Net New',
      tooltip: "Newly discovered vulnerabilities from the latest scan in comparison to the previous month."
    }
  }
]

export const resetFilterConfig = {
  Severity: {
    filter_name: "severity",
    isOpen: true,
    filters: {
      Critical: false, High: false, Medium: false, Low: false
    }
  },
  'Action Status': {
    filter_name: "action",
    isOpen: true,
    filters: {
      'New Risk': false,
      'Accept Risk': false,
      'Defer Risk': false,
      'Ignore': false,
      'In-Progress': false,
      'Risk Mitigated': false
    }
  },
  'Source': {
    filter_name: "source",
    isOpen: true,
    filters: {'Internal': false, 'External': false}
  },
  Exploit: {
    filter_name: "exploit",
    isOpen: true,
    filters: {'Yes': false, 'No': false}
  },
  New_Vulnerabilities: {
    title: 'Net New',
    filter_name: "new_vulnerabilities",
    isOpen: true,
    filters: {'Yes': false}
  }
}

export const passedInConfig = (type, value) => {
  let tempConfig = filterConfig
  tempConfig.map(filterObj => {
    let filterTitle = Object.keys(filterObj)[0]
    let filterValue = filterObj[filterTitle]
    if (filterValue.filter_name === type) {
      Object.keys(filterValue.filters).map(filterKey => {
        filterValue.filters[filterKey] = filterKey === value;
        return filterKey
      })
    }
    return filterObj
  })
  return tempConfig
}
