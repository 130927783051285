import React, {useContext, useEffect, useState} from 'react';
import '../../styles/pages/Hosts/Hosts.scss';
import Endpoints from "./Endpoints/Endpoints";
import Updates from "./MissingUpdates/Updates";
import {Loader} from "../../components/Loader/Loader";
import {getEndpointSummary} from "../../api/ApiClient";
import UserData from "../../store/User/UserData";
import moment from 'moment';
import GlobalState from "../../store/GlobalState/GlobalState";
import {trackPageView} from "../../util/analytics";
import Tooltip from "../../components/Tooltip/Tooltip";
import {tooltipMapping} from "../../config/tooltipTextMapping";
import { captureSentryError } from '../../util/sentry';
import { useLocation } from 'react-router-dom';

const Hosts = () => {
  const [azState] = useContext(GlobalState);
  const [userData] = useContext(UserData);
  const [missingUpdatesCount, setMissingUpdatesCount] = useState(0)
  const [endpointsCount, setEndpointsCount] = useState(0)
  const [statsLoading, setStatsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("ENDPOINTS")
  const location = useLocation();
  const defaultFilters = location.state;


  const grabActiveTabComponent = () => {
    switch (activeTab) {
      case 'MISSING UPDATES':
        return <Updates setCount={setMissingUpdatesCount}/>;
      default:
        return <Endpoints setActiveTab={setActiveTab} setCount={setEndpointsCount} defaultFilters={defaultFilters}/>;
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => trackPageView("Hosts", userData, azState), [userData?.userId, azState.reactGaInitializedState]);

  useEffect(() => {
    // Temporary API call until Stats API is implemented
    const getNumberOfHosts = async () => {
      try {
        setStatsLoading(true)
        let today = new Date();
        let priorDate = new Date(new Date().setDate(today.getDate() - 30))
        let params = {'start_time': moment(priorDate).format(), 'end_time': moment(today).format()}
        const endpointsResponse = await getEndpointSummary(userData?.tenant, params)
        setMissingUpdatesCount(endpointsResponse?.results?.missing_patches || 0)
        setEndpointsCount(endpointsResponse?.results?.endpoints || 0)
      } catch (e) {
        captureSentryError(e, userData, "getEndpointsResponse API in Hosts.js");
      } finally {
        setStatsLoading(false)
      }
    }
    userData?.tenant && getNumberOfHosts()
  }, [userData, userData?.tenant, userData?.userType]);

  return (
    <div className={"hosts-container"}>
      <div className={"header-row"}>
        <p className={"page-title"}>ENDPOINTS</p>
      </div>
      {statsLoading ?
        <div className={"loader-container"}><Loader/></div>
        :
        <div className={"hosts-tabs"}>
          <div className={"tab-nav"}>
            <div className={"hr-line first"}/>
            <div className={"tab-container"}>
              <Tooltip content={tooltipMapping.endpoints.title} className={"title-tooltip"} direction={"right"}>
                <button
                  className={"tab-button" + (activeTab === 'ENDPOINTS' ? ' active' : '')}
                  onClick={() => setActiveTab("ENDPOINTS")}>
                  <span className={"tab-value"}>{endpointsCount} </span>
                  {endpointsCount === 1 ? 'ENDPOINT' : 'ENDPOINTS'}
                </button>
              </Tooltip>
              <Tooltip content={tooltipMapping.endpoints.missing} className={"title-tooltip"} direction={"right"}>
                <button
                  className={"tab-button right" + (activeTab === 'MISSING UPDATES' ? ' active' : '')}
                  onClick={() => setActiveTab("MISSING UPDATES")}>
                <span
                  className={"tab-value"}>{missingUpdatesCount} </span>
                  {missingUpdatesCount === 1 ? 'MISSING UPDATE' : 'MISSING UPDATES'}
                </button>
              </Tooltip>
            </div>
            <div className={"hr-line second"}/>
          </div>
        </div>}
      <div className={"hosts-tables"}>{grabActiveTabComponent()}</div>
    </div>
  )
}

export default Hosts;