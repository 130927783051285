export const tenantDetailEditInputs = [
  {
    label: "Tenant ID",
    value: "tenant",
    display: "root",
    editable: false,
    type: "text",
  },
  {
    label: "Name",
    value: "friendly_name",
    editable: true,
    type: "text",
  },
  {
    label: "Is Active",
    value: "is_active",
    editable: true,
    display: "root",
    type: "toggle",
  },
  {
    label: "VM Expected",
    value: "vm_expected",
    editable: true,
    display: "root",
    type: "toggle",
  },
  {
    label: "CrowdStrike ID",
    value: "cs_cid",
    editable: true,
    display: "root",
    type: "text",
  },
  {
    label: "Stellar ID",
    value: "stellar_id",
    editable: false,
    display: "root",
    type: "text",
  },
  {
    label: "Sensor IP",
    value: "sensor_ip",
    editable: true,
    display: "root",
    type: "text",
  },
  {
    label: "Log Management",
    value: "log_management",
    display: "root",
    editable: false,
    type: "text",
  },
  {
    label: "Primary Alert Contact",
    value: "customer_dl",
    editable: true,
    type: "text",
    validation: "email",
  },
  {
    label: "Secondary Contacts",
    value: "secondary_alert_emails",
    editable: true,
    type: "text",
    tooltip: "Enter emails separated by commas.",
  }
];

export const valueMapping = {
  friendly_name: "friendly_name",
  is_active: "is_active",
  cs_cid: "crowdstrike_id",
  stellar_id: "stellar_id",
  customer_dl: "email",
  sensor_ip: "sensor_ip",
  cs_policy: "crowdstrike_policy",
  secondary_alert_emails: "secondary_emails",
  vm_expected: "vm_expected"
};
