import React, {useContext, useEffect, useState, useRef} from "react";
import '../../styles/pages/MaturityAssessment/MaturityAssessmentTable.scss'
import Table from "../../components/Table/Table";
import TableHeader from "../../components/Table/TableHeader";
import {severities} from "../../config/mappingData";
import MaturityAssessmentRow from "./MaturityAssessmentRow";
import {getMATableData} from "../../api/ApiClient";
import UserData from "../../store/User/UserData";
import {Loader} from "../../components/Loader/Loader";
import TableControls from "./TableControls";
import ExpandView from "./ExpandedView";
import {downloadFile} from "../../util/export";
import {useLocation, useNavigate} from "react-router-dom";
import GlobalState from "../../store/GlobalState/GlobalState";
import NotFound from "../../navigation/NotFound";
import _ from 'lodash';
import { captureSentryError } from "../../util/sentry";

const MaturityAssessmentTable = ({
                                   tableParams,
                                   setTableParams,
                                   sortFilters,
                                   setSortFilters,
                                   page,
                                   setPage,
                                   loadingNote,
                                   setLoadingNote,
                                   resetAllFilters,
                                 }) => {
  const [userData] = useContext(UserData);
  const [azState] = useContext(GlobalState);
  const location = useLocation();
  const navigate = useNavigate();
  const [expandedControl, setExpandedControl] = useState('');
  const statuses = [{'Incomplete': '#FFA415'},
    {'In Progress': '#018D8D'},
    {'Ready for Review': 'white'}]
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState({results: [], total: 0})
  const [exportLoading, setExportLoading] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const prevParams = useRef({});
  const headerData = {
    "Control": {"field": "control", "header": "CONTROL"},
    "Category": {"field": "category", "header": "CATEGORY", "sort": true, "defaultSortValue": false},
    "Tool": {"field": "tool", "header": "TOOL", "sort": true, "defaultSortValue": false},
    "Name": {"field": "name", "header": "NAME"},
    "Difficulty": {"field": "difficulty", "header": "DIFFICULTY"},
    "Status": {"field": "status", "header": "STATUS"},
  }

  const getTableData = async () => {
    setFetchError(false);
    let isExport = tableParams.isExport || false
    if (isExport) {
      setTableParams({...tableParams, isExport: false})
      setExportLoading(true)
    } else setLoading(true)
    try {
      if (location?.state?.toolCategoryParams?.sort_by === 'tool') {
        tableParams = location?.state?.toolCategoryParams
        setTableParams(location?.state?.toolCategoryParams)
        setSortFilters({tool: false})
      }
      isExport && setExportLoading(true);
      tableParams.page = page
      let response = await getMATableData(userData?.tenant, tableParams, isExport)
      if (isExport) {
        downloadFile(response)
        setExportLoading(false);
      } else {
        setTableData(response)
      }
      document.getElementsByTagName("body")[0]?.scrollTo(0, azState?.scrollYPosition)
    } catch (e) {
      captureSentryError(e, userData, 'getMATableData API in MaturityAssessmentTable.js');
      setFetchError(true);
    } finally {
      if (location?.state?.toolCategoryParams?.sort_by === 'tool') {
        navigate(location.pathname, {});
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    if(!_.isEqual(prevParams.current, _.omitBy(tableParams, val => val === null))) {
      userData?.tenant && getTableData()
      prevParams.current = tableParams;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.tenant, tableParams])

  useEffect(() => {
    expandedControl && setExpandedControl(tableData?.results[0] ? tableData?.results[0].control : '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData])

  const handleSearch = (e) => {
    const q = e.target.value || e.target.innerText;
    if (q === '') {
      setTableParams(prevState => {
        delete prevState.q;
        return {...prevState}
      })
    } else if (q.length >= 3) {
      setPage(1)
      setTableParams(prevState => {
        return {...prevState, q}
      })
    }
  }

  const onRowClick = (event, data) => {
    let control = data.data.control
    control !== expandedControl ? setExpandedControl(control) : setExpandedControl('')
  }

  return (
    <>
      <TableControls
        resetAllFilters={resetAllFilters}
        setPage={setPage}
        page={page}
        total={tableData?.total || 0}
        handleSearch={handleSearch}
        params={tableParams}
        setParams={setTableParams}
        exportLoading={exportLoading}
      />
      <div className="fixed-table-container maturity-assessment-table-container">
        {!loading && fetchError ? <NotFound isError dataError />
         : !loading && tableData?.results?.length > 0
          ?
          <Table page={"maturity-assessment " + (expandedControl !== '' ? 'expanded' : '')}>
            <TableHeader
              mainRow={headerData}
              className={"maturity-assessment"}
              setSortFilters={setSortFilters}
              sortFilters={sortFilters}
              setParams={setTableParams}
              params={tableParams}
            />
            {tableData?.results?.map((item, i) =>
              <MaturityAssessmentRow
                key={i}
                onRowClick={onRowClick}
                severities={severities}
                page={"maturity-assessment " + (expandedControl === item.control ? 'expanded' : '')}
                data={item}
                statuses={item.status === 'Not Applicable' ? [...statuses, {'Not Applicable': 'grey'}] :
                  [...statuses, {'Completed': '#d0f31b'}]}
              />)}
          </Table> : !loading && tableData?.results?.length === 0 ?
            <div>
              <p className={'line-filter'}/>
              <p className={'no-results-text'}>No results, try expanding your filter parameters.</p>
            </div>
            : <Loader/>}
        {expandedControl !== '' &&
        <ExpandView
          getTableData={getTableData}
          className={"expanded-maturity-assessment-container"}
          control={expandedControl}
          statusColors={statuses}
          close={setExpandedControl}
          loadingNote={loadingNote}
          setLoadingNote={setLoadingNote}
        />}
      </div>
    </>
  );
};

export default MaturityAssessmentTable;
