import React from "react";
import "../../../../styles/pages/Onboarding/Cloud/Tab.scss";
import {cloudMapping} from "../../../../config/mappingData";
import CloseIcon from "@mui/icons-material/Close";

const Tab = ({data, onClick, selected, showCloseIcon, onClose}) => (
  <div 
    className={"cloud-tab " + data.display.toLowerCase() + (selected ? ' selected' : '') + (showCloseIcon ? " close-visible" : "")} 
    onClick={() => onClick(data, data.configName)}
  >
    <img
      className={"tab-icons " + data.display.toLowerCase()}
      alt={data.display} src={cloudMapping[data.display.toLowerCase().replace(' ', '')]}
    />
    <span className={"tab-text"}>{data.display}</span>
    {showCloseIcon && 
      <CloseIcon 
        className={"tab-close-icon " + data.display.toLowerCase()} 
        fontSize="small" 
        onClick={(e) => {
          onClose(data); 
          e.stopPropagation();
        }}
      />
    }
  </div>
);

export default Tab;
