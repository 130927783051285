export const resize = (marginLeft, ref, setState) => {
  const resizeObserver = new ResizeObserver(() => setTimeout(() => {
    if (!ref || !ref.current) return;
    setState({
      width: ref.current.offsetWidth - marginLeft,
      height: ref.current.offsetHeight - marginLeft
    });
  }), 0);
  if (ref && ref.current) {
    resizeObserver.observe(ref.current);
  }
  return () => resizeObserver.disconnect();
};
