import React, { useState } from "react";
import LoaderButton from "../../../../components/Button/LoaderButton";
import ErrorIcon from "@mui/icons-material/Error";
import TooltipIcon from "../../../../assets/icons/tooltip.svg";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import "../../../../styles/pages/Onboarding/VulnerabilityScan/IPAddressInput.scss";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import { timeOptions } from "../util";
import Select from 'react-select';
import {SingleValue, Option} from "./OverrideSelect"
import { searchableDropdownStyles } from "../config";
import moment from 'moment-timezone';

const IPAddressInput = ({
  currTab,
  inputValue,
  setInputValue,
  submitLoading,
  setSubmitLoading,
  submitError,
  setSubmitError,
  externalInput,
  internalInput,
  handleAdd,
  isEdit,
  handleOnEditSubmit,
  verifySubmission,
  showScanDay,
  dayOptions,
  selectedDay,
  setSelectedDay,
  scanDayFetchError,
  scanTime,
  setScanTime,
  scanTimezone,
  setScanTimezone,
  showDateResponseModal,
  setShowDateResponseModal,
  internal_scans,
  external_scans
}) => {
  
  const [showDateDropdown, setShowDateDropdown] = useState(false);
  const [showScanTimeDropdown, setShowScanTimeDropdown] = useState(false);
  const scanTimeOptions = timeOptions()
  const timezones = moment.tz.names();
  const options = timezones.map((timezone) => {
    const offset = moment.tz(timezone).format('Z');
    return { value: timezone, label: `${timezone.replace("_", " ")}`, offset: `(UTC${offset})` };
  });

  const handleSelection = (selection) => {
    setSelectedDay(selection);
    setShowDateDropdown(false);
  };

  const handleScanTimeSelection = (selection) => {
    setScanTime(selection);
    setShowScanTimeDropdown(false);
  };

  const handleScanTimezoneSelection = (selection) => {
    setScanTimezone(selection.value);
  };

  const getDefaultValue = () => {
    const offset = moment.tz(scanTimezone).format('Z');
    return { value: scanTimezone, label: `${scanTimezone.replace("_", " ")}`, offset: `(UTC${offset})` };
  };

  const getShowDateModalLoading = () => {
      if((internal_scans.length > 0) || (external_scans.length > 0)) {
          setShowDateResponseModal(true)
          return true
      }
      return false
  }

  const handleOnAddSubmit = () => {
     verifySubmission();
     if((!submitError) || (submitError === "")) {
        if(!getShowDateModalLoading()) {
          handleAdd()
        }
     }
  };

  return (
    <div className="ip-address-input">
      {showScanDay && (
        <div className={"scan-datetime-container"}>
          <div className="scan-day-container">
            <div className="input-label">{"Scan Day of Month"}
              <div className={"scan-day-tooltip"}>
                <Tooltip
                  content={"Scan days are presented in the order that best fits the available capacity for the size of your network. Selecting day from the top of the list will result in the most reliable scan results."}
                  className={"vuln-scan-tooltip"}
                  direction={"right"}
                >
                  <img className="tooltip-icon" src={TooltipIcon} alt="?" />
                </Tooltip>
              </div>
            </div>
            <Dropdown
              name={selectedDay || "Day"}
              dropdownList={dayOptions}
              selectItem={handleSelection}
              selectedItem={selectedDay}
              showDropdown={showDateDropdown}
              setShowDropdown={setShowDateDropdown}
            />
            <div className={`input-instructions scan-day ${scanDayFetchError && "is-error"}`}>
              {scanDayFetchError || "SELECT AN AVAILABLE MONTHLY CALENDAR DAY"}
            </div>
          </div>
          <div className={`scan-time-container ${showDateDropdown}`} data-keyboard="false" data-backdrop="static">
            <div className={"input-label"}>{"Scan Time"}
              <div className={"scan-time-tooltip"}>
                <Tooltip
                  content={"Select the preferred time for ActZero to initiate the scan. Please note that the chosen scan trigger time is subject to a 'best effort' basis for scan initiation"}
                  className={"vuln-scan-tooltip"}
                  direction={"right"}
                >
                  <img className="tooltip-icon" src={TooltipIcon} alt="?" />
                </Tooltip>
              </div>
            </div>
              <Dropdown
                name={scanTime}
                dropdownList={scanTimeOptions}
                selectItem={handleScanTimeSelection}
                selectedItem={scanTime}
                showDropdown={showScanTimeDropdown}
                setShowDropdown={setShowScanTimeDropdown}
                useList={true}
            />
            <div className={"dropdown-container autocomplete"}>
              <Select
                options={options}
                styles={searchableDropdownStyles}
                onChange={handleScanTimezoneSelection}
                getOptionLabel={(options) => `${options.label} ${options.offset}`}
                components={{ SingleValue, Option }}
                isSearchable
                defaultValue={getDefaultValue}
                placeholder="Select a timezone"
              />
            </div>
          </div>
        </div>
      )}
      <div className={"input-label"}>
        {`${
          currTab === "external_scans" ? "External" : "Internal"
        } IP Address Ranges`}
      </div>
      <div className={"ip-tooltip"}>
        <Tooltip
          content={`ENTER INDIVIDUAL IPs${currTab === "external_scans" ? ", RANGES WITH CIDR, ADDRESSES WITH FQDN" : "/RANGES WITH CIDR"}, ONE PER LINE`}
          className={"vuln-scan-tooltip"}
          direction={"right"}
        >
          <img className="tooltip-icon" src={TooltipIcon} alt="?" />
        </Tooltip>
      </div>
      <div className="input-right">
        <textarea
          className="ip-input"
          placeholder={`0.0.0.0\n0.0.0.0/0\n00.000.0.0${currTab === "external_scans" ? "\nwww.n.com" : ""}`}
          value={inputValue}
          onChange={setInputValue}
        />
        <div className="add-btn-container">
          <LoaderButton
            text="SAVE"
            onClick={isEdit? handleOnEditSubmit : handleOnAddSubmit}
            loading={submitLoading}
          />
          {submitError && (
            <div className={`submit-error ${isEdit && "edit"}`}>
              <ErrorIcon className="err-icon" /> {submitError}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IPAddressInput;
