import React from "react";
import '../../styles/components/Button/Button.scss';

const Button = ({className, disabled, text, onClick}) => {
  return (
    <button
      className={className + ' button' + (disabled ? " disabled" : "")}
      disabled={disabled}
      onClick={(e) => onClick(e)}
    >{text}</button>
  )
}

export default Button;
