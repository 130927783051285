import "../../../styles/pages/Onboarding/Endpoints/Onboarding.scss";
import React, {useContext, useEffect, useState} from "react";
import AccountInputForm from "../Cloud/components/AccountInputForm";
import DefenderIcon from "../../../assets/icons/microsoft-defender.svg";
import { Loader } from "../../../components/Loader/Loader";
import CrowdstrikeIcon from "../../../assets/icons/crowdstrike-bird.svg";
import {
  addOnboardingConnector,
  deleteOnboardingConnector,
  editOnboardingConnector,
  getOnboardingConnectors,
  testOnboardingConnector
} from "../../../api/ApiClient";
import UserData from "../../../store/User/UserData";
import {instructions, statsConfig} from "./config";
import AgentList from "./AgentList";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AccountRow from "../Cloud/components/AccountRow";
import AccountTable from "../Cloud/components/AccountTable";
import DeleteModal from "../Cloud/components/DeleteModal";
import EditModal from "../Cloud/components/EditModal";
import NotFound from "../../../navigation/NotFound";
import AddingAccountModal from "../Cloud/components/AddingAccountModal";
import Tooltip from "../../../components/Tooltip/Tooltip";
import TooltipIcon from "../../../assets/icons/tooltip.svg";
import {useOnClickOutside} from "../../../util/clickOutside";
import EditIcon from '@mui/icons-material/Edit';
import CrowdStrikeModal from "./CrowdStrikeModal";
import { isRoot } from "../../../util/userControl";
import CrowdStrikeOptionsModal from "./components/CrowdStrikeOptionsModal";
import { captureSentryError } from "../../../util/sentry";

const Endpoint = ({
                              endpointType,
                              endpointConnectionData,
                              endpointAgentData,
                              expandModal,
                              setExpandModal,
                              crowdstrikeInfoRef,
                              setShowCrowdstrikeInfo,
                              setStringCopied,
                              showCrowdstrikeInfo,
                              stringCopied,
                              loading,
                            }) => {
  const [userData] = useContext(UserData);
  const isRootUser = isRoot(userData.userType);
  const [, setLoadingTestingConnector] = useState(false)
  const [onboardingConnector, setOnboardingConnector] = useState([])
  const [loadingModal, setLoadingModal] = useState(false)
  const [loadingConnector, setLoadingConnector] = useState(false)
  const [addingAccountMessages, setAddingAccountMessages] = useState([])
  const [currentModal, setCurrentModal] = useState();
  const [resetEditModal, setResetEditModal] = useState(true)
  const [inputValues, setInputValues] = useState(currentModal?.data?.connector_config || {});
  let modalCloseTimeout;
  const [errors, setErrors] = useState({
    fetch: "",
    add: "",
    edit: "",
    delete: "",
  });
  const [fetchError, setFetchError] = useState(false);
  const [showCSEditModal, setShowCSEditModal] = useState(false);
  const [showCSOptionsModal, setShowCSOptionsModal] = useState(false);
  const MDRAppLink = userData?.tenant.toLowerCase().includes('defendify') ? 'https://login.microsoftonline.com/organizations/v2.0/adminconsent?response_type=code&scope=35e8fe83-fd6c-4747-bcf4-ff46ea12e725%2F.default&resource=https%3A%2F%2Fmanage.office.com&client_id=35e8fe83-fd6c-4747-bcf4-ff46ea12e725'
    : 'https://login.microsoftonline.com/organizations/v2.0/adminconsent?response_type=code&scope=9070802f-d159-462e-8dee-351c5db94179%2F.default&resource=https%3A%2F%2Fmanage.office.com&client_id=9070802f-d159-462e-8dee-351c5db94179'
  const inputConfig = [
    {
      label: "Tenant ID",
      value: "directory_id",
      tooltip: "",
    }
  ];

  const closeLoadingModal = () => {
    setLoadingModal(false)
    setAddingAccountMessages([])
    clearTimeout(modalCloseTimeout)
  }

  const modalTimeout = () => {
    modalCloseTimeout = setTimeout(() => {
      setAddingAccountMessages([])
      setLoadingModal(false)
    }, 5000)
  }

  const verifyParams = (params, isEdit) => {
    let error;
    for (const i in inputConfig) {
      const enteredValue = params[inputConfig[i].value];

      error = !enteredValue // Check if inputs have content
        ? `Must enter ${inputConfig[i].label}`
        : inputConfig[i].isEmail && // Check valid email format
        !enteredValue.toLowerCase().match(
          // eslint-disable-next-line
          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        )
          ? `Invalid email entered for ${inputConfig[i].label}`
          : inputConfig[i].domain && // Check if value ends with specified domain
          !enteredValue.endsWith(inputConfig[i].domain)
            ? `${inputConfig[i].label} must end with "${inputConfig[i].domain}"`
            : null;

      if (inputConfig[i].parseJSON)
        // Check valid JSON and parse
        try {
          params[inputConfig[i].value] = JSON.parse(
            params[inputConfig[i].value]
          );
        } catch {
          error = "Invalid JSON";
        }

      if (error) break;
    }

    if (error) {
      setErrors((prevState) => {
        const copy = {...prevState};
        copy[`${isEdit ? "edit" : "add"}`] = error;
        return copy;
      });
      return false;
    }

    return params;
  };

  const downloadModalRef = useOnClickOutside(() => setCurrentModal());

  const getConnectorData = async () => {
    setFetchError(false);
    setLoadingConnector(true);
    let results
    try {
      let connectorType = await getOnboardingConnectors(userData?.tenant)
      results = connectorType?.results
      results = results?.filter(item => item.connector_type === "MicrosoftDefender")
      if (endpointType !== "Microsoft Defender") results = []
      setOnboardingConnector(results)
    } catch (e) {
      captureSentryError(e, userData, "getOnboardingConnectors API in Onboarding/Endpoint.js");
      setFetchError(true);
    } finally {
      setLoadingConnector(false);
    }
    return results
  }

  useEffect(() => {
    userData?.tenant && loading === false && getConnectorData()
    // eslint-disable-next-line
  }, [userData?.tenant])

  const postDefenderConnector = async (data) => {
    try {
      let params = {
        "tenant": userData?.tenant,
        "connector_type": "MicrosoftDefender",
        "connector_config": {"directory_id": data?.directory_id}
      }
      const results = await addOnboardingConnector(userData?.tenant, params)
      if (results?.error)
        return results?.error?.statusCode === 409 ? "Duplicate account" : "Error adding account";
      else {
        return true; // resets input values
      }
    } catch (e) {
      setErrors((prevState) => {
        return {...prevState, add: "Error adding account"};
      });
      captureSentryError(e, userData, "addOnboardingConnector API in Onboarding/Endpoint.js");
    }
  }

  const testAccountHandler = async (connectorId) => {
    //TODO: remove input field when test is loading
    setLoadingTestingConnector(true)
    setErrors((prevState) => {
      return {...prevState, test: ""};
    });
    try {
      return await testOnboardingConnector(userData.tenant, connectorId);
    } catch (e) {
      captureSentryError(e, userData, "testOnboardingConnector API in Onboarding/Endpoint.js");
      return {success: false, error: e.message};
    } finally {
      setLoadingTestingConnector(false)
    }
  };

  const deleteAccountHandler = async (params) => {
    setErrors((prevState) => {
      return {...prevState, delete: ""};
    });
    try {
      const results = await deleteOnboardingConnector(
        userData.tenant,
        params.id
      );
      if (results?.error)
        setErrors((prevState) => {
          return {...prevState, delete: "Error deleting account"};
        });
      else {
        setCurrentModal({action: null, data: null});
        setOnboardingConnector((prevState) =>
          prevState.filter((account) => account.id !== params.id)
        );
      }
    } catch (e) {
      setErrors((prevState) => {
        return {...prevState, delete: "Error deleting account"};
      });
      captureSentryError(e, userData, "deleteOnboardingConnector API in Onboarding/Endpoint.js");
    }
  };

  const editAccountHandler = async (params) => {
    setErrors((prevState) => {
      return {...prevState, edit: ""};
    });
    if (params)
      try {
        params = {
          connector_type: "MicrosoftDefender",
          connector_config: params,
        };
        const results = await editOnboardingConnector(
          userData.tenant,
          params,
          currentModal.data.id
        );
        if (results?.error)
          setErrors((prevState) => {
            return {...prevState, edit: "Error updating account"};
          });
        else {
          setCurrentModal({action: null, data: null});
          setOnboardingConnector((prevState) => {
            const index = prevState
              .map((account) => account.id)
              .indexOf(currentModal.data.id);
            Object.keys(prevState[index].connector_config).forEach((key) => {
              prevState[index].connector_config[key] =
                params.connector_config[key];
            });
            return prevState;
          });
        }
      } catch (e) {
        setErrors((prevState) => {
          return {...prevState, edit: "Error updating account"};
        });
        captureSentryError(e, userData, "editOnboardingConnector API in Onboarding/Endpoint.js");
      }
  };

  const resetErrors = () =>
    setErrors({fetch: "", add: "", edit: "", delete: "", test: ""});

  const resetModal = () => {
    resetErrors();
    setCurrentModal({action: null, data: null});
  };

  return (
    <div className="defender-onboarding-container">
      {currentModal?.action === "delete" && (
        <DeleteModal
          confirmFunc={() => deleteAccountHandler(currentModal?.data)}
          cancelFunc={resetModal}
          modalErrors={errors.delete}
          className="cloud-modal"
          message={"Delete account?"}
        />
      )}
      {currentModal?.action === "edit" && (
        <EditModal
          header={`Edit account: ${currentModal?.data?.connector_name}`}
          error={errors.edit}
          resetModal={resetModal}
          className={'edit-modal-action'}
          setResetEditModal={setResetEditModal}
          inputForm={
            <AccountInputForm
              isEdit={true}
              data={currentModal.data.connector_config}
              inputConfig={inputConfig}
              submitFunction={(params) => editAccountHandler(params)}
              buttonText={"SUBMIT"}
              modalTimeout={modalTimeout}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setResetEditModal={setResetEditModal}
              resetEditModal={resetEditModal}
            />
          }
        />
      )}
      {showCSEditModal && <CrowdStrikeModal closeFunction={setShowCSEditModal}/>}
      {showCSOptionsModal && <CrowdStrikeOptionsModal closeFunction={setShowCSOptionsModal}/>}
      {loadingModal && (
        <AddingAccountModal
          messages={addingAccountMessages}
          cancelFunc={closeLoadingModal}
          userData={userData}
        />
      )}
      <div className={"title-container"}>
        <div className={"title"}>
          <img
            className={"defender-icon"}
            src={endpointType === 'Microsoft Defender' ? DefenderIcon : CrowdstrikeIcon}
            alt={endpointType}
          />
          <p className={endpointType === 'Microsoft Defender' ? "defender-title" : "crowdstrike-title"}>
            {endpointType}
          </p>
          {endpointType === "CrowdStrike" && isRootUser && <EditIcon className="cs-edit-btn" fontSize="medium" onClick={() => setShowCSEditModal(true)} />}
        </div>
        <div className={"instructions-tooltip"}>
          <Tooltip
            disableTimeout
            content={
              <div>
                <div>Know your contracted and installed endpoint agents at a glance.</div>
                {endpointType !== 'Microsoft Defender' &&
                <div>Download OS-specific agents and view corresponding CID/Checksum information.</div>}
                <div>
                  Click{" "}
                  <img
                    className="tooltip-icon-small"
                    src={TooltipIcon}
                    alt="Instructions"
                  />{" "}
                  to find simple installation instructions.
                </div>
              </div>
            }
            direction={"left"}
            className="instructions-tooltip"
          >
            <a
              href={endpointType === 'Microsoft Defender' ? instructions.defender : instructions.crowdstrike}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="tooltip-icon"
                src={TooltipIcon}
                alt="Instructions"
              />
            </a>
          </Tooltip>
        </div>
      </div>
      <div className={"display-data"}>
        {statsConfig.map((s, i) => (
          <span className="stat-label" key={i}>
          {s.label}
            <span className="stat-number">
            {endpointConnectionData && endpointConnectionData[s.value]}
          </span>
        </span>
        ))}
        {endpointType !== 'Microsoft Defender' &&
        <div className="info">
          <div className={"flex"} ref={crowdstrikeInfoRef}>
            <span
              className="cs-info"
              onClick={() => {
                setShowCrowdstrikeInfo((prevState) => !prevState);
                setStringCopied(false);
              }}
            >{"CROWDSTRIKE INFO »"}</span>
            {showCrowdstrikeInfo && (
              <div className="cid-container">
                <div className="title">{"CID-Checksum:"}</div>
                <span>{`${endpointAgentData?.cid}-${endpointAgentData?.checksum}`}</span>
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(`${endpointAgentData?.cid}-${endpointAgentData?.checksum}`);
                    setStringCopied(true);
                  }}
                  className="copy-btn"
                >
                  <ContentCopyIcon className={`copy-icon ${stringCopied && "copied"}`}/>
                </IconButton>
              </div>
            )}
          </div>
        </div>}
      </div>
      {endpointType !== 'Microsoft Defender' &&<><AgentList
        setCurrentModal={setCurrentModal}
        endpointAgentData={endpointAgentData}
        currentModal={currentModal}
        expandModal={expandModal}
        downloadModalRef={downloadModalRef}
        setExpandModal={setExpandModal}
      />
      <div className="cs-options">
        <div
          className="cs-options-modal-toggle"
          onClick={() => setShowCSOptionsModal(true)}
        >
          {"Setup Automatic Response »"}
        </div>
      </div>
      </> }
      {endpointType === 'Microsoft Defender' && <p>To grant <i>Defender MDR App</i> Access,
        <b><a
          className={"clickable-link"}
          target='_blank'
          rel='noopener noreferrer'
          href={MDRAppLink}
        >click here
        <img className={"defender-icon grant-access"} src={DefenderIcon} alt={"Microsoft Defender"}/>»
        </a></b>
      </p>}
      {loadingConnector ? <div className={"loader"}><Loader/></div> : onboardingConnector.length === 0 && endpointType === 'Microsoft Defender' &&
      <AccountInputForm
        fetchData={getConnectorData}
        verifyParams={verifyParams}
        setErrors={setErrors}
        testAccountHandler={testAccountHandler}
        inputConfig={inputConfig}
        submitFunction={postDefenderConnector}
        buttonText={"ADD"}
        header={""}
        setAddingAccountMessages={setAddingAccountMessages}
        setLoadingModal={setLoadingModal}
        modalTimeout={modalTimeout}
        inputValues={inputValues}
        setInputValues={setInputValues}
        setResetEditModal={setResetEditModal}
        resetEditModal={resetEditModal}
        connectorType={endpointType.replace(" ", "")}
        addingAccountMessages={addingAccountMessages}
      />}
      {fetchError ? <NotFound isError dataError className="panel"/> :
        onboardingConnector.length > 0 &&
        <AccountTable
          rows={onboardingConnector?.map((connector) => (
            <AccountRow
              key={connector.id}
              account={connector}
              testAccountHandler={testAccountHandler}
              setModal={setCurrentModal}
              disableTestButton={false}
            />
          ))}
        />}
    </div>
  );
};

export default Endpoint;