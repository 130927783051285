import React from "react";
import "../../../styles/pages/AdhocInvestigation/SummaryPanel.scss";
import { Loader } from "../../../components/Loader/Loader";

const SummaryPanel = ({ data, loading }) => {
  return (
    <div className={"summary-panel-container"}>
      <div className={"header summary"}>Summary</div>
      {!loading ? <p>{data?.summary}</p> : <Loader />}
    </div>
  );
};

export default SummaryPanel;