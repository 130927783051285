import React, {useContext, useEffect, useRef, useState} from 'react';
import '../../styles/pages/Vulnerabilities/Vulnerabilities.scss';
import PaginationControls from "../../components/Table/PaginationControls";
import {Pagination} from "../../components";
import {severities} from "../../config/mappingData";
import DataList from "./DataList";
import LineChart from "../../components/D3/LineChart/LineChart";
import GlobalState from "../../store/GlobalState/GlobalState";
import UserData from "../../store/User/UserData";
import {
  getVulnerabilitiesData,
  getVulnerabilitiesMetrics,
  getVulnerabilitiesTrends,
  updateMultipleActions
} from "../../api/ApiClient";
import PageFilters from "../../components/Filters/PageFilters";
import {filterConfig, passedInConfig, resetFilterConfig} from "./filterconfig";
import {filterData} from "../../util/format.js"
import LineChartLegend from "../../components/D3/LineChart/Legend";
import {Loader} from "../../components/Loader/Loader";
import {downloadFile} from "../../util/export"
import TopVulnerabilities from "./TopVulnerabilities";
import {leftColumn, mainRow, rightColumn} from "../../components/Mappings/Vulnerabilities"
import Table from "../../components/Table/Table";
import VulnerabilitiesRow from "./VulnerabilitiesRow";
import {mapChartData} from "../../util/mapChartData";
import TableHeader from "../../components/Table/TableHeader";
import {useLocation, useNavigate} from "react-router-dom";
import {setSortFilterValue} from "../../util/handleSortResults";
import {setResetFilter} from "../../util/handleResetFilter";
import {trackPageView} from "../../util/analytics";
import {tooltipMapping} from "../../config/tooltipTextMapping";
import Tooltip from "../../components/Tooltip/Tooltip";
import NotFound from "../../navigation/NotFound";
import Dropdown from "../../components/Dropdown/Dropdown";
import CommentModal from "./CommentModal";
import _ from 'lodash';
import {captureSentryError} from '../../util/sentry.js';
import {isAZUser} from "../../util/userControl";
import TogglePage from "../Vulnerabilities2/TogglePage";

const Vulnerabilities = () => {
  const [azState, setAzState] = useContext(GlobalState);
  const [userData] = useContext(UserData);
  let isAzUser = isAZUser(userData)
  const location = useLocation()
  const navigate = useNavigate();
  const [pageCount] = useState(50)
  const [page, setPage] = useState(1)
  const [vulnerabilitiesTrends, setVulnerabilitiesTrends] = useState({})
  const [vulnerabilitiesMetrics, setVulnerabilitiesMetrics] = useState({})
  const [vulnerabilitiesTrendLoading, setVulnerabilitiesTrendLoading] = useState(true)
  const [vulnerabilitiesMetricsLoading, setVulnerabilitiesMetricsLoading] = useState(true)
  const [vulnerabilitiesLoading, setVulnerabilitiesLoading] = useState(false)
  const [vulnerabilitiesData, setVulnerabilitiesData] = useState({results: [], total: 0})
  let [sortFilters, setSortFilters] = useState([])
  const [showDropdown, toggleShowDropdown] = useState('')
  const [showActionDropdown, toggleShowActionDropdown] = useState(false)
  const [expandAll, setExpandAll] = useState([])
  const [filterList, setOpenFilters] = useState(location.state?.severity ?
    passedInConfig('severity', location.state?.severity, location) : filterConfig)
  const [vulnerabilitiesParams, setVulnerabilitiesParams] = useState({})
  const [vulnerabilitiesTrendsParams, setVulnerabilitiesTrendsParams] = useState({})
  const statuses = [{'New': '#FFE133'}, {'In Progress': '#d0f31b'}, {'Waiting For Customer': '#FFA415'}, {'Closed': '#018D8D'}]
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const [topIssuesParams, setTopIssuesParams] = useState({})
  const [mappedGraphData, setMappedGraphData] = useState({})
  const [axisData, setAxisData] = useState({x: {min: 0, max: 0}, y: {max: 0}})
  const [selectedIDs, setSelectedIDs] = useState([])
  const [selectedAllActionStatus, setSelectedAllActionStatus] = useState(false)
  let [allData, setAllData] = useState([])
  const [loadingUpdateAction, setLoadingUpdateAction] = useState(false)
  const [bulkComment, setBulkComment] = useState('')
  const [showCommentModal, setShowCommentModal] = useState('')
  const [postError, setPostError] = useState('')
  const [defaultDate,] = useState({
    text: 'Current',
    time: null
  })
  const [isReset, setIsReset] = useState(false)
  const [selectedDate, setSelectedDate] = useState('Current')
  const [toggleMetricsFilter, setToggleMetricsFilter] = useState(false)
  const [initialConfig, setInitialConfig] = useState({
    page: 1,
    size: pageCount,
    sort_by: 'severity',
    order: 'desc',
    start_time: 'current'
  })
  const [exportLoading, setExportLoading] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const prevParams = useRef({});
  const actionOptions = [
    {'Accept Risk': false},
    {'Defer Risk': false},
    {'In-Progress': false},
    {'Ignore': false},
    {"": ""},
    {"Add Comments": false},
    {"Edit Comments": false},
  ]

  const lineData = {
    lineOpacity: "1",
    lineOpacityHover: "1",
    otherLinesOpacityHover: "0.3",
    lineStroke: "3px",
    lineStrokeHover: "5px"
  };

  const colors = {
    Critical: "#D64526",
    High: "#FFA415",
    Medium: "#FFE133",
    Low: "rgba(255,255,255,0.3)"
  };

  useEffect(() => {
    if (azState?.vulnerabilitiesPage === 2) navigate("/risk-management/vulnerabilities2", {replace: true, state: null});
    // eslint-disable-next-line
  }, [])

  const resetAllFilters = async () => {
    setIsReset(true)
    setSelectedDate('Current')
    setSortFilterValue([], mainRow, setSortFilters)
    handleClose()
    setResetFilter(filterConfig, resetFilterConfig, setOpenFilters)
    setVulnerabilitiesParams({page: 1, size: 50})
    setVulnerabilitiesTrendsParams({page: 1, size: 50})
    window.history.replaceState('', location.state?.severity)
    await getVulnerabilitiesTableData({
      page: 1,
      size: pageCount,
      sort_by: 'severity',
      order: 'desc',
      start_time: 'current'
    })
    setIsReset(false)
  }

  const handleChangePage = async (currPage) => {
    setPage(currPage)
    Object.keys(topIssuesParams).length > 0 ?
      await getVulnerabilitiesTableData({...topIssuesParams, page: currPage}) :
      setVulnerabilitiesParams({...vulnerabilitiesParams, page: currPage})
  };

// eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => trackPageView("Vulnerabilities", userData, azState), [userData?.userId, azState.reactGaInitializedState]);

  useEffect(() => {
    setSortFilterValue([], mainRow, setSortFilters)
    location.state?.severity === undefined && setResetFilter(filterConfig, resetFilterConfig, setOpenFilters)
  }, [location.state?.severity])


  useEffect(() => {
    const getVulnerabilitiesTrendData = async () => {
      setVulnerabilitiesTrendLoading(true)
      try {
        let results = [
          {name: 'Critical', values: []},
          {name: 'High', values: []},
          {name: 'Medium', values: []},
          {name: 'Low', values: []}
        ]
        let vulnerabilitiesTrendsResponse = await getVulnerabilitiesTrends(userData?.tenant, vulnerabilitiesTrendsParams)
        mapChartData(vulnerabilitiesTrendsResponse.severity_trend, setAxisData, setMappedGraphData, results)
        setVulnerabilitiesTrends(vulnerabilitiesTrendsResponse)
      } catch (e) {
        captureSentryError(e, userData, 'getVulnerabilitiesTrendResponse API');
      } finally {
        setVulnerabilitiesTrendLoading(false)
      }
    }
    const getVulnerabilitiesMetricsData = async () => {
      setVulnerabilitiesMetricsLoading(true)
      try {
        let vulnerabilitiesMetricsResponse = await getVulnerabilitiesMetrics(userData?.tenant, vulnerabilitiesTrendsParams)
        setVulnerabilitiesMetrics(vulnerabilitiesMetricsResponse)
      } catch (e) {
        captureSentryError(e, userData, 'getVulnerabilitiesMetricsResponse API in Vulnerabilities.js');
      } finally {
        setVulnerabilitiesMetricsLoading(false)
      }
    }

    userData?.tenant && Object.keys(vulnerabilitiesTrendsParams).length > 0 && getVulnerabilitiesMetricsData()
    userData?.tenant && Object.keys(vulnerabilitiesTrendsParams).length > 0 && getVulnerabilitiesTrendData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.tenant, userData?.userType, vulnerabilitiesTrendsParams]);

  useEffect(() => {
    setPage(1)
    filterData(filterList, vulnerabilitiesParams, pageCount, 1, setVulnerabilitiesParams, initialConfig, setInitialConfig)
    filterData(filterList, vulnerabilitiesTrendsParams, pageCount, 1, setVulnerabilitiesTrendsParams)
    setTopIssuesParams({})
    // eslint-disable-next-line
  }, [filterList, pageCount])

  const handleTopVulnClick = (event, type) => {
    let searchText = event.target.innerText;
    let searchParam = type === 'TOP IP/HOSTS' ? {ip_address: searchText} : {name: searchText}
    if (type === 'TOP IP/HOSTS') {
      delete vulnerabilitiesParams.name
    } else if (type === 'TOP VULNERABILITIES') {
      delete vulnerabilitiesParams.ip_address
    }
    const tempParams = {...vulnerabilitiesParams, ...searchParam, page: 1}
    setPage(1)
    setTopIssuesParams(tempParams)
    getVulnerabilitiesTableData(tempParams)
    setVulnerabilitiesParams(tempParams)
  }

  const getVulnerabilitiesTableData = async (tempParams = {}) => {
    setFetchError(false);
    let params = vulnerabilitiesParams
    if (Object.keys(tempParams).length > 0) { // Parameters we use with location.state
      params = tempParams
    }
    let isExport = params.isExport || false
    if (isExport) {
      setVulnerabilitiesParams({...vulnerabilitiesParams, isExport: false})
      setExportLoading(true)
    } else setVulnerabilitiesLoading(true)
    if (location?.state?.severity) {
      params = {...params, severity: location.state.severity}
      delete location.state.severity
    }
    if (location?.state?.initialConfig) {
      params = location?.state?.initialConfig
      delete location.state.initialConfig
      window.history.replaceState(undefined, location.state?.initialConfig)
    }

    try {
      let vulnerabilitiesResponse = await getVulnerabilitiesData(userData?.tenant, params, isExport)
      if (isExport) {
        downloadFile(vulnerabilitiesResponse)
        setExportLoading(false)
      } else {
        setExpandedIndexes([]);
        setVulnerabilitiesData(vulnerabilitiesResponse)
        setAllData(vulnerabilitiesResponse.results)
      }
      document.getElementsByTagName("body")[0]?.scrollTo(0, azState?.scrollYPosition)
    } catch (e) {
      captureSentryError(e, userData, 'getVulnerabilitiesData API in Vulnerabilities.js');
      setFetchError(true);
    } finally {
      setVulnerabilitiesLoading(false)
      setIsReset(false)
    }
  }

  useEffect(() => {
    if (!_.isEqual(prevParams.current, vulnerabilitiesParams)) {
      userData?.tenant && !isReset && Object.keys(vulnerabilitiesParams).length > 0 && getVulnerabilitiesTableData();
      prevParams.current = vulnerabilitiesParams;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.tenant, userData?.userType, vulnerabilitiesParams]);

  const handleClose = () => {
    setExpandAll([])
    setExpandedIndexes([])
    setTopIssuesParams({})
  }

  const selectAction = async (value) => {
    let body;
    if (value.includes('Comments')) {
      setShowCommentModal(value)
      toggleShowActionDropdown(false)
      if (value === 'Edit Comments')
        setBulkComment(vulnerabilitiesData?.results.filter(item => item['Vulnerability ID'] === selectedIDs[0])[0].Comment || '')
      if (value === 'Add Comments') setBulkComment('')
    } else {
      body = {vulnerability_ids: selectedIDs, action: value}
      await bulkUpdateAction(body)
    }
  }

  const bulkUpdateAction = async (body) => {
    setLoadingUpdateAction(true)
    try {
      await updateMultipleActions(userData?.tenant, body)
      await getVulnerabilitiesTableData()
      toggleShowActionDropdown(false)
    } catch (e) {
      setPostError(`Action Failed`)
      setTimeout(() => setPostError(''), 8000)
      captureSentryError(e, userData, 'updateMultipleActions API in Vulnerabilities.js');
    } finally {
      setLoadingUpdateAction(false)
    }
  }

  const setAllSelectedIDs = (item) => {
    if (item.field === 'Action') {
      setSelectedAllActionStatus(!selectedAllActionStatus)
      let ids = []
      if (selectedIDs.length < allData.length) ids = [...allData?.map(item => item['Vulnerability ID'])]
      setSelectedIDs([...ids])
    }
  }

  const selectActionID = (e, id) => {
    e.stopPropagation();
    let ids = selectedIDs.includes(id) ? selectedIDs.filter(val => val !== id) : [...selectedIDs, id]
    ids.length !== allData.length && setSelectedAllActionStatus(false)
    ids.length === allData.length && setSelectedAllActionStatus(true)
    setSelectedIDs([...ids])
  }

  const toNewVulnerabilitiesPage = () => {
    setAzState(state => ({...state, vulnerabilitiesPage: 2}));
    navigate("/risk-management/vulnerabilities2", {replace: true, state: null});
  };

  return (
    <div className={"vulnerabilities-page-container all-page-container"}>
      <div className={"header-row"}>
        <Tooltip content={tooltipMapping.vulnerabilities.title} className={"title-tooltip"} direction={"right"}>
          <p className={"page-title"}>VULNERABILITIES</p>
        </Tooltip>
        {(azState?.flags?.hasNewVulnAccess || isAzUser) &&
        <TogglePage onClick={toNewVulnerabilitiesPage} defaultChecked={false}/>}
      </div>
      <div className={`top-container ${toggleMetricsFilter}`}>
        {vulnerabilitiesTrendLoading ? <div className={"loader-container trends"}><Loader/></div> :
          <div className={"graph-container"} onClick={() => setToggleMetricsFilter(false)}>
            <div className={`title-and-legend ${toggleMetricsFilter}`}>
                <span className={`expand-btn ${toggleMetricsFilter}`} onClick={(e) => {
                  e.stopPropagation();
                  setToggleMetricsFilter(!toggleMetricsFilter)
                }}>{toggleMetricsFilter ? '+' : '-'}</span>
              <p className={`graph-title  ${toggleMetricsFilter}`}>TOTAL BY SEVERITY
              </p>
            </div>
            {mappedGraphData.length > 0 && <LineChartLegend
              colors={colors}
              rectPosition={400}
              textPosition={415}
              data={mappedGraphData}
            />}
            {mappedGraphData.length > 0 ?
              <LineChart
                width={550}
                plotRadius={2}
                yAxisTicks={4}
                xAxisTicks={mappedGraphData[0].values.length}
                lineData={lineData}
                data={mappedGraphData}
                className={"vulns-line-chart"}
                axis={axisData}
                widthOffset={.7}
                height={130}
                marginLeft={50}
                colors={colors}
              /> : <p className={"no-data"}>No Data</p>}
            <div className={"top-vulns-container"}>
              {vulnerabilitiesTrends?.top_hosts && vulnerabilitiesTrends?.top_hosts.length !== 0 &&
              <TopVulnerabilities
                title={"TOP IP/HOSTS"}
                data={vulnerabilitiesTrends.top_hosts}
                handleClick={handleTopVulnClick}
              />}
              {vulnerabilitiesTrends?.top_vulnerabilities && vulnerabilitiesTrends?.top_vulnerabilities.length !== 0 &&
              <TopVulnerabilities
                title={"TOP VULNERABILITIES"}
                data={vulnerabilitiesTrends.top_vulnerabilities}
                type={'top-vulnerabilities'}
                handleClick={handleTopVulnClick}
              />}
            </div>
          </div>}
        {vulnerabilitiesMetricsLoading ? <div className={"loader-container metrics"}><Loader/></div> :
          <DataList data={vulnerabilitiesMetrics} metricsExpanded={toggleMetricsFilter}/>}
      </div>
      <PageFilters
        type={"vulnerabilities"}
        isReset={isReset}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        handleClose={handleClose}
        filterList={filterList}
        setOpenFilters={setOpenFilters}
        setParams={setVulnerabilitiesParams}
        setMetricParams={setVulnerabilitiesTrendsParams}
        className={"vulnerabilities-filters"}
        timeConfig={[{text: 'Current', time: 'current'}, {text: 'Previous', time: 'previous'}]}
        collapsible={true}
        defaultDate={defaultDate}
        newVulnerabilities={true}
        initialSearch={location?.state?.initialConfig?.q}
      />
      <div className={"vuln-pagination-container"}>
        {!vulnerabilitiesLoading &&
        <PaginationControls
          resetAllFilters={resetAllFilters}
          className={"vulnerabilities-table"}
          displayData={vulnerabilitiesData}
          page={page}
          expandAll={expandAll}
          showDropdown={showDropdown}
          setExpandAll={setExpandAll}
          toggleShowDropdown={toggleShowDropdown}
          setPage={handleChangePage}
          setParams={setVulnerabilitiesParams}
          params={vulnerabilitiesParams}
          setExpandedIndexes={setExpandedIndexes}
          expandedIndexes={expandedIndexes}
          bauExportLoading={exportLoading}
        />}
        {showCommentModal.includes("Comments") &&
        <CommentModal
          resetModal={() => setShowCommentModal('')}
          submit={bulkUpdateAction}
          selectedIDs={selectedIDs}
          type={showCommentModal}
          bulkComment={bulkComment}
          setBulkComment={setBulkComment}
        />}
        {!vulnerabilitiesLoading && <div className={"action-dropdown-container"}>
          {loadingUpdateAction ? <Loader/> : allData.length > 0 && !loadingUpdateAction &&
            <Dropdown
              className={selectedIDs.length > 0 ? "action-dropdown multi-edit" : "action-dropdown multi-edit disabled"}
              name={'Actions'}
              dropdownList={actionOptions}
              selectItem={selectedIDs.length > 0 ? selectAction : () => {
              }}
              selectedItem={'Actions'}
              showDropdown={showActionDropdown}
              setShowDropdown={toggleShowActionDropdown}
            />}
        </div>}
        {postError !== '' && <p className={"error-message"}>{postError}</p>}
      </div>
      {vulnerabilitiesLoading ?
        <div className={"loader-container vulns"}><Loader/></div>
        : fetchError ? <NotFound isError dataError/>
          : !vulnerabilitiesLoading && vulnerabilitiesData.results.length === 0 ?
            <div>
              <p className={'line-filter'}/>
              <p className={'no-results-text'}>No results, try expanding your filter parameters.</p>
            </div>
            :
            <div>
              <div className="fixed-table-container">
                <Table page={"vulnerabilities"}>
                  <TableHeader
                    mainRow={mainRow}
                    className={"vulnerabilities"}
                    setSortFilters={setSortFilters}
                    sortFilters={sortFilters}
                    setParams={setVulnerabilitiesParams}
                    params={vulnerabilitiesParams}
                    tooltipField={"action"}
                    tooltipValue={tooltipMapping.vulnerabilities.action}
                    selectCheckbox={setAllSelectedIDs}
                    checked={selectedAllActionStatus}
                  />
                  {allData.map((item, i) => <VulnerabilitiesRow
                    key={i}
                    severities={severities}
                    page={"vulnerabilities"}
                    data={item}
                    mapMainRow={mainRow}
                    statuses={statuses}
                    setExpandedIndexes={setExpandedIndexes}
                    expandedIndexes={expandedIndexes}
                    expandAll={expandAll}
                    index={i}
                    setExpandAll={setExpandAll}
                    leftColumn={leftColumn}
                    rightColumn={rightColumn}
                    getTableData={getVulnerabilitiesTableData}
                    selectActionCheckbox={selectActionID}
                    selectedIDs={selectedIDs}
                  />)}
                </Table>
              </div>
              <div className={"pagination-controls"}>
                <Pagination
                  setPage={handleChangePage}
                  page={page}
                  total={vulnerabilitiesData.total}
                />
              </div>
            </div>}
    </div>
  )
}

export default Vulnerabilities;
