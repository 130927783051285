import React, {useContext, useEffect, useState} from 'react';
import GlobalState from "../../store/GlobalState/GlobalState";
import UserData from "../../store/User/UserData";
import '../../styles/pages/MaturityAssessment/MaturityAssessment.scss';
import {trackPageView} from "../../util/analytics";
import {filterConfig, frameworkFilters} from "./config/filterConfig";
import Filters from "./Filters";
import MaturityAssessmentTable from "./MaturityAssessmentTable";
import {filterData} from "../../util/format";
import {useLocation} from "react-router-dom";
import _ from "lodash";
import {setResetFilterToFalse} from "../../util/handleResetFilter";

const MaturityAssessment = () => {
  const [userData] = useContext(UserData);
  const [azState] = useContext(GlobalState);
  const [page, setPage] = useState(1)
  const [sortFilters, setSortFilters] = useState([])
  const [tableParams, setTableParams] = useState({page: 1, size: 50})
  const [filterList, setOpenFilters] = useState(filterConfig)
  const [frameworkOpenFilters, setFrameworkOpenFilters] = useState(frameworkFilters)
  const [loadingNote, setLoadingNote] = useState(false);
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState('')
  const [isReset, setIsReset] = useState(false);
  const [defaultDate, setDefaultDate] = useState({time: '', text: ''});

  const resetAllFilters = () => {
    setIsReset(true)
    setTableParams({page: 1, size: 50})
    setSelectedDate('')
    window.history.replaceState(null, location.state?.toolCategoryParams)
    setResetFilterToFalse(filterConfig, setOpenFilters)
    setResetFilterToFalse(frameworkFilters, setFrameworkOpenFilters)
    setDefaultDate({time: '', text: ''})
  }

  useEffect(() => {
    let allFilters = [...frameworkOpenFilters, ...filterList]
    filterData(allFilters, tableParams, 50, page, setTableParams)
    // eslint-disable-next-line
  }, [filterList, frameworkOpenFilters, page])

  useEffect(() => setPage(1), [tableParams.start_time, tableParams.end_time])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => trackPageView("Maturity Assessment", userData, azState), [userData?.userId, azState.reactGaInitializedState]);

  const selectCheckbox = (type, checkboxName) => {
    filterList.map(filter => {
      let name = Object.keys(filter)[0]
      if (name === type) {
        filter[type].filters[checkboxName] = !filter[type].filters[checkboxName]
      }
      return filter
    })
    setPage(1)
    setOpenFilters([...filterList])
  }

  useEffect(()=>{
    if (location?.state?.toolCategoryParams?.sort_by === 'tool') {
      _.set(filterList[1], 'STATUS.filters.Completed', true);
      selectCheckbox(...filterList)
    }
    // eslint-disable-next-line
  },[])

  const selectFramework = (type, checkboxName) => {
    frameworkOpenFilters.map(filter => {
      let name = Object.keys(filter)[0]
      if (name === type) {
        filter[type].filters[checkboxName] = !filter[type].filters[checkboxName]
      }
      return filter
    })
    setPage(1)
    setFrameworkOpenFilters([...frameworkOpenFilters])
  }

  return (
    <div className={"maturity-assessment-page-container all-page-container"}>
      <div className={"header-row"}>
        <h3 className={"page-title"}>MATURITY ASSESSMENT</h3>
      </div>
      <Filters
        defaultDate={defaultDate}
        isReset={isReset}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        filterList={filterList}
        setOpenFilters={setOpenFilters}
        selectCheckbox={selectCheckbox}
        setTableParams={setTableParams}
        frameworkOpenFilters={frameworkOpenFilters}
        selectFramework={selectFramework}
        loadingNote={loadingNote}
      />
      <MaturityAssessmentTable
        resetAllFilters={resetAllFilters}
        tableParams={tableParams}
        page={page}
        setPage={setPage}
        sortFilters={sortFilters}
        setSortFilters={setSortFilters}
        setTableParams={setTableParams}
        loadingNote={loadingNote}
        setLoadingNote={setLoadingNote}
      />
    </div>
  )
}

export default MaturityAssessment;
