import React, {useState, useContext} from "react";
import ServicesComponent from "./ServicesTable";
import LoaderButton from "../../../components/Button/LoaderButton";
import UserData from "../../../store/User/UserData";
import {postNewChildTenant} from "../../../api/ApiClient"
import { validateOfferings, refreshOfferings } from "./config/serviceUnits";
import "../../../styles/pages/Onboarding/Tenants/NewChildTenant.scss";
import { captureSentryError } from "../../../util/sentry";

const NewChildTenant = () => {
  const [userData] = useContext(UserData);
  const [inputTenant, setInputTenant] = useState("");
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState("");
  const [missingSelections, setMissingSelections] = useState([])
  const [submitLoading, setSubmitLoading] = useState(false);
  const [offerings, setOfferings] = useState(refreshOfferings())
  const [isSelected, setIsSelected] = useState([])

  const validateParams = () => {
    let missingUnits = []
    if (inputTenant === "") {
      missingUnits.push("tenant-name")
    }
    validateOfferings(offerings, missingUnits)
    setMissingSelections(missingUnits)
    return missingUnits.length === 0
  }

  const handleSubmit = async () => {
    if (validateParams()) {
      setSubmitError(false);
      setSubmitLoading(true);
      setSubmitSuccess(false);
      try {
        await postNewChildTenant(userData?.tenant, {'tenant_name': inputTenant, 'offerings': offerings})
        setSubmitSuccess(true)
        setTimeout(() => {
          setSubmitSuccess(false)
        }, 7000);
        setInputTenant("")
        setOfferings(refreshOfferings())
        setIsSelected([])
      } catch (e) {
        setSubmitError(e.message);
        captureSentryError(e, userData, "postNewChildTenant API in NewChildTenant.js");
      } finally {
        setSubmitLoading(false)
      }
    } else {
      setSubmitError(true)
    }
  }

  return (
    <div className="child-tenant-container">
      <div className={"child-header-row"}>
        <h4 className={"child-page-title"}>REQUEST A TENANT +</h4>
        <div className={"child-page-subtitle"}>Request a tenant by choosing the required services from the list</div>
      </div>
      <div className={"tenant-name-container"}>
        <div className={"tenant-name"}>
        <span className={"tenant-name-title"}>Tenant name</span>
        <input
          className={`input-tenant-name${missingSelections.filter(selection => selection === 'tenant-name').length > 0 ? " input-error" : ""}`}
          value={inputTenant}
          maxLength={"50"}
          onChange={(e) => {
            setInputTenant(e.target.value)
            setMissingSelections(prevState => prevState.filter(selection => selection !== 'tenant-name'))
          }}
        />
        </div>
      </div>
      <div className={"services-container"}>
          <ServicesComponent inputData={offerings} setInputData={setOfferings} missingSelections={missingSelections} setMissingSelections={setMissingSelections} isSelected={isSelected} setIsSelected={setIsSelected}/>
      </div>
      <div className="add-btn-container">
          <div className={"submit-message"}>
            {submitError && !submitLoading && <div className={`submit-error`}>
              Please fix the issues in red before continuing.
            </div>}
            <div className={`submit-success ${submitSuccess ? 'shown' : 'hidden'}`}>
              Successfully requested a tenant, one of the ActZero members will contact you shortly.
            </div>
          </div>
          <LoaderButton
            text="ADD"
            onClick={handleSubmit}
            loading={submitLoading}
          />
        </div>
    </div>)
};

export default NewChildTenant;