export const getTrendDifference = (totalDifference, percentage = false, text = " more endpoints on average than contracted for.") => {
  let difference = Math.abs(totalDifference)
  return (
    <div className={"trend-container"}>
      <span className={"trend-title"}>TREND: </span>
      <div className={"trend-text"}>
        <span className={"trend-number"}>{percentage ? `${difference}%` : difference}</span>{text}
      </div>
    </div>
  )
}
