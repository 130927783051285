import React, {useEffect, useState} from 'react';
import ReactGA from "react-ga4";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import GlobalState from "./store/GlobalState/GlobalState";
import './App.scss';
import UserData from "./store/User/UserData";
import Login from "./pages/Login";
import config from './config'
import {Authenticator} from '@aws-amplify/ui-react';
import * as Sentry from "@sentry/react";

Sentry.init({
  // This is not a secret
  dsn: "https://3249a54bebc64656a87261a209d8371c@o370841.ingest.sentry.io/6700832",
  // This enables included instrumentation (page load performance)
  integrations: [Sentry.browserTracingIntegration(), Sentry.httpClientIntegration()],
  // To set a uniform sample rate
  tracesSampleRate: parseFloat(config.sentry_sample_rate),
  environment: config.env,
  ignoreErrors: ['ResizeObserver', "No current user", "Refresh Token has expired"]
});

const App = () => {
  const [azState, setAzState] = useState({});
  const [userData, setUserData] = useState({})
  const [reactGaInitialized, setReactGaInitialized] = useState(false);

  const setScroll = () => {
    setAzState(state => ({...state, scrollYPosition: document.body.scrollTop}));
  };

  useEffect(() => {
    document.querySelector('body')
      .addEventListener('scroll', setScroll);

    window.addEventListener("scroll", setScroll);
    return () => {
      window.removeEventListener("scroll", setScroll);
    };
  }, []);

  // Initialize Google Analytics
  useEffect(() => {
    let trackingID = config.trackingID;

    try {
      ReactGA.initialize(trackingID, {
        // gaOptions: {
        //   debug_mode: true,
        // },
      });
      setReactGaInitialized(true);
    } catch (e) {
      Sentry.captureException(e);
    }
  }, []);

  useEffect(() => {
    setAzState(state => ({...state, reactGaInitializedState: reactGaInitialized}));
  }, [reactGaInitialized]);

  return (
    <UserData.Provider value={[userData, setUserData]}>
      <GlobalState.Provider value={[azState, setAzState]}>
        <Authenticator.Provider>
          <Login/>
        </Authenticator.Provider>
      </GlobalState.Provider>
    </UserData.Provider>
  );
}

export default App;
