import React, {useEffect, useRef, useState} from "react";
import "../../../styles/pages/Network/NetworkMetrics.scss";
import {DateDropdown} from "../../../components";
import CaretDown from "../../../assets/icons/caret-down.svg";
import CaretUp from "../../../assets/icons/caret-up.svg";
import moment from "moment";
import {useOnClickOutside} from "../../../util/clickOutside";

const DateFilters = ({setFilters, filters}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedDate, setSelectedDate] = useState('Last 30 Days');
    const [invalidDateFormat, setInvalidDateFormat] = useState(false);
    const [defaultDate] = useState({
      text: 'Last 30 Days',
      time: new Date(new Date().setDate(new Date().getDate() - 30))
    });
    const [startDate, handleStartDateChange] = useState({
      time: defaultDate?.time ? defaultDate?.time : defaultDate?.time === '' ? '' : new Date().setDate(new Date().getDate() - 30),
      text: defaultDate?.text ? defaultDate?.text : ''
    });
    const [endDate, handleEndDateChange] = useState({
      time: defaultDate?.time === '' ? '' : new Date(),
      text: defaultDate?.text ? defaultDate?.text : ''
    });

    const handleSelectTime = (time, cb) => {
      cb(time)
      setShowDropdown(false)
    }

    useEffect(() => {
      setFilters({
        ...filters,
        start_time: moment(startDate.time).format('YYYY-MM-DDTHH:mm:ssZ'),
        end_time: moment(endDate.time).format('YYYY-MM-DDTHH:mm:ssZ')
      })
      // eslint-disable-next-line
    }, [startDate, endDate])

    let domNode = useRef(null);

    useOnClickOutside((e) => {
      if (!e?.target?.closest('.MuiCalendarPicker-root') && !e?.target?.firstChild?.textContent?.includes('Filter page by time')) {
        setShowDropdown(false);
      }
    }, domNode);

    return (
      <div className={`network-dropdown-container dates`} ref={domNode}>
        <div className={`caret-container date`} onClick={() => setShowDropdown(!showDropdown)}>
          {showDropdown ? "Filter page by time" : selectedDate !== '' ? selectedDate :
            moment(startDate.time).format('M/D/YY') + " - " + moment(endDate.time).format('M/D/YY')}
          <img className={"caret"} src={!showDropdown ? CaretDown : CaretUp} alt={""}/>
        </div>
        {showDropdown && <DateDropdown
          type={"network"}
          domNode={domNode}
          title={""}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          defaultDate={defaultDate}
          handleStartDateChange={(e) => handleSelectTime(e, handleStartDateChange)}
          handleEndDateChange={(e) => handleSelectTime(e, handleEndDateChange)}
          endDate={endDate.time}
          startDate={startDate.time}
          invalidDateFormat={invalidDateFormat}
          setInvalidDateFormat={setInvalidDateFormat}
        />}
      </div>
    );
  }
;

export default DateFilters;
