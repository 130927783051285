import React from "react";
import Tooltip from "../../../components/Tooltip/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "../../../assets/icons/edit-icon.svg";
import TrashIcon from "../../../assets/icons/trash-icon.svg";
import {vendors} from "./config";
import Checkbox from "@mui/material/Checkbox";

const FirewallTableRow = ({
                            data,
                            setPendingRow,
                            setCurrentModal,
                            setSelectedRows,
                            selectedRows
                          }) => {
  const tableHeaders = {
    hostname: "NAME",
    ip: "MANAGEMENT ADDRESS",
    type: "VENDOR",
    notes: "NOTES",
  };

  const rowButtonConfig = [
    {action: "edit", tooltip: "Edit", icon: EditIcon},
    {action: "delete", tooltip: "Delete", icon: TrashIcon}
  ]

  return (
    <>
      {data?.map((row, i) =>
        <tr className={"firewall-row"} key={i}>
          <td className="checkbox-cell">
            <div className="checkbox-icon">
              <Checkbox
                key={i}
                className="custom-checkbox"
                checked={selectedRows.includes(row?._id)}
                onChange={(e) => e.target.checked ?
                  setSelectedRows([...selectedRows, row?._id]) :
                  setSelectedRows([...selectedRows.filter((x) => x !== row?._id)])
                }
              />
            </div>
          </td>
          {Object.keys(tableHeaders).map((col, i) => {
            let value = row[col]
            let display = vendors?.filter(x => x[value])
            if (col === 'type') value = display?.length > 0 ? display[0][value] : value
            return <td className={col} key={`${i}${col}`}>{value}</td>
          })}
          <td className="table-icon-container">
            {rowButtonConfig.map((b, i) =>
              <Tooltip content={b.tooltip} className={`${b.action}-tooltip`} direction={"right"} key={i}>
                <IconButton className={`table-icon ${b.action}`} onClick={() => {
                  setPendingRow(row, b.action)
                  setCurrentModal({action: b.action, data: row})
                }}>
                  <img src={b.icon} alt={b.tooltip}/>
                </IconButton>
              </Tooltip>
            )}
          </td>
        </tr>
      )}
    </>
  )
};

export default FirewallTableRow;
