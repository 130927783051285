import React from "react";
import '../../styles/components/Table/Table.scss';

const Table = (props) => {
  return (
      <table className={props.page + ' table-container'}>
        <tbody>
          {props.children}
        </tbody>
      </table>
  );
}

export default Table;