import React from 'react';
import '../../styles/pages/Detections/DataList.scss';
import GreyArrowUp from '../../assets/icons/grey-arrow-up.svg';
import GreyArrowDown from '../../assets/icons/grey-arrow-down.svg';
import GreenArrowDown from '../../assets/icons/green-arrow-down.svg';
import YellowArrowUp from '../../assets/icons/yellow-arrow-up.svg';
import {formatPercentage, getTrendDirection, displayValue} from "../../util/format";

const DataList = ({data, metricsExpanded=false}) => {
  const percentChange = formatPercentage(data.percent_change_from_last_month)
  return (
    <div className={`data-list-container-detections ${metricsExpanded}`}>
      <span className={`table-title ${metricsExpanded}`}>TRENDS</span>
      <table className="table-container">
        <tbody>
          <tr className={"data-table-row"}>
            <td className={`data-table-cell ${metricsExpanded}`}>Change over last 30 days</td>
            <td
              className={"amount " + getTrendDirection(data.previous_monthly_total, data.current_monthly_total, 'green', 'yellow') + ` ${metricsExpanded}`}>
              {percentChange !== null ? `${percentChange}%` : 'No data'}
              {(percentChange !== null && percentChange !== 0) && <img
                className={"arrow"}
                alt={"critical"}
                src={getTrendDirection(data.previous_monthly_total, data.current_monthly_total, GreenArrowDown, YellowArrowUp)}
              />}
            </td>
          </tr>
        {data?.severity_totals && Object.entries(data.severity_totals).filter(([key, _]) => key !== 'Informational').map(([name, value], index) => {
        return <tr key={index} className={"data-table-row"}>
            <td>Total<span className={name.toLowerCase()}> {name}</span> over the last 30 days</td>
            <td className={"amount severity"}> {displayValue(value.current)}
            {(value.previous !== null && value.previous !== value.current) &&
                <img
                className={"arrow"}
                alt={"critical"}
                src={getTrendDirection(value.previous, value.current, GreyArrowDown, GreyArrowUp)}
            />}
            </td>
        </tr>
        })}
        </tbody>
      </table>
    </div>
  )
}

export default DataList;