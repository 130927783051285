import macOSLogo from "../../../pages/Connections/config/icons/apple-logo.svg";
import WindowsLogo from "../../../pages/Connections/config/icons/windows-logo.svg";
import LinuxLogo from "../../../pages/Connections/config/icons/linux-logo.svg";

export const instructions = {
  crowdstrike: "https://docs.actzero.ai/endpoints/crowdstrike/agent-prereqs/",
  defender: "https://docs.actzero.ai/mdfe/",
};

export const statsConfig = [
  { label: "Contracted", value: "contracted" },
  { label: "Installed", value: "installed" },
];

export const agentLogos = [
  { name: "windows", svg: WindowsLogo },
  { name: "mac", svg: macOSLogo },
  { name: "linux", svg: LinuxLogo, isModal: true },
];