import React, {useContext, useEffect, useState} from 'react';
import GlobalState from "../../store/GlobalState/GlobalState";
import UserData from "../../store/User/UserData";
import {Loader} from "../../components/Loader/Loader";
import {downloadFile} from "../../util/export"
import {getExportConnectionsData} from "../../api/ApiClient";
import '../../styles/pages/Connections/Connections.scss';
import {trackPageView} from "../../util/analytics";
import {tooltipMapping} from "../../config/tooltipTextMapping";
import Tooltip from "../../components/Tooltip/Tooltip";
import {useNavigate} from "react-router-dom";
import {formatTimeFilters} from "../../util/format";
import {connectionsConfig, mdrLiteConnectionsConfig, mobileConnectionsConfig} from "./config/connectionsMap";
import { captureSentryError } from '../../util/sentry';

const Connections = () => {
  const [userData] = useContext(UserData);
  const [azState] = useContext(GlobalState);
  const navigate = useNavigate();
  const [exportLoading, setExportLoading] = useState(false);
  const isMDRLite = userData?.tenantConfig?.is_mdr_lite
  const isMobileOnly = userData?.tenantConfig?.is_mobile_edr_only
  const connectionsPanels = isMobileOnly ? mobileConnectionsConfig : isMDRLite ? mdrLiteConnectionsConfig : connectionsConfig

  const createEmail = (feature) => {
    let tenant = userData?.tenantConfig?.sf_account_name || userData?.tenant
    let subject = `${tenant} - ActZero Upgrade Requested - Support Notification`
    let bodyMessage = `Dear ActZero Team%2C%0D%0A%0D%0AI%E2%80%99d like to request more information about the ${feature} feature I saw in the customer portal.%0D%0A%0D%0AThank you %21%21%0D%0A`
    return `mailto:upgraderequest-portal@actzero.ai?subject=${subject}&body=${bodyMessage}`
  }

  const exportServiceStatusData = async () => {
    setExportLoading(true)
    try {
      let exportConnectionsResponse = await getExportConnectionsData(userData?.tenant)
      downloadFile(exportConnectionsResponse, false)
    } catch (e) {
      captureSentryError(e, userData, "getExportConnectionsData API in Connections.js");
    } finally {
      setExportLoading(false)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => trackPageView("Connections", userData, azState), [userData?.userId, azState.reactGaInitializedState]);

  const onInvestigationClick = (type) => {
    navigate('/activity/investigations', {
      state: {
        filters: {
          investigations: {  
            initialConfig: {
              page: 1,
              size: 50,
              source: [type],
              start_time: formatTimeFilters(2592000).start_time,
              end_time: formatTimeFilters(2592000).end_time
            }
          }
        }
      }
    })
  }

  return (
    <div className={"connections-page-container"}>
      <div className={"header-row"}>
        <Tooltip content={tooltipMapping.serviceStatus.title} className={"title-tooltip"} direction={"right"}>
          <h3 className={"page-title"}>SERVICE STATUS</h3>
        </Tooltip>
        <div className={"export-connections"}>
          {!exportLoading ? <div className={"export-text"} onClick={() => exportServiceStatusData()}>
            Export all data <span className={"carrot"}>{">>"}</span>
          </div> : <div className={"export-loader"}><Loader/></div>}
        </div>
      </div>
      <table>
        <tbody>
        {(isMDRLite && isMobileOnly) !== undefined && connectionsPanels.map((Panel, index) =>
          <Panel
            isMobileOnly={isMobileOnly}
            isMDRLite={isMDRLite}
            key={index}
            onInvestigationClick={onInvestigationClick}
            createEmail={createEmail}
          />)}
        </tbody>
      </table>
    </div>
  )
}

export default Connections;
