import React, {useEffect, useState} from "react";
import '../../styles/pages/Hygiene/ExpandedView.scss';
import {stringifyObject, sortObjectKeys, isJson} from "../../util/format.js"
import {leftColumn, rightColumn} from "../../components/Mappings/Hygiene"

const ExpandedView = ({className, data}) => {
  const [leftExpandedData, setLeftExpandedData] = useState({})
  const [rightExpandedData, setRightExpandedData] = useState({})

  useEffect(() => {
    if (data) {
      let tempLeft = {}
      let tempRight = {}
      Object.keys(data).map((item) => {
        if (leftColumn.some(x => x === item)) {
          tempLeft[item] = data[item]
        }
        if (rightColumn.some(x => x === item)) {
          tempRight[item] = data[item]
        }
        return item
      })
      setLeftExpandedData(sortObjectKeys(tempLeft, leftColumn))
      setRightExpandedData(tempRight)
    }
  }, [data]);
  
  return (
    <div className={(className ? className : '') + " hygiene-labeled-expanded-view-container"}>
      <table className={"left-container " + (Object.keys(data).length > 1 ? 'border' : '')}>
      <div className={"left-table"}>
        <tbody>
          {Object.keys(leftExpandedData).map((item, index) => {
            let value = stringifyObject(leftExpandedData[item] || 'N/A')
            let displayName = item.replace('_', ' ')
            if (displayName === "Compliance") {
              let complianceControls = leftExpandedData[item]
              return isJson(complianceControls) && Object.keys(complianceControls).length > 0 && (
              <tr className={"expanded-item"} key={index}>
                <td className={"expanded-item-title"}>{"Framework"}</td>
                <td className={"expanded-item-value"}>
                  <ul className={"compliance-list"}>
                    {Object.keys(complianceControls).map((framework, i) => {
                      let controls = complianceControls[framework]
                      return (
                        <div key={`${framework}-${i}`}>
                          {controls.map((control) => {
                            return (
                              <li key={control} className={"compliance-item"}>
                                {`${framework} : ${control}`}
                              </li>
                            )
                          })}
                      </div>)
                    })}
                  </ul>
                </td>
              </tr>)
            } else {
              return <tr className={"expanded-item"} key={index}>
                <td className={"expanded-item-title"}>{displayName}</td>
                <td className={"expanded-item-value"}><div>{value}</div></td>
              </tr>
            }
          })}
        </tbody>
      </div>
      </table>
      <table className={"right-container"}>
        <div className={"right-table"}>
          <tbody className={"right-body"}>
            {Object.keys(rightExpandedData).map((item, index) => {
              let value = rightExpandedData[item] || 'N/A'
              let displayName = item.replace('_', ' ')
              if (displayName === 'Remediation' && data['Doc link']) {
                value = 
                <a className={"clickable-link"} target="_blank" rel="noreferrer" href={data['Doc link']}>
                  {value}<span className="link-arrows">{" »"}</span>
                </a>
              } else {
                value = stringifyObject(value)
              }
              return <tr key={index} className={"expanded-item right"}>
                  <td className={"expanded-item-title"}>{displayName}</td>
                  <td className={"expanded-item-value"}><div className={"expanded-box"}>{value}</div></td>
                </tr>
            })}
            </tbody>
        </div>
      </table>
    </div>
  )
}
export default ExpandedView;