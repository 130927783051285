import React from "react";
import "../../../styles/pages/Network/NetworkMetrics.scss";
import GreenArrowDown from "../../../assets/icons/green-arrow-down.svg";
import YellowArrowUp from "../../../assets/icons/yellow-arrow-up.svg";
import { trendsTableConfig } from "../config/config";

const NetworkMetrics = ({ data }) => (
  <div className={"network-metrics-content"}>
    <span className={`table-title`}>TRENDS</span>
    <table className="table-container">
      <tbody>
        {data &&
          Object.keys(data).map((item, index) => (
            <tr className={"data-table-row"} key={index}>
              <td className={`data-table-cell`}>
                {trendsTableConfig[item]?.text}
              </td>
              <td className={`amount ${data[item] < 0 ? "yellow" : "green"}`}>
                {data[item] !== null ? `${Math.round(data[item])}%` : "No data"}
                {data[item] !== null && data[item] !== 0 && (
                  <img
                    className={"arrow"}
                    alt={"critical"}
                    src={data[item] < 0 ? GreenArrowDown : YellowArrowUp}
                  />
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  </div>
);
export default NetworkMetrics;
