import React, { useState, useContext, useEffect } from "react";
import UserData from "../../../store/User/UserData";
import "../../../styles/pages/AdhocInvestigation/ResultsPanel.scss";
import { captureSentryError } from "../../../util/sentry";
import { Loader } from "../../../components/Loader/Loader";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import CommentIcon from "@mui/icons-material/Comment";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactTyped } from "react-typed";

const ActionButton = ({ onClick, loading, icon }) => (
  <div onClick={onClick} className="action-button">
    {loading ? <Loader /> : icon}
  </div>
);

const ResultsPanel = ({ data, setCommentModalData }) => {
  const [userData] = useContext(UserData);
  const [loadingLike, setLoadingLike] = useState(false);
  const [loadingDislike, setLoadingDislike] = useState(false);
  const [likeStatus, setLikeStatus] = useState();
  const [collapsed, setCollapsed] = useState(false);
  const [typedOnce, setTypedOnce] = useState(false);
  const loading = !data.text;

  useEffect(() => setTypedOnce(false), [data.text]);

  const handleLikeDislike = async (type) => {
    try {
      if (type === "like") setLoadingLike(true);
      if (type === "dislike") setLoadingDislike(true);
      // TODO: Add API call here
      if (likeStatus === type) {
        setLikeStatus(null);
      } else {
        setLikeStatus(type);
      }
    } catch (e) {
      captureSentryError(e, userData, `Error ${type}ing in ResultsPanel.js`);
    } finally {
      if (type === "like") setLoadingLike(false);
      if (type === "dislike") setLoadingDislike(false);
    }
  };

  const handleComment = async () => setCommentModalData(data);

  const actions = [
    {
      onClick: () => handleLikeDislike("like"),
      loading: loadingLike,
      icon: loadingLike ? (
        <Loader />
      ) : likeStatus === "like" ? (
        <ThumbUpAltIcon fontSize="inherit" className="panel-icon" />
      ) : (
        <ThumbUpOffAltIcon fontSize="inherit" className="panel-icon" />
      ),
    },
    {
      onClick: () => handleLikeDislike("dislike"),
      loading: loadingDislike,
      icon: loadingDislike ? (
        <Loader />
      ) : likeStatus === "dislike" ? (
        <ThumbDownAltIcon fontSize="inherit" className="panel-icon" />
      ) : (
        <ThumbDownOffAltIcon fontSize="inherit" className="panel-icon" />
      ),
    },
    {
      onClick: handleComment,
      icon: <CommentIcon fontSize="inherit" className="panel-icon" />,
    },
  ];

  return (
    <div className="results-panel">
      <div className="header">
        <div>{data?.module.replace(/_/g, ' ')}</div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="actions">
            {actions.map((action, index) => (
              <ActionButton key={index} {...action} />
            ))}
          </div>
          <div
            onClick={() => setCollapsed(!collapsed)}
            className="collapse-button"
          >
            {collapsed ? (
              <ExpandMoreIcon
                fontSize="inherit"
                className="panel-icon collapse"
              />
            ) : (
              <ExpandLessIcon
                fontSize="inherit"
                className="panel-icon collapse"
              />
            )}
          </div>
          {!collapsed && (
            <div className="text-box">
              {!typedOnce && (
                <ReactTyped
                  strings={[data?.text]}
                  typeSpeed={20}
                  loop={false}
                  showCursor={false}
                  onComplete={() => setTypedOnce(true)}
                  onDestroy={() => setTypedOnce(true)}
                />
              )}
              {typedOnce && <div>{data?.text}</div>}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ResultsPanel;
