export const filterConfig = [
  {
    LEVEL: {
      filter_name: "level",
      isOpen: true,
      filters: {'1': false, '2': false, '3': false, '4': false, '5': false}
    },
  },
  {
    STATUS: {
      filter_name: "status",
      isOpen: true,
      filters: {
        'Incomplete': false,
        'In Progress': false,
        'Ready for Review': false,
        'Completed': false,
        'Not Applicable': false,
      }
    }
  },
  {
    COVERAGE: {
      filter_name: "coverage",
      isOpen: true,
      filters: {'Yes': false, 'No': false, 'Partial': false}
    }
  },
  {
    SERVICE: {
      filter_name: "service",
      isOpen: true,
      filters: {'Hygiene': false, 'MDR': false, 'vCISO': false, 'Vulnerability': false, 'Ransomware Blueprint': false}
    }
  },
  {
    DIFFICULTY: {
      filter_name: "difficulty",
      isOpen: true,
      filters: {'Easy': false, 'Medium': false, 'Hard': false}
    }
  },
  {
    THREAT: {
      filter_name: "threat",
      isOpen: true,
      filters: {'Cloud ATO': false, 'Ransomware': false}
    }
  }
]

export const frameworkFilters = [
  {
    FRAMEWORK: {
      filter_name: "framework",
      isOpen: true,
      filters: {
        'CIS 7.1': false,
        'CIS 8.0': false,
        'CMMC': false,
        'CMMC 2.0': false,
        'ISO 27001:2022': false,
        'ISO 27002:2022': false,
        'NIST 800-171': false,
        'NIST 800-172': false,
        'NIST 800-66 r1 (HIPAA)': false
      }
    },
  },
]
