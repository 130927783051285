export const endpointFilterConfig = [
  {
    OS: {
      filter_name: "os_type",
      isOpen: true,
      filters: {'Windows': false, 'Mac': false, 'Linux': false}
    },
  },
  {
    Firewall: {
      filter_name: "firewall_enabled",
      isOpen: true,
      booleanConfig: {'Enabled': true, 'Disabled': false, 'Unknown': 'Unknown'},
      filters: {'Enabled': false, 'Disabled': false, 'Unknown': false}
    }
  },
  {
    Encryption: {
      filter_name: "encryption_enabled",
      isOpen: true,
      booleanConfig: {'Encrypted': true, 'Unencrypted': false, 'Unknown': 'Unknown'},
      filters: {'Encrypted': false, 'Unencrypted': false, 'Unknown': false}
    }
  },
  {
    Status: {
      filter_name: "status",
      isOpen: true,
      capitalize: true,
      tooltip: "Endpoints that have not been online in the past 30 days are considered inactive",
      filters: {'active': false, 'inactive': false}
    }
  }
]

export const updatesFilterConfig = [
  {
    Severity: {
      filter_name: "severity",
      isOpen: true,
      filters: {'Critical': false, 'Important': false, 'Informational': false, 'Low': false, 'Moderate': false}
    },
  }
]

export const mobileFilterConfig = [
  {
    'OS': {
      filter_name: "os_type",
      isOpen: true,
      filters: {'iOS': false, 'Android': false}
    },
  },
  {
    'Vulnerability Severity': {
      filter_name: "severity",
      isOpen: true,
      filters: {'High': false, 'Medium': false, 'Low': false}
    },
  }
]

export const resetEndpointFilterConfig = {
  OS: {
    filter_name: "os_type",
    isOpen: true,
    filters: {'Windows': false, 'Mac': false, 'Linux': false}
  },
  Firewall: {
    filter_name: "firewall_enabled",
    isOpen: true,
    booleanConfig: {'Enabled': true, 'Disabled': false, 'Unknown': 'Unknown'},
    filters: {'Enabled': false, 'Disabled': false, 'Unknown': false}
  },
  Encryption: {
    filter_name: "encryption_enabled",
    isOpen: true,
    booleanConfig: {'Encrypted': true, 'Unencrypted': false, 'Unknown': 'Unknown'},
    filters: {'Encrypted': false, 'Unencrypted': false, 'Unknown': false}
  },
  Status: {
    filter_name: "status",
    isOpen: true,
    tooltip: "Endpoints that have not been online in the past 30 days are considered inactive",
    filters: {'active': false, 'inactive': false}
  }
}
export const resetUpdatesFilterConfig = {
  Severity: {
    filter_name: "severity",
    isOpen: true,
    filters: {'Critical': false, 'Important': false, 'Informational': false, 'Low': false, 'Moderate': false}
  },
}
export const resetMobileFilterConfig = {
  'OS': {
    filter_name: "os_type",
    isOpen: true,
    filters: {'iOS': false, 'Android': false}
  },
  'Vulnerability Severity': {
    filter_name: "severity",
    isOpen: true,
    filters: {'High': false, 'Medium': false, 'Low': false}
  },
}
