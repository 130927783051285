import React from "react";
import "../../../../styles/pages/Onboarding/VulnerabilityScan/TableHeader.scss";

export const TableHeader = ({ headerConfig }) => (
  <>
    {Object.keys(headerConfig).map((col) => {
      return (
        <td className={`table-column ${col}`} key={col}>
          {headerConfig[col].toUpperCase()}
        </td>
      );
    })}
    <td className={"table-column icon-btns"} />
  </>
);
