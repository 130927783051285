import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Loader } from "../Loader/Loader";
import React, { useState } from "react";
import '../../styles/components/Table/Export.scss';
import { useOnClickOutside } from "../../util/clickOutside.js";
import Tooltip from "../Tooltip/Tooltip";
import { tooltipMapping } from "../../config/tooltipTextMapping";

const Export = ({
                  className,
                  currentSearchConfig,
                  setParams,
                  params,
                  bauExportLoading,
                  refresh,
                  onExport,
                  pastExportsLoading,
                  pastExportedFiles,
                  exportLoading,
                  exportFile
                }) => {
  const [showExportDropdown, setShowExportDropdown] = useState(false);
  const [showPastExportedFiles, setShowPastExports] = useState(false);

  const toggleExport = () => {
    if (pastExportedFiles.length === 0) {
      refresh();
    }
    setShowExportDropdown(prevState => !prevState);
    setShowPastExports(false);
  };

  const handleBAUExport = () => {
    setParams({ ...params, isExport: true });
  };

  const domNode = useOnClickOutside(() => {
    setShowExportDropdown(false);
    setShowPastExports(false);
  });

  return (
    <div className={`export-dropdown ${currentSearchConfig ? '' : 'export-bau'} ${className || ''}`}>
      {currentSearchConfig ?
        <div className="export-container" ref={domNode}>
          <div className="export-button" onClick={toggleExport}>
            <div className="export-toggle-container">
              <Tooltip content={tooltipMapping.logSearch.export} className="export-logs" direction="left">
                <button className="export-toggle">Export</button>
              </Tooltip>
              {showExportDropdown || showPastExportedFiles ?
                <KeyboardDoubleArrowLeftIcon className="arrow-down-export" />
               : <KeyboardDoubleArrowRightIcon className="arrow-up-export" />
              }
            </div>
          </div>
          {showExportDropdown &&
            <div className={`export-menu past-files ` + (showPastExportedFiles && pastExportedFiles.length > 0 ? 'true' : '')}>
              {showPastExportedFiles ?
                <>
                  {pastExportedFiles.map((data, i) =>
                    <div key={i}><a href={data.url}>{data.file_name}</a></div>
                  )}
                  {pastExportedFiles.length > 0 && <div className={"export-menu-divider"} />}
                  {pastExportsLoading ? <Loader /> :
                    <div
                      className={`export-menu-item ${pastExportsLoading ? 'true' : ''}`}
                      onClick={refresh}
                    >
                      <i>Refresh...</i>
                    </div>
                  }
                </>
               :
                <>
                  <div className={`export-menu-item ${exportLoading ? 'true' : ''}`} onClick={onExport}>
                    Export All
                  </div>
                  <div className={"export-menu-divider"} />
                  <div className={"export-menu-item"} onClick={() => setShowPastExports(true)}>Past Exports</div>
                </>
              }
            </div>
          }
        </div>
       : bauExportLoading ?
        <Loader/>
       :
        <button download={exportFile} onClick={handleBAUExport}>Export</button>
      }
    </div>
  );
};

export default Export;
