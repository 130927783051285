import React from 'react';
import LeftDashboard from "./LeftDashboard";
import RightDashboard from "./RightDashboard";
import '../../styles/pages/MultiTenantDashboard/MultiTenantDashboard.scss';

const MultiTenantDashboard = () => {
  return (
    <div className={"multitenant-dashboard-container"}>
      <LeftDashboard/>
      <RightDashboard/>
    </div>
  )
}

export default MultiTenantDashboard;
