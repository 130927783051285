import React, {useState} from "react";
import "../../styles/pages/KnowledgeGraph/GraphFilters.scss";
import PresetDropdown from "./PresetDropdown";
import {filterSections, graphFilterConfig} from "./config/filterConfig";
import {FilterCheckbox} from "./FilterCheckbox";
import LlmSummary from "./LlmSummary";
import LargeLanguageModel from "./LargeLanguageModel";
import CollapseFilters from "../../assets/icons/close.svg";
import ExpandFilters from "../../assets/icons/open.svg";

const GraphFilters = ({
                        setSelectedFilters,
                        selectedFilters,
                        selectedPreset,
                        setSelectedPreset,
                        getLLMData,
                        setSearchTerm,
                        fetchAndFormatData,
                        setSelectedByPreset
                      }) => {
  const [toggleRow, setToggleRow] = useState(false)
  const [togglePageFilters, setTogglePageFilters] = useState({})


  return (
    <div className={"graph-filters-container " + toggleRow}>
      <span className={`expand-btn ${toggleRow}`} onClick={(e) => {
        e.stopPropagation();
        setToggleRow(!toggleRow)
      }}>
        {toggleRow ? <img src={ExpandFilters} alt="Expand"/> : <img src={CollapseFilters} alt="Collapse"/>}
      </span>
      {!toggleRow && <>
        <div className={"filters-and-legend"}>
          {filterSections.map((section) => (
            <div key={section.value} className={'filters ' + section.name}>
              <div className="filter-header">
                <span className={`expand-btn`} onClick={(e) => {
                  e.stopPropagation();
                  setTogglePageFilters({...togglePageFilters, [section.name]: !togglePageFilters[section.name]})
                }}>{togglePageFilters[section.name] ? '+' : '-'}</span>
                {section.name}
              </div>
              {!togglePageFilters[section.name] && graphFilterConfig
                .filter((item) => item.type === section.value)
                .map(
                  (item) =>
                    (!item.parentCheckbox ||
                      selectedFilters[item.parentCheckbox]) && (
                      <FilterCheckbox
                        fetchAndFormatData={fetchAndFormatData}
                        key={item.name}
                        item={item}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        className={item.parentCheckbox ? "child-checkbox " + section.name : section.name}
                      />
                    )
                )}
            </div>
          ))}
          <div className="preset-dropdown-container">
            <PresetDropdown
              setSelectedByPreset={setSelectedByPreset}
              currValue={selectedPreset}
              setValue={setSelectedPreset}
            />
          </div>
        </div>
        <LargeLanguageModel getLLMData={getLLMData} setSearchTerm={setSearchTerm}/>
        <LlmSummary/></>}
    </div>
  );
};

export default GraphFilters;
