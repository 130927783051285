import React from "react";
import {Modal} from "../../../../components";
import '../../../../styles/pages/Onboarding/VulnerabilityScan/NewScanDateModal.scss';

const NewScanDateModal = ({ cancelFunc,
                            submitFunc,
                            defaultDateSelection,
}) => {

  return <Modal close={() => cancelFunc()} className={'new-scan-date-modal'}>
    <div className={"new-scan-date-modal"}>
      <div>
      <div className={"new-scan-date-modal-title"}> {"Please Confirm Your Action"} </div>
      <div className={"new-scan-date-modal-description"}> {"The new scan will be defaulted to your current scan date set to day " + defaultDateSelection + " of every month."} </div>
      <div className="border-btn cancel" onClick={async () => cancelFunc()}>{"CANCEL"}</div>
      <div className="border-btn submit" onClick={async () => submitFunc()}>{"SUBMIT"}</div>
      </div>
    </div>
  </Modal>
};

export default NewScanDateModal;
