import React, {useRef, useState, useLayoutEffect} from 'react';
import '../../styles/components/Table/StatusBar.scss';

const StatusBar = ({className, statuses, selectedStatus}) => {
  const [progressSteps, setProgressSteps] = useState(statuses);
  const [width, setWidth] = useState();
  const statusBarRef = useRef(null);
  const statusList = statuses.map(s => Object.keys(s)[0]);

  useLayoutEffect(() => {
    const barResizeObserver = new ResizeObserver(() => setTimeout(() => {
      const newWidth = statusBarRef.current?.offsetWidth;
      if(width !== newWidth) { 
        if(newWidth < 200) { // reduce amount of progress dots when compressed
          const statusIndex = statusList.indexOf(selectedStatus);
          const sliceIndices = statusIndex < 2 ? [0, 4] : statusIndex > 3 ? [2] : [1, 5];
          setProgressSteps(statuses.slice(...sliceIndices));
        } else {
          setProgressSteps(statuses)
        }
        setWidth(newWidth);
      }
    }), 0);
    barResizeObserver.observe(statusBarRef.current);
    return () => barResizeObserver.disconnect();
  }, [width, statusList, selectedStatus, statuses]);

  return (
    <div className={(className ? className : '') + " status-bar-container"} ref={statusBarRef}>
      {progressSteps.map((status, index) => {
        const title = Object.keys(status)[0]
        return <li
          key={index}
          style={{border: selectedStatus === title && `2px solid ${status[title]}`}}
          className={selectedStatus === title ? "active " + selectedStatus : 'inactive'}
        >
          {selectedStatus === title && title.replace(/[^a-zA-Z]/g, " ")}
        </li>
      })}
    </div>
  )
}

export default StatusBar;
