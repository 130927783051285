import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "../../styles/pages/FileStorage/UploadModal.scss";
import { FileUploader } from "react-drag-drop-files";
import UploadIcon from "../../assets/icons/file-upload-icon.svg";
import { uploadFile } from "../../api/ApiClient";
import _ from "lodash";
import { Loader } from "../../components/Loader/Loader";
import { trackClick } from "../../util/analytics";
import { captureSentryError } from "../../util/sentry";

const fileTypes = ["JPG", "PNG", "SVG", "PDF", "TXT", "JSON", "XML", "RTF", "PPT", "PPTX", "XLS", "XLSX", "CSV", "DOC", "DOCX"];
const fileTypesMIME = ["image/jpeg", "image/png", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/pdf", "text/plain", "application/json", "application/ld+json", "application/xml", "text/xml", "application/rtf", "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/csv", "image/svg+xml", ".saltpack", "application/octet-stream"];
const frequentlyUsedTags = [
  "Reports",
  "Maturity Assessment",
  "Vulnerabilities",
];

export default function UploadModal(props) {
  const { isOpen, setOpen, userData } = props;

  const [files, setFiles] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [incompatibleFileMsg, setIncompatibleFileMsg] = useState(false);

  const handleClose = async () => {
    setFiles([]);
    setTagInput("");
    setTags([]);
    setError(false);
    setOpen(false);
    setIncompatibleFileMsg(false);
    setLoading(false);
  };

  const handleUpload = async () => {
    setLoading(true);
    try {
      const stringifyTags = tags.toString();
      for (let i in files) {
        await uploadFile(userData?.tenant, userData?.email, files[i], {
          file_name: files[i].name,
          tags: stringifyTags,
          content_type: files[i].type,
        });
        trackClick(userData, `uploaded file ${files[i].name}`, "click");
      }
      handleClose();
    } catch (e) {
      setError(true);
      setLoading(false);
      captureSentryError(e, userData, "add files API in UploadModal.js");
    }
  };

  const addFiles = (files) => {
    const filtered = [...files].filter(f => {
      if(!fileTypesMIME.includes(f.type)){
        setIncompatibleFileMsg(true);
        return false;
      }
      return true;
    });
    setFiles((prevState) => [...prevState, ...filtered]);
  };

  const deleteFile = (file) => {
    setFiles((prevState) => prevState.filter((f) => f.name !== file.name));
  };

  const addTag = (input) => {
    if (input.length && !tags.includes(input))
      setTags((prevState) => [...prevState, input]);
  };

  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };

  const tagInputKeyDown = (e) => {
    const { key } = e;

    if (key === "Enter" || key === ",") {
      e.preventDefault();
      addTag(tagInput.trim());
      setTagInput("");
    }

    if (key === "Backspace" && !tagInput.length && tags.length) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setTagInput(poppedTag);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableAutoFocus
      disableScrollLock
    >
      <Box className="upload-modal-container" sx={{ boxShadow: 24 }}>
        <div className="title-container">
          <span className="title">File upload</span>
        </div>
        {loading ? (
          <Loader />
        ) : error ? (
          <div className="error-msg">
            {"Error uploading file(s). Please try again."}
          </div>
        ) : (
          <div className="upload-content-container">
            {incompatibleFileMsg && <span className="incompatible-msg">{"Incompatible file type added."} <br/> {"Accepted file types: " + fileTypes.join(", ") + "."}</span>}
            <FileUploader
              handleChange={addFiles}
              name="file"
              types={fileTypes}
              hoverTitle=" "
              multiple
            >
              <div className="dragdrop-area">
                <div className="dragdrop-content">
                  <img className="upload-icon" src={UploadIcon} alt="Upload" />
                  <span className="dragdrop-text">
                    Drag and drop files here to upload
                  </span>
                </div>
              </div>
            </FileUploader>

            <div className="files-btn-container">
              <span className="upload-text">{"or"}</span>
              <IconButton>
                <label className="upload-btn" htmlFor="select-files">
                  CHOOSE FILES
                </label>
                <input
                  type="file"
                  id="select-files"
                  multiple
                  onChange={(e) => {addFiles(e.target.files); e.target.value = null}}
                  accept={fileTypesMIME.join()}
                />
              </IconButton>
            </div>
            <div className="file-list-container">
              <ul className="file-list">
                {files.map((f) => {
                  return (
                    <li className="file-list-item" key={_.uniqueId()}>
                      {f.name}
                      <button onClick={() => deleteFile(f)}>x</button>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="tags-container">
              <span className="tags-text">Add tags</span>
              <div className="tags-input">
                {tags.map((tag, index) => (
                  <div className="tag" key={_.uniqueId()}>
                    {tag}
                    <button onClick={() => deleteTag(index)}>x</button>
                  </div>
                ))}
                <input
                  value={tagInput}
                  placeholder={!tags.length ? "Type and press enter" : ""}
                  onKeyDown={tagInputKeyDown}
                  onChange={(e) => {
                    setTagInput(e.target.value);
                  }}
                />
              </div>
              <div className="suggestions-container">
                <span className="label">FREQUENTLY USED</span>
                <span className="text">
                  {frequentlyUsedTags.map((tag, i) => {
                    return (
                      <span
                        className="suggestion-button"
                        onClick={() => addTag(tag)}
                        key={_.uniqueId()}
                      >
                        {tag +
                          (i === frequentlyUsedTags.length - 1 ? "" : " | ")}
                      </span>
                    );
                  })}
                </span>
              </div>
              <div className="upload-btn-container">
                <IconButton onClick={handleUpload} disabled={!files.length}>
                  <label className="upload-btn" htmlFor="upload">
                    UPLOAD
                  </label>
                </IconButton>
              </div>
            </div>
          </div>
        )}

        <IconButton
          aria-label="Close"
          onClick={handleClose}
          className="close-button-container"
        >
          <CloseIcon className="close-icon" fontSize="small" />
        </IconButton>
      </Box>
    </Modal>
  );
}
