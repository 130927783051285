import React from "react";
import { iconMap } from "../../Connections/config/iconMap";
import { Loader } from "../../../components/Loader/Loader";
import { tooltipMapping } from "../../../config/tooltipTextMapping";
import Tooltip from "../../../components/Tooltip/Tooltip";

const ServicePanel = ({ data, loading, title, type, options, iconLabels }) => {
  const serviceList = options ? options : data.services ? Object.keys(data.services) : [];
  return <div className={`service-panel ${title}`}>
    {loading ? (
      <Loader />
    ) : (
      <>
        <div className="service-panel-header">
          <div className="total">{data.total || 0}</div>
          <Tooltip
            content={tooltipMapping.serviceStatus[type]}
            className={"title-tooltip"}
            direction={"right"}
            disableTimeout
          >
            <div className="title">{title.toUpperCase()}</div>
          </Tooltip>
        </div>
        <div className="service-panel-stats">
          {data.contracted ? (
            <div className="contracted">
              Contracted <span className="amount">{data.contracted}</span>
            </div>
          ) : (
            <></>
          )}
          <div className="percent-used">
            {data.contracted && data.percentUsed
              ? `${data.percentUsed}% used`
              : ""}
          </div>
        </div>
        <div className="service-panel-services">
          {serviceList.map((service) => (
             <div
                key={service}
                className={`service ${service} ${
                  !data?.services ||
                  !data?.services[service] ||
                  (data?.services[service] && data?.services[service] === "0 B")
                    ? "disabled"
                    : ""
                }`}
              >
                <div className={`service-icon ${service}`}>
                  <img src={iconMap[service.toLowerCase()]} alt={service} />
                </div>
                <div className="total">{data?.services && data?.services[service] ? data?.services[service] : 0}</div>
                {iconLabels && iconLabels[service] ? <div className={`icon-label ${service}`}>{iconLabels[service]}</div> : <></>}
              </div>
            ))}
        </div>
      </>
    )}
  </div>
};

export default ServicePanel;
