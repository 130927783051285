import React from 'react';
import '../../styles/components/Dropdown/GenericCheckbox.scss';
import CaretDown from '../../assets/icons/caret-down.svg';

const GenericDropdown = ({className, data, isOpen, name, dropdownList, selectCheckbox, setOpenSort, showDropdown, toggleShowDropdown, sortFilters, setSortFilters, params, setParams}) => {
  return (
    <div className={className}>
      <p className={"dropdown-title"} onClick={() => setOpenSort(name, showDropdown, toggleShowDropdown)}>
        {name}<img className={"caret"} src={CaretDown} alt={name}/>
      </p>
      {isOpen && Object.keys(dropdownList).map((item, index) => {
        return (
          <div className={"list-container"} onClick={() => selectCheckbox(name, item, sortFilters, setSortFilters, params, setParams)} key={index}>
             <p className={"list-item " + (data[name]?.filters[item] ? 'selected' : '')}>{item.includes('date') ? 'Timestamp' : item}</p>
           </div>)
      })}
    </div>
  )
}

export default GenericDropdown;
