import React, { useState, useContext } from "react";
import UserData from "../../store/User/UserData";
import "../../styles/pages/SupportTicket/CreateModal.scss";
import "../../styles/pages/FileStorage/UploadModal.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Modal } from "../../components";
import { useOnClickOutside } from "../../util/clickOutside";
import LoaderButton from "../../components/Button/LoaderButton";
import {
  createInputConfig,
  defaultInputValues,
} from "./config/createModalConfig";
import { handleCreate, handleUpdate } from "./util";
import _ from "lodash";
import { FileUploader } from "react-drag-drop-files";
import UploadIcon from "../../assets/icons/file-upload-icon.svg";

const CreateModal = ({ handleClose, data, handleFetchTickets, setTicketsData, setOpenTicketsData }) => {
  const isUpload = data?.id;
  const [userData] = useContext(UserData);
  const [inputValues, setInputValues] = useState(defaultInputValues);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  let priorityNode = useOnClickOutside((e) => setShowDropdown(false, e));
  let categoryNode = useOnClickOutside((e) => setShowDropdown(false, e));
  const [files, setFiles] = useState([]);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [inputError, setInputError] = useState("");

  const handleSubmit = () => {
    if (isUpload)
      handleUpdate({
        setSubmitLoading,
        inputValues,
        userData,
        handleClose,
        data,
        setError,
        files,
        setTicketsData,
        setOpenTicketsData
      });
    else
      handleCreate({
        setSubmitLoading,
        setError,
        userData,
        inputValues,
        handleClose,
        files,
        handleFetchTickets,
        setInputError
      });
  };

  const renderDropdown = (input) => {
    let options;
    if (input.value === "status") {
      options = [
        {
          name: data.status?.replace(/_/g, " "),
          value: data.status,
        },
      ];
      if (data.status !== "Resolved")
        options.push({ name: "Resolved", value: "closed" });
    } else options = input.options;

    const value = inputValues[input.value]
      ? options
          .find((opt) => opt.value === inputValues[input.value])
          .name.replace(/_/g, " ")
      : "Select";

    return (
      <div
        className="modal-dropdown-container"
        ref={input.value === "priority" ? priorityNode : categoryNode}
      >
        <span
          className="selection flex list-item"
          onClick={() =>
            setShowDropdown((prevState) =>
              prevState === input.value ? false : input.value
            )
          }
        >
          {value}
          <KeyboardArrowDownIcon className="down-arrow list-item" />
        </span>
        {input.value === showDropdown && (
          <ul className="options dropdown-options">
            {options.map((val) => (
              <li
                className="list-item"
                onClick={() => {
                  setInputValues({
                    ...inputValues,
                    [input.value]: val.value,
                  });
                  setShowDropdown(false);
                }}
                key={val.value}
              >
                {val.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const addFiles = (files) => {
    const filtered = [...files].filter((f) => {
      if (f.size > 1500000000) {
        setFileSizeError(true);
        return false;
      }
      return true;
    });
    setFiles((prevState) => [...prevState, ...filtered]);
  };

  const deleteFile = (file) => {
    setFiles((prevState) => prevState.filter((f) => f.name !== file.name));
  };

  return (
    <div className="create-modal-container">
      <Modal close={handleClose}>
        <p className={"modal-title"}>
          {isUpload ? "Attachments" : "Create Ticket"}
        </p>
        {error ? (
          <div className="error-msg">
            {Array.isArray(error) ? (
              <div>
                <span>The following error(s) have occurred:</span>
                <ul>
                  {error.map((e, i) => (
                    <li key={i}>{e}</li>
                  ))}
                </ul>
              </div>
            ) : (
              <div>{"Error uploading file(s). Please try again."}</div>
            )}
          </div>
        ) : (
          <div className="create-content-container">
            {inputValues.category === "incident" && (
              <div className="critical-warning">
                {
                  "Please contact us urgently on our breach hotline at 855-917-4981 x 1 for immediate assistance."
                }
              </div>
            )}
            <ul className="input-container">
              {!isUpload &&
                createInputConfig.map((input) => (
                  <li className="input-row" key={input.value}>
                    <div className="input-name">{input.name}</div>
                    <div className="input-input">
                      {input.type === "readOnly" ? (
                        <div className="read-only-input">
                          {inputValues[input.value]}
                        </div>
                      ) : input.type === "input" ? (
                        <input
                          key={`${input.value}-input`}
                          className={`input-box`}
                          type={"text"}
                          value={inputValues[input.value] || ""}
                          placeholder={""}
                          onChange={(e) =>
                            setInputValues({
                              ...inputValues,
                              [input.value]: e.target.value,
                            })
                          }
                        />
                      ) : input.type === "dropdown" ? (
                        renderDropdown(input)
                      ) : input.type === "textarea" ? (
                        <textarea
                          value={inputValues[input.value] || ""}
                          className={`input-box description`}
                          onChange={(e) =>
                            setInputValues({
                              ...inputValues,
                              [input.value]: e.target.value,
                            })
                          }
                        />
                      ) : null}
                    </div>
                  </li>
                ))}
              <li className={`files-container ${isUpload ? "upload" : ""}`}>
                {isUpload && (
                  <div className="dragdrop-container">
                    <FileUploader
                      handleChange={addFiles}
                      name="file"
                      hoverTitle=" "
                      multiple
                    >
                      <div className="dragdrop-area">
                        <div className="dragdrop-content">
                          <img
                            className="upload-icon"
                            src={UploadIcon}
                            alt="Upload"
                          />
                          <span className="dragdrop-text">
                            Drag and drop files here to upload
                          </span>
                        </div>
                      </div>
                    </FileUploader>
                  </div>
                )}
                <div className="files-btn-container">
                  <label className="upload-text" htmlFor="select-files">
                    ADD ATTACHMENTS »
                  </label>
                  <input
                    type="file"
                    id="select-files"
                    multiple
                    onChange={(e) => {
                      addFiles(e.target.files);
                      e.target.value = null;
                    }}
                  />
                  {fileSizeError && (
                    <div className="file-size-error">
                      <div>{"File size cannot exceed 1.5gb"}</div>
                    </div>
                  )}
                </div>
                <div className="file-list-container">
                  <ul className="file-list">
                    {files.map((f) => {
                      return (
                        <li className="file-list-item" key={_.uniqueId()}>
                          <div>{f.name}</div>
                          <button onClick={() => deleteFile(f)}>x</button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {inputError && <div className="input-error">{inputError}</div>}
                <LoaderButton
                  text={isUpload ? "SAVE" : "CREATE"}
                  onClick={handleSubmit}
                  loading={submitLoading}
                  className="upload-btn-container"
                  id="create-btn"
                />
              </li>
            </ul>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default CreateModal;