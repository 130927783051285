import React from "react";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

const PolicyOptions = ({ osName, optionsList, selectItem, selectedItem, serverGroupID, workstationGroupID }) => {
  const workstations = optionsList.filter((opt) =>
    opt.groups.includes("Workstations")
  );
  const servers = optionsList.filter((opt) => opt.groups.includes("Servers"));

  return (
    <div className="cs-policy-container">
      <div className="cs-policy-os">{osName}</div>
      <div className="cs-option-list">
        {servers.length && osName !== "Mac" && serverGroupID ? (
          <div key={osName} className="server-options">
            <div className="cs-label">{"SERVER POLICIES:"}</div>
            <ul>
              {servers.map((policy) => (
                <li
                  key={policy.policy_id}
                  className={`radio-container`}
                  onClick={() =>
                    selectItem({ ...selectedItem, server: policy })
                  }
                >
                  {selectedItem?.server?.policy_id === policy.policy_id ? (
                    <RadioButtonCheckedIcon className="radio" />
                  ) : (
                    <RadioButtonUncheckedIcon className="radio" />
                  )}
                  <div className={`policy-name`}>
                    {policy.friendly_name || policy.policy_name}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <></>
        )}
        {workstations.length && workstationGroupID ? (
          <div className="workstation-options">
            <div className="cs-label">{"WORKSTATION POLICIES:"}</div>
            <ul>
              {workstations.map((policy) => (
                <li
                  key={policy.policy_id}
                  className={`radio-container`}
                  onClick={() =>
                    selectItem({
                      ...selectedItem,
                      workstation: policy,
                    })
                  }
                >
                  {selectedItem?.workstation?.policy_id === policy.policy_id ? (
                    <RadioButtonCheckedIcon className="radio" />
                  ) : (
                    <RadioButtonUncheckedIcon className="radio" />
                  )}
                  <div className={`policy-name`}>
                    {policy.friendly_name || policy.policy_name}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default PolicyOptions;
