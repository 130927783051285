import CIS7 from './icons/frameworks/CIS 7.1.png'
import CIS8 from './icons/frameworks/CIS 8.0.png'
import CMMC from './icons/frameworks/CMMC.png'
import CMMC2 from './icons/frameworks/CMMC 2.0.png'
import NIST from './icons/frameworks/NIST.png'
import ISO27001 from './icons/frameworks/ISO27001.png'
import ISO27002 from './icons/frameworks/ISO27002.png'

import InventoryandControlofEnterpriseAssets from './icons/categories/inventoryandcontrolofenterpriseassets.svg'
import Maintenance from './icons/categories/maintenance.svg'
import PhysicalProtection from './icons/categories/physicalprotections.svg'
import SystemInformationIntegrity from './icons/categories/systeminformationintegrity.svg'
import SystemsInformationIntegrity from './icons/categories/systeminformationintegrity.svg'
import EmailandWebBrowserProtections from './icons/categories/emailandwebbrowserprotections.svg'
import ConfigurationManagement from './icons/categories/configurationmanagement.svg'
import InventoryandControlofSoftwareAssets from './icons/categories/inventoryandcontrolofsoftwareassets.svg'
import AccessControlManagement from './icons/categories/accesscontrolmanagement.svg'
import SystemCommunicationsProtection from './icons/categories/systemcommunicationsprotection.svg'
import RiskAssessment from './icons/categories/riskassessment.svg'
import DataRecovery from './icons/categories/datarecovery.svg'
import AuditLogManagement from './icons/categories/auditlogmanagement.svg'
import SecurityAwarenessandSkillsTraining from './icons/categories/securityawarenessandskillstraining.svg'
import Unknown from './icons/categories/unknown.svg'
import SecurityAssessment from './icons/categories/securityassessment.svg'
import RiskManagement from './icons/categories/riskmanagement.svg'
import MalwareDefenses from './icons/categories/malwaredefense.svg'
import IdentificationAuthentication from './icons/categories/identificationandauthentication.svg'
import ServiceProviderManagement from './icons/categories/serviceprovidermanagement.svg'
import IncidentResponseManagement from './icons/categories/incidentresponsemanagement.svg'
import ContinuousVulnerabilityManagement from './icons/categories/continuousvulnerabilitymanagement.svg'
import AccountManagement from './icons/categories/accountmanagement.svg'
import PersonnelSecurity from './icons/categories/personnelsecurity.svg'
import IncidentResponse from './icons/categories/incidentresponse.svg'
import NetworkInfrastructureManagement from './icons/categories/networkinfrastrucuturemanagement.svg'
import SituationalAwareness from './icons/categories/situationalawareness.svg'
import AuditAccountability from './icons/categories/auditaccountablility.svg'
import Recovery from './icons/categories/recovery.svg'
import DataProtection from './icons/categories/dataprotection.svg'
import MediaProtection from './icons/categories/mediaprotection.svg'
import AssetManagement from './icons/categories/assetmanagement.svg'
import ApplicationSoftwareSecurity from './icons/categories/applicationsoftwaresecurity.svg'
import AccessControl from './icons/categories/accesscontrol.svg'
import NetworkMonitoringandDefense from './icons/categories/networkmonitoringanddefense.svg'
import AwarenessTraining from './icons/categories/awarenessandtraining.svg'
import PenetrationTesting from './icons/categories/penetrationtesting.svg'
import SecureConfigurationofEnterpriseAssetsandSoftware
  from './icons/categories/secureconfigurationofenterpriseassetsandsoftware.svg'

export const iconMapFramework = {
  'CIS 7.1': CIS7,
  'CIS 8.0': CIS8,
  'CMMC': CMMC,
  'CMMC 2.0': CMMC2,
  'ISO 27001:2022': ISO27001,
  'ISO 27002:2022': ISO27002,
  'NIST 800-171': NIST,
  'NIST 800-172': NIST,
  'NIST 800-66 r1 (HIPAA)': NIST
}

export const iconMapCategory = {
  "InventoryandControlofEnterpriseAssets": InventoryandControlofEnterpriseAssets,
  "Maintenance": Maintenance,
  "PhysicalProtection": PhysicalProtection,
  "SystemInformationIntegrity": SystemInformationIntegrity,
  "EmailandWebBrowserProtections": EmailandWebBrowserProtections,
  "ConfigurationManagement": ConfigurationManagement,
  "InventoryandControlofSoftwareAssets": InventoryandControlofSoftwareAssets,
  "AccessControlManagement": AccessControlManagement,
  "SystemCommunicationsProtection": SystemCommunicationsProtection,
  "RiskAssessment": RiskAssessment,
  "DataRecovery": DataRecovery,
  "AuditLogManagement": AuditLogManagement,
  "SecurityAwarenessandSkillsTraining": SecurityAwarenessandSkillsTraining,
  "Unknown": Unknown,
  "SecurityAssessment": SecurityAssessment,
  "RiskManagement": RiskManagement,
  "MalwareDefenses": MalwareDefenses,
  "IdentificationAuthentication": IdentificationAuthentication,
  "ServiceProviderManagement": ServiceProviderManagement,
  "IncidentResponseManagement": IncidentResponseManagement,
  "ContinuousVulnerabilityManagement": ContinuousVulnerabilityManagement,
  "AccountManagement": AccountManagement,
  "PersonnelSecurity": PersonnelSecurity,
  "IncidentResponse": IncidentResponse,
  "NetworkInfrastructureManagement": NetworkInfrastructureManagement,
  "SituationalAwareness": SituationalAwareness,
  "SystemsInformationIntegrity": SystemsInformationIntegrity,
  "AuditAccountability": AuditAccountability,
  "Recovery": Recovery,
  "DataProtection": DataProtection,
  "MediaProtection": MediaProtection,
  "AssetManagement": AssetManagement,
  "ApplicationSoftwareSecurity": ApplicationSoftwareSecurity,
  "AccessControl": AccessControl,
  "NetworkMonitoringandDefense": NetworkMonitoringandDefense,
  "AwarenessTraining": AwarenessTraining,
  "PenetrationTesting": PenetrationTesting,
  "SecureConfigurationofEnterpriseAssetsandSoftware": SecureConfigurationofEnterpriseAssetsandSoftware
}
