export const vulnToggleTooltip = "Try out our beta 2.0 design for Vulnerabilities, which offers more information and the " +
  "ability to drill down to see what assets are affected and which assets have specific vulnerabilities more easily."

export const vulnTabsTooltips = {
  "vulnerabilities": "The Vulnerabilities page lists all currently open vulnerabilities in your infrastructure, " +
    "aggregated by vulnerability ID. You can also view all associated assets and their current status.",
  "assets": "The Assets with Vulnerabilities page lists all the vulnerable assets in your infrastructure. You can also " +
    "view all associated vulnerabilities and their current status."
}

export const overviewTooltips = {
  VULNERABILITIES: "This table highlights the top recommended vulnerabilities to address. The number indicates the total " +
    "count of unique vulnerabilities detected across your infrastructure, aggregated from Network Scan & Endpoint Scan.",
  'AFFECTED ASSETS': "This table displays the most vulnerable assets requiring immediate attention. The number represents " +
    "the total count of assets impacted by detected vulnerabilities."
}

export const totalBySeverityTooltip = "Visualizes the number of vulnerabilities over time, categorized by severity: " +
  "Critical, High, Medium, and Low."
