import React from "react";
import "../../../../styles/pages/Onboarding/Cloud/EditModal.scss";
import Modal from "../../../../components/Modal/Modal";
import ErrorIcon from "@mui/icons-material/Error";

const EditModal = ({ inputForm, header, resetModal, error, className, setResetEditModal = () => {} }) => {
  const handleClose = () => {
     setResetEditModal(true)
     resetModal(true)
  }
  return (<div className={`edit-modal ${className}`}>
    <Modal close={resetModal} handleClose={handleClose}>
      <span className="edit-header">{header}</span>
      <div className="edit-modal-content">
        <div className="edit-form-container">{inputForm}</div>
      </div>
      {error && (
        <div className="error edit cloud-modal">
          <ErrorIcon className="err-icon" /> {error}
        </div>
      )}
    </Modal>
  </div>)
}

export default EditModal;
