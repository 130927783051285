import React, {useState} from "react";
import '../../styles/pages/Connections/CloudCard.scss';
import {mapIcons} from "./config/iconMap";
import {abbreviateNumber, formatTimeAgo, formatTimeFilters} from "../../util/format";
import AccountsModal from "./AccountsModal";
import {useNavigate} from "react-router-dom";
import moment from "moment";

const CloudCard = ({data, isMobileOnly}) => {
  let name = data[0]
  let values = data[1]
  const [modalOpen, setModalOpen] = useState('')
  let cloudType = name.charAt(0).toUpperCase() + name.slice(1)
  if (name === "aws") {
    cloudType = 'AWS'
  }
  const mapValues = {
    "Last Seen": {field: 'last_seen'},
    "Events": {field: 'event_count'},
    "Investigations": {field: 'investigation_count'}
  }
  const navigate = useNavigate();

  const closeModal = (e) => {
    setModalOpen('')
    if(e) e.stopPropagation()
  }

  const onInvestigationCloudClick = (cloudType) => {
    navigate('/activity/investigations', {
      state: {
        filters: {
          investigations: {
            initialConfig: {
              page: 1,
              size: 50,
              source: ['Cloud'],
              type: [cloudType],
              start_time: formatTimeFilters(2592000).start_time,
              end_time: formatTimeFilters(2592000).end_time
            }
          }
        }
      }
    })
  }

  const navigateToOnboarding = (cloudType) => {
    if(!isMobileOnly){
      navigate("/onboarding/integrations", {
        state: {
          cloudType: cloudType,
        },
      });
    }
  };

  const formatConnected = (data) => {
    let lastSeenInHours = moment().diff(moment(data?.last_seen * 1000), 'hours')
    if (data.status === 'green') {
      return 'Connected'
    } else if (data?.status === 'red' && lastSeenInHours <= 12) {
      return 'Newly disconnected'
    } else if (data?.status === 'red' && lastSeenInHours > 12) {
      return 'Disconnected'
    }
  }

  const isDisabled = (data) => data.status !== "green" && !isMobileOnly;

  return (
    <td
      className={`cloud-card-container  ${
        values.status === "green" ? "is-connected" : ""
      } ${isDisabled(values) ? "clickable" : ""}`}
      onClick={isDisabled(values) ? () => navigateToOnboarding(name) : null}
    >     
      <div className={`cloud-type ${isDisabled(values) && "disabled"}`} >
        <img src={mapIcons(name.toLowerCase())} alt={name}/></div>
      <div className={"card-data"}>
      <p className={"connected"}>
          <span className={`circle ${values.status}`} />
          {values.status && (
            <span className={"connected-text " + values.status}>
              {formatConnected(values)}
            </span>
          )}
        </p>
        <p className={"accounts"}>
          {values?.active_connections?.length > 0 && (
            <i
              onClick={(e) => {
                e.stopPropagation();
                setModalOpen("accounts-list");
              }}
            >
              {values.active_connections.length}{" "}
              {`account${values.active_connections.length !== 1 ? "s" : ""}`} »
            </i>
          )}
        </p>
        {modalOpen === 'accounts-list' &&
        <AccountsModal close={closeModal} name={name} values={values}/>}
        <div className={"display-container"}>
          {Object.entries(mapValues).map((elem, index) => {
            let field = elem[1].field
            let value = values[field]
            let hasInvestigationLink = elem[0] === "Investigations" && value > 0
            if (field === 'last_seen') value = formatTimeAgo(values.last_seen)
            if (field === 'event_count') value = abbreviateNumber(values.event_count)
            return <div key={index} className={"display-values"}>
              <p className={"name"}>{value !== null && elem[0]}</p>
              <p
                className={"value " + (hasInvestigationLink ? ' link' : '')}
                onClick={() => hasInvestigationLink && onInvestigationCloudClick(cloudType)}
              >{values.status && value !== null && !isMobileOnly ? value : ''}</p>
            </div>
          })}
        </div>
      </div>
    </td>
  );
};

export default CloudCard;
