import React from "react";
import '../../styles/components/Modal/ConfirmModal.scss';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Modal from "./Modal";
import { Loader } from "../Loader/Loader";
import IconButton from "@mui/material/IconButton";
import ErrorIcon from "@mui/icons-material/Error";

const ConfirmModal = ({
  message,
  confirmFunc,
  cancelFunc,
  modalErrors,
  rowLoading,
  className,
}) => (
  <div
    className={`confirm-modal ${
      Array.isArray(modalErrors) && modalErrors.length ? "errors" : ""
    } ${className}`}
  >
    <Modal close={() => cancelFunc()}>
      {modalErrors?.length > 0 && !rowLoading ? (
        <div className="error-msg">
          {Array.isArray(modalErrors) ? (
            <ul>
              <li>Error occurred with the following users:</li>
              {modalErrors.map((e, i) => (
                <li key={i}>{e}</li>
              ))}
            </ul>
          ) : (
            <div className="confirm-dialog error">
              <ErrorIcon className="err-icon" />
              {modalErrors}
            </div>
          )}
        </div>
      ) : rowLoading ? (
        <Loader />
      ) : (
        <div className="confirm-dialog">
          <span>{message}</span>
          <IconButton className="confirm" onClick={() => confirmFunc()}>
            <CheckCircleOutlineIcon className="color" />
          </IconButton>
          <IconButton className="cancel" onClick={() => cancelFunc()}>
            <HighlightOffIcon className="color" />
          </IconButton>
        </div>
      )}
    </Modal>
  </div>
);

export default ConfirmModal;
