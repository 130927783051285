import React, { useEffect, useState } from "react";
import '../../styles/pages/Mobile/MobileVulnerabilitiesModal.scss';
import { Modal } from "../../components";
import {Loader} from "../../components/Loader/Loader";
import { linkText } from "../../util/format";
import { formatTime } from "../../util/format";
import NotFound from "../../navigation/NotFound";

const MobileVulnerabilitiesModal = ({ data, closeModal, setModalData, loading, endpoint, setEndpoint, fetchError, setFetchError }) => {
  const [selectedVulnerability, setSelectedVulnerability] = useState(data?.length > 0 ? data[0] : null);

  useEffect(() => {
    data?.length > 0 && selectedVulnerability === null && setSelectedVulnerability(data[0])
  }, [data, selectedVulnerability])


  const table_columns = {
    'Severity:': 'severity',
    'Timestamp:': 'last_seen',
    'Device owner email:': 'email',
    'Device owner:': `${endpoint.first_name} ${endpoint.last_name}`,
    'Device OS version:': 'os_version',
    'Device model:': 'phone_model_name',
    'Recommendation:': 'recommendation'
  }

  return (
    <div className={`mobile-vuln-modal`}>
      <Modal
        close={() => {
          setModalData([]);
          setEndpoint({});
          closeModal(false);
          setFetchError(false);
        }}
      >
        <div className="modal-header">{`${endpoint.first_name} ${endpoint.last_name}: Device Vulnerabilities`}</div>
        {loading ? <div className={"loader-container"}><Loader/></div> : fetchError ? <NotFound isError dataError className="panel service"/> :<div className="modal-content">
          <div className={"mobile-vuln-list-container"}>
            <ul className="mobile-vuln-list">
              {data && data?.map((item) => {
                return (
                  <li className={`mobile-vuln-item${item.threat_id === selectedVulnerability?.threat_id ? ' selected' : ''}`} key={item.threat_id}>
                    <span className={`mobile-vuln-item-text ${item.severity}`} onClick={() => setSelectedVulnerability(item)}>{item.display_name}</span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={"mobile-vuln-info"}>
            {selectedVulnerability &&
              <div className={"mobile-vuln-table"}>
                {Object.keys(table_columns).map((item) => {
                  let value = table_columns[item]
                  if (item !== 'Device owner:') {
                    value = selectedVulnerability[value] || endpoint[value]
                  }
                  if (item === 'Timestamp:') {
                    value = formatTime(value)
                  }
                  if (item === 'Recommendation:') {
                    value = linkText(value)
                  }
                  return (
                    <div className={`vuln-column ${table_columns[item]}`} key={table_columns[item]}>
                      <span className={`mobile-vuln-column ${table_columns[item]}`}>{item}</span><span className={`mobile-vuln-text ${table_columns[item]}`}>{value}</span>
                    </div>
                  )
                })}
              </div>
            }
          </div>
        </div>}
      </Modal>
    </div>
  );
};

export default MobileVulnerabilitiesModal;
