import Checkbox from "@mui/material/Checkbox";
import detection from '../../assets/icons/detection-transparent.svg';

export const FilterCheckbox = ({
                                 item,
                                 selectedFilters,
                                 setSelectedFilters,
                                 className,
                                 fetchAndFormatData
                               }) => (
  <div className={`filter-container ${className} ${item.name}`} key={item.name}>
    <div
        className={`legend-color ${Array.isArray(item.value) ? item.value.join('') : item.value} ${item.type}`}
        style={{backgroundColor: item.value !== "vulnerability" ? item.color : ""}}
      >{(item.icon || item.type === 'severity') &&
      <img
        src={item.type === 'severity' || item.value === 'detection' ? detection : item.icon}
        alt={item.value}
        className={className + ' filter-icon'}
      />}
      </div>
    <Checkbox
      key={item.name}
      className="custom-checkbox"
      checked={selectedFilters[item.name]}
      onChange={async () => {
        setSelectedFilters({...selectedFilters, [item.name]: !selectedFilters[item.name]})
        await fetchAndFormatData({...selectedFilters, [item.name]: !selectedFilters[item.name]})
      }}
    />
    <p className={"filter-label"}>{item.name}</p>
  </div>
);
