import '../../styles/pages/LogSearch/LogSearch.scss';
import React, {useContext, useEffect, useState} from 'react';
import UserData from "../../store/User/UserData";
import GlobalState from "../../store/GlobalState/GlobalState";
import TextField from '@mui/material/TextField';
import {DateDropdown, GenericRadio, Pagination} from "../../components";
import Table from "../../components/Table/Table";
import moment from 'moment';
import {Loader} from "../../components/Loader/Loader";
import {getFeatureFlag, getLogSearchConfig, postLogSearch} from "../../api/ApiClient";
import PaginationControls from "../../components/Table/PaginationControls";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import LogSearchRow from "./LogSearchRow";
import {trackPageView} from "../../util/analytics";
import {tooltipMapping} from "../../config/tooltipTextMapping";
import Tooltip from "../../components/Tooltip/Tooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import {isRoot} from '../../util/userControl.js';
import NotFound from "../../navigation/NotFound";
import { LogSearchDropdown } from './LogSearchDropdown';
import { captureSentryError } from '../../util/sentry';

const LogSearch = () => {
  const [userData] = useContext(UserData);
  const [azState] = useContext(GlobalState);
  const [startDate, handleStartDateChange] = useState(
    {time: new Date(moment(new Date()).subtract('seconds', 3600)), text: ''}); // Converting current time to milliseconds
  const [endDate, handleEndDateChange] = useState({time: new Date(), text: ''})
  const [showSortBy, toggleSortBy] = useState('')
  const [logSearchConfig, setLogSearchConfig] = useState([])
  const [isLoadingPageConfig, setIsLoadingPageConfig] = useState(true)
  const [isLoadingSearchResponse, setIsLoadingSearchResponse] = useState(false)
  const [searchMade, setSearchMade] = useState(false)
  const [selectedLogSource, setSelectedLogSource] = useState([])
  const [logSearchData, setLogSearchData] = useState({})
  const [postLogSearchConfig, setPostLogSearchConfig] = useState({page: 1, size: 50, log_sources: []});
  const [logSearchError, setLogSearchError] = useState(false)
  const [expandAll, setExpandAll] = useState([])
  const [sortFilters, setSortFilters] = useState({'Sort by': {filters: {Timestamp: false}}})
  const [invalidDateFormat, setInvalidDateFormat] = useState(false);
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const [selectedDate, setSelectedDate] = useState('Last Hour')
  const [fetchError, setFetchError] = useState(false);

  const resetAllFilters = () => {
    handleStartDateChange({time: new Date(moment(new Date()).subtract('seconds', 3600)), text: ''})
    handleEndDateChange({time: new Date(), text: ''})
    toggleSortBy('')
    setExpandAll([])
    setSortFilters({'Sort by': {filters: {Timestamp: false}}})
    setSelectedDate('Last Hour')
    setSelectedLogSource([])
    setPostLogSearchConfig({page: 1, size: 50, log_sources: []})
    setSearchMade(false)
  }

  const onSearchFieldChange = (event, key, type, name) => {
    let value = event.target.value
    if (!postLogSearchConfig.log_sources.some(x => x.type === type)) {
      setSelectedLogSource([...selectedLogSource, name])
      postLogSearchConfig.log_sources.push({type: type, fields: {}})
    }
    postLogSearchConfig.log_sources.map(item => {
      if (item.type.toLowerCase() === type.toLowerCase()) {
        if (value === '') {
          delete item.fields[key]
        } else {
          return item.fields[key] = value
        }
      }
      return item
    })
    setPostLogSearchConfig(postLogSearchConfig)
  }

  const paginationControls = () => {
    return (
      <PaginationControls
        resetAllFilters={resetAllFilters}
        className={"log-search-table"}
        setExpandAll={setExpandAll}
        displayData={logSearchData}
        page={postLogSearchConfig.page}
        setPage={handleChangePage}
        expandAll={expandAll}
        showDropdown={showSortBy}
        setSortFilters={setSortFilters}
        sortFilters={sortFilters}
        toggleShowDropdown={toggleSortBy}
        setParams={postSearchData}
        params={postLogSearchConfig}
        currentSearchConfig={postLogSearchConfig}
        showSortDropdown={true}
        setExpandedIndexes={setExpandedIndexes}
        expandedIndexes={expandedIndexes}
      />)
  }

  const postSearchData = async (postData) => {
    setFetchError(false);
    setIsLoadingSearchResponse(true)
    try {
      let variables = {...postData, tenant: userData?.tenant}
      setLogSearchError(false)
      let responseData = await postLogSearch(variables)
      responseData.results.forEach((x) => {
        x.display = x.display.filter(s => s.value !== '');
        x.expand = x.expand.filter(t => t.value !== '')
      });
      setLogSearchData(responseData)
    } catch (e) {
      setLogSearchError(true)
      captureSentryError(e, userData, "getLogSearchResponse API")
      setFetchError(true);
    } finally {
      setIsLoadingSearchResponse(false)
    }
    document.getElementsByTagName("body")[0]?.scrollTo(0, azState?.scrollYPosition)
  }

  const onSearch = async (page) => {
    setSearchMade(true)
    setIsLoadingSearchResponse(true)
    let start, end
    if (startDate.text === 'default') {
      start = moment(startDate.time).set({hour: 0, minute: 0, second: 0, millisecond: 0})
      end = moment(endDate.time).set({hour: 0, minute: 0, second: 0, millisecond: 0})
    } else {
      start = moment(startDate.time)
      end = moment(endDate.time)
    }
    let searchData = postLogSearchConfig
    searchData.start_time = start.toISOString(true) // setting ISOString(timeOffset) to true to keep local tz
    searchData.end_time = end.toISOString(true)
    searchData.tenant = userData?.tenant
    searchData.page = 1
    if (page) {
      searchData.page = page
    }
    setPostLogSearchConfig(searchData)
    await postSearchData(postLogSearchConfig)
  }

  useEffect(() => {
    const getPageConfig = async () => {
      if (postLogSearchConfig.log_sources.length === 0) {
        setIsLoadingPageConfig(true)
        try {
          const logSearchConfigResponse = await getLogSearchConfig()
          const iisFeatureFlag = await getFeatureFlag(userData.tenant, "iis-logs-ff")
          const includeIISLogs = isRoot(userData.userType) | iisFeatureFlag.value
          const filteredLogSources = !includeIISLogs ? logSearchConfigResponse?.log_sources.filter(elem => elem.name !== 'IIS') : logSearchConfigResponse?.log_sources
          logSearchConfigResponse.log_sources = filteredLogSources
          const allLogSources = logSearchConfigResponse?.log_sources.map(elem => elem.name)
          let field = {type: allLogSources[0].toLowerCase(), fields: {}}
          postLogSearchConfig.log_sources.length === 0 && postLogSearchConfig.log_sources.push(field)
          setLogSearchConfig(logSearchConfigResponse)
          setSelectedLogSource([allLogSources[0]])
          setPostLogSearchConfig(postLogSearchConfig)
        } catch (e) {
          captureSentryError(e, userData, "getLogSearchConfig API")
        } finally {
          setIsLoadingPageConfig(false)
        }
      }
    }
    userData?.tenant && getPageConfig()
  //  eslint-disable-next-line
  }, [userData?.tenant, userData?.userType, postLogSearchConfig])

// eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => trackPageView("LogSearch", userData, azState), [userData?.userId, azState.reactGaInitializedState]);


  const handleChangePage = async (newPage) => {
    await onSearch(newPage)
  };

  const handleSelectLogSource = (event) => {
    let name = event.target.value || event.target.innerText;
    let value = name.toLowerCase()
    if (!selectedLogSource.includes(name)) {
      setSelectedLogSource([name])
      postLogSearchConfig.log_sources = [{type: value, fields: {}}]
    }
    setPostLogSearchConfig(postLogSearchConfig)
  }

  function grabLogSources() {
    let logSources = logSearchConfig?.log_sources.map(function (e) {
      return {name: e.name, obj: e.name}
    })
    return logSources
  }

  return (
    <div className={"log-search-container all-page-container"}>
      <div className={"header-row"}>
        <Tooltip content={tooltipMapping.logSearch.title} className={"title-tooltip"} direction={"right"}>
          <p className={"page-title"}>LOG SEARCH</p>
        </Tooltip>
      </div>
      {isLoadingPageConfig ?
        <div className={"loader-container"}>
          <Loader/>
        </div>
        :
        <div className={"inner-log-container upper"}>
          <div className={"log-sources"}>
            <div className={"search-filter-container"}>
              <div className={"toggle-log-sources"}>
                <Tooltip
                  content={tooltipMapping.logSearch.logSource}
                  className={"log-source-tooltip"}
                  direction={"right"}
                >
                  <div
                    className={"search-field-title log-source-button log-source-title"}>
                    LOG SOURCE
                  </div>
                </Tooltip>
                <div className={"ls-row open-log-source"}>
                  <GenericRadio
                    openField={selectedLogSource[0]}
                    handleView={handleSelectLogSource}
                    className={"dropdown-checkbox log-source-type customize-container-radio"}
                    selected={selectedLogSource}
                    handleChange={handleSelectLogSource}
                    options={grabLogSources()}
                    icon={<RadioButtonUncheckedIcon/>}
                    checkedIcon={<RadioButtonCheckedIcon className={"checked-box-icon"}/>}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={"filter-container"}>
            {logSearchConfig?.log_sources?.map((elem, index) => {
              return selectedLogSource.includes(elem.name) && (
                <div key={index} className={"log-search-fields"}>
                  {elem?.searchable_fields.map((item, i) => {
                    let value = ''
                    let filteredByType = postLogSearchConfig?.log_sources.filter(x => x.type === elem.value)
                    if (filteredByType[0]?.fields.hasOwnProperty(item.value)) {
                      value = filteredByType[0].fields[item.value]
                    }
                    return (
                      <div className={`log-search-field ${item.name === 'Service' ? 'cloud-services' : ''}`} key={i}>
                        <span className={`search-field-title ${item.name === 'Service' || item.value === 'firewall' ? 'cloud-service-title' : ''}`}>{item.name + (item.name === 'Service' || item.value === 'firewall' ? ":" : "")}</span>
                        {item.name === "Service" || item.value === "firewall" ? 
                          <LogSearchDropdown
                            options={elem.options}
                            setPostLogSearchConfig={setPostLogSearchConfig}
                            type={elem.value}
                            field={item.value}
                          />
                          :
                          <Tooltip
                            content={tooltipMapping.logSearch.logConfig}
                            direction={"right"}
                            type={'log-source-config'}
                          >
                            <TextField
                              defaultValue={value}
                              className={"log-search-input"}
                              onChange={(event) =>
                                onSearchFieldChange(event, item.value, elem.value, elem.name)}
                              onKeyPress={(ev) => {
                                ev.key === 'Enter' && onSearch()
                              }}
                              variant="standard"
                              InputProps={{disableUnderline: true}}
                              sx={{input: {color: 'black'}}}
                            />
                          </Tooltip>
                        }
                      </div>)
                  })}
                </div>)
            })}
          </div>
          <div className={"time-actions ls-row"}>
            <div className={"time-dropdown"}>
              <DateDropdown
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
                endDate={endDate.time}
                startDate={startDate.time}
                toggleShowDropdown={() => {
                }}
                type={"log-search"}
                invalidDateFormat={invalidDateFormat}
                setInvalidDateFormat={setInvalidDateFormat}
                defaultDate={{text: 'Last Hour', time: new Date(moment(new Date()).subtract('seconds', 3600))}}
              />
              <button
                className={'search-button' + (invalidDateFormat || isLoadingSearchResponse ? " disabled" : "")}
                disabled={invalidDateFormat}
                onClick={() => onSearch()}
              >SEARCH
              </button>
              <Tooltip
                content={tooltipMapping.logSearch.searchButton}
                className={"log search-btn-tooltip"}
                direction={"left"}
              >
                <IconButton className={"search-i-icon"}>
                  <InfoOutlinedIcon fontSize="small"/>
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>}
      {searchMade &&
      <div className={"inner-log-container"}>
        {!isLoadingSearchResponse && fetchError ? <NotFound isError dataError />
        : !isLoadingSearchResponse && logSearchData?.results?.length > 0 ?
          <div className={"log-search-table-container"}>
            {paginationControls()}
            <Table page={"log-search"}>
              {
                logSearchData.results.map((item, i) => <LogSearchRow
                  key={i}
                  page={"log-search"}
                  data={item}
                  setExpandedIndexes={setExpandedIndexes}
                  expandedIndexes={expandedIndexes}
                  expandAll={expandAll}
                  isExpanded={(expandedIndexes?.includes(i) || expandAll?.includes(i))}
                  setExpandAll={setExpandAll}
                  index={i}
                />)
              }
            </Table>
            <div className={"pagination-controls"}>
              <Pagination
                setPage={handleChangePage}
                page={postLogSearchConfig.page}
                total={logSearchData.total}
              />
            </div>
          </div>
          : !isLoadingSearchResponse && logSearchData?.results?.length === 0 ?
            <div>
              {paginationControls()}
              <p className={'line-filter'}/>
              <p className={'no-results-text'}>No results, try expanding your filter parameters.</p>
            </div>
            : !logSearchError ? <div className={"loader-container"}><Loader/></div> :
              <div>
                {paginationControls()}
                <p className={'line-filter error'}/>
                <span
                  className={"error-text"}>Search timed out. Please refine your search using the filters above.</span>
              </div>}
      </div>
      }
    </div>
  )
}

export default LogSearch;
