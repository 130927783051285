import "../../../styles/pages/Onboarding/Firewall/FirewallHeader.scss";
import React from "react";

const FirewallHeader = () => {
  return (
    <div>
      <p className={"onboarding-title contacts firewall-title"}>NETWORK</p>
      <p className={"help-text"}>List of network devices that are forwarding logs to ActZero</p>
      <p className={"help-text"}>ActZero operates ONLY on 5-tuple (Source IP, port, Destination IP, port, transport protocol) data extracted from syslog of supported firewalls for security monitoring and analysis.</p>
      <p className={"help-text"}><i>Note: For Non-Log Management (LMM) customers, non-5 tuple data may not be collected.</i></p>
    </div>
  );
};

export default FirewallHeader;
