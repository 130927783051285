import "../../../styles/pages/Onboarding/VirtualMachine/VirtualMachineTable.scss";
import React from "react";
import AscSorting from "../../../assets/icons/triangle-up.svg";
import DescSorting from "../../../assets/icons/triangle-down.svg";

const VirtualMachineTableHeader = ({sortOrder, setSortOrder, setSortBy, sortBy}) => {
  const tableHeaders = {
    hostname: "HOSTNAME",
    ip_address: "LOCAL IP",
    public_ip_address: "EXTERNAL HOST IP",
    vm_roles: "TYPE",
    hypervisor_version: "HYPERVISOR",
    buttons: ""
  };

  const toggleSortOrder = () => sortOrder === "asc" ? setSortOrder("desc") : setSortOrder("asc");

  return (
    <>
      {Object.keys(tableHeaders).map((col) =>
        <td className={`table-column ${col}`} key={col}>
          <div
            onClick={() => sortBy === col ? toggleSortOrder() : setSortBy(col)}
            className={"clickable" + (sortBy === col ? " active" : "")}
          >
            {tableHeaders[col].toUpperCase()}
            <span className="sort-icon">
                {sortBy === col && sortOrder === "asc" ? <img className={"asc"} src={AscSorting} alt=""/> :
                  sortBy === col && sortOrder === "desc" && <img src={DescSorting} alt=""/>}
              </span>
          </div>
        </td>
      )}
    </>
  );
};

export default VirtualMachineTableHeader;
