import React, { useContext, useEffect, useState } from "react";
import "../../../styles/pages/Onboarding/Endpoints/CrowdStrikeModal.scss";
import UserData from "../../../store/User/UserData";
import "react-phone-number-input/style.css";
import { Modal } from "../../../components";
import LoaderButton from "../../../components/Button/LoaderButton";
import { Loader } from "../../../components/Loader/Loader";
import ErrorIcon from "@mui/icons-material/Error";
import ErrorRobot from "../../../assets/icons/error-robot.svg";
import { updateCSPolicies, getCSPolicies } from "../../../api/ApiClient";
import PolicyOptionsContainer from "./components/PolicyOptionsContainer";
import { capitalize } from "../../../util/format";
import { captureSentryError } from "../../../util/sentry";

const CrowdStrikeModal = ({ closeFunction }) => {
  const [userData] = useContext(UserData);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState("");
  const [fetchLoading, setFetchLoading] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [csPolicyOptions, setCsPolicyOptions] = useState({});
  const [workStationGroupID, setWorkstationGroupID] = useState();
  const [serverGroupID, setServerGroupID] = useState();
  const [success, setSuccess] = useState(false);
  const [windowsPolicies, setWindowsPolicies] = useState({
    server: "",
    workstation: "",
  });
  const [macPolicies, setMacPolicies] = useState({
    workstation: "",
  });
  const [linuxPolicies, setLinuxPolicies] = useState({
    server: "",
    workstation: "",
  });

  useEffect(() => {
    userData.tenant && getCSPolicyOptions();
    // eslint-disable-next-line
  }, [userData.tenant]);

  const getCSPolicyOptions = async () => {
    setFetchLoading(true);
    try {
      const csPolicies = await getCSPolicies(userData.tenant);
      const populatePolicyMapping = [
        { value: "Windows", setValue: setWindowsPolicies },
        { value: "Mac", setValue: setMacPolicies },
        { value: "Linux", setValue: setLinuxPolicies },
      ];

      populatePolicyMapping.forEach((platform) => {
        const currentPolicies = csPolicies.policies[platform.value].filter(
          (opt) => opt.has_policy
        );

        // if no policies are set, set to platform default
        const currentServer = currentPolicies.filter(
          (opt) => opt.server_group_id
        )[0] || {
          ...csPolicies.policies[platform.value].filter(
            (opt) => opt.policy_name === "platform_default"
          )[0],
          server_group_id: true,
        };

        const currentWorkstation = currentPolicies.filter(
          (opt) => opt.workstation_group_id
        )[0] || {
          ...csPolicies.policies[platform.value].filter(
            (opt) => opt.policy_name === "platform_default"
          )[0],
          workstation_group_id: true,
        };

        platform.setValue({
          server: currentServer,
          workstation: currentWorkstation,
        });

        csPolicies.policies[platform.value].sort((a, b) => {
          const aVal = a.friendly_name || a.policy_name;
          const bVal = b.friendly_name || b.policy_name;
          return aVal.localeCompare(bVal);
        });
      });

      setCsPolicyOptions(csPolicies.policies);
      setServerGroupID(csPolicies.group_ids.server_group_id);
      setWorkstationGroupID(csPolicies.group_ids.workstation_group_id);
    } catch (e) {
      captureSentryError(e, userData, "getCSPolicies API in CrowdStrikeModal.js");
      setFetchError(e.message);
    }
    setFetchLoading(false);
  };

  const handleUpdate = async (platform, type, groupId) => {
    if (platform.values[type] && !platform.values[type][`${type}_group_id`]) {
      if (!groupId) throw new Error(`Error updating: no ${type} group ID`);
      try {
        await updateCSPolicies(userData.tenant, {
          policy_id: platform.values[type].policy_id,
          platform_name: platform.platform_name,
          policy_name: platform.values[type].policy_name,
          group_id: groupId,
          group_type: capitalize(`${type}s`),
        });
      } catch (e) {
        captureSentryError(e, userData, "updateCSPolicies API in CrowdStrikeModal.js");
        setUpdateError(e?.message || "Error updating policies");
      }
    }
  };

  const updateCSPolicyOptions = async () => {
    setUpdateLoading(true);
    setUpdateError("");
    try {
      const updateMapping = [
        { values: windowsPolicies, platform_name: "Windows" },
        { values: macPolicies, platform_name: "Mac" },
        { values: linuxPolicies, platform_name: "Linux" },
      ];
      for (let i in updateMapping) {
        let platform = updateMapping[i];
        await handleUpdate(platform, "server", serverGroupID);
        await handleUpdate(platform, "workstation", workStationGroupID);
      }
      setSuccess(true);
      setTimeout(() => {
        closeFunction();
      }, 2000);
    } catch (e) {
      captureSentryError(e, userData, "updateCSPolicies API in CrowdStrikeModal.js");
      setUpdateError(e.message);
    }
    setUpdateLoading(false);
  };

  return (
    <div
      className={`crowdstrike-policy-modal-container  ${
        updateError && "update-error"
      }`}
    >
      <Modal close={() => closeFunction()} className={(workStationGroupID && !serverGroupID) || (!workStationGroupID && serverGroupID) ? `single-column-cs ${!workStationGroupID && "no-workstation"}` : !fetchLoading && !workStationGroupID && !serverGroupID ? "cs-no-results" : ""}>
        {fetchLoading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : fetchError ? (
          <img src={ErrorRobot} className="error-img" alt="Error" />
        ) : !workStationGroupID && !serverGroupID ?
        ( <div className="group-id-error">Group IDs not created for workstations and servers</div> )
          :
        (
          <>
            <div className="modal-header">{`CrowdStrike Policies: ${
              userData?.tenantConfig?.friendly_name || userData?.tenant
            }`}</div>
            <PolicyOptionsContainer
              windowsPolicies={windowsPolicies}
              setWindowsPolicies={setWindowsPolicies}
              macPolicies={macPolicies}
              setMacPolicies={setMacPolicies}
              linuxPolicies={linuxPolicies}
              setLinuxPolicies={setLinuxPolicies}
              csPolicyOptions={csPolicyOptions}
              serverGroupID={serverGroupID}
              workstationGroupID={workStationGroupID}
            />
            {updateError && (
              <div
                className={`submit-error ${
                  updateError.length > 60 && "text-wrapped"
                }`}
              >
                <ErrorIcon className="err-icon" />
                {updateError}
              </div>
            )}
            {success ? (
              <div className="loader-btn-container success">
                {"Successfully updated!"}
              </div>
            ) : (
              <LoaderButton
                loading={updateLoading}
                onClick={() => updateCSPolicyOptions()}
                text={"SAVE"}
                className="save-btn"
              />
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default CrowdStrikeModal;
