import {getInvestigations} from "../api/ApiClient";
import { downloadFile } from "./export";
import { captureSentryError } from "./sentry";

export const getInvestigationData = async (setInvestigationsLoading, setInvestigationsData, setFetchError, userData, investigationsParams, setAllData = () => {}, setExportLoading, setInvestigationsParams, yPos) => {
  setFetchError(false);
  let isExport = investigationsParams.isExport || false
  if(isExport){
    setInvestigationsParams({...investigationsParams, isExport : false})
    setExportLoading(true)
  } setInvestigationsLoading(true)
  try {
    let investigationsResponse = await getInvestigations(userData?.tenant, investigationsParams, isExport)
    if (isExport) {
      downloadFile(investigationsResponse)
      setExportLoading(false)
    } else {
      setInvestigationsData(investigationsResponse)
      setAllData(investigationsResponse.results)
    }
    document.getElementsByTagName("body")[0]?.scrollTo(0, yPos)
  } catch (e) {
    captureSentryError(e, userData, "getInvestigationsResponse API in /util/getInvestigationsData.js");
    setFetchError(true);
  } finally {
    setInvestigationsLoading(false)
  }
}