import EndpointConnection from "../Endpoint";
import FirewallConnection from "../Firewall";
import CloudConnection from "../Cloud";
import LogConnection from "../Log";
import MobileConnection from "../Mobile";
import IdentityConnection from "../Identity";

export const connectionsConfig = [
  EndpointConnection,
  FirewallConnection,
  CloudConnection,
  IdentityConnection,
  LogConnection,
  MobileConnection
]

export const mobileConnectionsConfig = [
  MobileConnection,
  LogConnection,
  EndpointConnection,
  FirewallConnection,
  CloudConnection,
  IdentityConnection
]

export const mdrLiteConnectionsConfig = [
  EndpointConnection,
  CloudConnection,
  IdentityConnection,
  MobileConnection,
  LogConnection,
  FirewallConnection
]

export const tableHeaderConfig = {
  "IP ADDRESS": {"field": "ip-address", "header": "IP ADDRESS", "sort": true, "defaultSortValue": false},
  "STATUS": {"field": "status", "header": "STATUS", "sort": false, "defaultSortValue": false},
  "VENDOR": {"field": "label", "header": "VENDOR", "sort": true, "defaultSortValue": false},
  "LAST SEEN": {"field": "last-seen", "header": "LAST SEEN", "sort": true, "defaultSortValue": false},
  "TOTAL EVENTS": {"field": "total-events", "header": "TOTAL EVENTS", "sort": true, "defaultSortValue": false},
  "COMMENTS": {"field": "comment", "header": "COMMENTS", "sort": false, "defaultSortValue": false},
}

export const firewallModalRadios = [
  {
    name: "onboarding",
    value: "Onboarded Firewalls",
    tooltip: "List of firewall IPs that have been updated in the onboarding page."
  },
  {
    name: "non-onboarding",
    value: "Non-Onboarded Syslog IPs",
    tooltip: "List of your infrastructure IP's sending syslog data seen in our pipeline."
  }
]
