export const leftColumn = {
    "Summary": {
        "CVE": {"field": "CVE",
                "transform": "linkValues",
                "params":["https://nvd.nist.gov/vuln/detail/", ","]},
        "Synopsis": {"field": "Synopsis"},
        "Description": {"field": "Description"},
        "Exploit?": {"field": "Exploit?"},
        "Exploit Ease": {"field": "Exploit Ease"},
        "Solution": {"field": "Solution"},
        "See Also": {"field": "See Also",
                     "transform": "linkValues",
                     "params":["", "\n"]}
    },
    "Host Details": {
        "DNS Name": {"field": "DNS Name"},
        "NetBIOS Name": {"field": "NetBIOS Name"},
        "IP Address": {"field": "IP Address"},
        "MAC Address": {"field": "MAC Address"},
        "Protocol": {"field": "Protocol"}
    }
}

export const rightColumn = {
    "Vulnerability Details": {
        "Vulnerability ID": {"field": "Vulnerability ID"},
        "Scan Result ID": {"field": "Scan Result ID"},
        "Severity": {"field": "Severity"},
        "First Discovered": {"field": "First Discovered", "transform": "formatTime"},
        "Last Observed": {"field": "Last Observed", "transform": "formatTime"},
        "Findings": { "field": "Findings" },
        "Risk Factor": {"field": "Risk Factor"},
        "Exploit Frameworks": {"field": "Exploit Frameworks"},
        "Check Type": {"field": "Check Type"},
        "CVSS V2 Vector": {"field": "CVSS V2 Vector"},
        "CVSS V2 Base Score": {"field": "CVSS V2 Base Score"},
        "CVSS V2 Temporal Score": {"field": "CVSS V2 Temporal Score"},
        "CVSS V3 Vector": {"field": "CVSS V3 Vector"},
        "CVSS V3 Base Score": {"field": "CVSS V3 Base Score"},
        "CVSS V3 Temporal Score": {"field": "CVSS V3 Temporal Score"},
        "Vuln Publication Date": {"field": "Vuln Publication Date",  "transform": "formatTime"},
        "Patch Publication Date": {"field": "Patch Publication Date",  "transform": "formatTime"}
    }
}

export const mainRow = {
  "Time": {"field": "timestamp", "header": "TIME", "sort": true, "defaultSortValue": false},
  "Family": {"field": "Family", "header": "FAMILY", "sort": true, "defaultSortValue": false},
  "Severity": {"field": "Severity", "header": "SEVERITY", "sort": true, "defaultSortValue": false},
  "Source": {"field": "Report Source", "header": "SOURCE", "sort": true, "defaultSortValue": false},
  "Name": {"field": "Name", "header": "NAME", "sort": true, "defaultSortValue": false},
  "IP Address": {"field": "IP Address", "header": "IP ADDRESS", "sort": true, "defaultSortValue": false},
  "CVE": {"field": "CVE", "header": "CVE", "sort": true, "defaultSortValue": false},
  "Actions": {"field": "Action", "header": "ACTION", "sort": true, "defaultSortValue": false, "checkbox": true}
}
