import React from "react";
import {Modal} from "../../components";
import Button from "../../components/Button/Button";
import '../../styles/pages/Vulnerabilities/ActionComment.scss';
import {Loader} from "../../components/Loader/Loader";
import EditPencil from './config/icons/pencil.svg';
import CloseIcon from "@mui/icons-material/Close";

const ActionComment = ({
                         setCurrentComment,
                         commentValue,
                         close,
                         existingComment,
                         submitComment,
                         setExistingComment,
                         readOnly,
                         loading
                       }) => {

  return (
    <Modal close={close} className={"vulnerabilities"} noOutsideClick={true}>
      <CloseIcon className={"comment-close-icon"} fontSize="small" onClick={(e) => close(false, e)}/>
      {loading && <Loader/>}
      {existingComment !== '' || readOnly ?
        <div>
          <div className={"modal-title"}>
            <div className={"add-comment-title"}>Comment</div>
            {!readOnly &&
            <img className={"edit-pencil"} src={EditPencil} alt={"edit comment"} onClick={() => setExistingComment('')}/>}
          </div>
          <p className={"comment-text"}>{existingComment}</p>
        </div>
        :
        <>
          <div className={"modal-title add-comment-title"}>Add comment</div>
          <textarea
            placeholder={"Enter comment here"}
            value={existingComment || commentValue}
            className={"add-comment-input"}
            onChange={(e) => setCurrentComment(e.target.value)}
          />
          <Button className={"update-button comment"} disabled={false} text={'ADD'} onClick={() => submitComment()}/>
        </>}
    </Modal>
  );
}

export default ActionComment;
