import React, {useEffect, useState} from "react";
import '../../styles/components/Tooltip/Tooltip.scss';

let timeout;
let closeTimeout;

const Tooltip = (props) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    return () => {
      if (timeout) clearTimeout(timeout);
      if (closeTimeout) clearTimeout(closeTimeout);
    }
  }, []);

  useEffect(() => {
    if (props.disable) hide();
  }, [props.disable]);

  const show = () => {
    if (props.disableTimeout) setActive(true);
    else {
      timeout = setTimeout(() => {
        setActive(true);
      }, props.delay || 500);
      closeTimeout = setTimeout(() => {
        setActive(false);
      }, props.close || 7000)
    }
  };

  const hide = () => {
    clearTimeout(timeout);
    clearTimeout(closeTimeout);
    setActive(false);
  };

  return (
    <div
      className={`tooltip-container ` + props.type}
      onMouseEnter={props.disable ? hide : show}
      onMouseLeave={hide}
    >
      {props.children}
      {active && (
        <div
          className={`tooltip ${props.direction || "right"} ${props.className}`}
          style={props?.style ? props.style : {}}
        >
          {props.content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
