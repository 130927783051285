import * as d3 from "d3";

// icons without background shape
import endpointsIcon from "../../../config/icons/azkg/endpoint.svg";
import ipIcon from "../../../config/icons/azkg/ip.svg";
import o365Icon from "../../../config/icons/azkg/o365.svg";
import oktaIcon from "../../../config/icons/azkg/okta.svg";
import detectionIcon from "../../../config/icons/azkg/detection.svg";
import rdpIcon from "../../../config/icons/azkg/rdp.svg";
import gwIcon from "../../../config/icons/azkg/Gworkspace.svg";
import vulnerabilityIcon from "../../../config/icons/azkg/vulnerability.svg";

// icons with shape
import endpoints from "../../../config/icons/zeroin/endpoint.svg";
import endpointsDiamond from "../../../config/icons/zeroin/endpoint-diamond.svg";
import ip from "../../../config/icons/zeroin/ip.svg";
import o365 from "../../../config/icons/zeroin/office365.svg";
import ip_large from "../../../config/icons/zeroin/ip-large.svg";
import okta from "../../../config/icons/zeroin/okta.svg";
import detection_low from "../../../config/icons/zeroin/detection-low.svg";
import detection_med from "../../../config/icons/zeroin/detection-medium.svg";
import detection_high from "../../../config/icons/zeroin/detection-high.svg";
import detection_crit from "../../../config/icons/zeroin/detection-critical.svg";
import vulnerability_low from "../../../config/icons/zeroin/vuln-low.svg";
import vulnerability_med from "../../../config/icons/zeroin/vuln-medium.svg";
import vulnerability_high from "../../../config/icons/zeroin/vuln-high.svg";
import vulnerability_crit from "../../../config/icons/zeroin/vuln-critical.svg";
import rdp from "../../../config/icons/zeroin/ip-rdp.svg";
import gw from "../../../config/icons/zeroin/gworkspace.svg";

// outlined icons
import ip_outlined from "../../../config/icons/zeroin/outlined/ip.svg";
import o365_outlined from "../../../config/icons/zeroin/outlined/office365.svg";
import okta_outlined from "../../../config/icons/zeroin/outlined/okta.svg";
import gw_outlined from "../../../config/icons/zeroin/outlined/gworkspace.svg";
import detection_low_outlined from "../../../config/icons/zeroin/outlined/detection-low.svg";
import detection_med_outlined from "../../../config/icons/zeroin/outlined/detection-medium.svg";
import detection_high_outlined from "../../../config/icons/zeroin/outlined/detection-high.svg";
import detection_crit_outlined from "../../../config/icons/zeroin/outlined/detection-critical.svg";
import vulnerability_low_outlined from "../../../config/icons/zeroin/outlined/vuln-low.svg";
import vulnerability_med_outlined from "../../../config/icons/zeroin/outlined/vuln-medium.svg";
import vulnerability_high_outlined from "../../../config/icons/zeroin/outlined/vuln-high.svg";
import vulnerability_crit_outlined from "../../../config/icons/zeroin/outlined/vuln-critical.svg";
import rdp_outlined from "../../../config/icons/zeroin/outlined/ip-rdp.svg";
import endpoints_outlined from "../../../config/icons/zeroin/outlined/endpoint.svg";
import endpointsDiamond_outlined from "../../../config/icons/zeroin/outlined/endpoint-diamond.svg";
import ip_large_outlined from "../../../config/icons/zeroin/outlined/ip-large.svg";

// highlighted icons
import ip_highlighted from "../../../config/icons/zeroin/highlighted/ip.svg";
import o365_highlighted from "../../../config/icons/zeroin/highlighted/office365.svg";
import okta_highlighted from "../../../config/icons/zeroin/highlighted/okta.svg";
import gw_highlighted from "../../../config/icons/zeroin/highlighted/gworkspace.svg";
import detection_low_highlighted from "../../../config/icons/zeroin/highlighted/detection-low.svg";
import detection_med_highlighted from "../../../config/icons/zeroin/highlighted/detection-medium.svg";
import detection_high_highlighted from "../../../config/icons/zeroin/highlighted/detection-high.svg";
import detection_crit_highlighted from "../../../config/icons/zeroin/highlighted/detection-critical.svg";
import vulnerability_low_highlighted from "../../../config/icons/zeroin/highlighted/vuln-low.svg";
import vulnerability_med_highlighted from "../../../config/icons/zeroin/highlighted/vuln-medium.svg";
import vulnerability_high_highlighted from "../../../config/icons/zeroin/highlighted/vuln-high.svg";
import vulnerability_crit_highlighted from "../../../config/icons/zeroin/highlighted/vuln-critical.svg";
import rdp_highlighted from "../../../config/icons/zeroin/highlighted/ip-rdp.svg";
import endpoints_highlighted from "../../../config/icons/zeroin/highlighted/endpoint.svg";
import endpointsDiamond_highlighted from "../../../config/icons/zeroin/highlighted/endpoint-diamond.svg";
import ip_large_highlighted from "../../../config/icons/zeroin/highlighted/ip-large.svg";

export const getDefaultFilters = () => {
  let defaultFilterValues = {};
  graphFilterConfig.forEach(
    (item) => (defaultFilterValues[item.name] = item.defaultSelection)
  );
  return defaultFilterValues;
};

export const categorizeFilters = (filters) => {
  const categorizedFilters = {};
  // Iterate over each filter
  for (const filter in filters) {
    // Check if the filter is true
    if (filters[filter]) {
      // Iterate over each category in graphFilterCategories
      for (const category in graphFilterCategories) {
        // Check if the current filter belongs to the category
        let filterName = filter;
        if (filter === "O365" || filter === "G-Workspace" || filter === "Okta")
          filterName = `${filterName} Account`;
        if (graphFilterCategories[category].includes(filterName)) {
          // Add the filter to the categorized object under its category
          if (!categorizedFilters[category]) {
            categorizedFilters[category] = [];
          }
          categorizedFilters[category].push(filterName);
        }
      }
    }
  }
  return categorizedFilters;
};

export const filterSections = [
  { name: "Source", value: "source" },
  { name: "Event", value: "event" },
  { name: "Severity", value: "severity" },
];

export const nodeColors = {
  aid: "#097A7A",
  untracked_endpoint: "#097A7A",
  ip: "#5F8666",
  highly_connected_ip: "#5F8666",
  rdp_ip: "#5F8666",
  o365_userid: "#3682AB",
  gw_userid: "#3682AB",
  okta_userid: "#3682AB",
  detection: "#D64426",
  vulnerability: "#E58C2F",
  critical: "#D64426",
  high: "#E58C2F",
  medium: "#CAAE0C",
  low: "#616B6B",
};

export const nodeShape = {
  aid: d3.symbolCircle,
  untracked_endpoint: d3.symbolDiamond,
  ip: d3.symbolCircle,
  highly_connected_ip: d3.symbolCircle,
  rdp_ip: d3.symbolCircle,
  o365_userid: d3.symbolCircle,
  gw_userid: d3.symbolCircle,
  okta_userid: d3.symbolCircle,
  detection: d3.symbolSquare,
  vulnerability: d3.symbolTriangle,
  critical: d3.symbolSquare,
  high: d3.symbolSquare,
  medium: d3.symbolSquare,
  low: d3.symbolSquare,
};

export const graphFilterCategories = {
  severity: ["Critical", "High", "Medium", "Low"],
  source: [
    "Endpoint",
    "IP",
    "Highly connected IP",
    "RDP IP",
    "O365 Account",
    "G-Workspace Account",
    "Okta Account",
  ],
  event: ["Vulnerabilities", "Detections"],
};

export const graphFilterConfig = [
  {
    name: "Endpoint",
    value: ["aid", "untracked_endpoint"],
    defaultSelection: true,
    type: "source",
    color: nodeColors["aid"],
    icon: endpointsIcon,
  },
  {
    name: "IP",
    value: "ip",
    defaultSelection: true,
    type: "source",
    color: nodeColors["ip"],
    icon: ipIcon,
  },
  {
    name: "Highly connected IP",
    value: "highly_connected_ip",
    defaultSelection: true,
    type: "source",
    color: nodeColors["highly_connected_ip"],
    parentCheckbox: "IP",
    icon: ipIcon,
  },
  {
    name: "RDP IP",
    value: "rdp_ip",
    defaultSelection: false,
    type: "source",
    color: nodeColors["rdp_ip"],
    icon: rdpIcon,
  },
  {
    name: "O365",
    value: "o365_userid",
    defaultSelection: true,
    type: "source",
    color: nodeColors["o365_userid"],
    icon: o365Icon,
  },
  {
    name: "G-Workspace",
    value: "gw_userid",
    defaultSelection: true,
    type: "source",
    color: nodeColors["gw_userid"],
    icon: gwIcon,
  },
  {
    name: "Okta",
    value: ["okta_userid", "stellar-okta"],
    defaultSelection: true,
    type: "source",
    color: nodeColors["okta_userid"],
    icon: oktaIcon,
  },
  {
    name: "Detections",
    value: "detection",
    defaultSelection: true,
    type: "event",
    color: nodeColors["detection"],
    icon: detectionIcon,
  },
  {
    name: "Vulnerabilities",
    value: "vulnerability",
    defaultSelection: true,
    type: "event",
    color: nodeColors["vulnerability"],
    icon: vulnerabilityIcon,
  },
  {
    name: "Critical",
    value: "critical",
    defaultSelection: true,
    type: "severity",
    color: nodeColors["critical"],
    icon: "",
  },
  {
    name: "High",
    value: "high",
    defaultSelection: true,
    type: "severity",
    color: nodeColors["high"],
    icon: "",
  },
  {
    name: "Medium",
    value: "medium",
    defaultSelection: true,
    type: "severity",
    color: nodeColors["medium"],
    icon: "",
  },
  {
    name: "Low",
    value: "low",
    defaultSelection: true,
    type: "severity",
    color: nodeColors["low"],
    icon: "",
  },
];

export const nodeConfig = [
  {
    value: "aid",
    icon: endpoints,
    outlinedIcon: endpoints_outlined,
    highlightedIcon: endpoints_highlighted,
    color: nodeColors["aid"],
  },
  {
    value: "untracked_endpoint",
    icon: endpointsDiamond,
    outlinedIcon: endpointsDiamond_outlined,
    highlightedIcon: endpointsDiamond_highlighted,
    color: nodeColors["untracked_endpoint"],
  },
  {
    value: "ip",
    icon: ip,
    outlinedIcon: ip_outlined,
    highlightedIcon: ip_highlighted,
    color: nodeColors["ip"],
  },
  {
    value: "highly_connected_ip",
    icon: ip_large,
    outlinedIcon: ip_large_outlined,
    highlightedIcon: ip_large_highlighted,
    color: nodeColors["highly_connected_ip"],
  },
  {
    value: "rdp_ip",
    icon: rdp,
    outlinedIcon: rdp_outlined,
    highlightedIcon: rdp_highlighted,
    color: nodeColors["rdp_ip"],
  },
  {
    value: "o365_userid",
    icon: o365,
    outlinedIcon: o365_outlined,
    highlightedIcon: o365_highlighted,
    color: nodeColors["o365_userid"],
  },
  {
    value: "gw_userid",
    icon: gw,
    outlinedIcon: gw_outlined,
    highlightedIcon: gw_highlighted,
    color: nodeColors["gw_userid"],
  },
  {
    value: "okta_userid",
    icon: okta,
    outlinedIcon: okta_outlined,
    highlightedIcon: okta_highlighted,
    color: nodeColors["okta_userid"],
  },
  {
    value: "stellar-okta",
    icon: okta,
    outlinedIcon: okta_outlined,
    highlightedIcon: okta_highlighted,
    color: nodeColors["okta_userid"],
  },
  {
    value: "detection-Low",
    icon: detection_low,
    outlinedIcon: detection_low_outlined,
    highlightedIcon: detection_low_highlighted,
    color: nodeColors["low"],
  },
  {
    value: "detection-Medium",
    icon: detection_med,
    outlinedIcon: detection_med_outlined,
    highlightedIcon: detection_med_highlighted,
    color: nodeColors["medium"],
  },
  {
    value: "detection-High",
    icon: detection_high,
    outlinedIcon: detection_high_outlined,
    highlightedIcon: detection_high_highlighted,
    color: nodeColors["high"],
  },
  {
    value: "detection-Critical",
    icon: detection_crit,
    outlinedIcon: detection_crit_outlined,
    highlightedIcon: detection_crit_highlighted,
    color: nodeColors["critical"],
  },
  {
    value: "vulnerability-Low",
    icon: vulnerability_low,
    outlinedIcon: vulnerability_low_outlined,
    highlightedIcon: vulnerability_low_highlighted,
    color: nodeColors["low"],
  },
  {
    value: "vulnerability-Medium",
    icon: vulnerability_med,
    outlinedIcon: vulnerability_med_outlined,
    highlightedIcon: vulnerability_med_highlighted,
    color: nodeColors["medium"],
  },
  {
    value: "vulnerability-High",
    icon: vulnerability_high,
    outlinedIcon: vulnerability_high_outlined,
    highlightedIcon: vulnerability_high_highlighted,
    color: nodeColors["high"],
  },
  {
    value: "vulnerability-Critical",
    icon: vulnerability_crit,
    outlinedIcon: vulnerability_crit_outlined,
    highlightedIcon: vulnerability_crit_highlighted,
    color: nodeColors["critical"],
  },
];

export const idMapping = {
  aid: "Hostname",
  IP: "IP Address",
  O365_userid: "UserId",
  okta_userid: "UserId",
  gw_userid: "UserId",
  Detection: "Detection Type",
  rdp_IP: "IP Address",
  untracked_endpoint: "Hostname",
  Vulnerability: "Name",
};
