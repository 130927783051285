import React from "react";
import LineChartLegend from "../../../components/D3/LineChart/Legend";
import LineChart from "../../../components/D3/LineChart/LineChart";
import {lineData} from "../config/graph-config";

const MultiTenantLineChart = ({axisData, colors, mappedGraphData, width, className, widthOffset, icon}) => {
  return (
    <div className={"chart-container"}>
      <img className={"multi-tenant-graph-icon"} src={icon} alt={className}/>
      <LineChartLegend
        colors={colors}
        data={mappedGraphData}
      />
      <LineChart
        width={width}
        plotRadius={2}
        yAxisTicks={4}
        xAxisTicks={mappedGraphData?.length}
        lineData={lineData}
        data={mappedGraphData}
        className={className}
        axis={axisData}
        widthOffset={widthOffset}
        height={140}
        marginLeft={50}
        colors={colors}
      />
    </div>
  );
};

export default MultiTenantLineChart;
