import React from "react";
import '../../styles/components/Table/Row.scss';
import '../../styles/components/Table/StatusBar.scss';
import {formatTime} from "../../util/format";
import {formatField} from "../../util/transform.js"
import SeverityImage from "../../components/Table/SeverityImage";
import Row from "../../components/Table/Row";
import ExpandedView from "./ExpandedView";
import {useNavigate} from "react-router-dom";
import {mapIcons} from "../../config/iconMapping";

const DetectionsRow = ({
                         data,
                         mapMainRow,
                         mapLeftColumn,
                         mapRightColumn,
                         page,
                         severities,
                         statuses,
                         onClick,
                         setExpandedIndexes,
                         expandedIndexes,
                         setExpandAll,
                         expandAll,
                         isExpanded,
                         index,
                         setQueryParameters
                       }) => {
  const navigate = useNavigate();
  const subsourcedSources = ['Cloud', 'Identity', 'Endpoint']
  const dataSource = subsourcedSources.includes(data.source)
    ? data.source + " / " + data[data.source + ' Subsource']
    : data.source

  const handleClick = async () => {
    if (!isExpanded) {
      if (Array.isArray(index)) index = index[0]
      expandedIndexes = [...expandedIndexes, index]
      setExpandedIndexes([...expandedIndexes])
      isExpanded = true
    } else if (expandAll.includes(index)) {
      setExpandAll(expandAll.filter(x => x !== index))
      setQueryParameters({})
    } else {
      setExpandedIndexes(expandedIndexes.filter(x => x !== index))
      setExpandAll(expandAll.filter(x => x !== index))
      isExpanded = false
      setQueryParameters({})
    }
    if (data.case_id) {
      await onClick(data.case_id)
    }
  }

  const onCaseNumberClick = (data) => {
    navigate("/activity/investigations", {
      state: {
        filters: {investigations: {initialConfig: {page: 1, size: 50, q: data["Case Number"]}}},
        case: {id: data["Case ID"]}
      }
    });
  };

  const mapDetectionsIcons = (source, col) => {
    switch (source) {
      case 'Cloud':
      case 'Identity':
      case 'Endpoint':
        return mapIcons(source, data[`${source} Subsource`], col)
      default:
        return mapIcons(source, data?.type || "", col)
    }
  }

  return (
    <>
      <Row page={page} onClick={handleClick} data={data}>
        <td className={"Time td-value"}>
          {isExpanded ?
            <div className={"expand-btn minus"}>-</div>
            : <div className={"expand-btn"}>+</div>
          }
          {formatTime(data.timestamp)}
        </td>
        <td className={"Source td-value"}>
          <div className="icon-container">
            {data?.source &&
            <img
              alt={'source-icon'}
              className={`source-icon-${data[data.source + ' Subsource']}`}
              src={mapDetectionsIcons(data.source, 1)}
            />}
          </div>
          <>{dataSource}</>
        </td>
        <td className={"Severity"}>
          <SeverityImage severities={severities} severity={data.severity}/>
        </td>
        {Object.values([mapMainRow['Detection Type'], mapMainRow['Target'], mapMainRow['Action'], mapMainRow['Case Number']])
          .map((item, index) => {
          let {value} = formatField(data, item, index)
          if (item.navigate && item.navigate === "investigations") {
            value = <button className={"clickable-link"} onClick={() => onCaseNumberClick(data)}>{value}</button>
          }
          return <td key={index} className={item.field + " td-value"}>
            {value && item.header === 'TARGET' && data.type &&
            <img className={`target-icon`} alt={'target-icon'} src={mapDetectionsIcons(data?.source, 2)}/>}
            <div>{value}</div>
          </td>
        })}
      </Row>
      {
        isExpanded && (
          subsourcedSources.includes(data.source) ?
            <ExpandedView
              isExpanded={isExpanded}
              source={dataSource}
              data={data}
              status={data.status}
              statuses={statuses}
              leftColumn={mapLeftColumn[data?.source + "-" + data[data.source + ' Subsource']]}
              rightColumn={mapRightColumn[data?.source + "-" + data[data.source + ' Subsource']]}
            /> :
            <ExpandedView
              isExpanded={isExpanded}
              source={dataSource}
              data={data}
              status={data.status}
              statuses={statuses}
              leftColumn={mapLeftColumn[data?.source]}
              rightColumn={mapRightColumn[data?.source]}
            />
        )
      }
    </>
  );
};

export default DetectionsRow;