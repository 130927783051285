import React, { useState } from "react";
import EditIcon from "../../../../assets/icons/edit-icon.svg";
import IconButton from "@mui/material/IconButton";
import Modal from "../../../../components/Modal/Modal";
import IPAddressInput from "./IPAddressInput";
import "../../../../styles/pages/Onboarding/VulnerabilityScan/IPAddressModal.scss";
import { handleEdit } from "../util";

const IPAddressModal = ({
  currTab,
  setModalData,
  modalData,
  handleFetchData,
  userData,
  scanDayOptions,
  editing,
  setEditing,
  isRootUser,
  scanDayFetchError,
  verifySubmission
}) => {
  const [editInput, setEditInput] = useState(modalData?.scan_ips?.join("\n"));
  const [editLoading, setEditLoading] = useState(false);
  const [editError, setEditError] = useState("");
  const [selectedDay, setSelectedDay] = useState(modalData?.scan_day_of_month);
  const [scanTime, setScanTime] = useState(modalData?.scan_time_of_day)
  const [scanTimezone, setScanTimezone] = useState(modalData?.timezone || 'America/New_York')

  return (
    <div className={`vuln-scan-modal ${editing && "edit"}`}>
      <Modal
        close={() => {
          setModalData();
          setEditing(false);
          setEditInput("");
        }}
      >
        <div className="modal-header">{`${editing ? "Edit " : ""}${
          modalData.scan_name
        } IP ranges`}</div>
        <div className="modal-content">
          {editing ? (
            <div className="add-ip-container edit">
              <IPAddressInput
                currTab={currTab}
                inputValue={editInput}
                setInputValue={(e) => setEditInput(e.target.value)}
                verifySubmission={verifySubmission}
                submitLoading={editLoading}
                submitError={editError}
                handleOnEditSubmit={() =>
                  handleEdit({
                    setEditError,
                    setEditLoading,
                    editInput,
                    selectedDay,
                    userData,
                    modalData,
                    handleFetchData,
                    setEditInput,
                    setModalData,
                    setEditing,
                    scanTime,
                    scanTimezone
                  })
                }
                isEdit
                showScanDay
                dayOptions={scanDayOptions}
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                scanDayFetchError={scanDayFetchError}
                scanTime={scanTime}
                setScanTime={setScanTime}
                scanTimezone={scanTimezone}
                setScanTimezone={setScanTimezone}
              />
            </div>
          ) : (
            <>
              <div className="subheader">{`${
                currTab === "external_scans" ? "External" : "Internal"
              } IP Address Ranges`}</div>
              <div className="ip-list">
                <ul>
                  {modalData?.scan_ips?.map((ip) => (
                    <li key={ip}>{ip}</li>
                  ))}
                </ul>
              </div>
              {isRootUser && (
                <IconButton
                  className={"edit-btn"}
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  <img src={EditIcon} alt={"Edit"} />
                </IconButton>
              )}
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default IPAddressModal;
