import moment from "moment";
import {capitalize} from "./format";

export const mapChartData = (data, setAxisData, setMappedGraphData, severities=[], results) => {
  if (severities.length > 0) {
    results = severities
  }

  let yMax = 0
  let xMin = data?.length > 0 ? data[0].date : 0
  let xMax = 0
  let yMin = 0

  data?.length > 0 && data.forEach((item) => {
    results.map(elem => {
      let dataName = item.severity || item.source || item.status || item.metric
      let value = {date: 0, value: 0}
      if (item.count > yMax) yMax = item.count
      if (item.count < yMin) yMin = item.count
      if (item.date < xMin) xMin = item.date
      if (item.date > xMax) xMax = item.date
      if (capitalize(dataName).replace('_', " ") === elem.name) {
        // converting the date from seconds to milliseconds
        const isoStr = moment(parseInt(item.date) * 1000).toISOString()
        value.date = new Date(isoStr.slice(0, -1))
        value.value = item.count
        elem.values.push(value)
      }
      return results
    })
  })

  const xMinIso = moment(parseInt(xMin) * 1000).toISOString()
  const xMaxISo = moment(parseInt(xMax) * 1000).toISOString()
  results = results.filter(item => item.values.length !== 0)
  let axisData = {x: {min: new Date(xMinIso.slice(0, -1)), max: new Date(xMaxISo.slice(0, -1))}, y: {max: yMax, min: yMin}}
  setAxisData(axisData)
  setMappedGraphData(results)
  return results
}

export const mapVulnerabilitiesChartData = (data) => {
  const result = {'Escape rate': [], 'Arrival rate': [], 'Burn rate': []};
  if(!data) return []
  data.forEach(({ month, arrival_rate, burn_rate, escape_rate }) => {
    const date = new Date(moment(parseInt(month) * 1000).toISOString().slice(0, -1))
    result['Escape rate'].push({ date, value: escape_rate });
    result['Arrival rate'].push({ date, value: arrival_rate });
    result['Burn rate'].push({ date, value: burn_rate });
  });

  return Object.keys(result).map(name => ({
    name,
    values: result[name]
  }));
}