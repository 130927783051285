import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import config from './config';
import { Amplify } from 'aws-amplify';

Amplify.configure(config.auth);
if (config.debug) {
    Amplify.Logger.LOG_LEVEL = 'DEBUG';
    window.LOG_LEVEL = 'DEBUG';
}

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);
