import NetworkType from './icons/network-type.svg'
import MobileType from './icons/mobile-type.svg'
import EndpointType from './icons/endpoint-type.svg';
import Cloud1Type from './icons/cloud-1-type.svg';
import Cloud2Type from './icons/cloud-2-type.svg';
import DefaultType from './icons/default-type.svg';
import CrowdStrike from './icons/crowdstrike-logo.svg';
import Defender from './icons/Defender3.svg';
import SentinelOne from './icons/sentinelone-logo.svg';
import O365 from './icons/o365-logo.svg';
import AWS from './icons/aws-logo.svg';
import GSuite from './icons/gsuite-logo.svg';
import Salesforce from './icons/salesforce-logo.svg';
import NetworkSource from './icons/firewall-logo.svg'
import Zimperium from './icons/zimperium-logo.svg';
import MobileSource from './icons/mobile-type.svg';
import DefaultSource from './icons/default-logo.svg';
import Windows from '../pages/Connections/config/icons/windows-logo.svg';
import Apple from './icons/apple-logo.svg';
import Android from './icons/android-logo.svg'
import Linux from '../pages/Connections/config/icons/linux-logo.svg';
import Okta from './icons/okta-logo.svg';
import Identity from './icons/identity-logo.svg';
import Firewall from '../pages/Connections/config/icons/firewall1-logo.svg';
import CloudVictim from './icons/cloud-victim.svg';
import DefaultVictim from './icons/default-victim.svg';
import EndpointVictim from './icons/endpoint-victim.svg';
import MobileVictim from './icons/mobile-victim.svg';
import NetworkVictim from './icons/network-victim.svg';

export const iconMap = {
  "network": NetworkType,
  "mobile": MobileType,
  "endpoint": EndpointType,
  "cloud1": Cloud1Type,
  "cloud2": Cloud2Type,
  "default": DefaultType,
  "endpointcrowdstrike": CrowdStrike,
  "endpointmicrosoft defender": Defender,
  "endpointdefender": Defender,
  "endpointsentinelone": SentinelOne,
  "cloudoffice365": O365,
  "cloudo365": O365,
  "cloudaws": AWS,
  "cloudgsuite": GSuite,
  "cloudsalesforce": Salesforce,
  "identityokta": Okta,
  "identityactive directory": Windows,
  "networksource": NetworkSource,
  "mobilezimperium": Zimperium,
  "mobilesource": MobileSource,
  "defaultsource": DefaultSource,
  "windows": Windows,
  "identity": Identity,
  "apple": Apple,
  "ios": Apple,
  "android": Android,
  "linux": Linux,
  "firewall": Firewall
}

const targetIcons = {
  "mobile": MobileVictim,
  "cloud" : CloudVictim,
  "endpoint" : EndpointVictim,
  "network" : NetworkVictim,
  "identity" : DefaultVictim,
  "default" : DefaultVictim
}

export const mapIcons = (source, type, column) => {
  source = source.toLowerCase()
  type = type.toLowerCase()
  let icon = iconMap[source]
  let key = source + type
  if (column === 0) {
    if (source === 'cloud') {
      return (type.includes('aws') || type.includes('suite') || type.includes('365')) ? iconMap['cloud1'] : iconMap['cloud2']
    } else if (icon) {
      return icon
    } else {
      return iconMap['default']
    }
  } else if (column === 1) {
    if (source === 'mobile') {
      return type === 'zimperium' ? iconMap['mobilezimperium'] : iconMap['mobilesource']
    } else if (iconMap[key]) {
      return iconMap[key]
    } else if (icon) {
      return icon
    } else {
      return iconMap['defaultsource']
    }
  } else {
    return targetIcons[source] ? targetIcons[source] : targetIcons.default;
  }
}
