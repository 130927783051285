import React, {useState} from 'react';
import '../../styles/components/Dropdown/DateDropdown.scss';
import DatePickerComponent from "./DatePickerComponent";
import Radio from '@material-ui/core/Radio';
import moment from "moment";

const DateDropdown = ({
                        handleStartDateChange,
                        handleEndDateChange,
                        endDate,
                        startDate,
                        type,
                        title = "TIME",
                        timeConfig,
                        invalidDateFormat,
                        setInvalidDateFormat,
                        togglePageFilters = false,
                        newVulnerabilities,
                        handleNewVulnerabilitiesChange,
                        selectedDate,
                        setSelectedDate,
                        domNode
                      }) => {
  const [openPicker, setOpenPicker] = useState(null);

  const timeOptions = timeConfig ? timeConfig :
    [{ text: 'Last Hour', time: 3600 },
      { text: 'Last 24 Hours', time: 86400 },
      { text: 'Last 7 Days', time: 604800 },
      { text: 'Last 30 Days', time: 2592000 }];
  const minDate = (type === 'log-search' ? moment(new Date()).startOf('day').subtract(30, 'days').valueOf() :
    moment(new Date()).startOf('day').subtract(365, 'days').valueOf());

  const handleTimeAgoDate = (value, e) => {
    let text = e.target.value || e.target.innerText;
    let now = new Date();
    // setting ISOString(timeOffset) to true to keep local tz
    let comparedTime = typeof value === 'string' ? value :
      new Date(moment(now).subtract('seconds', value).toISOString(true));
    if ((text === selectedDate) && type !== "network") {
      text = 'default'; // Will let the parent know nothing is clicked, goes back a year for all pages except log search which goes back 1 hour
      comparedTime = new Date(moment(now).subtract('seconds', type === 'log-search' ? 3600 : 31536000).toISOString(true));
      if (type?.includes("maturity-page")) {
        //resets back to all time for maturity page only
        text = '';
        comparedTime = '';
        now = '';
      }
      if (type?.includes("vulnerabilities")) {
        text = '';
      }
    }
    handleStartDateChange({ time: comparedTime, text: text });
    handleEndDateChange({ time: now, text: text });
    setSelectedDate(text);
    newVulnerabilities && handleNewVulnerabilitiesChange();
  };

  return (
    <div className="time-dropdown-container" ref={domNode}>
      <div className={`time-and-search ${type}`}>
        <div className="time-filter-col">
          <div className={`time-title-text ${type} ${togglePageFilters}`}>{title}</div>
          {timeOptions?.map((elem) => {
            let selected = elem.text === selectedDate;
            return (
              <div
                key={elem.text}
                className={`radio-container ${type} ${togglePageFilters}`}
                onClick={(e) => {
                  handleTimeAgoDate(elem.time, e);
                  newVulnerabilities && handleNewVulnerabilitiesChange();
                }}
              >
                <Radio
                  checked={selected}
                  value={elem.text}
                  name="radio-buttons"
                />
                <div key={elem.text} className="quick-time-search-filters">{elem.text}</div>
              </div>
            );
          })}
        </div>
        <div className={`time-module-container ${type}`} ref={domNode}>
          <div className="time-row-container">
            <span className={`time-row start-time ${togglePageFilters}`}>From</span>
            <DatePickerComponent
              text={'start'}
              minDate={new Date(minDate)} maxDate={endDate || new Date()}
              setIsSelected={setSelectedDate}
              isOpen={openPicker === 'start'}
              setIsOpen={(isOpen) => setOpenPicker(isOpen ? 'start' : null)}
              handleChange={handleStartDateChange}
              type={type}
              value={{ time: startDate, text: selectedDate }}
              togglePageFilters={togglePageFilters}
              invalidDateFormat={invalidDateFormat}
              setInvalidDateFormat={setInvalidDateFormat}
              newVulnerabilities={newVulnerabilities}
              handleNewVulnerabilitiesChange={handleNewVulnerabilitiesChange} />
          </div>
          <div className="time-row-container">
            <span className="time-row end-time">To</span>
            <DatePickerComponent
              text={'end'}
              minDate={startDate || new Date(minDate)} maxDate={new Date()}
              setIsSelected={setSelectedDate}
              isOpen={openPicker === 'end'}
              setIsOpen={(isOpen) => setOpenPicker(isOpen ? 'end' : null)}
              handleChange={handleEndDateChange}
              type={type}
              value={{ time: endDate, text: selectedDate }}
              togglePageFilters={togglePageFilters}
              invalidDateFormat={invalidDateFormat}
              setInvalidDateFormat={setInvalidDateFormat}
              newVulnerabilities={newVulnerabilities}
              handleNewVulnerabilitiesChange={handleNewVulnerabilitiesChange} />
          </div>
        </div>
      </div>
      {type === 'log-search' &&
      <p className="time-text">FOR DATES OLDER THAN 30 DAYS, <b className="bold-text">OPEN A TICKET</b></p>}
    </div>
  );
};

export default DateDropdown;
