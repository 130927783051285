import React from "react";
import '../../styles/components/Table/ResetFilters.scss';

const ResetFilters = ({onClick}) => {
  return (
    <button className={"reset-filters-button"} onClick={() => onClick()}>Reset Filters</button>
  );
};

export default ResetFilters;
