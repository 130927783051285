import { updateTenantData, getTenant } from "../../../../api/ApiClient";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import Checkbox from "@mui/material/Checkbox";
import { captureSentryError } from "../../../../util/sentry";

export const getTenantData = async ({
  setFetchError,
  setFetchLoading,
  tenantId,
  setTenantData,
  setInputValues,
  userData
}) => {
  setFetchError(false);
  setFetchLoading(true);
  try {
    const results = await getTenant(tenantId);
    setTenantData(results);
    const mappedValues = {
      tenant_id: results.tenant,
      friendly_name: results.friendly_name,
      is_active: results.is_active,
      cs_cid: results.cs_cid,
      stellar_id: results.stellar_id,
      customer_dl: results.customer_dl,
      sensor_ip: results.sensor_ip,
      cs_policy: results.cs_policy,
      secondary_alert_emails: results.secondary_alert_emails,
      vm_expected: results.vm_expected
    };
    setInputValues(mappedValues);
  } catch (e) {
    captureSentryError(e, userData, "getTenant API in tenant details onboarding modal");
    setFetchError(e.message);
  }
  setFetchLoading(false);
};

export const handleUpdate = async ({
  setUpdateError,
  setUpdateLoading,
  inputValues,
  valueMapping,
  tenantId,
  refreshData,
  closeFunction,
  userData,
  tenantData
}) => {
  setUpdateError("");
  setUpdateLoading(true);
  try {
    const params = {};
    Object.keys(inputValues).forEach((key) => {
      if (tenantData[key] !== inputValues[key] && valueMapping[key])
        // only update values that have been edited
        params[valueMapping[key]] = 
          key === "cs_policy"
            ? inputValues[key]?.toLowerCase()
            : inputValues[key];
    });
    Object.keys(params).length &&
      (await updateTenantData(tenantId, params, "PATCH"));
    refreshData();
    closeFunction();
  } catch (e) {
    captureSentryError(e, userData, "updateTenantData API in tenant details onboarding modal")
    setUpdateError(e.message);
  }
  setUpdateLoading(false);
};

export const renderInput = ({input, inputValues, setInputValues, showDropdown, setShowDropdown}) => {
  if (input.type === "text") {
    return (
      <input
        className="input-field text"
        defaultValue={inputValues[input.value] || ""}
        onChange={(e) =>
          setInputValues({ ...inputValues, [input.value]: e.target.value })
        }
      />
    );
  } else if (input.type === "dropdown") {
    return (
      <div className="modal-dropdown-container">
        <Dropdown
          name={
            inputValues[input.value]
              ? inputValues[input.value].toUpperCase()
              : "Select"
          }
          dropdownList={input.options.map((option) => ({
            [option.label]: false,
          }))}
          selectItem={(e) => {
            setInputValues({ ...inputValues, [input.value]: e });
            setShowDropdown(false);
          }}
          selectedItem={inputValues[input.value]}
          showDropdown={showDropdown === input.value}
          setShowDropdown={(shouldShow) =>
            setShowDropdown(!shouldShow || input.value)
          }
        />
      </div>
    );
  } else if (input.type === "toggle") {
    return (
      <div className="modal-toggle-container">
        <Checkbox
          checked={inputValues[input.value]}
          onChange={(e) =>
            setInputValues({
              ...inputValues,
              [input.value]: e.target.checked,
            })
          }
          className="modal-toggle"
        />
      </div>
    );
  }
};

export const renderLogManagementValue = (value) =>
  value ? "Enabled" : "Disabled";
