import React, {useContext, useEffect, useState} from 'react';
import UserData from "../../store/User/UserData";
import '../../styles/pages/Investigations/ExpandedView.scss';
import moment from "moment";
import {formatTime} from "../../util/format";
import {hasWriteAccess} from "../../util/userControl"
import {Loader} from "../../components/Loader/Loader";
import SeverityImage from "../../components/Table/SeverityImage";
import {getAction, getActionStatus, getInvestigationComments} from "../../api/ApiClient";
import CloseIcon from '../../config/icons/close-icon.svg';
import Border from "../../config/icons/dossier-border.svg";
import {Link} from 'react-router-dom';
import Grid from "@mui/material/Grid";
import {mapIcons} from "../../config/iconMapping";
import { captureSentryError } from '../../util/sentry';

const TableRow = ({first, second, valueClassName}) => {
  return (
    <tr>
      <td className={"label"}>{first}</td>
      <td className={"value " + valueClassName ? valueClassName : ''}>{second}</td>
    </tr>
  )
}

const Comment = ({item, index}) => (
  <div key={index}>
    <hr/>
    <div className={"row comment-fields"}>
      <div className={"comment-info"}>
        <div className={"comment-label-value-author"}>
          {item.author && <>
          <div className={"comment-label-value"}>{"RESPONSE BY:"}</div>
          <div className={"comment-author"}>{item.author.toUpperCase()}</div></>}
        </div>
        <div className={"comment-time"}>{formatTime(item.timestamp)}</div>
      </div>
      <p key={index} className={"comment"}>{item.body}</p>
    </div>
  </div>
)

const Status = ({item, index, statuses}) => {
  return (
    <div key={index}>
      <hr/>
      <div className={"row comment-fields status"}>
        <div className={"status-info"}>
          <div className={"status-label"}>STATUS CHANGE: </div> <div className={"status-value"}> {item.status.toUpperCase().replaceAll('_', ' ')}</div> <div className={"status-timestamp"}> {formatTime(item.timestamp)} </div>
        </div>
      </div>
    </div>)
}

const getCommentAndStatus = (data) => {
  let commentAndStatus = (data.comments).length > 0
    ? data.comments.concat(data.status_history)
    : data.status_history
  commentAndStatus.length > 0 && commentAndStatus.sort((a, b) => a.timestamp - b.timestamp)
  return commentAndStatus
}

const ExpandView = ({className, data, statuses, severities, closeView}) => {
  const [userData] = useContext(UserData);
  const [statusName, setStatusName] = useState('')
  const [statusColor, setStatusColor] = useState('#7D9395')
  const [currentComment, setCurrentComment] = useState('')
  const [commentsLoading, setCommentsLoading] = useState(false)
  const [actionLoading, setActionLoading] = useState(false)
  let commentAndStatus = getCommentAndStatus(data)
  const [actionStatus, setActionStatus] = useState({})
  
  useEffect(() => {
    const getStatus = () => {
      let filteredStatus = statuses.find(status => status[data.status])
      if (filteredStatus) {
        setStatusName(data.status)
        setStatusColor(filteredStatus[data.status])
      }
    }
    data.status && getStatus()
  }, [statuses, data.status])


  useEffect(() => {
    const getStatus = async (detectionID) => {
      try {
        setActionLoading(true)
        let actionStatusResponse = await getActionStatus(userData?.tenant, detectionID)
        if (actionStatusResponse?.status) {
          setActionStatus(actionStatusResponse)
        }
      } catch (e) {
        captureSentryError(e, userData, 'getActionStatus API in investigations ExpandView.js');
      } finally {
        setActionLoading(false)
      }
    }
    data?.detection_id && getStatus(data?.detection_id)
    // eslint-disable-next-line
  }, [data?.detection_id, userData?.tenant, userData?.userType])

  const addInvestigationsComment = async (e) => {
    e.preventDefault();
    setCommentsLoading(true)
    try {
      let params = {'comment': currentComment}
      await getInvestigationComments(userData?.tenant, data.case_id, params)
      closeView('event', data, true)
    } catch (e) {
      captureSentryError(e, userData, 'getInvestigationComments API in investigations ExpandView.js');
    } finally {
      setCommentsLoading(false)
    }
  }

  const submitAction = async (status) => {
    setActionLoading(true)
    try {
      let params = {'user_action': status, 'user_email': actionStatus.email}
      await getAction(userData?.tenant, data.detection_id, params)
      closeView('event', data, true)
    } catch (e) {
      captureSentryError(e, userData, 'getAction API in investigations ExpandView.js');
    } finally {
      setActionLoading(false)
    }
  }

  return (
    <div className={className}>
      <img onClick={() => closeView('event', data)} className={"close-icon"} src={CloseIcon} alt={"close"}/>
      <div className={"investigation-header"}>
        <div className={"expanded-header"}>
          <div className={"date-ticket"}>
            <p className={"date"}>{moment.unix(data.created_date).format("MM-DD-YY HH:mm")}</p>
            <p className={"ticket"}>{`${data.case_number}`}</p>
          </div>
          <SeverityImage severities={severities} severity={data.severity}/>
          <p className={"status"} style={{color: statusColor}}>
            {statusName.replace(/[^a-zA-Z]/g, " ").toUpperCase()}
          </p>
        </div>
        <div className={"header-border"} style={{backgroundImage: `url(${Border})`}}/>
        <div className={"header-content"}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <div className={"threat-container"}>
                <img className={"threat-icon"} alt={'attack-icon'} src={mapIcons(data.source, data.type, 0)}/>
                <p className={"threat-label"}>THREAT</p>
                <p className={"threat-value"} key="title"> {data.title}</p>
              </div>
            </Grid>
            {data.affected_resource &&
            <Grid item xs={5}>
              <div className={"target-container"}>
                <img className="target-icon" alt={'attack-icon'} src={mapIcons(data.source, data.type, 2)}/>
                <p className={"target-label"}>TARGET</p>
                <p>{data.affected_resource}</p>
              </div>
            </Grid>}
            <Grid item xs={data.affected_resource ? 7 : 12}>
              <table className={"threat-table" + (data.affected_resource ? "" : " left")}>
                <tbody>
                {data.assignee &&
                <TableRow first={'Reporter'} second={data.assignee}/>}
                {data.status &&
                <TableRow first={'Resolution'} second={data.status.replace(/_/g, ' ')}/>}
                {data.last_updated &&
                <TableRow first={'Last Updated'} second={formatTime(data.last_updated)}/>}
                {data.updated_by &&
                <TableRow first={'Last Updated By'} second={data.updated_by}/>}
                {data.detection_id &&
                <TableRow
                  first={'Detection ID'}
                  valueClassName={"detection-id"}
                  second={<Link
                    to='/activity/detections'
                    state={{id: data.detection_id, expandIndex: 0}}
                    className="detection-link">{data.detection_id}</Link>}
                />}
                {data.detection_type &&
                <TableRow first={'Detection Type'} second={data.detection_type}/>}
                </tbody>
              </table>
            </Grid>
            <Grid item xs={12}>
              {actionStatus?.status === 'READY' && !actionLoading ?
                <div className={"o365-button-container " + (data.affected_resource ? 'target' : '')}>
                  <button
                    className={"o365-response-button"}
                    type="submit"
                    value="Submit"
                    onClick={() => submitAction('Accept')}>RESET USER'S PASSWORD
                  </button>
                  <button
                    className={"o365-response-button reject"}
                    type="submit"
                    value="Submit"
                    onClick={() => submitAction('Reject')}>DISMISS DETECTION
                  </button>
                </div> : actionLoading && <div className={"loader-container"}><Loader/></div>}
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={"description"}>
        <hr/>
        <div className={"row"}>
          <p>{data.description}</p>
        </div>
      </div>
      {commentsLoading ? <div className={"loader-container"}><Loader/></div> :
        <div className={"comments"}>
          {commentAndStatus.map((item, index) =>
            item.status ? <Status key={index} item={item} index={index} statuses={statuses}/> :
              <Comment key={index} item={item} index={index}/>
          )}
          {hasWriteAccess(userData?.role) &&
          <div>
            <hr/>
            <div className={"user-comment"}>
              <div className="form-title">Add Comment</div>
              <form>
                <textarea
                  className={"add-comment-input"}
                  onChange={(e) => setCurrentComment(e.target.value)}
                />
              </form>
              <div
                className={"comment-submit-button"}
                type="submit"
                value="Submit"
                onClick={(e) => addInvestigationsComment(e)}>
                UPDATE
              </div>
            </div>
          </div>
          }
        </div>}
    </div>
  );
}

export default ExpandView;
