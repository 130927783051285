import React, { forwardRef } from "react";
import '../../styles/components/Table/Row.scss';

const Row = forwardRef((props, ref) => {
  let className = props?.isExpanded ? props?.page + ' ' + props.isExpanded + ' table-row' : props?.page + ' table-row'
  if (props?.className) className = `${className} ${props.className}`

  return (
    <tr
      ref={ref}
      className={className}
      onClick={props?.onClick ? (e) => props?.onClick(e, props) : null}
    >
      {props.children}
    </tr>
  );
});

export default Row;
