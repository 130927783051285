import React, { useState } from "react";
import "../../../styles/pages/Network/MapProjection.scss";
import {ComposableMap, Geographies, Geography, ZoomableGroup, Marker,} from "react-simple-maps";
import mapGeography from "../config/mapGeography.json";
import { geoMiller } from "d3-geo-projection";
import { abbreviateNumber } from "../../../util/format";
import {defaultMapPosition, maxZoom, minZoom,} from "../config/config";
import ZoomButtons from "../../../components/Graph/ZoomButtons";

const projection = geoMiller().scale(90).rotate([-12, 0, 0]);

const handleDisplayTooltip = (e, country, setDisplayTooltip) => {
  setDisplayTooltip(() => {
    let circleCoords = e.target.getBoundingClientRect();
    let centerX = circleCoords.left + circleCoords.width / 2;
    let centerY = circleCoords.top + circleCoords.height / 2;
    return {
      country: country,
      position: { x: centerX, y: centerY },
    };
  });
};

const handleHideTooltip = (e, setDisplayTooltip) => {
  !e.relatedTarget?.classList?.contains("country-circle") &&
    !e.relatedTarget?.classList?.contains("country-tooltip-container") &&
    setDisplayTooltip();
};

const MapProjection = ({ mapStatistics, type }) => {
  const [displayTooltip, setDisplayTooltip] = useState();
  const [mapPosition, setMapPosition] = useState(defaultMapPosition);

  const handleZoomButton = (type) =>
    setMapPosition((prev) => ({
      coordinates: prev.coordinates,
      zoom:
        type === "zoom-in"
          ? Math.min(prev.zoom + 1, maxZoom)
          : Math.max(prev.zoom - 1, minZoom),
    }));

  return (
    <div className={`map ${type}`}>
      {displayTooltip && displayTooltip.country.country && (
        <div
          className={`country-tooltip-container ${displayTooltip.country.country}`}
          style={{
            left: displayTooltip.position.x + 25,
            top: displayTooltip.position.y - 25,
          }}
          onMouseLeave={(e) => handleHideTooltip(e, setDisplayTooltip)}
        >
          <div className="country-name">
            {displayTooltip.country?.name?.toUpperCase()}
          </div>
          <div className="tooltip-right">
            <div className="country-value">
              {`${abbreviateNumber(displayTooltip.country.total)}`}
            </div>
            <div className="country-allowed">
              {displayTooltip.country.allow ? "ALLOW" : "BLOCKED"}
            </div>
          </div>
        </div>
      )}
      <div className="map-title">{`${type.toUpperCase()} TRAFFIC`}</div>
      <div className="projection-container">
        <ComposableMap projection={projection} className="projection">
          <ZoomableGroup
            onMoveStart={() => setDisplayTooltip()}
            onMoveEnd={({ coordinates, zoom }) =>
              setMapPosition({ zoom, coordinates })
            }
            zoom={mapPosition.zoom}
            center={mapPosition.coordinates}
          >
            <Geographies geography={mapGeography}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      style={{
                        default: { outline: "none" },
                        hover: { outline: "none" },
                        pressed: { outline: "none" },
                      }}
                    />
                  );
                })
              }
            </Geographies>
            {mapStatistics.map((country, i) => (
              <Marker
                key={`$${country.name} ${i}`}
                coordinates={[country.longitude, country.latitude]}
                className="country-marker"
              >
                <circle
                  r={country.value}
                  className={`country-circle ${country.country}`}
                  onMouseEnter={(e) =>
                    handleDisplayTooltip(e, country, setDisplayTooltip)
                  }
                  onMouseLeave={(e) => handleHideTooltip(e, setDisplayTooltip)}
                />
              </Marker>
            ))}
          </ZoomableGroup>
        </ComposableMap>
        <ZoomButtons handleZoomButton={handleZoomButton}/>
      </div>
    </div>
  );
};
export default MapProjection;
