import React, { useState } from "react";
import ConfirmModal from "../../../../components/Modal/ConfirmModal";

const DeleteModal = ({
  confirmFunc,
  cancelFunc,
  modalErrors,
  className,
  message
}) => {
  const [rowLoading, setRowLoading] = useState(false);

  async function confirm() {
    setRowLoading(true);
    await confirmFunc();
    setRowLoading(false);
  }

  return (
    <ConfirmModal
      message={message}
      confirmFunc={() => confirm()}
      cancelFunc={cancelFunc}
      modalErrors={modalErrors}
      rowLoading={rowLoading}
      className={className}
    />
  );
};

export default DeleteModal;
