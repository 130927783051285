import React from "react";
import '../../styles/components/Radio/Radio.scss';

const Radio = ({leftLabel, rightLabel, onClick, defaultChecked}) => (
  <div className="form-group">
    <div className="switch-stack">
      <span className="label">{leftLabel}</span>
      <label className="switch" onClick={onClick}>
        <input type="checkbox" defaultChecked={defaultChecked} />
        <span className="slider" />
      </label>
      <span className="label">{rightLabel}</span>
    </div>
  </div>
);

export default Radio;
