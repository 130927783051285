import ReactGA from "react-ga4";
import { isAZUser } from "./userControl";

export function trackPageView(page, userData, azState) {
    if (userData?.userId && azState?.reactGaInitializedState && !isAZUser(userData)) {
        ReactGA.send({ hitType: "pageview", page });
    }
}

export function trackClick(userData, category, action) {
    !isAZUser(userData) && ReactGA.event({ category, action })
}