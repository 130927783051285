import React from 'react';
import '../../styles/pages/Dashboard/KeyMetrics.scss';
import CircularGraph from "../../components/D3/CircularGraph/CircularGraph";
import {tooltipMapping} from "../../config/tooltipTextMapping";
import Tooltip from "../../components/Tooltip/Tooltip";
import {Loader} from "../../components/Loader/Loader";
import NotFound from '../../navigation/NotFound';

const KeyMetrics = React.memo(({loadingKeyMetricsData, keyMetricsData, images, fetchError, title}) => {
  const calculateColor = (name, value) => {
    if (name === 'MTTFR (Critical)') {
      return value <= 24 ? '#cff319' : (value > 24 && value < 50) ? '#ffe133' : (value > 50 && value < 75) ? '#FFA415' : '#d64426'
    } else if ((name === 'Noise Reduction' && value < 0) || (name === 'Block Rate' && !value && value === 0)) {
      return '#6DB6AF'
   } else {
      return value <= 24 ? '#d64426' : (value > 24 && value < 50) ? '#FFA415' : (value >= 50 && value < 75) ? '#ffe133' : '#cff319'
    }
  }

  return (
    <div className={"key-metrics-container"}>
      {fetchError ? <NotFound isError dataError className={"panel"}/> :
      <div className={"key-metrics-parent"}>
        <p className={"circle-graph-title key-metrics-title"}><b>{title}</b></p>
        {loadingKeyMetricsData && <Loader/>}
        <div className={"key-metrics"}>
          {keyMetricsData.map((item, index) => (
            <div className={"key-metric"} key={index} style={{backgroundImage: `url(${images[index]}`}}>
              <CircularGraph
                containerName={'key-metrics'}
                data={item}
                color={calculateColor(item.name, item.value * 100)}
                xAxis={((item.name === 'Block Rate' && !item.value) || (item.name === 'Noise Reduction' && item.value < 0)) ? "0" : "5"}
                percentText={item.name === 'Block Rate' && !item.value && '- -'}
              />
              <Tooltip
                content={tooltipMapping.dashboard.keyMetrics[item.name]}
                direction="bottom"
                className={"key-metrics-tooltip"}
              >
                <p>{item.name}</p>
              </Tooltip>
            </div>
          ))}
        </div>
      </div>
    }
    </div>
  )
})

export default KeyMetrics;
