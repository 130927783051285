import CriticalSeverity from "../assets/icons/critical-severity.svg";
import HighSeverity from "../assets/icons/high-severity.svg";
import ImportantSeverity from "../assets/icons/important-severity.svg";
import ModerateSeverity from "../assets/icons/moderate-severity.svg";
import MediumSeverity from "../assets/icons/medium-severity.svg";
import LowSeverity from "../assets/icons/low-severity.svg";
import TreeTargetIcon from "../assets/icons/tree-warning.svg";
import TreeEndIcon from "../assets/icons/tree-warning-child.svg";
import TreeParentIcon from "../assets/icons/tree-root.svg";
import AscSorting from "../assets/icons/triangle-up.svg";
import DescSorting from "../assets/icons/triangle-down.svg";
import AWS from "../assets/logos/aws.svg";
import GSuite from "../assets/logos/gsuite.svg";
import O365 from "../assets/logos/o365.svg";
import Salesforce from "../assets/logos/salesforce.svg";
import Okta from "../assets/logos/okta.svg";

export const severities = {
  Critical: CriticalSeverity,
  High: HighSeverity,
  Important: ImportantSeverity,
  Moderate: ModerateSeverity,
  Medium: MediumSeverity,
  Low: LowSeverity,
}

export const processTree = {
  TargetIcon: TreeTargetIcon,
  ParentIcon: TreeParentIcon,
  EndIcon: TreeEndIcon
}

export const sorting = {
  Desc: DescSorting,
  Asc: AscSorting
}

export const timeMapping = {
  '< 3 Hours': 10800,
  '< 12 Hours': 43200,
  'Today': 86400,
  'Past Week': 604800,
  'Past Month': 2592000
}

export const cloudMapping = {
  'aws': AWS,
  "gsuite": GSuite,
  "o365": O365,
  "salesforce": Salesforce,
  "okta": Okta,
  '+ ADD ADDITIONAL SERVICE>>': 'data:,'
}
