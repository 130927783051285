export const createInputConfig = [
  { name: "Summary", value: "subject", required: true, type: "input" },
  {
    name: "Priority",
    value: "priority",
    required: true,
    type: "dropdown",
    options: [
      { name: "Informational", value: "informational" },
      { name: "Low", value: "low" },
      { name: "Medium", value: "medium" },
      { name: "High", value: "high" },
      { name: "Critical", value: "critical" },
    ],
  },
  {
    name: "Regarding",
    value: "category",
    required: true,
    type: "dropdown",
    options: [
      { name: "Incident", value: "incident" },
      { name: "AllowListing", value: "allowlisted" },
      { name: "Virtual machine", value: "virtual machine" },
      { name: "Endpoint Agents", value: "endpoint" },
      { name: "Vulnerability Scans", value: "tenable" },
      { name: "Other", value: "other" },
    ],
  },
  {
    name: "Description",
    value: "description",
    required: true,
    type: "textarea",
  },
];

export const editInputConfig = [
  { name: "Summary", value: "subject", required: true, type: "input" },
  {
    name: "Priority",
    value: "priority",
    required: true,
    type: "readOnly",
  },
  {
    name: "Status",
    value: "status",
    required: true,
    type: "dropdown",
  },
  {
    name: "Description",
    value: "description",
    required: true,
    type: "textarea",
  },
];

export const defaultInputValues = {
  summary: "",
  priority: "",
  regarding: "",
  description: "",
};

export const requiredInputs = [
  { value: "subject", error: "Must enter a summary" },
  { value: "priority", error: "Must select a priority" },
  { value: "category", error: "Must select a category (regarding)" },
];
