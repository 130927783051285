import {useEffect, useRef} from 'react';

export const useOnClickOutside = (handler, ref) => {
  let domNode = useRef()
  if (ref) domNode = ref

  useEffect(() => {
    const listener = event => {
      if (domNode.current && !domNode.current.contains(event.target) && !event.target.classList.contains('list-item')) {
        handler(event)
      }
    };
    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  });
  return domNode
}
