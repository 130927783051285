import React, {useContext, useState} from "react";
import FirewallTable from "./FirewallTable";
import FirewallHeader from "./FirewallHeader";
import AddFirewallForm from "./AddFirewallForm";
import {firewallOnboardingAPI} from "../../../api/ApiClient";
import UserData from "../../../store/User/UserData";
import EditModal from "../Cloud/components/EditModal";
import DeleteModal from "../Cloud/components/DeleteModal";
import { captureSentryError } from "../../../util/sentry";

const Firewall = () => {
  const [userData] = useContext(UserData);
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [fetchError, setFetchError] = useState(false)
  const [currentModal, setCurrentModal] = useState({
    action: null,
    data: null,
  });
  const [errors, setErrors] = useState({
    fetch: "",
    add: "",
    edit: "",
    delete: "",
  });

  const resetModal = () => {
    resetErrors();
    setCurrentModal({action: null, data: null});
  };

  const resetErrors = () => setErrors({fetch: "", add: "", edit: "", delete: "", test: ""});

  const getFirewallOnboardingData = async () => {
    setLoading(true)
    try {
      const tableData = await firewallOnboardingAPI('GET', userData?.tenant)
      setTableData(tableData?.results.filter(item => item.type !== "netscaler")) // TODO: remove filter once netscaler is no longer returned (tenants: eamc and championhomes)
      resetModal()
    } catch (e) {
      captureSentryError(e, userData, "GET firewallOnboardingAPI")
      setFetchError(true);
    } finally {
      setLoading(false)
      resetModal()
    }
  }

  const deleteAccountHandler = async (idList) => {
    setLoading(true)
    try {
      await firewallOnboardingAPI("DELETE", userData?.tenant, {"firewall_ids": idList})
      await getFirewallOnboardingData()
    } catch (e) {
      captureSentryError(e, userData, "DELETE firewallOnboardingAPI")
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="firewall-container">
      {currentModal.action === "edit" && (
        <EditModal
          header={`Edit firewall:`}
          error={errors.edit}
          resetModal={resetModal}
          className={'edit-firewall'}
          inputForm={<AddFirewallForm
            resetModal={resetModal}
            existingData={currentModal.data}
            getData={getFirewallOnboardingData}
          />}
        />
      )}
      {currentModal.action === "delete" && (
        <DeleteModal
          confirmFunc={() => deleteAccountHandler([currentModal?.data?._id])}
          cancelFunc={resetModal}
          modalErrors={errors.delete}
          className="cloud-modal"
          message={"Delete firewall?"}
        />
      )}
      <FirewallHeader/>
      <FirewallTable
        setCurrentModal={setCurrentModal}
        getData={getFirewallOnboardingData}
        loading={loading}
        tableData={tableData}
        setTableData={setTableData}
        fetchError={fetchError}
        deleteAccountHandler={deleteAccountHandler}
      />
      <AddFirewallForm getData={getFirewallOnboardingData}/>
    </div>);
};

export default Firewall;
