import React from 'react';
import '../../../styles/pages/Detections/DataList.scss';
import GreyArrowUp from '../../../assets/icons/grey-arrow-up.svg';
import GreyArrowDown from '../../../assets/icons/grey-arrow-down.svg';
import GreenArrowDown from '../../../assets/icons/green-arrow-down.svg';
import YellowArrowUp from '../../../assets/icons/yellow-arrow-up.svg';
import {formatDisplayValue, formatPercentage, getTrendDirection} from "../../../util/format";

const DataTableRow = ({percentChange, title, data}) => (
  <tr className={"data-table-row"}>
    <td className={`data-table-cell`}>{title}</td>
    <td
      className={"amount " + getTrendDirection(data?.total_count.previous, data?.total_count.current, 'green', 'yellow')}>
      {percentChange !== null ? `${percentChange}%` : 'No data'}
      {(percentChange !== null && percentChange !== 0) && <img
        className={"arrow"}
        alt={"critical"}
        src={getTrendDirection(data?.total_count.previous, data?.total_count.current, GreenArrowDown, YellowArrowUp)}
      />}
    </td>
  </tr>
)

const Trends = ({data}) => {
  const findPercentChange = () => {
    const change = data?.total_count.current - data?.total_count.previous;
    return formatPercentage((change / data?.total_count.previous) * 100)
  }

  return (
    <div className={`data-list-container-detections`}>
      <span className={`table-title`}>Monthly Trend Review</span>
      <table className="table-container">
        <tbody>
        {data?.total_count.previous ? <DataTableRow percentChange={findPercentChange()} title={"Overall Change (Previous Month)"} data={data}/> : <></>}
        {data?.severities && Object.entries(data?.severities)
          .filter(([key, _]) => key !== 'Informational').map(([name, value], index) => {
            return <tr key={index} className={"data-table-row"}>
              <td>Total<span className={name.toLowerCase()}> {name}</span> Changes Over the Last Month</td>
              <td className={"amount severity"}> {formatDisplayValue(value?.current, value?.previous)}
                {(value?.previous !== null && value?.previous !== value?.current) &&
                <img
                  className={"arrow"}
                  alt={"critical"}
                  src={getTrendDirection(value?.previous, value?.current, GreyArrowDown, GreyArrowUp)}
                />}
              </td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Trends;