import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import '../../styles/components/Dropdown/GenericRadio.scss';


const GenericRadio = ({
                            options,
                            handleChange,
                            selected,
                            className,
                            handleView,
                            openField,
                            icon,
                            checkedIcon,
                            filters,
                            name
                          }) => {

  return (
    <div className={className}>
      {options?.map((option, index) => (
        <MenuItem key={index} className={(openField === option.name ? 'open-field' : '')}>
          <ListItemIcon>
            <Checkbox
              onClick={(event) => handleChange(event, name, option.value || option.name)}
              icon={icon}
              checkedIcon={checkedIcon}
              value={option.value || option.name}
              checked={(selected !== undefined ? selected.includes(option.value || option.name) : filters[option.value || option.name])}
            />
          </ListItemIcon>
          {option.value ? <ListItemText
                               primary={option.name}
                               onClick={(event) => handleView(event, name, option.value)}
                             />
          : <ListItemText
              primary={option.name}
              onClick={(event) => handleView(event, name, option.name)}
            />}
        </MenuItem>
      ))}
    </div>
  );
}

export default GenericRadio;
