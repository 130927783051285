import React from 'react';
import '../../styles/components/Table/SeverityImage.scss';
import {stringifyObject} from "../../util/format.js"

const SeverityImage = ({severities, severity}) => {
  return (
      <div className={severity.toLowerCase() + " severity-container"}>
        {severity.toLowerCase() !== 'informational' && <img className={severity +" severity-img"} alt={severities[severity]} src={severities[severity]}/>}
          <div className={severity + " severity ls-table-cell"}>
               {stringifyObject(severity)}
          </div>
      </div>
  );
}

export default SeverityImage;
