export const serviceUnits = {
  'endpoints': ['0-25', '26-50', '51-75', '76-100', '101-150', '151-200', '201-250', '251-300', '301-350', '351-400', '401-450', '451-500', '501-550', '551-600', '601-650', '651-700', '701-750', '751-800', '801-850', '851-900', '901-1000', '1001-1250', '1251-1500', '1501-1750', '1751-2000', '2001-2250', '2251-2500'],
  'mobile_endpoints': ['0-25', '26-50', '51-100', '101-200', '201-300', '301-400', '401-500', '501-750', '751-1000', '1001-1500', '1501-2000', '2001-2500'],
  'volume': ['0-5 GB', '5-10 GB', '10-15 GB', '15-20 GB', '20-25 GB', '25-30 GB', '30-40 GB', '40-50 GB', '50-75 GB', '77-100 GB'],
  'duration': ['1 year', '2 years', '3 years', '4 years', '5 years']
}

const checkOffering = (enabled, selection, missingUnits, key) => {
  if (enabled && selection === "") {
    missingUnits.push(key)
  }
}

export const validateOfferings = (offerings, missingUnits) => {
  Object.keys(offerings).map((key) => {
    let service = offerings[key]
    if (key === 'Managed Detection and Response (MDR)') {
      checkOffering(service.crowdstrike_enabled, service.crowdstrike_selection, missingUnits, `${key}-cs`)
      checkOffering(service.defender_enabled, service.defender_selection, missingUnits, `${key}-defender`)
      if (!service.crowdstrike_enabled && service.crowdstrike_selection !== "") {
        service.crowdstrike_selection = ""
      }
      if (!service.defender_enabled && service.defender_selection !== "") {
        service.defender_selection = ""
      }
    }
    else if (key.includes('Log')) {
      checkOffering(service.enabled, service.selection, missingUnits, key)
      checkOffering(service.enabled, service.storageSelection, missingUnits, `${key}-storage`)
      if (!service.enabled && (service.selection !== "" || service.storageSelection !== "")) {
        service.selection = ""
        service.storageSelection = ""
      }
    } else {
      checkOffering(service.enabled, service.selection, missingUnits, key)
      if (!service.enabled && service.selection !== "") {
        service.selection = ""
      }
    }
    return key
  })
}

export const refreshOfferings = () => {
  return {
    'Managed Detection and Response (MDR)': {crowdstrike_enabled: false, crowdstrike_selection: "", defender_enabled: false, defender_selection: ""},
    'Enhanced Vulnerability Management': {enabled: false, selection: ""},
    'Device Control': {enabled: false, selection: ""},
    'Mobile MDR': {enabled: false, selection: ""},
    'Log Management': {enabled: false, selection: "", storageSelection: ""},
    'Log Storage': {enabled: false, selection: "", storageSelection: ""},
    'vCISO': {enabled: false},
  }
}

export const tooltipContent = {
  'Managed Detection and Response (MDR)': "Activate MDR protection by choosing the necessary Endpoint Detection & Response (EDR) platform and select the endpoint quantity for protection.",
  "Enhanced Vulnerability Management": "Activate this module for ongoing host-based vulnerability scanning. \n\nNote: This requires Crowdstrike to be selected as part of EDR and involves an additional cost for activation. This module is customer-managed.",
  "Device Control": "Activate this module for monitoring and controlling portable media devices like USB. \n\nNote: This requires Crowdstrike to be selected as part of EDR and involves an additional cost for activation. This module is customer-managed..",
  "Mobile MDR": "Activate this module  by selecting the required mobile endpoint quantity for protection. \n\nNote: This module incurs an extra cost for activation",
  "Log Management": "Activate this module for log ingestion, storage, correlation, alerting & web portal search. Specify the amount of logs to be ingested in gigabytes (GB) and duration of storage. \n\nNote: This module incurs an extra cost for activation",
  "Log Storage": "Activate this module for log ingestion, storage (log storage only, no search). Specify the amount of logs to be ingested in gigabytes (GB) and duration of storage. \n\nNote: This module incurs an extra cost for activation",
  "vCISO": "Activate this module for vCISO consultation for security and risk management. \n\nNote: This module incurs an extra cost for activation and currently capped at 4 hours/quarter"
}