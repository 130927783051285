import React, {useContext, useEffect, useState, useRef} from 'react';
import '../../../styles/pages/Hosts/Endpoints.scss';
import PageFilters from "../../../components/Filters/PageFilters";
import GlobalState from "../../../store/GlobalState/GlobalState";
import PaginationControls from "../../../components/Table/PaginationControls";
import {endpointFilterConfig, resetEndpointFilterConfig} from "../filterconfig";
import Table from "../../../components/Table/Table";
import {Loader} from "../../../components/Loader/Loader";
import {filterData} from "../../../util/format.js"
import {Pagination} from "../../../components";
import EndpointsRow from "./EndpointsRow";
import moment from 'moment';
import {downloadFile} from "../../../util/export"
import UserData from "../../../store/User/UserData";
import {leftColumn, mainRow, rightColumn} from "../../../components/Mappings/Endpoints";
import TableHeader from "../../../components/Table/TableHeader";
import {getEndpoints} from "../../../api/ApiClient";
import {setSortFilterValue} from "../../../util/handleSortResults";
import {setResetFilter} from "../../../util/handleResetFilter";
import {trackPageView} from "../../../util/analytics";
import NotFound from "../../../navigation/NotFound";
import _ from 'lodash';
import { captureSentryError } from '../../../util/sentry.js';

const Endpoints = ({setActiveTab, setCount, defaultFilters}) => {
  const [userData] = useContext(UserData);
  const [azState] = useContext(GlobalState);
  const [pageCount] = useState(50)
  const today = new Date()
  const defaultStartTime = new Date(new Date().setDate(today.getDate() - 30))
  const [filterList, setOpenFilters] = useState(defaultFilters || endpointFilterConfig)
  const [endpointsParams, setEndpointsParams] = useState({})
  const [page, setPage] = useState(1)
  let [sortFilters, setSortFilters] = useState([])
  const [expandAll, setExpandAll] = useState([])
  const [showDropdown, toggleShowDropdown] = useState('')
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const [endpointsLoading, setEndpointsLoading] = useState(false);
  const [endpointsData, setEndpointsData] = useState({results: [], total: 0})
  const [isReset, setIsReset] = useState(false)
  const [selectedDate, setSelectedDate] = useState('Last 30 Days')
  const [defaultDate, setDefaultDate] = useState({text: 'Last 30 Days', time: defaultStartTime})
  const [initialConfig, setInitialConfig] = useState({
    start_time: moment(defaultStartTime).format(),
    end_time: moment(today).format(),
    page: 1,
    size: pageCount
  })
  const [exportLoading, setExportLoading] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const prevParams = useRef({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => trackPageView("Endpoints", userData, azState), [userData?.userId, azState.reactGaInitializedState]);

  const resetAllFilters = () => {
    setIsReset(true)
    handleClose()
    setSortFilterValue([], mainRow, setSortFilters)
    setResetFilter(endpointFilterConfig, resetEndpointFilterConfig, setOpenFilters)
    setEndpointsParams({
      start_time: moment(defaultStartTime).format(),
      end_time: moment(today).format(),
      page: 1,
      size: pageCount
    })
    setDefaultDate({text: 'Last 30 days', time: defaultStartTime})
    setInitialConfig({
      start_time: moment(defaultStartTime).format(),
      end_time: moment(today).format(),
      page: 1,
      size: pageCount
    })
    setSelectedDate('Last 30 Days')
    setIsReset(false)
  }

  const handleClose = () => {
    setExpandAll([])
    setExpandedIndexes([])
  }

  const handleChangePage = async (currPage) => {
    setPage(currPage)
    setEndpointsParams({...endpointsParams, page: currPage})
  }

  useEffect(() => {
    setSortFilterValue([], mainRow, setSortFilters)
    setResetFilter(endpointFilterConfig, resetEndpointFilterConfig, setOpenFilters)
  }, [])

  useEffect(() => {
    setPage(1)
    const filters = defaultFilters || filterList;
    filterData(filters, endpointsParams, pageCount, 1, setEndpointsParams, initialConfig, setInitialConfig)
    // eslint-disable-next-line
  }, [filterList, pageCount])

  useEffect(() => {
    const getEndpointsData = async () => {
      setFetchError(false);
      setExpandedIndexes([]);
      let isExport = endpointsParams.isExport || false
      if (isExport) {
        setEndpointsParams({...endpointsParams, isExport: false})
        setExportLoading(true)
      } else setEndpointsLoading(true)
      try {
        const endpointsResponse = await getEndpoints(userData?.tenant, endpointsParams, isExport)
        if (isExport) {
          downloadFile(endpointsResponse)
          setExportLoading(false)
        } else {
          setEndpointsData(endpointsResponse)
          setCount(endpointsResponse?.total || 0)
        }
        document.getElementsByTagName("body")[0]?.scrollTo(0, azState?.scrollYPosition)
      } catch (e) {
        captureSentryError(e, userData, 'getEndpointsResponse API in Endpoints.js');
        setFetchError(true);
      } finally {
        setEndpointsLoading(false)
      }
    }
    if(!_.isEqual(prevParams.current, endpointsParams)) {
      userData?.tenant && Object.keys(endpointsParams).length > 0 && getEndpointsData()
      prevParams.current = endpointsParams;
    }
    // eslint-disable-next-line
  }, [userData?.tenant, userData?.userType, endpointsParams, setCount]);

  return (
    <div className={"endpoints-container all-page-container"}>
      <PageFilters
        isReset={isReset}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        className={"hosts-filters endpoints"}
        handleClose={handleClose}
        filterList={defaultFilters || filterList}
        setOpenFilters={setOpenFilters}
        setParams={setEndpointsParams}
        setExpandAll={setExpandAll}
        defaultDate={defaultDate}
      />
      {
        !endpointsLoading &&
        <PaginationControls
          resetAllFilters={resetAllFilters}
          setExpandAll={setExpandAll}
          displayData={endpointsData}
          page={page}
          setPage={handleChangePage}
          expandAll={expandAll}
          showDropdown={showDropdown}
          toggleShowDropdown={toggleShowDropdown}
          setParams={setEndpointsParams}
          params={endpointsParams}
          className={"endpoint-pagination"}
          setExpandedIndexes={setExpandedIndexes}
          expandedIndexes={expandedIndexes}
          bauExportLoading={exportLoading}
        />
      }
      {endpointsLoading ?
        <div className={"loader-container"}><Loader/></div>
        : fetchError ? <NotFound isError dataError />
        : !endpointsLoading && endpointsData.results.length === 0 ?
          <div>
            <p className={'line-filter'}/>
            <p className={'no-results-text'}>No results, try expanding your filter parameters.</p>
          </div>
          :
          <div>
            <div className="fixed-table-container">
              <Table page={"endpoints"}>
                <TableHeader
                  mainRow={mainRow}
                  className={"endpoints"}
                  setSortFilters={setSortFilters}
                  sortFilters={sortFilters}
                  setParams={setEndpointsParams}
                  params={endpointsParams}
                />
                {
                  endpointsData.results.map((item, i) => <EndpointsRow
                    key={i}
                    page={"endpoints"}
                    data={item}
                    onClick={async () => {}}
                    index={i}
                    setExpandedIndexes={setExpandedIndexes}
                    expandedIndexes={expandedIndexes}
                    setExpandAll={setExpandAll}
                    expandAll={expandAll}
                    isExpanded={(expandedIndexes?.includes(i) || expandAll?.includes(i))}
                    mainRow={mainRow}
                    leftColumn={leftColumn}
                    rightColumn={rightColumn}
                    setActiveTab={setActiveTab}
                  />)
                }
              </Table>
            </div>
            <div className={"pagination-controls"}>
              <Pagination
                setPage={handleChangePage}
                page={page}
                total={endpointsData.total}
              />
            </div>
          </div>
      }
    </div>
  )
}

export default Endpoints;