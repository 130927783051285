import React, {useContext, useEffect, useState, useRef} from 'react';
import GlobalState from "../../store/GlobalState/GlobalState";
import {Pagination} from "../../components";
import UserData from "../../store/User/UserData";
import '../../styles/pages/Hygiene/Hygiene.scss';
import {Loader} from "../../components/Loader/Loader";
import filterConfig, {resetFilterConfig, topIssuesFilterConfig} from "./filterconfig";
import LineChart from "../../components/D3/LineChart/LineChart";
import LineChartLegend from "../../components/D3/LineChart/Legend";
import {getCloudHygiene, getCloudHygieneTrends} from "../../api/ApiClient";
import PaginationControls from "../../components/Table/PaginationControls";
import {severities} from "../../config/mappingData";
import PageFilters from "../../components/Filters/PageFilters";
import {filterData} from "../../util/format.js"
import {downloadFile} from "../../util/export"
import DataList from "./DataList";
import {leftColumn, mainRow, rightColumn} from "../../components/Mappings/Hygiene"
import {useLocation} from 'react-router-dom';
import Table from "../../components/Table/Table";
import TableHeader from "../../components/Table/TableHeader";
import HygieneRow from "./HygieneRow";
import {mapChartData} from "../../util/mapChartData";
import {setSortFilterValue} from "../../util/handleSortResults";
import {setResetFilter} from "../../util/handleResetFilter";
import {trackPageView} from "../../util/analytics";
import TopHygieneIssues from './TopHygieneIssues';
import NotFound from "../../navigation/NotFound";
import _ from 'lodash';
import { captureSentryError } from '../../util/sentry.js';

const Hygiene = () => {
  const location = useLocation() // Used to grab state from react router state
  const [userData] = useContext(UserData);
  const [pageCount] = useState(50)
  const [page, setPage] = useState(1)
  const [hygieneData, setHygieneData] = useState({results: [], total: 0})
  const [hygieneTrends, setHygieneTrends] = useState({})
  let [sortFilters, setSortFilters] = useState([])
  const [filterList, setOpenFilters] = useState(filterConfig)
  const [hygieneLoading, setHygieneLoading] = useState(false)
  const [hygieneTrendLoading, setHygieneTrendLoading] = useState(true)
  const [toggleMetricsFilter, setToggleMetricsFilter] = useState(false)
  const [mappedGraphData, setMappedGraphData] = useState([])
  const [mappedStatusGraphData, setMappedStatusGraphData] = useState([])
  const [axisData, setAxisData] = useState({x: {min: 0, max: 0}, y: {max: 0}})
  const [axisSourceData, setAxisStatusData] = useState({x: {min: 0, max: 0}, y: {max: 0}})
  const [hygieneTrendsParams, setHygieneTrendsParams] = useState({})
  const [showDropdown, toggleShowDropdown] = useState('')
  const [expandAll, setExpandAll] = useState([])
  const [hygieneParams, setHygieneParams] = useState({})
  const [topIssuesParams, setTopIssuesParams] = useState({})
  const [defaultDate, setDefaultDate] = useState({
    text: 'Current',
    time: null
  })
  const statuses = [
    {'Re-Opened': '#FFE133'},
    {'Waiting_On_ActZero': '#d0f31b'},
    {'Waiting_On_Customer': '#FFA415'},
    {'Resolved': '#018D8D'},
    {'Merged': '#018D8D'},
    {'Closed': '#018D8D'}]
  const [expandedIndexes, setExpandedIndexes] = useState(location.state?.expandIndex === undefined ? [] :
    [location.state?.expandIndex]);
  const [selectedDate, setSelectedDate] = useState('Current')
  const [isReset, setIsReset] = useState(false)
  const [initialConfig, setInitialConfig] = useState({
    start_time: 'current',
    page: 1,
    size: pageCount,
    sort_by: 'severity',
    order: 'desc'
  })
  const [azState] = useContext(GlobalState);
  let [allData, setAllData] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const prevParams = useRef({});

  const lineData = {
    lineOpacity: "1",
    lineOpacityHover: "1",
    otherLinesOpacityHover: "0.3",
    lineStroke: "3px",
    lineStrokeHover: "5px"
  };

  const colors = {
    Critical: "#D64526",
    High: "#FFA415",
    Medium: "#FFE133",
    Low: "#638185",
    Informational: "#066666"
  };

  const statusColors = {
    PASS: "#D0F31B",
    FAIL: "#FFA415",
    WARNING: "#FFE133",
    INFO: "#C0D0D0"
  };

  const severitiesValues = [
    {name: 'Critical', values: []},
    {name: 'High', values: []},
    {name: 'Medium', values: []},
    {name: 'Low', values: []},
    {name: 'Informational', values: []}
  ]

  const statusValues = [
    {name: 'PASS', values: []},
    {name: 'FAIL', values: []},
    {name: 'WARNING', values: []},
    {name: 'INFO', values: []}
  ]

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => trackPageView("Hygiene", userData, azState), [userData?.userId, azState.reactGaInitializedState]);


  const resetAllFilters = async () => {
    setIsReset(true)
    handleClose()
    setSelectedDate('Current')
    setHygieneTrendsParams({"page": 1, "size": pageCount, "status": ["FAIL"]})
    setHygieneParams({
      "start_time": "current",
      "page": 1,
      "size": pageCount,
      "sort_by": "severity",
      "order": "desc",
      "status": ["FAIL"]
    })
    setDefaultDate({
      text: 'Current',
      time: null
    })
    setTopIssuesParams({})
    resetFilters()
    window.history.replaceState(undefined, location.state?.expandIndex)
    setIsReset(false)
  }

  const resetFilters = () => {
    setSortFilterValue([], mainRow, setSortFilters)
    // So filters don't hide searched detection
    setResetFilter(filterConfig, resetFilterConfig, setOpenFilters)
  }

  const getHygieneData = async (tempParams = {}) => {
    setFetchError(false);
    setExpandedIndexes([]);
    let params = hygieneParams
    if (Object.keys(tempParams).length > 0) { // Parameters we use with top x issues
      params = tempParams
    }
    let isExport = params.isExport || false
    if (isExport) {
      setHygieneParams({...hygieneParams, isExport: false})
      setExportLoading(true)
    } else setHygieneLoading(true)
    try {
      isExport && setExportLoading(true);
      const hygieneResponse = await getCloudHygiene(userData?.tenant, params, isExport)
      if (isExport) {
        downloadFile(hygieneResponse)
        setExportLoading(false);
      } else {
        setHygieneData(hygieneResponse)
        setAllData(hygieneResponse.results)
      }
      document.getElementsByTagName("body")[0]?.scrollTo(0, azState?.scrollYPosition)
    } catch (e) {
      captureSentryError(e, userData, 'getCloudHygiene API in Hygiene.js');
      setFetchError(true);
    } finally {
      setHygieneLoading(false)
    }
  }

  useEffect(() => {
    if(!_.isEqual(prevParams.current, hygieneParams)) {
      userData?.tenant && Object.keys(hygieneParams).length > 0 && getHygieneData()
      prevParams.current = hygieneParams;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.tenant, userData?.userType, hygieneParams]);

  const getHygieneTrendData = async () => {
    setHygieneTrendLoading(true)
    setExpandedIndexes([]);
    try {
      let hygieneTrendResponse = await getCloudHygieneTrends(userData?.tenant, hygieneTrendsParams)
      mapChartData(hygieneTrendResponse.severities, setAxisData, setMappedGraphData, severitiesValues, [])
      mapChartData(hygieneTrendResponse.statuses, setAxisStatusData, setMappedStatusGraphData, statusValues, [])
      setHygieneTrends(hygieneTrendResponse)
    } catch (e) {
      captureSentryError(e, userData, 'getCloudHygieneTrends API in Hygiene.js');
    } finally {
      setHygieneTrendLoading(false)
    }
  }

  useEffect(() => {
    userData?.tenant && Object.keys(hygieneTrendsParams).length > 0 && getHygieneTrendData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.tenant, userData?.userType, hygieneTrendsParams]);


  const handleChangePage = async (currPage) => {
    setPage(currPage)
    Object.keys(topIssuesParams).length > 0 ? getHygieneData({
      ...topIssuesParams,
      page: currPage
    }) : setHygieneParams({...hygieneParams, page: currPage})
  };

  const handleTopIssuesClick = (searchText, title) => {
    let searchParam = title === 'TOP ISSUES BY FAILURE' ? {
      control_name: searchText.replace(';', '%3B'),
      status: 'FAIL'
    } : {remediation: searchText.replace(';', '%3B'), status: 'FAIL'}
    setResetFilter(filterConfig, topIssuesFilterConfig, setOpenFilters)
    const tempParams = {...hygieneParams, ...searchParam, page: 1}
    setPage(1)
    setTopIssuesParams(tempParams)
    getHygieneData(tempParams)
    setHygieneParams(tempParams)
  }

  useEffect(() => {
    setPage(1)
    filterData(filterList, hygieneParams, pageCount, 1, setHygieneParams, initialConfig, setInitialConfig)
    filterData(filterList, hygieneTrendsParams, pageCount, 1, setHygieneTrendsParams)
    setTopIssuesParams({})
    // eslint-disable-next-line
  }, [filterList, pageCount])

  const handleClose = () => {
    setExpandAll([])
    setExpandedIndexes([])
    setTopIssuesParams({})
  }

  return (
    <div className={"hygiene-page-container all-page-container"}>
      <div className={"header-row"}>
        <p className={"page-title"}>CLOUD POSTURE</p>
      </div>
      {hygieneTrendLoading ?
        <div className={"loader-container"}><Loader/></div>
        :
        <div
          className={`top-container ${toggleMetricsFilter} ${mappedGraphData.length === 0 && mappedStatusGraphData.length === 0 ? 'no-graph-data' : ''}`}
          onClick={() => setToggleMetricsFilter(false)}>
          <div className={"graph-container" + (mappedGraphData.length === 0 ? ' no-data' : '')}>
            <div className={`title-and-legend ${toggleMetricsFilter}`}>
              <span className={`expand-btn ${toggleMetricsFilter}`} onClick={(e) => {
                e.stopPropagation();
                setToggleMetricsFilter(!toggleMetricsFilter)
              }}>{toggleMetricsFilter ? '+' : '-'}</span>
              <p className={`graph-title  ${toggleMetricsFilter}`}>TOTAL BY SEVERITY</p>
            </div>
            <LineChartLegend
              colors={colors}
              data={mappedGraphData}
            />
            {mappedGraphData.length > 0 ?
              <LineChart
                width={550}
                plotRadius={2}
                yAxisTicks={5}
                xAxisTicks={mappedGraphData[0].values.length}
                lineData={lineData}
                data={mappedGraphData}
                className={"severity-line-chart"}
                axis={axisData}
                widthOffset={.7}
                height={150}
                marginLeft={50}
                colors={colors}
              /> : <p className={"no-data"}>No Data</p>
            }
            <div className={"top-issues-by-failure-container"}>
              {hygieneTrends?.top_control_by_fail_status && hygieneTrends?.top_control_by_fail_status.length !== 0 &&
              <TopHygieneIssues
                title={"TOP ISSUES BY FAILURE"}
                data={hygieneTrends.top_control_by_fail_status}
                type={'top-issues'}
                handleClick={handleTopIssuesClick}
              />}
            </div>
          </div>
          <div className={"graph-container right" + (mappedStatusGraphData.length === 0 ? ' no-data' : '')}>
            <div className={`title-and-legend right ${toggleMetricsFilter}`}>
              <p className={`graph-title right ${toggleMetricsFilter}`}>TOTAL BY STATUS</p>
            </div>
            <LineChartLegend
              colors={statusColors}
              position={'right'}
              data={mappedStatusGraphData}
            />
            {mappedStatusGraphData.length > 0 ?
              <LineChart
                width={550}
                plotRadius={2}
                yAxisTicks={5}
                xAxisTicks={mappedStatusGraphData[0].values.length}
                lineData={lineData}
                data={mappedStatusGraphData}
                className={"source-line-chart"}
                axis={axisSourceData}
                widthOffset={.7}
                height={150}
                marginLeft={50}
                colors={statusColors}
                position={'right'}
              /> : <p className={"no-data right"}>No Data</p>
            }
            <div className={"top-remediations-container"}>
              {hygieneTrends?.top_remediation_by_fail_status && hygieneTrends?.top_remediation_by_fail_status.length !== 0 &&
              <TopHygieneIssues
                type={'top-remediations'} title={"REMEDIATIONS BY TOP FAILURES"}
                data={hygieneTrends.top_remediation_by_fail_status}
                handleClick={handleTopIssuesClick}
              />}
            </div>
          </div>
          {Object.keys(hygieneTrends).length > 0 &&
          <DataList data={hygieneTrends} metricsExpanded={toggleMetricsFilter}/>}
        </div>
      }
      <PageFilters
        isReset={isReset}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        handleClose={handleClose}
        filterList={filterList}
        setOpenFilters={setOpenFilters}
        setParams={setHygieneParams}
        setMetricParams={setHygieneTrendsParams}
        setExpandAll={setExpandAll}
        collapsible={true}
        defaultDate={defaultDate}
        timeConfig={[{text: 'Current', time: 'current'}, {text: 'Previous', time: 'previous'}]}
      />
      {
        !hygieneLoading &&
        <PaginationControls
          resetAllFilters={resetAllFilters}
          setExpandAll={setExpandAll}
          displayData={hygieneData}
          page={page}
          setPage={handleChangePage}
          expandAll={expandAll}
          showDropdown={showDropdown}
          toggleShowDropdown={toggleShowDropdown}
          setParams={setHygieneParams}
          params={hygieneParams}
          showSortDropdown={false}
          setExpandedIndexes={setExpandedIndexes}
          expandedIndexes={expandedIndexes}
          bauExportLoading={exportLoading}
        />
      }
      {hygieneLoading ?
        <div className={"loader-container table"}><Loader/></div>
        : fetchError ? <NotFound isError dataError />
        : !hygieneLoading && hygieneData.results.length === 0 ?
          <div>
            <p className={'line-filter'}/>
            <p className={'no-results-text'}>No results, try expanding your filter parameters.</p>
          </div>
          :
          <div>
            <div className="fixed-table-container">
              <Table page={"hygiene"}>
                <TableHeader
                  mainRow={mainRow}
                  className={"hygiene"}
                  setSortFilters={setSortFilters}
                  sortFilters={sortFilters}
                  setParams={setHygieneParams}
                  params={hygieneParams}
                />
                {
                  allData.map((item, i) => <HygieneRow
                    key={i}
                    severities={severities}
                    page={"hygiene"}
                    data={item}
                    mapMainRow={mainRow}
                    mapLeftColumn={leftColumn}
                    mapRightColumn={rightColumn}
                    statuses={statuses}
                    onClick={async () => {
                    }}
                    index={i}
                    setExpandedIndexes={setExpandedIndexes}
                    expandedIndexes={expandedIndexes}
                    setExpandAll={setExpandAll}
                    expandAll={expandAll}
                    isExpanded={(expandedIndexes?.includes(i) || expandAll?.includes(i))}
                  />)
                }
              </Table>
            </div>
            <div className={"pagination-controls"}>
              <Pagination
                setPage={handleChangePage}
                page={page}
                total={hygieneData.total}
              />
            </div>
          </div>
      }
    </div>
  )
}

export default Hygiene;