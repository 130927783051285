import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import '../../styles/components/Table/Tree.scss';
import { alpha, styled } from '@mui/material/styles';
import UserData from "../../store/User/UserData";
import {Loader} from "../Loader/Loader";
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { treeItemClasses } from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
import { useSpring, animated } from 'react-spring/web.cjs';
import {getProcessTreeData} from "../../api/ApiClient";
import {formatTime} from "../../util/format.js"
import {processTree} from "../../config/mappingData";
import { captureSentryError } from '../../util/sentry.js';
function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = styled((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const CustomizedTreeView = ({detectionId}) => {
  const [userData] = useContext(UserData);
  const [treeLoading, setTreeLoading] = useState(false)
  const [triggeredTreeIds, setTriggeredTreeIds] = useState([])
  const [selectedTreeIds, setSelectedTreeIds] = useState([])
  const [formattedTree, setFormattedTree] = useState([])
  const [processTreeResponse, setProcessTreeResponse] = useState({})
  const [selectedProcess, setSelectedProcess] = useState({})
  const processDetailsHeader = 'Process Details'

  const getTargetIcon = (className, key) => {
    return (<div key={key} className={className}><img className={"tree-icon"} alt={""} src={processTree['TargetIcon']}/></div>)
  }

	const getCollapseTargetIcon = (key) => {
	  return (<div key={key} className="icon"><div className="collapse">-</div><img className={"tree-icon"} alt={""} src={processTree['TargetIcon']}/></div>)
	}

	const getExpandTargetIcon = (key) => {
    return (<div key={key} className="icon"><div className="expand">+</div><img className={"tree-icon"} alt={""} src={processTree['TargetIcon']}/></div>)
  }

  const buildTree = (currentTree, target) => {
    if (Object.keys(currentTree).length === 0) { // No response from API
      return (
        <StyledTreeItem nodeId={'0'} label={"No process tree available at this time"}/>
      )
    }
    if (currentTree['children'] && currentTree['children'].length === 0) {
      return(
       <div className={target? "targetTree": ""} key={currentTree['TargetProcessId']+currentTree['BaseFileName']}>
         <StyledTreeItem
           endIcon={ currentTree.TriggeredProcess? getTargetIcon("icon end-target", currentTree['TargetProcessId']+currentTree['BaseFileName']) : <img className={"tree-icon"} alt={""} src={processTree['EndIcon']}/>}
           key={currentTree['TargetProcessId']}
           nodeId={currentTree['TargetProcessId']}
           label={currentTree['BaseFileName']} open/>
       </div>)
    }
    return (
      <div className={currentTree.TriggeredProcess ? "targetTree" : ""}>
        <StyledTreeItem
            collapseIcon={currentTree.TriggeredProcess ? getCollapseTargetIcon(currentTree['BaseFileName']+currentTree['TargetProcessId']): <div className="icon"><div className="collapse">-</div><img className={"tree-icon"} alt={""} src={processTree['ParentIcon']}/></div>}
            expandIcon={currentTree.TriggeredProcess ? getExpandTargetIcon(currentTree['BaseFileName']+currentTree['TargetProcessId']) : <div className="icon"><div className="expand">+</div><img className={"tree-icon"} alt={""} src={processTree['ParentIcon']}/></div>}
            key={currentTree['TargetProcessId']}
            nodeId={currentTree['TargetProcessId']}
            label={currentTree['BaseFileName']}>
          {currentTree['children'] && currentTree['children'].map((item, _) => {
            let triggered = false
            currentTree.TriggeredProcess ? triggered = true : item.TriggeredProcess? triggered=true : triggered = false
            return buildTree(item, triggered)
          })}
        </StyledTreeItem>
      </div>
    )
  }

  const grabTriggeredProcesses = (treeData) => {
    let triggeredProcessList = []
    if (treeData && treeData['TriggeredProcess'] === true) {
        return [treeData['TargetProcessId']]
    }
    if (treeData['children'] && treeData['children'].length === 0) {
        return []
    }
    treeData['children'] && treeData['children'].forEach(item => {
        let extendedProcess = grabTriggeredProcesses(item)
        if (extendedProcess.length > 0) {
            extendedProcess = [...extendedProcess, treeData['TargetProcessId']]
            triggeredProcessList = [...triggeredProcessList, ...extendedProcess]
        }
      })
    return triggeredProcessList
  }

  const grabTriggeredProcess = (treeData) => {
    let triggeredProcess = ''
    if (treeData && treeData['TriggeredProcess'] === true) {
        return treeData['TargetProcessId']
    }
    if (treeData['children'] && treeData['children'].length === 0) {
        return ''
    }
    treeData['children'] && treeData['children'].forEach(item => {
        let extendedProcess = grabTriggeredProcess(item)
        if (extendedProcess.length > 0) {
            triggeredProcess = extendedProcess
            return triggeredProcess
        }
      })
    return triggeredProcess
  }

  const grabProcessFromNodeId = (treeData, nodeId) => {
    let selectedProcess = {}
    if (treeData['TargetProcessId'] === nodeId) {
        return {
          "Process ID": treeData.TargetProcessId || 'N/A', 
          "Start Time": formatTime(treeData.ProcessStartTime) || 'N/A',
          "Command Line": treeData.CommandLine || 'N/A', 
          "Base File Name": treeData.BaseFileName || 'N/A', 
          "Image File Name": treeData.ImageFileName || 'N/A', 
          "Parent Process ID": treeData.ParentProcessId || 'N/A'
        }
    }
    if (treeData['children'] && treeData['children'].length === 0) {
      return {}
    }
    treeData['children'] && treeData['children'].forEach(item => {
        let tempProcess = grabProcessFromNodeId(item, nodeId)
        if (Object.keys(tempProcess).length !== 0) {
          selectedProcess = tempProcess
          return selectedProcess
        }
      })
    return selectedProcess
  }

  useEffect(() => {
    const getProcessTree = async () => {
      setTreeLoading(true)
      try {
        let detectionProcessTreeResponse = await getProcessTreeData(userData?.tenant, detectionId)
        let process_ids = detectionProcessTreeResponse.process_tree
        setProcessTreeResponse(process_ids)
        setFormattedTree(buildTree(process_ids, false))
        setTriggeredTreeIds(grabTriggeredProcesses(process_ids))
        let targetProcessId = grabTriggeredProcess(process_ids)
        setSelectedTreeIds([targetProcessId])
        let selectedLeaf = grabProcessFromNodeId(process_ids, targetProcessId)
        setSelectedProcess(selectedLeaf)
        return detectionProcessTreeResponse
      } catch (e) {
        setFormattedTree(buildTree([], false))
        setTriggeredTreeIds(grabTriggeredProcesses([]))
        captureSentryError(e, userData, "getProcessTreeData API in /components/Table/Tree.js");
      } finally {
        setTreeLoading(false)
      }
    }
    detectionId && getProcessTree()
    // eslint-disable-next-line
  }, [userData?.tenant, userData?.userType, detectionId])
  

  const onProcessSelected = (event, nodeId) => {
    let selectedLeaf = grabProcessFromNodeId(processTreeResponse, nodeId)
    setSelectedProcess(selectedLeaf)
  }

  return (
    <div className={"tree-component-container"}>
    {treeLoading ?
      <div className={"loader-container"}><Loader/></div> :
    <div>
      <div className={"tree-container"}>
        <TreeView
          aria-label="customized"
          defaultSelected={selectedTreeIds}
          defaultExpanded={triggeredTreeIds}
          defaultCollapseIcon={<div className="icon"><div className="collapse">-</div><img className={"tree-icon"} alt={""} src={processTree['ParentIcon']}/></div>}
          defaultExpandIcon={<div className="icon"><div className="expand">+</div><img className={"tree-icon"} alt={""} src={processTree['ParentIcon']}/> </div>}
          defaultEndIcon={<img className={"tree-icon"} alt={""} src={processTree['EndIcon']}/>}
          onNodeSelect={(event, nodeId) => onProcessSelected(event, nodeId)}
          sx={{ width: 700, flexGrow: 1, overflowY: 'auto'}}
        >
          {formattedTree}
        </TreeView>
      </div>
      {
        Object.keys(selectedProcess).length > 0 ? 
        <div>
          <p className={'line-filter'} />
          <div className={'process-details-container'}>
            <table className={"process-details-table"}>
              <thead>
              <tr className={'table-header-row'}>
                <th>{processDetailsHeader}</th>
              </tr>
              </thead>
              <tbody className={'table-body'}>
                {Object.keys(selectedProcess).map((key, index) => {
                    return selectedProcess[key] !== 'N/A' &&
                    <tr className={"detail-item-container"} key={index}>
                      <td className={"detail-item-title"}>{key}</td>
                      <td className={"detail-item"}>{selectedProcess[key]}</td>
                    </tr>
                })}
              </tbody>
            </table> 
          </div>
        </div>
         : null
      }
    </div>
    }
    </div>
  );
}

export default CustomizedTreeView;