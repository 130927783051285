import moment from 'moment-timezone';
import _ from 'lodash';
import {byIso} from 'country-code-lookup'

export function stringifyObject(value) {
  if (typeof value === 'object') {
    return JSON.stringify(value)
  }
  return value
}

export const getTrendDirection = (prev, current, down, up) => prev > current ? down : up

export const displayValue = (value) => value !== null ? Math.round(value) : 'No data'

export const formatDisplayValue = (value, previous) => (value !== null && previous !== null) ? Math.abs(Math.round(value - previous)) : 'No data'

export const displayStringValue = (value) => value !== null ? stringifyObject(value) : 'N/A'

export function formatTimeFilters(filteredTime) {
  const timeAgo = moment().subtract(filteredTime, 'seconds').toISOString(true) // setting ISOString(timeOffset) to true to keep local tz
  const now = moment().toISOString(true)
  return {'start_time': timeAgo, 'end_time': now}
}

export function getTimezone() {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return moment.tz.zone(timeZone)
}

export function getLocale() {
  return Intl.DateTimeFormat().resolvedOptions().locale
}

export function getDateTimeFormat(locale, includeTime = true) {
  return !locale || locale === 'en-US' || locale === 'en-CA' ? `MM-DD-YY${includeTime ? " HH:mm" : ""}` : `DD-MM-YY${includeTime ? " HH:mm" : ""}`
}

export function capitalize(str) {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
}

export function capitalizeAndFormat(str) {
  return capitalize(str.replaceAll("_", " ")); 
}

export function getBaseParameters(params, pageCount, page, initalConfig={}, setInitialConfig=null) {
    if (Object.keys(initalConfig).length > 0) {
      setInitialConfig && setInitialConfig({})
      return initalConfig
    }
    const filters = {page: page, size: pageCount}
    if (params.q) {
        filters.q = params?.q
    }
    if (params.start_time) {
        filters.start_time = params.start_time
    }
    if (params.end_time) {
        filters.end_time = params.end_time
    }
    if (params.sort_by) {
        filters.sort_by = params.sort_by
    }
    if (params.order) {
        filters.order = params.order
    }
    if (params.mitigated) {
      filters.mitigated = params.mitigated
    }

    return filters
}

export function formatTime(timestamp, includeTime = true) {
  if (!timestamp) {
    return ''
  }

  let formatted = ''

  const timezone = getTimezone()
  const locale = getLocale()
  const pattern = getDateTimeFormat(locale, includeTime);

  // Parse timestamp - handle strings, moment objects and epoch times
  let m = null;
  if (typeof timestamp === 'string' && timestamp.includes('T')) {
    m = moment(timestamp);
  } else if (typeof timestamp === 'object' && timestamp.constructor.name === 'Moment') {
    m = timestamp;
  } else {
    m = moment.unix(timestamp);
  }

  if (m.isValid()) {
    // Convert time to timezone
    m = m.tz(timezone.name);
    formatted = m.format(pattern);

    if (includeTime) {
      const tzAbbr = timezone.abbr(m.valueOf());
      formatted = formatted + " " + tzAbbr;
    }
  }

  return formatted;
}

export function formatTimestamp(inputDay, inputTime, timezone) {
  // Convert time and day to utc from locale timezone
  const today = moment();
  if (inputDay.length === 1) {
    inputDay = `0${inputDay}`
  }
  const dateTimeString = `${today.format('YYYY-MM')}-${inputDay}T${inputTime}`;
  const formattedDate = moment.tz(dateTimeString, 'YYYY-MM-DDTHH:mm', timezone);
  return formattedDate.valueOf() / 1000;
}

export function formatDate(value) {
  return value.split(" ")[0];
}

export function addNA(value) {
  if (!value) {
    return 'N/A'
  }
  return value
}

export function getUsers(users) {
  if (users.length === 0) {
    return 'N/A'
  }

  return users.map((user, index) => {
    let enabled = user.enabled
    let username = user.username
    let comma = index === users.length - 1 ? '' : ', '
    return <span style={{color: (enabled ? '#ffffff99' : '#748181')}}>{username}<span>{comma}</span></span>
  })
}

export function formatIndicators(indicators) {
  return indicators.map((indicator, index) => {
    return (
      <div key={index} className={"threat-indicators"}>
        <b className={"category-title"}>{`Category: ${indicator.category}`}</b>
        <ul className={"indicators-list"}>
          <li key={index}>{`Description: ${indicator.description}`}
            <ul className={"tactics-list"}>
              {indicator.tactics.map((tactic, index) => {
                return (
                <li key={index}>{`${tactic.source} : ${tactic.name} `}
                  <span className={"technique-list"}>
                    {tactic.techniques.map((t, index) => (
                      <span key={index}>[<a href={t.link} target="_blank" rel="noreferrer">{t.name}</a>]</span>
                    ))}
                  </span>
                </li>)
              })}
            </ul>
          </li>
        </ul>
      </div>
    )
  })
}

export function linkValues(value, index, link, delimiter) {
  if (!value) {
    return
  }
  const values = value.split(delimiter)

  if (link) {
    return values.map((item, valueIndex) =>
      <div key={index + valueIndex}>
        <a className={"clickable-link"} target="_blank" rel="noreferrer" key={"a" + index + valueIndex} href={link + item}>
          {item}
        </a>
      </div>)
  }

  return values.map((item, valueIndex) =>
    <div key={index + valueIndex}>
      <a className={"clickable-link"} target="_blank" rel="noreferrer" key={"a" + index + valueIndex} href={item}>
        {item}
      </a>
    </div>)
}

export const formatLargeNumber = (value) => {
  let suffixes = ["", "k", "m", "b", "t"];
  let number = Math.floor(("" + value).length / 3);
  let shortValue = parseFloat((number !== 0 ? (value / Math.pow(1000, number)) : value).toPrecision(2));
  if (shortValue % 1 !== 0) {
    shortValue = shortValue.toFixed(1);
  }
  return shortValue + suffixes[number];
}

export const linkText = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Split the text into parts with and without URLs
  const parts = text.split(urlRegex);

  // Map over the parts and wrap URLs with <a> tags
  return parts.map((part, index) => {
    if (part.match(urlRegex)) {
      return (
        <a className={"clickable-link"} key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }
    return part;
  });
}

export const abbreviateNumber = (number, decPlaces, abbrev=["K", "M", "B", "T"]) => {
  if (number > 999) decPlaces = 1
  decPlaces = Math.pow(10, decPlaces);
  for (let i = abbrev.length - 1; i >= 0; i--) {
    let size = Math.pow(10, (i + 1) * 3);
    if (size <= number) {
      number = Math.round(number * decPlaces / size) / decPlaces;
      if ((number === 1000) && (i < abbrev.length - 1)) {
        number = 1;
        i++;
      }
      number += abbrev[i];
    }
  }
  return number;
}

export const abbreviateData = (number, decPlaces) => {
  return abbreviateNumber(number, decPlaces, ["kB", "MB", "GB", "TB"])
}

export const filterData = (filterList, params, pageCount, page, setParams, initialConfig={}, setInitialConfig=null) => {
  let filterParameters = getBaseParameters(params, pageCount, page, initialConfig, setInitialConfig)
  filterList?.forEach(filter => {
    let name = Object.keys(filter)[0] // Filter name (i.e. Severity, Time)
    let filterName = filter[name].filter_name // Filter value string name (i.e. severity, created_date)
    let booleanConfig = filter[name].booleanConfig
    let keys = Object.keys(filter[name].filters); // Filter values available (i.e. High, Critical)
    keys.forEach((key) => { // Check keys to see if they are turned on
      if (filter[name].filters[key] === true) { // filter is turned on
        let valueKey = key
        if (booleanConfig && booleanConfig[key] !== undefined) {
          valueKey = booleanConfig[key]
        }
        if (filterName in filterParameters) { // already exists
          filterParameters[filterName].push(valueKey)
        } else {
          filterParameters[filterName] = [valueKey]
        }
      }
    })
  })
  if (!_.isEqual(filterParameters, params)) {
    setParams(filterParameters)
  }
}

export function sortObjectKeys(obj, keyOrder) {
  const keys = Object.keys(obj);

  const sortedKeys = keys.sort((a, b) => {
    const indexA = keyOrder.indexOf(a);
    const indexB = keyOrder.indexOf(b);

    // Handle undefined keys
    if (indexA === -1 && indexB === -1) {
      return keys.indexOf(a) - keys.indexOf(b);
    }

    // If only one key is in the key order, prioritize it
    if (indexA !== -1 && indexB === -1) {
      return -1;
    }
    if (indexA === -1 && indexB !== -1) {
      return 1;
    }

    // If both keys are in the key order, compare their indices
    return indexA - indexB;
  });

  const sortedObject = {};
  sortedKeys.forEach((key) => {
    sortedObject[key] = obj[key];
  });

  return sortedObject;
}

export const formatPercentage = (value) => value ? Math.trunc(Math.abs(value)) : value;

export const formatTimeAgo = (timeStamp) => !timeStamp ? "-" : moment(timeStamp * 1000).fromNow()


export function isJson(value) {
  try {
      const obj = typeof value === 'string' ? JSON.parse(value) : value
      // handles these false positives:
      // JSON.parse(1234) or
      // JSON.parse(0) or
      // JSON.parse(false) or
      // JSON.parse(null)
      if (obj && typeof obj === `object`) {
        return true;
      }
    } catch (err) {
      return false;
    }
   return false;
}

export const formatPythonLists = (value) => {
  if (!value) {
    return value
  }

  // sometimes data will look like this: "['1.2.3.4', '1.1.1.1']"
  // so format python formatted string objects and make them json compatible
  if (typeof value == 'string' && value.includes("'")) {
      value = value.replaceAll("'", '"')
  }

  if (typeof value !== `object` && isJson(value)) {
    value = JSON.parse(value)
  }

  if (Array.isArray(value)) {
    return <ul>{value.map((element, i) => <li key={i}>{element}</li>)}</ul>
  }

  return value
}

export const beautifyText = (value) => {
  if (isJson(value)) {
    return <pre>{JSON.stringify(value, null, 4)}</pre>
  }

  if (value !== undefined) {
    return <pre>{value}</pre>
  } 

  return value
}

export const countryCodeToName = (value) => {
  if (!value) {
    return value
  }
  const countryValues = byIso(value);
  return countryValues ? countryValues.country : value;
}

export const convertUTCToLocal = (utcTime) => {
  const utcMoment = moment.utc(utcTime, 'YYYY-MM-DD HH:mm:ss');
  const localMoment = utcMoment.local();
  return formatTime(localMoment);
}

export const convertDataSize = (size, decimalPlaces) => {
  let i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (size / Math.pow(1024, i)).toFixed(decimalPlaces) * 1;
}

export const getDataUnit = (size) => {
  let i = !size ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return ['B', 'kB', 'MB', 'GB', 'TB'][i];
}