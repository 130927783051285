import "../../../styles/pages/Onboarding/Mobile/MobileOnboarding.scss";
import React, { useState, useEffect, useContext, useRef } from "react";
import {
  getMobileUsers,
  addMobileUser,
  editMobileUser,
  deleteMobileUser,
} from "../../../api/ApiClient";
import UserData from "../../../store/User/UserData";
import GlobalState from "../../../store/GlobalState/GlobalState";
import _ from "lodash";
import { trackClick, trackPageView } from "../../../util/analytics";
import TooltipIcon from "../../../assets/icons/tooltip.svg";
import IconButton from "@mui/material/IconButton";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { tooltipMapping } from "../../../config/tooltipTextMapping";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UploadModal from "../UploadModal";
import { Loader } from "../../../components/Loader/Loader";
import ErrorIcon from "@mui/icons-material/Error";
import MobileOnboardingTable from "./MobileOnboardingTable";
import ConfirmModal from "../../../components/Modal/ConfirmModal";
import SampleCSV from "../../../assets/files/Sample_CSV_Format_Mobile_Onboarding.csv";
import { defaultInputs, iconLinks, addInputsConfig } from "./config";
import NotFound from "../../../navigation/NotFound";
import { captureSentryError } from "../../../util/sentry";

const MobileOnboarding = () => {
  const [azState] = useContext(GlobalState);
  const [userData] = useContext(UserData);
  const [mobileUserData, setMobileUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [addUserInputs, setAddUserInputs] = useState(defaultInputs);
  const [actionError, setActionError] = useState();
  const [addError, setAddError] = useState();
  const [rowError, setRowError] = useState();
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [rowLoading, setRowLoading] = useState(false);
  const [showConfirmResendModal, setShowConfirmResendModal] = useState(false);
  const [modalErrors, setModalErrors] = useState([]);
  const [pendingRow, setPendingRow] = useState(() => ({
    row: {},
    action: null,
  }));
  const [fetchError, setFetchError] = useState(false);
  const actionMenuConfig = [
    { label: "Reinvite", function: setShowConfirmResendModal },
    { label: "Delete", function: setShowConfirmDeleteModal },
  ];

  useEffect(
    () => trackPageView("Mobile Onboarding", userData, azState),
    // eslint-disable-next-line
    [userData?.userId, azState.reactGaInitializedState]
  );

  useEffect(() => {
    userData.tenant && getMobileUserData();
    // eslint-disable-next-line
  }, [userData?.tenant]);

  async function getMobileUserData() {
    try {
      setFetchError(false);
      setLoading(true);
      const { results } = await getMobileUsers(userData?.tenant);
      setMobileUserData(results);
      setLoading(false);
    } catch (e) {
      captureSentryError(e, userData, "getMobileUsers API in MobileOnboarding.js");
      setFetchError(true);
    }
  }

  const handleAddUser = async () => {
    setIsUploading(true);
    const params = {
      first_name: addUserInputs.first_name.trim(),
      last_name: addUserInputs.last_name.trim(),
      email: addUserInputs.email.trim(),
    };
    const validated = verifyParams(params, setAddError, false);
    if (validated)
      try {
        const results = await addMobileUser(userData?.tenant, params);
        if (results.failed_users.length)
          setAddError(`Error adding user: ${results.failed_users[0].reason}`);
        else {
          setAddUserInputs(defaultInputs);
          trackClick(userData, `added mobile onboarding user ${params.email}`, "click");
          setMobileUserData((prevState) => [params, ...prevState]); // Updating manually because of a 1-2 second delay in data table update (due to the lambda invoking lambda approach in backend, will be refactored in the future)
        }
      } catch (e) {
        setAddError("Error adding user");
        captureSentryError(e, userData, "addMobileUser API in MobileOnboarding.js");
      }
    setIsUploading(false);
  };

  const handleEditUser = async () => {
    setRowLoading(true);
    const params = {
      first_name: editNameInput.current.value.trim(),
      last_name: editSurnameInput.current.value.trim(),
      email: pendingRow.row.email.trim(),
    };
    setPendingRow((prevState) => {
      let newState = { ...prevState };
      newState.row = params;
      return newState;
    });
    const validated = verifyParams(params, setRowError, true);
    if (validated)
      try {
        const results = await editMobileUser(userData?.tenant, [params]);
        if (results.failed_users.length) setRowError("Error editing user");
        else {
          resetPendingStage();
          setMobileUserData((prevState) => {
            results.updated_users.forEach((u) => {
              let index = _.findIndex(prevState, (e) => e.email === u);
              prevState[index] = params;
            });
            return prevState;
          });
        }
      } catch (e) {
        setRowError("Error editing user");
        captureSentryError(e, userData, "editMobileUser API in MobileOnboarding.js");
      }
    setRowLoading(false);
  };

  const handleDeleteUsers = async (isMultiple) => {
    setRowLoading(true);
    try {
      if (isMultiple) {
        const results = await deleteMobileUser(userData?.tenant, {
          users: selectedRows.map((r) => r.email),
        });
        if (results.failed_users.length)
          setModalErrors(
            results.failed_users.map((e) => `${e.email} - ${e.reason}`)
          );
        else {
          setSelectedRows([]);
          setShowConfirmDeleteModal();
        }
        setMobileUserData((prevState) =>
          prevState.filter((u) => !results.deleted_users?.includes(u.email))
        );
      } else {
        const results = await deleteMobileUser(userData?.tenant, {
          users: [pendingRow.row.email],
        });
        results.failed_users.length
          ? setRowError("Error deleting user")
          : resetPendingStage();
        setMobileUserData((prevState) =>
          prevState.filter((u) => !results.deleted_users?.includes(u.email))
        );
      }
      trackClick(userData, `deleted mobile onboarding user(s)`, "click");
    } catch (e) {
      isMultiple
        ? setActionError(
            `Error deleting user${selectedRows.length > 1 ? "s" : ""}`
          )
        : setRowError("Error deleting user");
      captureSentryError(e, userData, "deleteMobileUser API in MobileOnboarding.js");
    }
    setRowLoading(false);
  };

  const handleResendEmail = async (isMultiple) => {
    setRowLoading(true);
    try {
      if (isMultiple) {
        const params = selectedRows.map((r) => ({
          email: r.email,
          first_name: r.first_name,
          last_name: r.last_name,
        }));
        const results = await editMobileUser(userData?.tenant, params);
        if (results.failed_users.length)
          setModalErrors(
            results.failed_users.map((e) => `${e.email} - ${e.reason}`)
          );
        else {
          setSelectedRows([]);
          setShowConfirmResendModal();
        }
      } else {
        const results = await editMobileUser(userData?.tenant, [
          {
            first_name: pendingRow.row.first_name,
            last_name: pendingRow.row.last_name,
            email: pendingRow.row.email,
          },
        ]);
        results.failed_users.length
          ? setRowError("Error resending invite")
          : resetPendingStage();
      }
      trackClick(userData, `resend zimperium invite`, "click");
    } catch (e) {
      isMultiple
        ? setActionError(
            `Error resending invite${selectedRows.length > 1 ? "s" : ""}`
          )
        : setRowError("Error resending invite");
      captureSentryError(e, userData, "editMobileUser API resending invite in MobileOnboarding.js");
    }
    setRowLoading(false);
  };

  const delayDataRetrieve = () => {
    setLoading(true);
    setTimeout(() => getMobileUserData(), 5000);
  };

 const verifyParams = (params, setErrorFunction, isEdit) => {
    const isValidName = params.first_name.length;
    const isValidSurname = params.last_name.length;
    const isValidEmail = params.email.toLowerCase().match(
      // eslint-disable-next-line
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    );
    const isDuplicate = isEdit
      ? false
      : mobileUserData
          .map((u) => u.email.trim().toLowerCase())
          .includes(params.email.trim().toLowerCase());
  
    isDuplicate
      ? setErrorFunction("User already exists")
      : !isValidName
      ? setErrorFunction("Must enter first name")
      : !isValidSurname
      ? setErrorFunction("Must enter last name")
      : !isValidEmail
      ? setErrorFunction("Must enter valid email")
      : setErrorFunction();
  
    return isValidName && isValidSurname && isValidEmail && !isDuplicate;
  };

  const handleSubmit = () => {
    const functionMap = {
      delete: handleDeleteUsers,
      edit: handleEditUser,
      resend: handleResendEmail,
    };
    functionMap[pendingRow.action]();
  };

  const handlePendingRow = (row, action) => {
    setPendingRow({ row: row, action: action });
  };

  const resetPendingStage = () => {
    setRowError();
    setPendingRow({ row: {}, action: null });
  };

  const handleActionMenu = (setModal) => {
    resetPendingStage();
    selectedRows.length && setModal(true);
    setShowActionMenu(false);
  };

  const resetActionModal = () => {
    setShowConfirmDeleteModal();
    setShowConfirmResendModal();
    setModalErrors([])
  }

  const editNameInput = useRef();
  const editSurnameInput = useRef();
  const editEmailInput = useRef();
  const mapInputRefs = {
    first_name: editNameInput,
    last_name: editSurnameInput,
    email: editEmailInput,
  };

  return (
      <div className="mobile-onboarding-content">
        {showConfirmResendModal && (
          <ConfirmModal
            message={`Resend invite to ${selectedRows.length} user${
              selectedRows.length > 1 ? "s" : ""
            }?`}
            confirmFunc={() => handleResendEmail(true)}
            cancelFunc={resetActionModal}
            modalErrors={modalErrors}
            rowLoading={rowLoading}
          />
        )}
        {showConfirmDeleteModal && (
          <ConfirmModal
            message={`Delete ${selectedRows.length} user${
              selectedRows.length > 1 ? "s" : ""
            }?`}
            confirmFunc={() => handleDeleteUsers(true)}
            cancelFunc={resetActionModal}
            modalErrors={modalErrors}
            rowLoading={rowLoading}
          />
        )}
        <UploadModal
          isOpen={showUploadModal}
          setOpen={setShowUploadModal}
          userData={userData}
          uploadFunction={addMobileUser}
          refreshData={delayDataRetrieve}
          sampleCSV={SampleCSV}
        />
        <div className="onboarding-title">MOBILE</div>
        <div className="app-link-container">
          <div className="label">Agent Download</div>
          {iconLinks.map((i) => (
            <a
              className={i.className}
              target="_blank"
              rel="noreferrer"
              href={i.href}
              key={i.className}
            >
              <img src={i.icon} alt={i.className} />
            </a>
          ))}
        </div>
        <div className="upload-container">
          <IconButton
            className="upload-btn-container"
            onClick={() => setShowUploadModal(true)}
          >
            <div className="files-button">{"UPLOAD >>"}</div>
          </IconButton>
          <Tooltip
            content={tooltipMapping.onboarding.mobileCSV}
            className={"csv-tooltip"}
            direction={"right"}
          >
            <img
              className="tooltip-icon"
              src={TooltipIcon}
              alt="?"
            />
          </Tooltip>
        </div>
        <div className="input-container">
          <div className="label">ADD NEW MOBILE USER +</div>
          {addInputsConfig.map((i) => (
            <input
              placeholder={i.placeholder}
              className={i.className ? i.className : i.column}
              value={addUserInputs[i.column]}
              onChange={(e) => {
                let copy = { ...addUserInputs };
                copy[i.column] = e.target.value;
                setAddUserInputs(copy);
              }}
              key={i.placeholder}
            />
          ))}
          <IconButton
            className="add-btn-container"
            onClick={() => handleAddUser()}
          >
            {isUploading ? <Loader /> : <div className="add-btn">ADD</div>}
          </IconButton>
          {actionError && (
            <div className="error action">
              <ErrorIcon className="err-icon" /> {actionError}
            </div>
          )}
          {addError && (
            <div className="error add">
              <ErrorIcon className="err-icon" /> {addError}
            </div>
          )}
        </div>

        <div className="actions-container">
          <span
            className="selection"
            onClick={() => setShowActionMenu(!showActionMenu)}
          >
            Actions <KeyboardArrowDownIcon className="down-arrow" />
          </span>
          {showActionMenu && (
            <ul
              className="options"
              onMouseLeave={() => setShowActionMenu(false)}
            >
              {actionMenuConfig.map((a) => (
                <li
                  className="option"
                  onClick={() => handleActionMenu(a.function)}
                  key={a.label}
                >
                  {a.label}
                </li>
              ))}
            </ul>
          )}
        </div>
        {fetchError ? <NotFound isError dataError className="panel"/> :
        <MobileOnboardingTable
          mobileUserData={mobileUserData}
          setMobileUserData={setMobileUserData}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          mapInputRefs={mapInputRefs}
          pendingRow={pendingRow}
          setPendingRow={handlePendingRow}
          handleSubmit={handleSubmit}
          resetPendingStages={resetPendingStage}
          dataLoading={loading}
          rowLoading={rowLoading}
          rowError={rowError}
        />}
      </div>
  );
};

export default MobileOnboarding;