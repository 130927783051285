import React, { useRef, useEffect, useMemo } from "react";
import "../../styles/pages/KnowledgeGraph/ExpandedDetails.scss";
import { convertUTCToLocal } from "../../util/format";
import {
  hiddenProperties,
  tableTitleMap,
  zeroinModalMap,
} from "./config/modalConfig";
import { useNavigate } from "react-router-dom";

const ExpandedDetails = ({ data, coords, trackCursor }) => {
  const containerRef = useRef();
  const propertiesToHide = useMemo(
    () =>
      data?.labels?.includes("Vulnerability")
        ? hiddenProperties.filter((prop) => prop !== "Name")
        : hiddenProperties,
    [data]
  );  
  const navigate = useNavigate();
  const onLinkClick = (data, page) => navigate(page, data);

  useEffect(() => {
    if (containerRef.current && trackCursor) {
      window.onmousemove = function (e) {
        var x = e.clientX,
          y = e.clientY;
        containerRef.current.style.top = y - 20 + "px";
        containerRef.current.style.left = x + 5 + "px";
        containerRef.current.style.display = "block";
      };
    }
    return () => {
      window.onmousemove = null;
    };
  }, [containerRef, trackCursor]);

  const formatPropertyValue = (key, value) => {
    if (key === "timestamp" || key === "Detection Time" || key === "Time Stamp") {
      return convertUTCToLocal(value);
    } else if (Array.isArray(value) ? "array" : typeof value === "object") {
      return JSON.stringify(value);
    } else return value.toString();
  };

  const mapDataProperties = () => {
    const order = data?.labels ? zeroinModalMap[data.labels[0]] : [];
    const mappedData = {};

    if (order.length > 0) {
      order.forEach((key) => {
        if (data.properties.hasOwnProperty(key)) {
          mappedData[key] = data.properties[key];
        } else if (data.hasOwnProperty(key)) {
          mappedData[key] = data[key];
        }
      });
    }
    Object.keys(data.properties).forEach((key) => {
      if (!mappedData.hasOwnProperty(key) && !order.includes(key)) {
        key === 'timestamp' ? mappedData['Time Stamp'] = data.properties[key] : mappedData[key] = data.properties[key];
      }
    });

    return Object.keys(mappedData).length > 0 ? mappedData : data?.properties;
  };

  const mapTableTitle = () => {
    let label = data.hasOwnProperty('labels') ? data?.labels[0] : data.hasOwnProperty('source') ?
      data?.source?.labels[0] : ''
    return data.hasOwnProperty('source') ?
      (data.source.labels[0] === 'aid' ? 'LINK' : (data.source.labels[0].replaceAll("_", " ") || '') + ' LINK')
      : (Object.entries(tableTitleMap).find(([_, labels]) => labels.includes(label))?.[0] || '');
  }
  
  return (
    <table
      ref={containerRef}
      className={`expanded-details-container ${trackCursor && "hover"}`}
      style={
        coords && { left: `${coords.x + 10}px`, top: `${coords.y + 10}px` }
      }
    >
      <caption className={"table-title"}>
        {mapTableTitle().toUpperCase()}
      </caption>
      <tbody className={"expanded-details"}>
      {Object.entries(mapDataProperties(data.properties)).map((key, index) =>
        (propertiesToHide.includes(key[0]) || key[1].toString().includes('stellar') || key[1] === 'aid') ? <tr key={index}/> :
          key[1].length > 0 && <tr className={"detail"} key={index}>
            <td className={"label"}>{key[0].replace(/_/g, ' ')} </td>
            <td className={"value"}>{formatPropertyValue(key[0], key[1])}
            </td>
          </tr>
      )}
      <tr className={"view-button-container"}>
        {data?.source?.labels.includes('Detection') && <td>
          <div className={"view-btn"} onClick={() => onLinkClick({
            state: {id: data?.properties?.az_event_id, expandIndex: 0}
          }, "/activity/detections")}>View Detection
          </div>
        </td>}
        {data.properties.hasOwnProperty("case_id") && <td>
          <div className={"view-btn"} onClick={() => onLinkClick({
            state: {
              filters: {investigations: {initialConfig: {page: 1, size: 50, q: data.properties.case_id}}},
              case: {id: data.properties.case_id}
            }
          }, "/activity/investigations")}>View Investigation
          </div>
        </td>}
        {data.properties.hasOwnProperty("vulnerability_id") && <td>
          <div className={"view-btn"} onClick={() => onLinkClick(
            {
              state: {
                initialConfig: {
                  page: 1,
                  size: 1,
                  q: data.properties.last_observed_scan_result_id,
                }
              }
            }
            , "/risk-management/vulnerabilities")}
          >View Vulnerability
          </div>
        </td>}
      </tr>
      </tbody>
    </table>
  );
};

export default ExpandedDetails;
