import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {isAZUser} from "../util/userControl";
import UserData from "../store/User/UserData";
import Beta from '../assets/icons/beta2.svg';

const DropdownNav = ({
                       data,
                       activeNavItem,
                       setActiveNavItem,
                       indent,
                       className,
                       handleClickTrack,
                       activeDropdown,
                       disableMDRLiteAndMobile,
                     }) => {
  const styles = {
    paddingLeft: indent,
    background: `linear-gradient(to right, rgba(87,110,115,1) 0%, rgba(46,82,89,1) ${
      indent - 10
    }px, rgba(46,82,89,1) 100%)`,
  };
  const [userData] = useContext(UserData);
  return data.map((sub, index) => {
    return (
      <div key={index}>
        {sub.featureflag && !isAZUser(userData) ? <></> :
          <div key={index}>
            {sub.path ? (
              <Link to={disableMDRLiteAndMobile(sub.menuText) !== " disabled-nav" && sub.path} key={sub.path}>
            <span
              style={styles}
              className={
                className +
                (sub.menuText === "SUPPORT" ? " help-btn sub-item path " : " nav-item sub-item path ") +
                (sub.menuText === "SUPPORT" ? "is-active ": " ") +
                (activeNavItem === sub.menuText ? "is-active" : "") +
                disableMDRLiteAndMobile(sub.menuText)
              }
              onClick={() => setActiveNavItem(sub.menuText)}
            >
              <span className={"text " + sub.menuText}>
                {sub.menuText}
                {sub.menuText === 'ZEROIN' && <img className={"asc"} src={Beta} alt=""/>}
              </span>
            </span>
              </Link>
            ) : sub.link ? (
              <a
                href={sub.link}
                target="_blank"
                rel="noreferrer"
                onClick={() => handleClickTrack(`${activeDropdown} menu - ${sub.menuText}`)}
              >
            <span
              style={styles}
              className={className + " help-btn sub-item path " + (activeNavItem === sub.menuText ? "is-active" : "")}
            >
              {sub.menuText}
            </span>
              </a>
            ) : (<span style={styles} className={className + " nav-item sub-item no-select"}>{sub.menuText}</span>)}
            {sub?.sub && (
              <DropdownNav
                className={"sub-sub-item"}
                data={sub.sub}
                activeNavItem={activeNavItem}
                setActiveNavItem={setActiveNavItem}
                indent={indent + 25}
              />
            )}
          </div>
        }
      </div>
    );
  });
};

export default DropdownNav;
