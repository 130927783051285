import React from "react";
import "../../../styles/pages/Network/TrafficLineChart.scss";
import LineChart from "../../../components/D3/LineChart/LineChart";
import LineChartLegend from "../../../components/D3/LineChart/Legend";
import {colors, lineData} from "../config/config";

const TrafficLineChart = ({mappedGraphData, axisData, timeFormat}) => (
  <div className={"traffic-chart-container"}>
    <div className={`title-and-legend`}>
      <div className={`graph-title`}>
        OVERALL TRAFFIC <span className="graph-subtitle">OVER TIME</span>
      </div>
    </div>
    {mappedGraphData?.length ? (
      <>
        <LineChartLegend
          colors={colors}
          rectPosition={400}
          textPosition={415}
          data={mappedGraphData}
        />
        <LineChart
          tickValues={timeFormat === '%b' ? mappedGraphData[0].values.filter((_, index) => index % 30 === 0).map(d => d.date) :
            timeFormat === '%m/%d' ? mappedGraphData[0].values.filter((_, index) => index % 7 === 0).map(d => d.date) :
              undefined}
          width={525}
          plotRadius={2}
          yAxisTicks={5}
          xAxisTicks={mappedGraphData[0].values.length}
          lineData={lineData}
          data={mappedGraphData}
          className={"network-line-chart"}
          axis={axisData}
          widthOffset={0.7}
          height={140}
          marginLeft={60}
          colors={colors}
          timeFormat={timeFormat}
          useLogScale={true}
        />
      </>
    ) : (
      <p className={"no-data"}>No Data</p>
    )}
  </div>
);

export default TrafficLineChart;
