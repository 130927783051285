import * as formatters from './format.js'

export function formatField(data, mapping, index) {
    let fieldName = mapping.field
    let displayName = mapping.field
    if (mapping.display) {
        displayName = mapping.display
    }
    let value = data[fieldName] || data[displayName]
    value = formatValue(mapping, value, index)
    return { fieldName, displayName, value }
}

export function transformValueWithFunction(value, functionName) {
    const transformFunction = formatters[functionName]

    return transformFunction.apply(null, [value])
}

export function formatValue(mapping, value, index) {
    if (!value && !mapping.transform) {
        return
    }
    if (mapping.value) {
        // eslint-disable-next-line no-new-func
        const f = new Function("value", "return `" + mapping.value + "`;")
        return f(value)
    }
    if (mapping.transform) {
        const transformFunction = formatters[mapping.transform]
        const params = [value, index]
        if (mapping.params) {
            params.push(...mapping.params)
        }
        return transformFunction.apply(null, params)
    }

    return value
}

