import React, {useContext, useEffect, useState, useRef} from 'react';
import '../../styles/pages/Mobile/Mobile.scss';
import PageFilters from "../../components/Filters/PageFilters";
import GlobalState from "../../store/GlobalState/GlobalState";
import PaginationControls from "../../components/Table/PaginationControls";
import {mobileFilterConfig, resetMobileFilterConfig,} from "../Hosts/filterconfig";
import Table from "../../components/Table/Table";
import {Loader} from "../../components/Loader/Loader";
import {filterData} from "../../util/format.js"
import {Pagination} from "../../components";
import MobileRow from "./MobileRow";
import {downloadFile} from "../../util/export"
import moment from 'moment';
import UserData from "../../store/User/UserData";
import {mainRow} from "../../components/Mappings/Mobile";
import TableHeader from "../../components/Table/TableHeader";
import {getMobileEndpoints, getMobileVulnerabilities} from "../../api/ApiClient";
import {setSortFilterValue} from "../../util/handleSortResults";
import {setResetFilter} from "../../util/handleResetFilter";
import {trackPageView} from "../../util/analytics";
import MobileVulnerabilitiesModal from './MobileVulnerabilitiesModal.js';
import {tooltipMapping} from '../../config/tooltipTextMapping';
import Tooltip from '../../components/Tooltip/Tooltip';
import NotFound from "../../navigation/NotFound";
import _ from 'lodash';
import { captureSentryError } from '../../util/sentry.js';

const Mobile = () => {
  const [userData] = useContext(UserData);
  const [azState] = useContext(GlobalState);
  const [pageCount] = useState(50)
  const today = new Date()
  const [showMobileVulnModal, setShowMobileVulnModal] = useState(false);
  const [mobileVulnerabilities, setMobileVulnerabilities] = useState([]);
  const [vulnerabilitiesLoading, setVulnerabilitiesLoading] = useState(false);
  const [selectedEndpoint, setSelectedEndpoint] = useState({})
  const defaultStartTime = new Date(new Date().setDate(today.getDate() - 30))
  const [filterList, setOpenFilters] = useState(mobileFilterConfig)
  const [mobileParams, setMobileParams] = useState({})
  const [page, setPage] = useState(1)
  let [sortFilters, setSortFilters] = useState([])
  const [showDropdown, toggleShowDropdown] = useState('')
  const [mobileLoading, setMobileLoading] = useState(false);
  const [mobileData, setMobileData] = useState({results: [], total: 0})
  const [initialConfig, setInitialConfig] = useState({
    start_time: moment(defaultStartTime).format(),
    end_time: moment(today).format(),
    page: 1,
    size: pageCount,
    mitigated: 'False'
  })
  const [exportLoading, setExportLoading] = useState(false);
  const [defaultDate, setDefaultDate] = useState({text: 'Last 30 Days', time: defaultStartTime})
  const [isReset, setIsReset] = useState(false)
  const [selectedDate, setSelectedDate] = useState('Last 30 Days')
  const [fetchError, setFetchError] = useState(false);
  const [mobileVulnError, setMobileVulnError] = useState(false);
  const prevParams = useRef({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => trackPageView("Mobile", userData, azState), [userData?.userId, azState.reactGaInitializedState]);

  const resetAllFilters = () => {
    setIsReset(true)
    setSortFilterValue([], mainRow, setSortFilters)
    setResetFilter(mobileFilterConfig, resetMobileFilterConfig, setOpenFilters)
    setMobileParams({
      start_time: moment(defaultStartTime).format(),
      end_time: moment(today).format(),
      page: 1,
      size: pageCount,
      mitigated: 'False'
    })
    setDefaultDate({text: 'Last 30 days', time: null})
    setInitialConfig({
      start_time: moment(defaultStartTime).format(),
      end_time: moment(today).format(),
      page: 1,
      size: pageCount,
      mitigated: 'False'
    })
    setSelectedDate('Last 30 Days')
    setIsReset(false)
  }

  const handleChangePage = async (currPage) => {
    setPage(currPage)
    setMobileParams({...mobileParams, page: currPage})
  }

  useEffect(() => {
    setSortFilterValue([], mainRow, setSortFilters)
    setResetFilter(mobileFilterConfig, resetMobileFilterConfig, setOpenFilters)
  }, [])

  const filterSeverity = (params) => {
    filterList.forEach(filter => {
      let name = Object.keys(filter)[0] // Filter name (i.e. Severity, Time)
      let filterName = filter[name].filter_name // Filter value string name (i.e. severity, created_date)
      if (filterName === 'severity') {
        let keys = Object.keys(filter[name].filters);
        keys.forEach((key) => { // Check keys to see if they are turned on
          if (filter[name].filters[key] === true) { // filter is turned on
              if (params.severity) {
                params.severity.push(key)
              } else {
                params.severity = [key]
              }
          }
        })
      }
    })
  }

  const onVulnerabilityClick = async (data) => {
    setMobileVulnError(false)
    setVulnerabilitiesLoading(true)
    setSelectedEndpoint(data)
    setShowMobileVulnModal(true)
    try {
      let params = {'sort_by': 'severity', 'order': 'desc', 'mitigated': 'False', 'mobile_edr_endpoint_id': data.mobile_edr_endpoint_id}
      filterSeverity(params)
      const mobileResponse = await getMobileVulnerabilities(userData?.tenant, params)
      setMobileVulnerabilities(mobileResponse?.results) 
    } catch (e) {
      captureSentryError(e, userData, "getMobileVulnerabilities API in Mobile.js");
      setMobileVulnError(true);
    } finally {
      setVulnerabilitiesLoading(false)
    }
  }

  useEffect(() => {
    setPage(1)
    filterData(filterList, mobileParams, pageCount, 1, setMobileParams, initialConfig, setInitialConfig)
    // eslint-disable-next-line
  }, [filterList, pageCount])

  useEffect(() => {
    const getMobileData = async () => {
      setFetchError(false);
      let isExport = mobileParams.isExport || false
      !isExport ? setMobileLoading(true) : setExportLoading(true);
      try {
        const mobileResponse = await getMobileEndpoints(userData?.tenant, mobileParams, isExport)
        if (isExport) {
          downloadFile(mobileResponse)
          setExportLoading(false)
        } else {
          setMobileData(mobileResponse)
        }
        document.getElementsByTagName("body")[0]?.scrollTo(0, azState?.scrollYPosition)
      } catch (e) {
        captureSentryError(e, userData, "getMobileEndpoints API in Mobile.js");
        setFetchError(true);
      } finally {
        setMobileLoading(false)
      }
    }
    if(!_.isEqual(prevParams.current, mobileParams)) {
      userData?.tenant && Object.keys(mobileParams).length > 0 && getMobileData()
      prevParams.current = mobileParams;
    }
    // eslint-disable-next-line
  }, [userData?.tenant, userData?.userType, mobileParams]);


  return (
    <div className="mobile-page-container all-page-container">
      {showMobileVulnModal && 
        <MobileVulnerabilitiesModal fetchError={mobileVulnError} setFetchError={setMobileVulnError} closeModal={setShowMobileVulnModal} data={mobileVulnerabilities} setModalData={setMobileVulnerabilities} loading={vulnerabilitiesLoading} endpoint={selectedEndpoint} setEndpoint={setSelectedEndpoint}/>
      }
      <div className={"header-row"}>
        <Tooltip content={tooltipMapping.mobile.title} className={"title-tooltip"} direction={"right"}>
          <p className={"page-title"}>MOBILE DEVICES</p>
        </Tooltip>
      </div>
      <div className={"mobile-content-container"}>
        <PageFilters
          isReset={isReset}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          className={"hosts-filters"}
          handleClose={async () => {
          }}
          filterList={filterList}
          setOpenFilters={setOpenFilters}
          setParams={setMobileParams}
          defaultDate={defaultDate}
        />
        {
          !mobileLoading &&
          <PaginationControls
            resetAllFilters={resetAllFilters}
            displayData={mobileData}
            page={page}
            setPage={handleChangePage}
            showDropdown={showDropdown}
            toggleShowDropdown={toggleShowDropdown}
            setParams={setMobileParams}
            params={mobileParams}
            bauExportLoading={exportLoading}
          />
        }
        {mobileLoading ?
          <div className={"loader-container"}><Loader/></div>
          : fetchError ? <NotFound isError dataError />
          : !mobileLoading && mobileData.results.length === 0 ?
            <div>
              <p className={'line-filter'}/>
              <p className={'no-results-text'}>No results, try expanding your filter parameters.</p>
            </div>
            :
            <div>
              <div className="fixed-table-container">
                <Table page={"mobile"}>
                  <TableHeader
                    mainRow={mainRow}
                    className={"mobile"}
                    setSortFilters={setSortFilters}
                    sortFilters={sortFilters}
                    setParams={setMobileParams}
                    params={mobileParams}
                  />
                  {
                    mobileData.results.map((item, i) => <MobileRow
                      key={i}
                      page={"mobile"}
                      data={item}
                      onVulnerabilityClick={onVulnerabilityClick}
                    />)
                  }
                </Table>
              </div>
              <div className={"pagination-controls"}>
                <Pagination
                  setPage={handleChangePage}
                  page={page}
                  total={mobileData.total}
                />
              </div>
            </div>
        }
      </div>
    </div>
  )

}

export default Mobile;