import React, { useState } from "react";
import "../../../../styles/pages/Onboarding/Cloud/DropdownSelection.scss";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useOnClickOutside } from "../../../../util/clickOutside";

const DropdownSelection = ({
  value = [],
  onChange,
  inputConfig,
  options,
  isMulti,
}) => {
  const [activeDropdown, setActiveDropdown] = useState();

  const handleAddDropdown = (opt) => {
    let currentSelection = [...value];
    let newSelection = currentSelection.includes(opt)
      ? [...currentSelection].filter((s) => s !== opt)
      : !isMulti
      ? [opt]
      : [...currentSelection, opt];
    onChange(newSelection);
  }

  const dropdownCheckbox = (selected) => {
    if (isMulti)
      return selected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
    else
      return selected ? (
        <RadioButtonCheckedIcon />
      ) : (
        <RadioButtonUncheckedIcon />
      );
  };

  const dropdownRef = useOnClickOutside(() => setActiveDropdown());

  return (
    <div className="add-account-dropdown" ref={dropdownRef}>
      <div
        className={`dropdown-container ${inputConfig.value} ${
          activeDropdown === inputConfig.value && "selected"
        }`}
      >
        <div
          className="dropdown-label"
          onClick={() =>
            setActiveDropdown((prevState) =>
              prevState !== inputConfig.value ? inputConfig.value : false
            )
          }
        >
          {"Select"}
          <KeyboardArrowDownIcon className="down-arrow" />
        </div>
        {activeDropdown === inputConfig.value && (
          <ul className="dropdown-options">
            {options.map((opt) => (
              <li
                onClick={() => handleAddDropdown(opt.value)}
                key={opt.value}
                className={`dropdown-option ${
                  value?.includes(opt.value) && "selected"
                }`}
              >
                {opt.label}
                <div className="dropdown-checkbox">
                  {dropdownCheckbox(
                    value?.includes(opt.value)
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default DropdownSelection;
