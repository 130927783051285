import {formatTime} from "../../util/format";
import SeverityImage from "../../components/Table/SeverityImage";
import Row from "../../components/Table/Row";
import StatusBar from "../../components/Table/StatusBar";
import LoaderButton from '../../components/Button/LoaderButton';
import {hasWriteAccess} from "../../util/userControl"

const TicketRow = ({data, page, severities, statuses, onClick, setPendingStatusChange, userData}) => {

  return (
    <Row page={page} onClick={(e) => !e.target.classList.contains("loader-btn") && onClick(e, data)} data={data}>
      <td className={"created_date"}>
        {formatTime(data.created_date)}
      </td>
      <td className={"case_number"}>
        {data.subject}
      </td>
      <td className={"priority"}>
        <SeverityImage severities={severities} severity={data.priority}/>
      </td>
      <td className={"submitted-by"}>
        <div> {data.email}</div>
      </td>
      <td className={"status-bar"}>
        {(data.hasOwnProperty('status') || data.hasOwnProperty('case_status')) && data.case_number &&
        <StatusBar statuses={statuses} selectedStatus={data.status || data['case_status']}/>}
      </td>
      <td className={"row-buttons"}>
      {hasWriteAccess(userData?.role) && data.status !== "Closed" && data.status !== "Merged" &&
        <LoaderButton
          className={"status-button"}
          text={data.status === "Resolved" ? "REOPEN" : "RESOLVE"}
          onClick={(e) => {setPendingStatusChange(data);}}
        />
      }
      </td>
    </Row>
  );
};

export default TicketRow;
