import React, {useCallback, useContext, useEffect, useState} from "react";
import VulnerabilitiesShield from "./config/images/vulns-shield.svg";
import CircularGraph from "../../components/D3/CircularGraph/CircularGraph";
import LineChart from "../../components/D3/LineChart/LineChart";
import {getVulnerabilitiesMetrics, getVulnerabilitiesTrends} from "../../api/ApiClient";
import UserData from "../../store/User/UserData";
import '../../styles/pages/Dashboard/VulnerabilitiesOverTime.scss';
import {mapChartData} from "../../util/mapChartData";
import * as d3 from "d3";
import {Loader} from "../../components/Loader/Loader";
import {formatLargeNumber, formatPercentage} from "../../util/format";
import {useNavigate} from "react-router-dom";
import GreenArrowDown from '../../assets/icons/green-arrow-down.svg';
import YellowArrowUp from '../../assets/icons/yellow-arrow-up.svg';
import OrangeArrowUp from '../../assets/icons/orange-arrow-up.svg';
import RedArrowUp from '../../assets/icons/red-arrow-up.svg';
import Tooltip from "../../components/Tooltip/Tooltip";
import {tooltipMapping} from "../../config/tooltipTextMapping";
import NotFound from '../../navigation/NotFound';
import { captureSentryError } from "../../util/sentry";

const VulnerabilitiesOverTime = () => {
  const [userData] = useContext(UserData);
  const navigate = useNavigate();
  const [percentDiff, setPercentDiff] = useState(null);
  const [mappedGraphData, setMappedGraphData] = useState({})
  const [axisData, setAxisData] = useState({x: {min: 0, max: 0}, y: {max: 0}})
  const [metricsData, setMetricsData] = useState({});
  const [vulnerabilitiesTrendLoading, setVulnerabilitiesTrendLoading] = useState(false)
  const [fetchError, setFetchError] = useState(false);
  const isMDRLite = userData?.tenantConfig?.is_mdr_lite;
  const isMobileOnly = userData?.tenantConfig?.is_mobile_edr_only
  const [graphData, setGraphData] = useState({
    name: 'vulnerabilities',
    value: 0,
    stringValue: ''
  })
  const colors = {
    Critical: "#D64526",
    High: "#FFA415",
    Medium: "#FFE133",
    Low: "rgba(255,255,255,0.3)"
  };

  const lineData = {
    lineOpacity: "1",
    lineOpacityHover: "1",
    otherLinesOpacityHover: "0.3",
    lineStroke: "3px",
    lineStrokeHover: "5px"
  };

  const calculateColor = (value) => {
    return value < 0 ? '#cff319' : value >= 0 && value < 20 ? '#FFE133' : value >= 21 && value < 49 ? '#FFA415' : '#D64526'
  }

  const displayArrow = (value) => {
    if (!value) return "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
    return value < 0 ? GreenArrowDown : value > 0 && value < 20 ? YellowArrowUp : value >= 21 && value < 49 ? OrangeArrowUp : RedArrowUp
  }

  const onClick = useCallback((severity) => {
    navigate('/risk-management/vulnerabilities', {state: {severity: severity, start_time: 'current'}})
  }, [navigate])

  useEffect(() => {
    const getVulnerabilitiesTrendData = async () => {
      if (userData.tenantConfig === undefined){
        return;
      }
      setFetchError(false);
      setVulnerabilitiesTrendLoading(true)
      try {
        let results = [
          {name: 'Critical', values: []},
          {name: 'High', values: []},
          {name: 'Medium', values: []},
          {name: 'Low', values: []}
        ]
        let data
        const [vulnerabilitiesMetricsResponse, vulnerabilitiesTrendsResponse] = await Promise.all([getVulnerabilitiesMetrics(userData?.tenant), getVulnerabilitiesTrends(userData?.tenant)])
        mapChartData(vulnerabilitiesTrendsResponse.severity_trend, setAxisData, setMappedGraphData, results)
        const {
          previous_scan_total: previousTotal,
          current_scan_total: currentTotal,
          percent_change_from_last_scan: percentDifference
        } = vulnerabilitiesMetricsResponse

        if (previousTotal === null && currentTotal === null) {   // No current or previous scans
          data = {
            name: 'vulnerabilities',
            value: 0,
            stringValue: 'No data'
          }
          d3.selectAll(`.total-count`).remove()
        } else if (previousTotal === null && currentTotal !== null) {  // Current scan but no previous scan
          data = {
            name: 'vulnerabilities',
            value: 0,
            stringValue: '-'
          }
        } else {
          data = {
            name: 'vulnerabilities',
            value: percentDifference / 100,
            stringValue: formatPercentage(percentDifference) + '%'
          }
        }
        if (isMDRLite || isMobileOnly) data.stringValue = ''
        setGraphData(data)

        // Save the truncated percent change for calculateColor and displayArrow
        percentDifference && setPercentDiff(Math.trunc(percentDifference))
        setMetricsData(vulnerabilitiesMetricsResponse)
      } catch (e) {
        captureSentryError(e, userData, 'getVulnerabilitiesTrendResponse API in VulnerabilitiesOverTime.js');
        setFetchError(true);
      } finally {
        setVulnerabilitiesTrendLoading(false)
      }
    }
    userData?.tenant && getVulnerabilitiesTrendData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.tenant, userData?.userType, isMDRLite, isMobileOnly]);

  useEffect(() => {
    const addTotalCount = (severity, count) => {
      d3.selectAll(`.${severity.toLowerCase()}.total-count`).remove()
      const mapValues = {
        Critical: {right: "147px", top: "12px", color: '#D64526'},
        High: {right: "169px", top: "66px", color: '#FFA415'},
        Medium: {right: "177px", top: "109px", color: '#FFE133'},
        Low: {right: "165px", top: "160px", color: 'rgba(255,255,255,0.3)'}
      }
      let value = count > 999 ? formatLargeNumber(count) : count
      let div = d3.select('.dashboard-vulns-graph')
      let total = div.append("text")
        .attr("text-anchor", "middle")
        .attr("class", `${severity.toLowerCase()} total-count`)
        .attr("dy", "0.3em")
        .style("right", mapValues[severity].right)
        .style("top", mapValues[severity].top)
        .style("color", mapValues[severity].color)
        .style("position", "absolute")
        .style("width", "20px")
        .style("font-family", "Roboto Condensed")
        .style("font-size", "16px")


      total.text(value?.toString());

      var tooltip = total
        .append("div")
        .attr("class", `d3-tooltip ${severity}`)
        .style("opacity", 0);
      let timeout;
      (!isMobileOnly && !isMDRLite) && total.append("div").attr("class", `d3-tooltip-mouseover`).on("mouseover", () => {
        timeout = setTimeout(() => tooltip
          .style("opacity", (isMDRLite && isMobileOnly) ? 0 : 1)
          .text(`Number of ${severity.toLowerCase()} vulnerabilities`), 500)
      })
        .style('cursor', (!isMDRLite && !isMobileOnly) && 'pointer')
        .on("mouseout", () => {
          clearTimeout(timeout);
          tooltip.style("opacity", 0);
        })
        .on("click", () => {
          onClick(severity)
        });
    }

    metricsData?.severities && Object.entries(metricsData?.severities).length > 0 &&
    Object.entries(metricsData.severities).forEach(([k, v]) => {
      addTotalCount(k, v.current)
    })

  }, [metricsData.severities, onClick, isMDRLite, isMobileOnly])

  return (
    <div className={"vulnerabilities-over-time right-item" + ((isMDRLite || isMobileOnly) ? ' disabled' : '')}>
      {vulnerabilitiesTrendLoading && <div className={"loader-container"}><Loader/></div>}
      {fetchError ? <NotFound isError dataError className={"panel vulnerabilities"}/> :
      <>
        <Tooltip
          content={tooltipMapping.dashboard.vulnerabilitiesOverTime}
          className={"vulns-title-tooltip"}
          direction={"right"}
          disable={isMDRLite || isMobileOnly}
        >
          <p className={"circle-graph-title vulns-over-time-title"}><b>VULNERABILITIES</b> <span>OVER TIME</span></p>
        </Tooltip>
        <div className={"dashboard-vulns-graph"} style={{backgroundImage: `url(${VulnerabilitiesShield}`}}>
          <Tooltip
            content={tooltipMapping.dashboard.vulnerabilitiesChange}
            className={"vulns-percent-tooltip"}
            direction={"right"}
            disable={isMDRLite || isMobileOnly}
          >
            <CircularGraph
              onClick={onClick}
              containerName={'dashboard-vulns'}
              data={graphData}
              color={calculateColor(percentDiff)}
              xAxis={"0"}
            />
            <img
              className={"vulns-arrow"}
              alt={"vulns-arrow"}
              src={displayArrow(percentDiff)}
            />
          </Tooltip>
        </div>
      </>
      }
      <div className={"dashboard-vulns-chart"}>
        {mappedGraphData.length > 0 && !vulnerabilitiesTrendLoading &&
        <LineChart
          width={500}
          plotRadius={2}
          yAxisTicks={4}
          xAxisTicks={mappedGraphData[0].values.length}
          lineData={lineData}
          data={mappedGraphData}
          className={"dashboard-vulns-line-chart"}
          axis={axisData}
          widthOffset={.68}
          height={130}
          marginLeft={50}
          colors={colors}
          tick={false}
        />}
      </div>
      {(isMDRLite || isMobileOnly) && <div className={"upsell-message"}>Vulnerability Management</div>}
    </div>
  );
};

export default VulnerabilitiesOverTime;
