import GenericCheckbox from "../../components/Dropdown/GenericCheckbox";
import React, {useEffect, useState} from "react";

const FrameworkFilters = ({data, filter, selectCheckbox}) => {
  const filterName = Object.keys(filter)[0]
  const [filters, setFilters] = useState([
    {'CIS 7.1': ''},
    {'CIS 8.0': ''},
    {'CMMC': ''},
    {'CMMC 2.0': ''},
    {'ISO 27001:2022': ''},
    {'ISO 27002:2022': ''},
    {'NIST 800-171': ''},
    {'NIST 800-172': ''},
    {'NIST 800-66 r1 (HIPAA)': ''}
  ])

  useEffect(() => {
    filters.map(item => {
      let name = Object.keys(item)[0]
      let values = data.find(value => name === value.framework);
      if (values) {
        item[name] = values.percent_completed
      }
      return item
    })
    setFilters([...filters])
    // eslint-disable-next-line
  }, [data])

  const calculateColor = (value) => {
    return value <= 25 ? '#D64526' : value > 25 && value <= 50 ? '#FFA415' : value > 50 && value <= 75 ? '#FFE133' : '#cff319'
  }

  return (
    <div className={"framework-container"}>
      <div className={"dropdown-checkbox-container"}>
        <span className={"dropdown-checkbox-title"}>{filterName.toUpperCase()}</span>
        <GenericCheckbox
          isOpen={filter[filterName].isOpen}
          dropdownList={filter[filterName].filters}
          selectCheckbox={selectCheckbox}
          name={filterName}
          data={filter}/>
      </div>
      <div className={"completed-container"}>
        {filters.map((value, i) => {
          let name = Object.keys(value)[0]
          let completed = value[name] !== '' ? Math.round(value[name]) + '%' : ''
          return <p key={i} style={{color: calculateColor(value[name])}} className={"completed"}>{completed}</p>
        })}
      </div>
    </div>
  )
}

export default FrameworkFilters