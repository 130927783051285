import React, { useState, useContext } from "react";
import UserData from "../../../store/User/UserData";
import Modal from "../../../components/Modal/Modal";
import LoaderButton from "../../../components/Button/LoaderButton";
import { captureSentryError } from "../../../util/sentry";
import Highlighter from "react-highlight-words";

const CommentModal = ({ data, handleClose }) => {
  const [userData] = useContext(UserData);
  const [selectedText, setSelectedText] = useState("");
  const [comment, setComment] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleCommentSubmit = async () => {
    try {
      setSubmitLoading(true);
      // API call with selectedText, full text context, and data?.id
      handleClose();
    } catch (e) {
      captureSentryError(e, userData, "POST adhoc investigation comment");
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleTextSelection = () => {
    const selected = window.getSelection().toString();
    setSelectedText(selected);
  };

  return (
    <Modal close={() => handleClose()}>
      <div className="modal-content">
        <div className="modal-header">{`${data?.module} - Feedback`}</div>
        <div className="text-box" onMouseUp={handleTextSelection}>
          <Highlighter
            highlightClassName="highlighted-text"
            searchWords={[selectedText]}
            autoEscape={true}
            textToHighlight={data?.text || ""}
          />
        </div>
        <textarea
          placeholder="Write your comment here..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <LoaderButton
          onClick={() => handleCommentSubmit()}
          loading={submitLoading}
          text="SUBMIT"
        />
      </div>
    </Modal>
  );
};

export default CommentModal;
