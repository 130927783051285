import React from "react";
import "../../../styles/pages/AdhocInvestigation/DetectionPanels.scss";
import { formatTime } from "../../../util/format";
import { Loader } from "../../../components/Loader/Loader";

const DetectionPanels = ({ data, loading, className }) => {
  const renderSection = (header, className, data) => (
    <div className={`section ${className}-section`}>
      <div className={`header ${className}`}>{header}</div>
      {!loading ? (
        <ul>
          {data.map((val, index) => (
            <li key={index}>{val}</li>
          ))}
        </ul>
      ) : (
        <Loader />
      )}
    </div>
  );

  const processTimelineData = (timeline) => {
    return timeline.map((item) => {
      const timestampMatch = item.match(/timestamp='([^']+)'/);
      const affectedAssetMatch = item.match(/affected_asset='([^']+)'/);
      const occurrenceMatch = item.match(/occurrence='([^']+)'/);

      const timestamp = timestampMatch ? timestampMatch[1] : "";
      const affectedAsset = affectedAssetMatch ? affectedAssetMatch[1] : "";
      const occurrence = occurrenceMatch ? occurrenceMatch[1] : "";

      return {
        timestamp: formatTime(timestamp),
        affectedAsset,
        occurrence,
      };
    });
  };

  return (
    <div className={`detection-panel-container ${className}`}>
      {data.summary && renderSection("Summary", "summary", [data.summary])}
      <div className="detection-right-side">
        {data?.timeline &&
          renderSection(
            "Timeline",
            "timeline",
            processTimelineData(data.timeline).map(
              (item) =>
                `${item.timestamp} — ${item.affectedAsset} — ${item.occurrence}`
            )
          )}
        {data.affected_assets &&
          renderSection(
            "Affected Assets",
            "affected_assets",
            data.affected_assets
          )}
        {data.findings && renderSection("findings", "findings", data.findings)}
      </div>
    </div>
  );
};

export default DetectionPanels;
