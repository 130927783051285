export const filterConfig = [
  {
    Severity: {
      filter_name: "severity",
      isOpen: true,
      filters: {
        Critical: false, High: false, Medium: false, Low: false, Informational: false
      }
    },
  },
  {
    Source: {
      filter_name: "source",
      isOpen: true,
      filters: {'Endpoint': false, 'Cloud': false, 'Network': false, 'Mobile': false, 'Identity': false}
    }
  },
  {
    Cloud: {
      filter_name: "type",
      isOpen: true,
      filters: {'AWS': false, 'Gsuite': false, 'Salesforce': false, 'O365': false}
    }
  },
  {
    Status: {
      filter_name: "status",
      isOpen: true,
      filters: {'Waiting On Customer': false, 'Waiting On ActZero': false, 'Resolved': false, 'Closed': false, 'Re-Opened': false}
    }
  }
]

export const resetFilterConfig = {
    Severity: {
      filter_name: "severity",
      isOpen: true,
      filters: {
        Critical: false, High: false, Medium: false, Low: false, Informational: false
      }
    },
    Source: {
      filter_name: "source",
      isOpen: true,
      filters: {'Endpoint': false, 'Cloud': false, 'Network': false, 'Mobile': false, 'Identity': false}
    },
    Cloud: {
      filter_name: "type",
      isOpen: true,
      filters: {'AWS': false, 'Gsuite': false, 'Salesforce': false, 'O365': false}
    },
    Status: {
      filter_name: "status",
      isOpen: true,
      filters: {'Waiting On Customer': false, 'Waiting On ActZero': false, 'Resolved': false, 'Closed': false, 'Re-Opened': false}
    }
}

export default filterConfig
