import "../../../styles/pages/Onboarding/VulnerabilityScan/VulnerabilityScan.scss";
import React, { useContext, useEffect, useState } from "react";
import UserData from "../../../store/User/UserData";
import GlobalState from "../../../store/GlobalState/GlobalState";
import { trackPageView } from "../../../util/analytics";
import "react-phone-number-input/style.css";
import NotFound from "../../../navigation/NotFound";
import Tab from "../../../components/Tab/Tab";
import DenseTable from "../../../components/Table/DenseTable";
import { tableHeaders, tabConfig } from "./config";
import { TableHeader } from "./components/TableHeader";
import { TableBody } from "./components/TableBody";
import Dropdown from "../../../components/Dropdown/Dropdown";
import IPAddressInput from "./components/IPAddressInput";
import IPAddressModal from "./components/IPAddressModal";
import NewScanDateModal from "./components/NewScanDateModal"
import { isRoot } from "../../../util/userControl";
import {
  verifySubmission,
  handleAdd,
  handleDelete,
  getScanDays,
  getVulnerabilityScanData,
} from "./util";
import { useOnClickOutside } from "../../../util/clickOutside";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import { externalScanIPs } from "./config";

const VulnerabilityScan = ({setPage}) => {
  const [azState] = useContext(GlobalState);
  const [userData] = useContext(UserData);
  const [data, setData] = useState([]);
  const [virtualMachines, setVirtualMachines] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [currTab, setCurrTab] = useState("external_scans");
  const [modalData, setModalData] = useState();
  const [externalInput, setExternalInput] = useState("");
  const [internalInput, setInternalInput] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [selectedVM, setSelectedVM] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showInternalInputs, setShowInternalInputs] = useState(false);
  const [showExternalInputs, setShowExternalInputs] = useState(false);
  const [pendingRow, setPendingRow] = useState();
  const [rowError, setRowError] = useState("");
  const [rowLoading, setRowLoading] = useState(false);
  const [scanDayOptions, setScanDayOptions] = useState([]);
  const [scanDayFetchError, setScanDayFetchError] = useState("");
  const [editing, setEditing] = useState(false);
  const [defaultDateSelection, setDefaultDateSelection] = useState();
  const [showDateResponseModal, setShowDateResponseModal] = useState(false);
  const [showIPInfo, setShowIPInfo] = useState();
  const [stringCopied, setStringCopied] = useState({}); // State for copy status

  // FEATURE FLAG to be removed in the future:
  const isRootUser = isRoot(userData.userType);
  let modalCloseTimeout;

  useEffect(
    () => trackPageView("Vulnerability Scan Onboarding", userData, azState),
    // eslint-disable-next-line
    [userData?.userId, azState.reactGaInitializedState]
  );


  useEffect(() => {
    if (userData.tenant) {
      handleFetchData()
      getScanDays({ userData, setScanDayOptions, setScanDayFetchError });
    }
    // eslint-disable-next-line
  }, [userData?.tenant]);

  const ipInfoRef = useOnClickOutside(() => setShowIPInfo());

  const handleFetchData = () =>
    getVulnerabilityScanData({
      setFetchError,
      setLoading,
      userData,
      setData,
      setShowInternalInputs,
      setShowExternalInputs,
      setVirtualMachines,
      setDefaultDateSelection
    });

  const handleSelection = (selection) => {
    setSelectedVM(selection);
    setShowDropdown(false);
  };

  const handleRowButton = ({ row, action }) => {
    if (action === "delete") setPendingRow({ row: row, action: action });
    else if (action === "edit") {
      setEditing(true);
      setModalData(row);
    }
  };

   const closeLoadingModal = () => {
      setShowDateResponseModal(false)
      clearTimeout(modalCloseTimeout)
    }

  const resetPendingStages = () => {
    setPendingRow();
    setRowError("");
  };

  const onSubmitFunc = async() => {
       let days = 22;
       if(data.external_scans.length > 0) {
         days = data.external_scans[0]['scan_day_of_month']
       } else {
         days = data.internal_scans[0]['scan_day_of_month']
       }

       handleAddFunc(days)

     setShowDateResponseModal(false)
  }

  const handleAddFunc = (days) => {
      handleAdd({
          setSubmitError,
          setSubmitLoading,
          setShowDateResponseModal,
          currTab,
          externalInput,
          internalInput,
          setExternalInput,
          setInternalInput,
          selectedVM,
          userData,
          setSelectedVM,
          handleFetchData,
          defaultDateSelection:days ? days : defaultDateSelection,
       })
  }

  const handlePendingRowSubmit = () => {
    if (pendingRow.action === "delete")
      handleDelete({
        id: pendingRow.row.id,
        setRowError,
        setRowLoading,
        userData,
        resetPendingStages,
        handleFetchData,
      });
  };
  
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setStringCopied({ [text]: true });
    setTimeout(() => {
      setStringCopied({ [text]: false });
    }, 2000);
  };

  return (
    <div className="vulnerability-scan-onboarding">
      {modalData && (
        <IPAddressModal
          currTab={currTab}
          setModalData={setModalData}
          modalData={modalData}
          handleFetchData={handleFetchData}
          userData={userData}
          scanDayOptions={scanDayOptions}
          editing={editing}
          setEditing={setEditing}
          isRootUser={isRootUser}
          scanDayFetchError={scanDayFetchError}
          verifySubmission={() =>
                verifySubmission({
                  setSubmitError,
                  setSubmitLoading,
                  showDateResponseModal,
                  setShowDateResponseModal,
                  currTab,
                  externalInput,
                  internalInput,
                  selectedVM
                })
              }
        />
      )}
      <div className="onboarding-title vulnerability-scan">
        VULNERABILITY SCAN
      </div>
      <div className="vulnerability-description">
        <span>
          Automatically detect vulnerabilities and enhance protection for IP ranges with scheduled security scans.
        </span>
      </div>
      <div className="info">
        <div className={"flex"} ref={ipInfoRef}>
          <span
            className="ip-info"
            onClick={() => {
              setShowIPInfo((prevState) => !prevState);
            }}
          >{"EXTERNAL SCAN IP »"}</span>
            {showIPInfo && (
            <div className="ip-info-container">
              <div className="title">
                {"External scans come from the following IP addresses:"}
              </div>
              {externalScanIPs.map((ip) => (
                <div className="ip-entry" key={ip}>
                  {`${ip}`}
                  <IconButton
                    onClick={() => handleCopy(ip)}
                    className="copy-btn"
                  >
                    <ContentCopyIcon
                      className={`copy-icon ${stringCopied[ip] && "copied"}`}
                    />
                  </IconButton>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="tab-container">
        <div className="vuln-scan-tabs">
          {tabConfig.map((tab) => (
            <Tab
              onClick={() => {
                setSubmitError("");
                setCurrTab(tab.configName);
              }}
              key={tab.configName}
              data={tab}
              selected={currTab === tab.configName}
            />
          ))}
        </div>
        <div className="tab-border" />
      </div>
      {fetchError ? (
        <NotFound isError dataError />
      ) : (
        <div className="vuln-scan-table">
          <DenseTable
            loading={loading}
            header={<TableHeader headerConfig={tableHeaders[currTab]} />}
            noDataMessage={"No scans added yet."}
            body={
              <TableBody
                data={data[currTab]}
                setModalData={setModalData}
                tableHeaders={tableHeaders[currTab]}
                pendingRow={pendingRow}
                setPendingRow={handleRowButton}
                handleSubmit={handlePendingRowSubmit}
                resetPendingStages={resetPendingStages}
                rowError={rowError}
                rowLoading={rowLoading}
                isRootUser={isRootUser}
              />
            }
          />
        </div>
      )}
      <>
        {currTab === "internal_scans" && data?.internal_scans?.length ? (
          <div
            className="reveal-add"
            onClick={() => setShowInternalInputs((val) => !val)}
          >
            {!showInternalInputs
              ? "+ ADD ADDITIONAL IP RANGES »"
              : "- ADD ADDITIONAL IP RANGES »"}
          </div>
        ) : (
          <></>
        )}
        {currTab === "internal_scans" && showInternalInputs && (
          <div className="dropdown-section">
            <div className="input-label">{"Select a VM"}</div>
            <div className="hostname-dropdown-container">
              {!virtualMachines.length ? (
                <>
                  <span>{"No VMs to select"}</span>
                  <span
                    onClick={() => setPage("virtual-machine")}
                    className="vm-link"
                  >
                    {"Virtual machine onboarding »"}
                  </span>
                </>
              ) : (
                <Dropdown
                  name={selectedVM || "VM name"}
                  dropdownList={virtualMachines}
                  selectItem={handleSelection}
                  selectedItem={selectedVM}
                  showDropdown={showDropdown}
                  setShowDropdown={setShowDropdown}
                />
              )}
            </div>
          </div>
        )}
        {showDateResponseModal && ((data.internal_scans.length > 0) || (data.external_scans.length > 0)) &&
          <NewScanDateModal
            cancelFunc={closeLoadingModal}
            submitFunc={onSubmitFunc}
            defaultDateSelection={defaultDateSelection}
            />}
        {((currTab === "external_scans" &&
          !data?.external_scans?.length &&
          showExternalInputs) ||
          (currTab === "internal_scans" && showInternalInputs)) && (
          <div className="add-ip-container">
            <IPAddressInput
              currTab={currTab}
              inputValue={
                currTab === "external_scans" ? externalInput : internalInput
              }
              setInputValue={
                currTab === "external_scans"
                  ? (e) => setExternalInput(e.target.value)
                  : (e) => setInternalInput(e.target.value)
              }
              verifySubmission={() =>
                verifySubmission({
                  setSubmitError,
                  setSubmitLoading,
                  showDateResponseModal,
                  setShowDateResponseModal,
                  currTab,
                  externalInput,
                  internalInput,
                  selectedVM
                })
              }
              handleAdd={() =>
                 handleAddFunc()
              }
              handleOnEditSubmit={()=>{}}
              isEdit={false}
              submitLoading={submitLoading}
              setSubmitLoading={setSubmitLoading}
              submitError={submitError}
              setSubmitError={setSubmitError}
              internalInput={internalInput}
              externalInput={externalInput}
              showDateResponseModal={showDateResponseModal}
              setShowDateResponseModal={setShowDateResponseModal}
              internal_scans={data.internal_scans}
              external_scans={data.external_scans}
            />
          </div>
        )}
      </>
    </div>
  );
};

export default VulnerabilityScan;
