import React, {useContext, useEffect, useState} from 'react';
import '../../styles/pages/Connections/Cloud.scss';
import {abbreviateNumber, formatTimeAgo} from "../../util/format";
import {mapIcons} from "./config/iconMap";
import ConnectionsLineChart from "./ConnectionsLineChart";
import CloudCard from "./CloudCard";
import {Loader} from "../../components/Loader/Loader";
import UserData from "../../store/User/UserData";
import {getCloudConnections} from "../../api/ApiClient";
import {tooltipMapping} from "../../config/tooltipTextMapping";
import Tooltip from "../../components/Tooltip/Tooltip";
import _ from "lodash";
import { hasAdminAccess } from '../../util/userControl';
import MailButton from "../../components/MailButton/MailButton";
import NotFound from "../../navigation/NotFound";
import { captureSentryError } from '../../util/sentry';

const CloudConnection = ({onInvestigationClick, isMobileOnly, createEmail}) => {
  const [userData] = useContext(UserData);
  const [colors] = useState({})
  const [connectionsData, setConnectionsData] = useState({})
  const [count, setCount] = useState(0)
  const [cloudLoading, setCloudLoading] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const mapValues = {
    "Last Seen": {field: 'last_seen'},
    "Events": {field: 'total_events'},
    "Investigations": {field: 'investigation_count'},
  }

  useEffect(() => {
    const getConnections = async () => {
      setFetchError(false);
      setCloudLoading(true)
      try {
        let connectionsResponse = await getCloudConnections(userData?.tenant)
        connectionsResponse.overall?.total_active_connections !== undefined && setCount(abbreviateNumber(connectionsResponse.overall.total_active_connections, 0))
        connectionsResponse.overall.total_events = abbreviateNumber(_.sumBy(_.keys(_.groupBy(connectionsResponse.clouds, "event_count")), (i) => Number(i)));
        setConnectionsData(connectionsResponse)
      } catch (e) {
        captureSentryError(e, userData, "getCloudConnections API in Cloud.js");
        setFetchError(true);
      } finally {
        setCloudLoading(false)
      }
    }
    userData?.tenant && isMobileOnly !== undefined && !isMobileOnly && getConnections()
    isMobileOnly && setConnectionsData({clouds: {aws:{}, gsuite:{}, o365: {}, salesforce: {}}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.tenant, isMobileOnly]);

  return (
    <>
      <tr className={"connections-row cloud"}>
        {!cloudLoading ? 
        fetchError ? <NotFound isError dataError className="panel service" /> 
        :
        <>
          <td className={"connection-type"}>
            <Tooltip content={tooltipMapping.serviceStatus.cloud} className={"title-tooltip"} direction={"right"}>
              <p className={"is-connected" + (isMobileOnly ? ' disabled' : '')}>CLOUD</p>
            </Tooltip>
            {connectionsData?.overall?.total_active_connections !== undefined && <p className={"count"  + (isMobileOnly ? ' disabled' : '')}>
              {count}
            </p>}
            {isMobileOnly && hasAdminAccess(userData?.role) &&
            <MailButton label={"ADD SERVICE »"} mailto={createEmail('cloud')}/>}
          </td>
          <td>
            <span className={"circle " + (!count ? "grey" : connectionsData?.overall?.status)}/>
          </td>
          <td className={"service-icons" + (isMobileOnly ? ' disabled' : '')}>
            <img alt={'cloud'} src={mapIcons('cloud')}/>
          </td>
          <td className={"connection-data"}>
            {connectionsData?.overall && Object.entries(mapValues).map((elem, index) => {
              let field = elem[1].field
              let count = connectionsData.overall[field]
              let value = field === 'last_seen' ? formatTimeAgo(connectionsData.overall[field]) : abbreviateNumber(connectionsData.overall[field], 0)
              let hasInvestigationLink = elem[0] === "Investigations" && count > 0
              return <div key={index} className={"display-values " + elem[0] + (hasInvestigationLink ? ' pointer' : '')}>
                <p className={"name"}>{elem[0]}</p>
                <p
                  className={"value " + (hasInvestigationLink ? ' link' : '')}
                  onClick={() => hasInvestigationLink && onInvestigationClick('Cloud')}
                >{!isMobileOnly ? value : '-'}</p>
              </div>
            })}
          </td>
          <td className={"chart-container"}>
            {connectionsData?.overall?.trend?.data &&
            <ConnectionsLineChart
              displayName={'cloud'}
              data={connectionsData.overall.trend.data}
              colors={colors}
            />}
          </td>
        </> : <td><Loader/></td>}
      </tr>
      {!cloudLoading && connectionsData?.clouds && Object.keys(connectionsData?.clouds).length > 0 &&
      <tr className={"cloud-card-row"}>
        {Object.entries(connectionsData.clouds).map((data, i) =>
          <CloudCard isMobileOnly={isMobileOnly} key={i} data={data}/>)}
      </tr>}
    </>
  )
}

export default CloudConnection;
