import React from "react";

export const firewallTooltip = (
  <ul className="main-list">
    <li>
      <div className={"circle green"}/>
      ActZero is successfully ingesting data
      <ul className="sub-list">
        <li>
          Supported Log Format (list of supported{" "}
          <a
            href="https://docs.actzero.ai/log-forwarding/"
            target="_blank"
            rel="noreferrer"
          >
            parsers
          </a>
          )
        </li>
        <li>IP/Device matches with the onboarding page</li>
      </ul>
    </li>

    <li>
      <span className={"circle yellow"}/>
      ActZero is successfully ingesting data and could be one of the conditions
      below
      <ul className="sub-list">
        <li>
          Unsupported Log Format (list of supported{" "}
          <a
            href="https://docs.actzero.ai/log-forwarding/"
            target="_blank"
            rel="noreferrer"
          >
            parsers
          </a>
          )
        </li>
        <li>IP/Device is not specified in the onboarding page</li>
      </ul>
    </li>

    <li>
      <span className={"circle red"}/> ActZero is unable to ingest data and
      could be a combination of below conditions
      <ul className="sub-list">
        <li>No data or no recent data in the past 12 hours</li>
        <li>
          Unsupported Log Format (list of supported{" "}
          <a
            href="https://docs.actzero.ai/log-forwarding/"
            target="_blank"
            rel="noreferrer"
          >
            parsers
          </a>
          )
        </li>
        <li>Not onboarded</li>
      </ul>
    </li>
  </ul>
);
