export const mainRow = {
	"Time": {"field": "last_seen", "header": "TIME", "sort": true, "defaultSortValue": false},
	"Email": {"field": "Email", "header": "EMAIL", "sort": true, "defaultSortValue": false},
	"Name": {"field": "Name", "header": "NAME", "sort": true, "defaultSortValue": false},
	"Status": {"field": "status", "header": "STATUS", "sort": true, "defaultSortValue": false},
	"Vulnerabilities": {"field": "vulnerabilities_count", "header": "VULNERABILITIES", "sort": true, "defaultSortValue": false},
	"Type": {"field": "os_type", "header": "OS TYPE", "sort": true, "defaultSortValue": false},
	"OSVersion": {"field": "os_version", "header": "OS VERSION", "sort": true, "defaultSortValue": false},
	"Phone Model": {"field": "phone_model_name", "header": "PHONE MODEL", "sort": true, "defaultSortValue": false},
}
