import React from "react";
import "../../styles/components/Table/DenseTable.scss";
import { Loader } from "../Loader/Loader";

const DenseTable = (props) => {
  const { header, body, loading, noDataMessage, className } = props;
  return (
    <table className={"dense-table " + (className ? className : '')}>
      <tbody>
        <tr className="dense-table-header">{header}</tr>
        {loading ? (
          <tr className="no-data">
            <td>
              <Loader />
            </td>
          </tr>
        ) : (body?.length || body?.props?.data?.length) ? (
          body
        ) : (
          <tr className="no-data">
            <td>{noDataMessage}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default DenseTable;
