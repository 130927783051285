import React, {useLayoutEffect, useState, useRef} from "react";
import {formatTime} from "../../util/format";
import SeverityImage from "../../components/Table/SeverityImage";
import Row from "../../components/Table/Row";
import StatusBar from "../../components/Table/StatusBar";
import Tooltip from "../../components/Tooltip/Tooltip";
import {mapIcons} from "../../config/iconMapping";

const InvestigationsRow = ({data, page, severities, statuses, onClick}) => {
  const [titleTooltip, showTitleTooltip] = useState(false);
  const [targetTooltip, showTargetTooltip] = useState(false);
  const titleContainerRef = useRef();
  const titleTextRef = useRef();
  const targetContainerRef = useRef();
  const targetTextRef = useRef();

  useLayoutEffect(()=> {
    const titleResizeObserver = new ResizeObserver(() => setTimeout(() => {
      if(titleTextRef.current?.offsetWidth > (titleContainerRef.current?.clientWidth * 0.75) - 5) showTitleTooltip(true) // css width is 75%
      else showTitleTooltip(false)
    }), 0);
    const targetResizeObserver = new ResizeObserver(() => setTimeout(() => {
      if(targetTextRef.current?.offsetWidth > (targetContainerRef.current?.clientWidth * 0.65) - 5) showTargetTooltip(true) // css width is 65%
      else showTargetTooltip(false)
    }), 0);

    titleResizeObserver.observe(titleContainerRef.current);
    targetResizeObserver.observe(targetContainerRef.current);

    return () => {
      titleResizeObserver.disconnect()
      targetResizeObserver.disconnect()
    }; 
  }, [])
  
  return (
    <Row page={page} onClick={onClick} data={data}>
      <td className={"created_date"}>
        {formatTime(data.created_date)}
      </td>
      <td className={"source"}>
      <div className="icon-source-container" ref={targetContainerRef}>
        <div className="icon-container">
            {data.source && <img className={`attack-icon`} alt={'attack-icon'} src={mapIcons(data.source, data.type, 1)}/>}
        </div>
        <span className={"text"}>{data.source}</span>
      </div>
      </td>
      <td className={"severity"}>
        <SeverityImage severities={severities} severity={data.severity}/>
      </td>
      <td className={"title"}>
        <div className="icon-title-container" ref={titleContainerRef}>
          <div className="icon-container">
            <img className={"attack-icon"} alt={'attack-icon'} src={mapIcons(data.source, data.type, 0)}/>
          </div>
          {titleTooltip ?
            <Tooltip content={data.title} direction={"right"}>
              <span className="text contained" ref={titleTextRef}>{data.title}</span>
            </Tooltip>
            : <span className="text" ref={titleTextRef}>{data.title}</span>
          }
        </div>
      </td>
      <td className={"target"}>
        <div className="icon-target-container" ref={targetContainerRef}>
          <div className="icon-container">
            {data.source && <img className={`attack-icon`} alt={'attack-icon'} src={mapIcons(data.source, data.type, 2)}/>}
          </div>
          {targetTooltip ?
            <Tooltip content={data.affected_resource} direction={"right"}>
              <span className="text contained" ref={targetTextRef}>{data.affected_resource}</span>
            </Tooltip>
            : <span className="text" ref={targetTextRef}>{data.affected_resource}</span>
          }
        </div>
      </td>
      <td className={"status-bar"}>
        {(data.hasOwnProperty('status') || data.hasOwnProperty('case_status')) && data.case_number &&
        <StatusBar statuses={statuses} selectedStatus={data.status || data['case_status']}/>}
      </td>
    </Row>
  );
};

export default InvestigationsRow;
