import React, {useContext, useEffect, useState} from 'react';
import '../../styles/pages/Connections/Identity.scss';
import {abbreviateNumber, formatTimeAgo} from "../../util/format";
import {mapIcons} from "./config/iconMap";
import ConnectionsLineChart from "./ConnectionsLineChart";
import {Loader} from "../../components/Loader/Loader";
import UserData from "../../store/User/UserData";
import {getIdentityConnections} from "../../api/ApiClient";
import {tooltipMapping} from "../../config/tooltipTextMapping";
import Tooltip from "../../components/Tooltip/Tooltip";
import { hasAdminAccess } from "../../util/userControl"
import DisabledChart from "./DisabledChart";
import MailButton from "../../components/MailButton/MailButton";
import NotFound from "../../navigation/NotFound";
import { captureSentryError } from '../../util/sentry';
import {useNavigate} from "react-router-dom";

const IdentityConnection = ({onInvestigationClick, isMDRLite, isMobileOnly, createEmail}) => {
  const [userData] = useContext(UserData);
  const [colors, setColors] = useState({})
  const [connectionsData, setConnectionsData] = useState({})
  const [count, setCount] = useState(0)
  const [identityLoading, setIdentityLoading] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const navigate = useNavigate();
  const mapValues = {
    "Last Seen": {field: 'last_seen'},
    "Events": {field: 'total_event_count'},
    "Investigations": {field: 'investigation_count'},
  }

  useEffect(() => {
    const getConnections = async () => {
      setFetchError(false);
      setIdentityLoading(true)
      try {
        let connectionsResponse = await getIdentityConnections(userData?.tenant)
        if(!connectionsResponse.error){
          let name = 'Identity_event_count_per_day'
          let color = connectionsResponse?.overall?.status && connectionsResponse?.overall?.status === 'green' ? "#99E100" : "grey"
          setColors({[name]: color})
          connectionsResponse.overall.trend.data[name] = connectionsResponse.overall.trend.data[Object.keys(connectionsResponse.overall.trend.data)[0]]
          delete connectionsResponse.overall.trend.data[Object.keys(connectionsResponse.overall.trend.data)[0]];
          connectionsResponse.overall?.total_active_connections !== undefined && setCount(abbreviateNumber(connectionsResponse.overall.total_active_connections, 0))
        }
        setConnectionsData(connectionsResponse)
      } catch (e) {
        captureSentryError(e, userData, "getCloudConnections API in Identity.js");
        setFetchError(true);
      } finally {
        setIdentityLoading(false)
      }
    }
    userData?.tenant && (!isMDRLite && !isMobileOnly) && getConnections()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.tenant, isMobileOnly, isMDRLite]);

  const redirectEnabled = () => !count && !isMDRLite && !isMobileOnly;

  return (
    <>
      <tr
        className={`connections-row identity ${redirectEnabled() && "clickable"}`}
        onClick={
          redirectEnabled()
            ? () =>
                navigate("/onboarding/integrations", {
                  state: {
                    cloudType: "okta",
                  },
                })
            : null
        }
      >
        {!identityLoading ? 
        fetchError ? <NotFound isError dataError className="panel service" /> 
        :
        <>
          <td className={"connection-type"}>
            <Tooltip content={tooltipMapping.serviceStatus.identity} className={"title-tooltip"} direction={"right"}>
              <p className={"is-connected" + (isMDRLite || isMobileOnly ? ' disabled' : '')}>IDENTITY</p>
            </Tooltip>
            {connectionsData?.overall?.total_active_connections !== undefined &&
            <p className={"count" + (isMDRLite || isMobileOnly ? ' disabled' : '')}>
              {(isMDRLite || isMobileOnly) ? '' : count}
            </p>}
            {(isMobileOnly || isMDRLite) && hasAdminAccess(userData?.role) &&
            <MailButton label={"ADD SERVICE »"} mailto={createEmail('identity')}/>}
          </td>
          <td>
            <span className={"circle " + (!count ? "grey" : connectionsData?.overall?.status)}/>
          </td>
          <td className={"service-icons" + (isMDRLite || isMobileOnly ? ' disabled' : '')}>
            <img className={"identity-icon"} alt={'identity'} src={mapIcons('okta')}/>
          </td>
          <td className={"connection-data"}>
            {connectionsData?.overall && Object.entries(mapValues).map((elem, index) => {
              let field = elem[1].field
              let count = connectionsData.overall[field]
              let value = field === 'last_seen' ? formatTimeAgo(connectionsData.overall[field]) : abbreviateNumber(connectionsData.overall[field], 0)
              let hasInvestigationLink = elem[0] === "Investigations" && count > 0
              return <div key={index}
                          className={"display-values " + elem[0] + (hasInvestigationLink ? ' pointer' : '')}>
                <p className={"name"}>{elem[0]}</p>
                <p
                  className={"value " + (hasInvestigationLink ? ' link' : '')}
                  onClick={() => hasInvestigationLink && onInvestigationClick('Identity')}
                >{!isMDRLite && !isMobileOnly ? value : ''}</p>
              </div>
            })}
          </td>
          <td className={"chart-container" + (isMDRLite || isMobileOnly ? ' disabled' : '')}>
            {connectionsData?.overall?.trend?.data && (!isMobileOnly && !isMDRLite) ?
              <ConnectionsLineChart
                numberFormat={".2s"}
                displayName={'identity'}
                data={connectionsData?.overall?.trend?.data}
                colors={colors}
              /> : (isMobileOnly || isMDRLite) &&
              <DisabledChart
                data={[{name: 'Identity_event_count_per_day', values: []}]}
                colors={{Identity_event_count_per_day: 'green'}}
              />}
          </td>
        </> : <td><Loader/></td>}
      </tr>
    </>
  )
}

export default IdentityConnection;
