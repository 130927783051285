import React from 'react';
import '../../styles/pages/MaturityAssessment/TableControls.scss';
import {Pagination} from "../../components";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Export from "../../components/Export/Export";
import ResetFilters from "../../components/Table/ResetFilters";

const TableControls = ({
                         page,
                         setPage,
                         total,
                         handleSearch,
                         query,
                         params,
                         setParams,
                         exportLoading,
                         resetAllFilters
                       }) => {
  return (
    <div className={"maturity-assessment pagination-controls"}>
      <div className={"total-results"}>
        <div>
          <span className={"total"}>{total}</span>
          {total === 1 ? 'Result' : 'Results'}
        </div>
        {total > 50 && <Pagination
          setPage={setPage}
          page={page}
          total={total}
        />}
      </div>
      <FormControl sx={{m: 1, width: '30ch', top: 8, left: 40}} variant="outlined" id="form-search-bar" size="small">
        <InputLabel className={"input-label"} htmlFor="outlined-adornment-search">
          Search
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-search"
          onChange={(e) => handleSearch(e)}
          endAdornment={
            <InputAdornment position="end" id="search-field"><SearchIcon id="search-field"/></InputAdornment>
          }
          label="Search"
          value={query}
          disabled={false}
        />
      </FormControl>
      <ResetFilters onClick={resetAllFilters}/>
      <Export
        currentSearchConfig={query}
        toggleShowDropdown={false}
        setParams={setParams}
        params={params}
        bauExportLoading={exportLoading}
      />
    </div>
  )
}

export default TableControls;
