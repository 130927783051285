import React, {useEffect, useState} from "react";
import "../../../../styles/pages/Onboarding/Cloud/AccountInputForm.scss";
import IconButton from "@mui/material/IconButton";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import TooltipIcon from "../../../../assets/icons/tooltip.svg";
import RadioSelection from "./RadioSelection";
import DropdownSelection from "./DropdownSelection.js";
import {Loader} from "../../../../components/Loader/Loader";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import {cloudInputConfigs} from "../config";
import {newClientId} from "../config";

const AccountInputForm = ({
                            inputConfig,
                            fetchData,
                            setErrors,
                            testAccountHandler,
                            submitFunction,
                            buttonText,
                            addingAccountMessages,
                            header,
                            data,
                            setAddingAccountMessages,
                            setLoadingModal,
                            isEdit = false,
                            modalTimeout,
                            connectorType,
                            showUploadModal,
                            setShowUploadModal,
                            fileNames,
                            editFileNames,
                            setFileNames = () => {},
                            setUploadFileName,
                            setUploadEditFileName,
                            selectedAuthenticationType,
                            setSelectedAuthenticationType,
                            displayFields,
                            setDisplayFields,
                            selectedEditAuthenticationType,
                            setSelectedEditAuthenticationType,
                            displayEditFields,
                            setDisplayEditFields,
                            handleAuthSelection,
                            handleEditAuthSelection,
                            setResetEditModal,
                            showResponseData = false,
                            addResponseModal = false,
                            selectedResponse,
                            setSelectedResponse,
                            handleRadioSelection,
                            inputValues,
                            setInputValues,
                            isSubmitted,
                            setIsSubmitted,
                            addSubmitFunc
                          }) => {
  const [escapedAutocomplete, setEscapedAutocomplete] = useState(false);
  const [editLoading, setEditLoading] = useState(false)
  const displayFieldValues = isEdit ? displayEditFields : displayFields
  const handleChange = isEdit ? handleEditAuthSelection : handleAuthSelection
  const handleAuthType = isEdit ? selectedEditAuthenticationType : selectedAuthenticationType
  const fromOnClick = 'fromOnClick'

  useEffect(() => {
    setInputValues(data ? data : {});
    if (connectorType === "O365") {
      setSelectedResponse(data && showResponseData? data.response_preference : 'ON_DEMAND_RESPONSE')
    } else if (data && data.client_id && connectorType === "SalesForce") {
      setSelectedEditAuthenticationType('client_id')
      setDisplayEditFields(['username', 'password', 'client_id', 'client_secret', 'security_token'])
    } else if (connectorType === "SalesForce") {
      setSelectedEditAuthenticationType('certificate')
      setDisplayEditFields(['certificate_file', 'private_key', 'jwt_user', 'consumer_key'])
    }
    // eslint-disable-next-line
  }, []);

  const addAccountWithRetry = async (messages, isRetry) => {
    if (inputValues.connector_type === 'O365') {
      inputValues['response_preference'] = selectedResponse
    }
    let success = await submitFunction(inputValues, fromOnClick)
    messages.splice(-1)
    if (success === true) {
       if (addResponseModal) {
        let selectedMessage = selectedResponse === "ON_DEMAND_RESPONSE" ? "On Demand" : "Auto"
        let pushMessage = "Your response selection has been successfully set to: " + selectedMessage
        messages.push(`<p className="auto-response">` + pushMessage + `</p>`)
      }
      messages.push(`Added a new integration `)
    } else {
      !isRetry ? messages.push(`Adding a new integration. `) : messages.push("Retried to add new Account. ")
      if (isRetry === undefined) {
        messages.push("Retrying to add the new integration...")
        success = await addAccountWithRetry(messages, true)
      } else if (isRetry === true) {
        (success !== '' || success?.add !== '') && messages.push(`<p className="error-text">Error: ${success || success?.add || "Error adding a new integration"}</p>`)
        messages.push(`
        <p class="indent">Please wait a few minutes and test the connection again. If the issue persists, please reach out to <a target="_blank" rel="noreferrer" href="mailto:support@actzero.ai">support@actzero.ai</a></p>`)
      }
    }
    return success === true
  }

  const testAccountWithRetry = async (messages, isRetry) => {
    !isRetry && messages.push('Testing newly added integration...')
    setAddingAccountMessages([...messages])
    const results = await fetchData(fromOnClick);

    // hacky fix before refactor
    let newAccountId = results.filter(connector => connector.connector_type === connectorType);
    newAccountId = newAccountId[newAccountId.length - 1].id;

    const testResult = await testAccountHandler(newAccountId, setErrors)
    messages.splice(-1)
    if (testResult?.success === true) {
      messages.push('Tested newly added integration ')
    } else {
      !isRetry ? messages.push(`Testing newly added integration. `) : messages.push("Second test of the newly added integration failed. ")
      if (isRetry === undefined) {
        messages.push("Retrying test of the newly added integration...")
        await testAccountWithRetry(messages, true)
      } else if (isRetry === true) {
        testResult?.error && messages.push(`<p className="error-text">Error: ${testResult?.error}</p>`)
        messages.push(`<p class='indent'>Integration was added successfully but please test the connection after sometime. If the issue persists, please reach out to <a target='_blank' rel='noreferrer' href='mailto:support@actzero.ai'>support@actzero.ai</a></p>`)
      }
    }
    setAddingAccountMessages([...messages])
  }

  const addUploadValues = (name) => {
     if (isEdit) {
       setUploadEditFileName(name)
     } else {
       setUploadFileName(name)
     }
     setResetEditModal(false)
     setShowUploadModal(true)
  }

  const submit = async () => {
    if (isEdit) {
      setEditLoading(true)
      await submitFunction(inputValues);
      setEditLoading(false)
      setResetEditModal(true)
    } else {
      setLoadingModal(true)
      let messages = ['Adding new account...']
      setAddingAccountMessages([...messages])
      let success = await addAccountWithRetry(messages)
      success !== true ? setAddingAccountMessages([...addingAccountMessages, ...messages]) :
        setAddingAccountMessages([...messages])
      success && await testAccountWithRetry(messages)
      // closes modal after 5 seconds when all apis are complete
      modalTimeout()
      setFileNames([])
      setInputValues([])
    }
  }

  const submitAdd = async() => {
     setSelectedResponse("ON_DEMAND_RESPONSE")
     setIsSubmitted(false)
     setLoadingModal(true)
  }

  return (
    <>
      {header && <div className="form-title">{header}</div>}
      <div className="account-form-container">
        <table>
          <tbody>
           {connectorType === "SalesForce" &&
            <tr>
            <td>
              <RadioSelection options={cloudInputConfigs['SalesForce']['authenticationType']}
                handleChange={handleChange}
                selected={handleAuthType}
                className="salesforce-connector"
                icon={<RadioButtonUncheckedIcon/>}
                checkedIcon={<RadioButtonCheckedIcon className={"checked-box-icon"}/>}
                isEdit={isEdit}
              />
            </td>
            </tr>}

          {inputConfig.map((input, i) => (
            (!connectorType || (connectorType !== "SalesForce") || ((connectorType === "SalesForce") && (displayFieldValues.includes(input.value))))  &&
            <tr key={input.value}>
              <td>
                <span className="input-label">{input.label}</span>
                {input.tooltip && (
                  <span className="input-tooltip-container">
                    <Tooltip
                      content={input.tooltip}
                      className={"input-tooltip"}
                      direction={"right"}
                      disableTimeout
                    >
                      <img
                        className="tooltip-icon"
                        src={TooltipIcon}
                        alt="?"
                      />
                      </Tooltip>
                    </span>
                )}
              </td>
              <td>
                {input.readOnly ? (
                  <span>{`${inputValues[input.value] ? inputValues[input.value] : ""}`}</span>
                ) : input.multiselect ? (
                  <DropdownSelection
                    value={inputValues[input.value]}
                    onChange={(e) => setInputValues({...inputValues, [input.value]: e})}
                    inputConfig={input}
                    options={input.multiselect}
                    isMulti
                  />
                ) : input.upload ?
                isEdit? editFileNames && editFileNames[input.value] ? <div className="fileName-upload"><div className="fileName">{editFileNames[input.value].name}</div>
                <div className="upload-btn">
                    <div className="files-button" onClick={() => addUploadValues(input.value)}> {"UPLOAD »"} </div>
                </div>
                </div>:

                <div className="upload-btn">
                    <div className="files-button" onClick={() => addUploadValues(input.value)}> {"UPLOAD »"} </div>
                </div> :
                fileNames && fileNames[input.value] ? <div className="fileName-upload"><div className="fileName">{fileNames[input.value].name}</div>
                <div className="upload-btn">
                    <div className="files-button" onClick={() => addUploadValues(input.value)}> {"UPLOAD »"} </div>
                </div>
                </div>:
                <div className="upload-btn">
                    <div className="files-button" onClick={() => addUploadValues(input.value)}> {"UPLOAD »"} </div>
                </div> :
                (<input
                    key={`${input.value}-input`}
                    className={`input-box`}
                    onFocus={() => input.secret && setEscapedAutocomplete(true)}
                    type={input.secret && escapedAutocomplete ? "password" : "text"}
                    value={inputValues[input.value] ? inputValues[input.value] : ""}
                    placeholder={input.placeholder ? input.placeholder : ""}
                    onChange={(e) => setInputValues({...inputValues, [input.value]: e.target.value})}
                  />
                )}
              </td>
              {buttonText === "ADD" && addResponseModal &&
              <td>
                {(i === inputConfig.length - 1 && (
                  <>
                    <div className={"form-btn"}>
                      {editLoading ? <div className={"edit-loader"}><Loader/></div> :
                        <IconButton className="border-btn-container" onClick={async () => submitAdd()}>
                          <div className="border-btn">{buttonText}</div>
                        </IconButton>}
                    </div>
                  </>
                ))}
              </td>}
              {(buttonText === "ADD" || buttonText === "SUBMIT") && !addResponseModal &&
              <td>
                {(((i === inputConfig.length - 1) || (connectorType === "SalesForce" && ((selectedAuthenticationType === "client_id" && i === 4) || (selectedEditAuthenticationType === "client_id" && i === 4)))) && (
                  <>
                    <div className={"form-btn"}>
                      {editLoading ? <div className={"edit-loader"}><Loader/></div> :
                        <IconButton className="border-btn-container" onClick={async () => submit()}>
                          <div className="border-btn">{buttonText}</div>
                        </IconButton>}
                    </div>
                  </>
                ))}
              </td>}
            </tr>
          ))}
          {showResponseData && isEdit && <tr key={"response-data"}>
            <td className="response-td-data">
              <span className="input-label">Response</span>
              <span className="input-tooltip-container">
                <Tooltip
                  content={"Response options can be enabled ONLY if your Azure and O365 environments are hosted in the Cloud. (Hybrid and On-Prem deployment models are NOT supported for this feature)."}
                  className={"input-tooltip"}
                  direction={"right"}
                  disableTimeout
                >
                  <img
                    className="tooltip-icon"
                    src={TooltipIcon}
                    alt="?"
                  />
                  </Tooltip>
              </span>
            </td>
            <td className="edit-response">
              {data.client_id && data.client_id !== newClientId?
                <div className={"response-modal-note-italics"}><p className="bold-note">Note:</p> Response options cannot be activated on this integration. Follow the migration instructions as detailed in the<a
                className={"clickable-link"}
                target="_blank"
                rel="noopener noreferrer"
                href={
                  "https://docs.actzero.ai/cloud-connections/upgrade-O365/"
                }>documentation</a></div> :
              <>
              <div className={"response-modal-note"}>Response options can be enabled <p className="bold-note">ONLY</p> if your <p className="bold-note">Azure and O365 environments</p> are hosted in the Cloud. (Hybrid and On-Prem deployment models are <p className={"bold-note"}>NOT</p> supported for this feature).</div>
              <div className={"response-modal-note-italics"}><p className="bold-note">Note:</p> To activate the response option, please complete the necessary steps in your Azure account as detailed in the<a
                className={"clickable-link"}
                target="_blank"
                rel="noopener noreferrer"
                href={
                  "https://docs.actzero.ai/cloud-connections/connect-O365/#enable-office-365-response-actions"
                }>documentation</a></div>
              <RadioSelection options={cloudInputConfigs['O365']['responseConfigOptions']}
                handleChange={handleRadioSelection}
                selected={selectedResponse}
                className="response-selection edit"
                icon={<RadioButtonUncheckedIcon/>}
                checkedIcon={<RadioButtonCheckedIcon className={"checked-box-icon"}/>}
              /></>}
            </td>
            <td>
            {data.client_id !== newClientId ?
            <div className={"form-btn "+showResponseData + " old-client-id"}>
              {editLoading ? <div className={"edit-loader"}><Loader/></div> :
                <IconButton className="border-btn-container" onClick={async () => submit()}>
                  <div className="border-btn">{buttonText}</div>
                </IconButton>}
            </div> :
            <div className={"form-btn "+showResponseData}>
              {editLoading ? <div className={"edit-loader"}><Loader/></div> :
                <IconButton className="border-btn-container" onClick={async () => submit()}>
                  <div className="border-btn">{buttonText}</div>
                </IconButton>}
            </div>
            }
            </td>
            </tr>}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AccountInputForm;