export function isRoot(userType) {
  return userType === "root";
}

export function showLogSearch(tenantConfig, userType) {
  return isRoot(userType) || tenantConfig?.log_management?.log_search?.enabled || false
}

export function isMobileMDRUser(userData) {
  return userData?.tenantConfig?.offerings?.mobile_mdr?.enabled || isRoot(userData?.userType);
}

export const isMobileOnly = (userData) =>  userData?.tenantConfig?.is_mobile_edr_only;

export const isMDRLite = (userData) => userData?.tenantConfig?.is_mdr_lite;

export const checkTenantType = (userData, value) => userData.hasOwnProperty('tenantConfig') && userData?.tenantConfig[value]

export const hasAdminAccess = (userRole) => {
  let roles = ['root', 'Admin']
  return roles.some(role => role === userRole)
}

export const hasWriteAccess = (userRole) => {
  let roles = ['root', 'Admin', 'Read_Write']
  return roles.some(role => role === userRole)
}

export const isAZUser = (userData) => isRoot(userData?.userType) || userData?.tenant === 'actzero' || userData?.email?.endsWith("actzero.ai");