import React, {useContext, useEffect, useState} from "react";
import DeleteModal from "./components/DeleteModal";
import AccountInputForm from "./components/AccountInputForm";
import EditModal from "./components/EditModal";
import AccountTable from "./components/AccountTable";
import AccountRow from "./components/AccountRow";
import {addOnboardingConnector, addOnboardingSalesforceConnector, deleteOnboardingConnector, editOnboardingConnector, editOnboardingSalesforceConnector} from "../../../api/ApiClient";
import UserData from "../../../store/User/UserData";
import TooltipIcon from "../../../assets/icons/tooltip.svg";
import Tooltip from "../../../components/Tooltip/Tooltip";
import {CloudOnboardingInstructions} from "./components/Instructions";
import AddingAccountModal from "./components/AddingAccountModal";
import {UploadModal} from "./UploadModal";
import { captureSentryError } from "../../../util/sentry";

let modalCloseTimeout;

const CloudOnboardingContent = (props) => {
    const {
      connectorType,
      config,
      fetchData,
      fetchError,
      accountData,
      setAccountData,
      loading,
      instructionsLink,
      defaultInputValues,
      customElements,
      testAccountHandler
    } = props;
    const {editAccountInputConfig, addAccountInputConfig} = config;
    const [userData] = useContext(UserData);
    const fromOnClick = 'fromOnClick'
    const [loadingModal, setLoadingModal] = useState(false)
    const [addingAccountMessages, setAddingAccountMessages] = useState([])
    const [uploadFileName, setUploadFileName] = useState('')
    const [uploadEditFileName, setUploadEditFileName] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errors, setErrors] = useState({
      fetch: "",
      add: "",
      edit: "",
      delete: "",
    });
    const [currentModal, setCurrentModal] = useState({
      action: null,
      data: null,
    });
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [fileNames, setFileNames] = useState([]);
    const [editFileNames, setEditFileNames] = useState([]);
    const [selectedAuthenticationType, setSelectedAuthenticationType] = useState('certificate')
    const [selectedEditAuthenticationType, setSelectedEditAuthenticationType] = useState('')
    const [displayFields, setDisplayFields] = useState(['certificate_file', 'private_key', 'jwt_user', 'consumer_key']);
    const [displayEditFields, setDisplayEditFields] = useState([]);
    const [resetEditModal, setResetEditModal] = useState(true)
    const [uploadFileFields] = ['certificate_file', 'private_key']
    const [inputValues, setInputValues] = useState(defaultInputValues || {});
    const [newInputValues, setNewInputValues] = useState(defaultInputValues || {});
    const [selectedResponse, setSelectedResponse] = useState(config.response_preference || "ON_DEMAND_RESPONSE")


    const handleRadioSelection = (event, name) => {
      setSelectedResponse(name)
    }

    const closeLoadingModal = () => {
      setLoadingModal(false)
      setAddingAccountMessages([])
      clearTimeout(modalCloseTimeout)
    }
    useEffect(() => {
      resetErrors();
    }, [accountData]);

    const handleAuthSelection = (event, name, options) => {
        setSelectedAuthenticationType(name)
        setDisplayFields(options)
    }
    const handleEditAuthSelection = (event, name, options) => {
        setSelectedEditAuthenticationType(name)
        setDisplayEditFields(options)
    }

    const deleteAccountHandler = async (params) => {
      setErrors((prevState) => {
        return {...prevState, delete: ""};
      });
      try {
        const results = await deleteOnboardingConnector(
          userData.tenant,
          params.id
        );
        if (results?.error)
          setErrors((prevState) => {
            return {...prevState, delete: "Error deleting account"};
          });
        else {
          setCurrentModal({action: null, data: null});
          setAccountData((prevState) =>
            prevState.filter((account) => account.id !== params.id)
          );
        }
      } catch (e) {
        setErrors((prevState) => {
          return {...prevState, delete: "Error deleting account"};
        });
        captureSentryError(e, userData, "deleteOnboardingConnector API in CloudOnboardingContent.js");
      }
    };

    const editAccountHandler = async (params) => {
      setErrors((prevState) => {
        return {...prevState, edit: ""};
      });
      params = verifyParams(params, true);
      let client_id = params.client_id
      if (connectorType === "O365") {
          params['response_preference'] = selectedResponse
          if (client_id) {
             delete params.client_id
          }
      }
      if (params)
        try {
          params = {
            connector_type: connectorType,
            connector_config: params,
          };
          let results = []
          if (connectorType === "SalesForce") {
            params['selector'] = selectedEditAuthenticationType
          }
          if (connectorType === "SalesForce" && selectedEditAuthenticationType === "certificate") {
            results = await editOnboardingSalesforceConnector(userData.tenant, params, currentModal.data.id, editFileNames)
          } else{
            results = await editOnboardingConnector(
                userData.tenant,
                params,
                currentModal.data.id
            );
          }
          if (connectorType === "O365") {
             params['connector_config']['client_id'] = client_id
          }

          if (results?.error)
            setErrors((prevState) => {
              return {...prevState, edit: "Error updating account"};
            });
          else {
            setCurrentModal({action: null, data: null});
            setAccountData((prevState) => {
              const index = prevState
                .map((account) => account.id)
                .indexOf(currentModal.data.id);
              Object.keys(prevState[index].connector_config).forEach((key) => {
                prevState[index].connector_config[key] =
                  params.connector_config[key];
              });
              return prevState;
            });
          }
        } catch (e) {
          setErrors((prevState) => {
            return {...prevState, edit: "Error updating account"};
          });
          captureSentryError(e, userData, "editOnboardingConnector API in CloudOnboardingContent.js");
        }
    };

    const addSubmitFunc = async () => {
      await addAccountHandler(newInputValues);
    }

  const addAccountWithRetry = async (messages, isRetry) => {
    setNewInputValues({...newInputValues, response_preference : selectedResponse || 'ON_DEMAND_RESPONSE'})
    let success = await addAccountHandler(newInputValues, fromOnClick)

    messages.splice(-1)
    if (success === true) {
        let selectedMessage = selectedResponse === "ON_DEMAND_RESPONSE" ? "On Demand" : "Auto"
        let pushMessage = "Your response selection has been successfully set to: " + selectedMessage
        messages.push(`<p className="auto-response">` + pushMessage + `</p>`)
      messages.push(`Added a new integration `)
    } else {
      !isRetry ? messages.push(`Adding a new integration. `) : messages.push("Retried to add new Account. ")
      if (isRetry === undefined) {
        messages.push("Retrying to add the new integration...")
        success = await addAccountWithRetry(messages, true)
      } else if (isRetry === true) {
        (success !== '' || success?.add !== '') && messages.push(`<p className="error-text">Error: ${success || success?.add || ''}</p>`)
        messages.push(`
        <p class="indent">Please wait a few minutes and test the connection again. If the issue persists, please reach out to <a target="_blank" rel="noreferrer" href="mailto:support@actzero.ai">support@actzero.ai</a></p>`)
      }
    }
    return success === true
  }

  const testAccountWithRetry = async (messages, isRetry) => {
    !isRetry && messages.push('Testing newly added integration...')
    setAddingAccountMessages([...messages])
    const results = await fetchData(fromOnClick)

    // hacky fix before refactor
    let newAccountId = results.filter(connector => connector.connector_type === connectorType);
    newAccountId = newAccountId[newAccountId.length - 1].id;
    
    const testResult = await testAccountHandler(newAccountId, setErrors)
    messages.splice(-1)
    if (testResult?.success === true) {
      messages.push('Tested newly added integration ')
    } else {
      !isRetry ? messages.push(`Testing newly added integration. `) : messages.push("Second test of the newly added integration failed. ")
      if (isRetry === undefined) {
        messages.push("Retrying test of the newly added integration...")
        await testAccountWithRetry(messages, true)
      } else if (isRetry === true) {
        testResult?.error && messages.push(`<p className="error-text">Error: ${testResult?.error}</p>`)
        messages.push(`<p class='indent'>Integration was added successfully but please test the connection after sometime. If the issue persists, please reach out to <a target='_blank' rel='noreferrer' href='mailto:support@actzero.ai'>support@actzero.ai</a></p>`)
      }
    }
    setAddingAccountMessages([...messages])
  }

    const addSubmitResponse = async() => {
        setSelectedResponse(selectedResponse)
        setLoadingModal(true)
        setIsSubmitted(true)
        setNewInputValues({newInputValues, response_preference : selectedResponse})
        setInputValues({inputValues, response_preference : selectedResponse})
        let messages = ['Adding new integration...']
        setAddingAccountMessages([...messages])
        let success = await addAccountWithRetry(messages)
        success !== true ? setAddingAccountMessages([...addingAccountMessages, ...messages]) : setAddingAccountMessages([...messages])
        success && await testAccountWithRetry(messages)
        setNewInputValues({})
        modalTimeout()
    }

    const addAccountHandler = async (params, value) => {
      setErrors((prevState) => {
        return {...prevState, add: ""};
      });
      params = verifyParams(params);
      if (typeof params !== 'string')
        try {
         if (connectorType === "O365") {
            if (!params.response_preference){
              params['response_preference'] = selectedResponse
            }
          }
          params = {
            tenant: userData.tenant,
            connector_type: connectorType,
            connector_config: params,
          };
          let results = []
          if (connectorType === "SalesForce") {
            params['selector'] = selectedAuthenticationType
          }
          if (connectorType === "SalesForce" && selectedAuthenticationType === "certificate") {
            results = await addOnboardingSalesforceConnector(userData.tenant, params, fileNames)
          } else{
            results = await addOnboardingConnector(userData.tenant, params);
          }

          if (results?.error) {
            setErrors((prevState) => {
              return {
                ...prevState,
                add: `${results?.error?.statusCode === 409 ? "Duplicate account" : "Error adding account"} `,
              };
            });
            return `${results?.error?.statusCode === 409 ? "Duplicate account" : "Error adding account"} `
          } else {
            value !== 'fromOnClick' && await fetchData(value);
            return true; // resets input values
          }
        } catch (e) {
          setErrors((prevState) => {
            return {...prevState, add: "Error adding account"};
          });
          captureSentryError(e, userData, "addOnboardingConnector API in CloudOnboardingContent.js");
          return false;
        }
      return params
    };

    const verifyParams = (params, isEdit) => {
      const inputConfig = isEdit ? editAccountInputConfig : addAccountInputConfig;
      const displayFieldValues = isEdit ? displayEditFields : displayFields;
      const fileNameValues = isEdit? editFileNames : fileNames
      let error;

      for (const i in inputConfig) {
        if (connectorType === "SalesForce" && !displayFieldValues.includes(inputConfig[i].value)){
           continue
        }
        const enteredValue = params[inputConfig[i].value] || fileNameValues[inputConfig[i].value];
        error = !enteredValue // Check if inputs have content
          ? uploadFileFields.includes([inputConfig[i].value])? `Must upload ${inputConfig[i].label}`: `Must enter ${inputConfig[i].label}`
          : inputConfig[i].isEmail && // Check valid email format
          !enteredValue.toLowerCase().match(
            // eslint-disable-next-line
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
          )
            ? `Invalid email entered for ${inputConfig[i].label}`
            : inputConfig[i].domain && // Check if value ends with specified domain
            !enteredValue.endsWith(inputConfig[i].domain)
              ? `${inputConfig[i].label} must end with "${inputConfig[i].domain}"`
              : null;

        if (inputConfig[i].parseJSON && typeof params[inputConfig[i].value] !== "object")
          // Check valid JSON and parse
          try {
            params[inputConfig[i].value] = JSON.parse(
              params[inputConfig[i].value]
            );
          } catch {
            error = "Invalid JSON";
          }

        if (error) break;
      }

      if (error) {
        let key = isEdit ? "edit" : "add"
        setErrors({...errors, [key]: error})
        return error;
      }
      return params;
    };

    const resetErrors = () =>
      setErrors({fetch: "", add: "", edit: "", delete: "", test: ""});

    const resetModal = (close = false) => {
      resetErrors();
      if (resetEditModal || close) {
        setCurrentModal({action: null, data: null});
        setFileNames([])
        setEditFileNames([])
      }
    };

    const modalTimeout = () => {
      modalCloseTimeout = setTimeout(() => {
        setAddingAccountMessages([])
        setLoadingModal(false)
      }, 5000)
    }

    const handleClose = () => {
      setShowUploadModal(false);
      setFileNames([]);
      setEditFileNames([])
    }

    return (
      <div>
        {currentModal.action === "delete" && (
          <DeleteModal
            confirmFunc={() => deleteAccountHandler(currentModal?.data)}
            cancelFunc={resetModal}
            modalErrors={errors.delete}
            className="cloud-modal"
            message={"Delete account?"}
          />
        )}
        {currentModal.action === "edit" && (
          <EditModal
            header={`Edit integration: ${currentModal?.data?.connector_name}`}
            error={errors.edit}
            resetModal={resetModal}
            className={connectorType}
            setResetEditModal={setResetEditModal}
            inputForm={
              <AccountInputForm
                isEdit={true}
                data={currentModal.data.connector_config}
                inputConfig={editAccountInputConfig}
                submitFunction={(params) => editAccountHandler(params)}
                buttonText={"SUBMIT"}
                testAccountHandler={testAccountHandler}
                setErrors={setErrors}
                connectorType={connectorType}
                showUploadModal={showUploadModal}
                setShowUploadModal={setShowUploadModal}
                fileNames={fileNames}
                editFileNames={editFileNames}
                setUploadFileName={setUploadFileName}
                selectedAuthenticationType={selectedAuthenticationType}
                setSelectedAuthenticationType={setSelectedAuthenticationType}
                displayFields={displayFields}
                setDisplayFields={setDisplayFields}
                setUploadEditFileName={setUploadEditFileName}
                selectedEditAuthenticationType={selectedEditAuthenticationType}
                setSelectedEditAuthenticationType={setSelectedEditAuthenticationType}
                displayEditFields={displayEditFields}
                setDisplayEditFields={setDisplayEditFields}
                handleEditAuthSelection={handleEditAuthSelection}
                setResetEditModal={setResetEditModal}
                setFileNames={setFileNames}
                addResponseModal={connectorType === "O365" && true}
                showResponseData={connectorType === "O365" ? true : false}
                selectedResponse={selectedResponse}
                setSelectedResponse={setSelectedResponse}
                handleRadioSelection={handleRadioSelection}
                inputValues={inputValues}
                setInputValues={setInputValues}
                isSubmitted={isSubmitted}
                setIsSubmitted={setIsSubmitted}
              />
            }
          />
        )}
        {loadingModal && (
          <AddingAccountModal
            messages={addingAccountMessages}
            cancelFunc={closeLoadingModal}
            addResponseModal={connectorType === "O365" && true}
            showResponseData={connectorType === "O365" && true}
            selectedResponse={selectedResponse}
            setSelectedResponse={setSelectedResponse}
            handleRadioSelection={handleRadioSelection}
            submitFunc={addSubmitResponse}
            inputValues={inputValues}
            setInputValues={setInputValues}
            isSubmitted={isSubmitted}
            setIsSubmitted={setIsSubmitted}
            userData={userData}
          />
        )}
        {showUploadModal && (
          <UploadModal isOpen={showUploadModal}
                setOpen={setShowUploadModal}
                onClose={handleClose}
                fileNames={fileNames}
                setFileNames={setFileNames}
                uploadFileName={uploadFileName}
                editFileNames={editFileNames}
                setEditFileNames={setEditFileNames}
                uploadEditFileName={uploadEditFileName}
                setResetEditModal={setResetEditModal}
                isEdit={currentModal.action==="edit"&&true}
          />
        )}
        <AccountTable
          dataError={fetchError}
          loading={loading}
          rows={accountData?.map((account) => (
            <AccountRow
              key={account.id}
              account={account}
              testAccountHandler={testAccountHandler}
              setErrors={setErrors}
              setModal={setCurrentModal}
              disableTestButton={false}
            />
          ))}
        />
        <div className={"instructions-tooltip"}>
          <Tooltip
            disableTimeout
            content={"Click for instructions"}
            direction={"left"}
            className="instructions-tooltip"
          >
            <a href={instructionsLink} target="_blank" rel="noreferrer">
              <img
                className="tooltip-icon"
                src={TooltipIcon}
                alt="Instructions"
              />
            </a>
          </Tooltip>
        </div>
        <>
          <CloudOnboardingInstructions connectorType={connectorType}/>
          {customElements}
        </>
        <AccountInputForm
          submitFunction={addAccountHandler}
          inputConfig={addAccountInputConfig}
          fetchData={fetchData}
          setErrors={setErrors}
          connectorType={connectorType}
          buttonText={"ADD"}
          errors={errors}
          error={errors.add}
          data={defaultInputValues || {}}
          testAccountHandler={testAccountHandler}
          verifyParams={verifyParams}
          setAddingAccountMessages={setAddingAccountMessages}
          setLoadingModal={setLoadingModal}
          addingAccountMessages={addingAccountMessages}
          modalTimeout={modalTimeout}
          showUploadModal={showUploadModal}
          setShowUploadModal={setShowUploadModal}
          fileNames={fileNames}
          editFileNames={editFileNames}
          setUploadFileName={setUploadFileName}
          selectedAuthenticationType={selectedAuthenticationType}
          setSelectedAuthenticationType={setSelectedAuthenticationType}
          setSelectedEditAuthenticationType={setSelectedEditAuthenticationType}
          selectedEditAuthenticationType={selectedEditAuthenticationType}
          displayFields={displayFields}
          setDisplayFields={setDisplayFields}
          displayEditFields={displayEditFields}
          setDisplayEditFields={setDisplayEditFields}
          handleAuthSelection={handleAuthSelection}
          handleEditAuthSelection={handleEditAuthSelection}
          setResetEditModal={setResetEditModal}
          setFileNames={setFileNames}
          addResponseModal={connectorType === "O365" && true}
          selectedResponse={selectedResponse}
          setSelectedResponse={setSelectedResponse}
          handleRadioSelection={handleRadioSelection}
          inputValues={newInputValues}
          setInputValues={setNewInputValues}
          isSubmitted={isSubmitted}
          setIsSubmitted={setIsSubmitted}
          addSubmitFunc={addSubmitFunc}
        />
      </div>
    );
  }
;

export default CloudOnboardingContent;