import React from "react";
import "../../styles/components/Button/LoaderButton.scss";
import { Loader } from "../Loader/Loader";

const LoaderButton = ({ className, text, onClick, loading }) => {
  return (
    <div className="loader-btn-container">
      {loading ? (
        <Loader />
      ) : (
        <div className={`loader-btn ${className}`} onClick={() => onClick()}>
          {text}
        </div>
      )}
    </div>
  );
};

export default LoaderButton;
