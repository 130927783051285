import React, {useContext, useEffect, useState, useRef} from 'react';
import { Loader } from "../../components/Loader/Loader";
import PaginationControls from "../../components/Table/PaginationControls";
import {Pagination} from "../../components";
import PageFilters from "../../components/Filters/PageFilters";
import moment from 'moment';
import GlobalState from "../../store/GlobalState/GlobalState";
import UserData from "../../store/User/UserData";
import {useLocation} from 'react-router-dom';
import filterConfig, {resetFilterConfig} from "./config/filterConfig";
import {setSortFilterValue} from "../../util/handleSortResults";
import {setResetFilter} from "../../util/handleResetFilter";
import TicketRow from "./TicketRow";
import Table from "../../components/Table/Table";
import TableHeader from "../../components/Table/TableHeader";
import {mainRow} from "../../components/Mappings/Tickets"
import {severities} from "../../config/mappingData";
import {getTicketsData, handleUpdate} from "./util";
import {trackPageView} from "../../util/analytics";
import '../../styles/pages/SupportTicket/SupportTicket.scss';
import {getBaseParameters} from "../../util/format.js"
import _ from 'lodash';
import NotFound from "../../navigation/NotFound";
import CreateModal from "./CreateModal";
import LoaderButton from '../../components/Button/LoaderButton';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import {statuses} from "../../config/status";
import { useSearchParams } from "react-router-dom"
import ExpandView from "./ExpandView";
import {sortObjects} from "../../util/sortObjects";
import { captureSentryError } from '../../util/sentry.js';

const SupportTicket = () => {
  const [azState] = useContext(GlobalState);
  const [userData] = useContext(UserData);
  const [queryParameters, setQueryParameters] = useSearchParams();
  const location = useLocation()
  const defaultID = queryParameters.get("id") || location.state?.id;
  const defaultConfig = location.state?.filters?.tickets?.initialConfig;
  const [pageCount] = useState(50)
  const [page, setPage] = useState(1)
  const today = new Date()
  const defaultStartTime = new Date(new Date().setDate(today.getDate() - 30))
  const [defaultDate, setDefaultDate] = useState(defaultConfig || defaultID ? {text: null, time: null} : {
    text: 'Last 30 Days',
    time: defaultStartTime
  })
  const [selectedDate, setSelectedDate] = useState(defaultConfig || defaultID ? '' : defaultDate.text)
  const [ticketsLoading, setTicketsLoading] = useState(false)
  const [ticketsDetailsLoading, setTicketsDetailsLoading] = useState(false)
  const [ticketsData, setTicketsData] = useState({results: [], total: 0})
  let [sortFilters, setSortFilters] = useState([])
  const [showDropdown, toggleShowDropdown] = useState('')
  let [filterList, setOpenFilters] = useState(filterConfig)
  const [ticketsParams, setTicketsParams] = useState({})
  const [openTicketsData, setOpenTicketsData] = useState({})
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [initialConfig, setInitialConfig] = useState(
      defaultConfig ||
      {start_time: moment(defaultStartTime).format(), end_time: moment(today).set("hour", 23).set("minute", 59).format()})
  let [allData, setAllData] = useState([])
  const [initialFilters, setInitialFilters] = useState(
    location.state?.filters?.tickets?.initialFilters && location.state?.filters?.tickets?.initialFilters)
  const [isReset, setIsReset] = useState(false)
  const [fetchError, setFetchError] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [statusError, setStatusError] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const prevParams = useRef({});
  const [sortBy, setSortBy] = useState();
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState(null);
  const [pendingStatusChange, setPendingStatusChange] = useState()
  const initialDataLoaded = useRef(false);

  useEffect(() => {
    handleChangePage(page);
    // eslint-disable-next-line
  }, [ticketsData]);

  const resetAllFilters = async () => {
    setIsReset(true)
    let config = {
      start_time: moment(defaultStartTime).format()
    }
    setTicketsParams(config)
    setExpandedIndex(null)
    setOpenTicketsData({})
  }

  const handleChangePage = async (currPage) => {
    setPage(currPage)
    let startPage = currPage === 1 ? 0 : ((currPage - 1) * pageCount)
    let endPage = currPage  * pageCount
    let tickets = ticketsData.results.slice(startPage, endPage);
    setAllData(tickets)
  };

  const handleFetchTickets = () => {
    setExpandedIndex(null)
    setOpenTicketsData({})
    if (!isReset) {
      Object.keys(ticketsParams).length > 0 && getTicketsData({setTicketsLoading, setTicketsData, setFetchError, userData, ticketsParams, searchTerm, setAllData, yPos : azState?.scrollYPosition, page, pageCount, defaultID, onRowClick})
    } else {
      setSortFilterValue([], mainRow, setSortFilters)
      setResetFilter(filterConfig, resetFilterConfig, setOpenFilters)
      setDefaultDate({
        text: 'default',
        time: defaultStartTime
      })
      setSelectedDate('Last 30 Days')
      getTicketsData({setTicketsLoading, setTicketsData, setFetchError, userData, ticketsParams, getTicketsData, setAllData, yPos : azState?.scrollYPosition, page, pageCount})
      !ticketsLoading && setIsReset(false)
    }
  }

  const handleChangeStatus = async () => {
    await handleUpdate({
      setSubmitLoading: setStatusLoading,
      inputValues: {status: pendingStatusChange.status === "Resolved" ? "Re-Opened" : "closed"},
      userData,
      data : pendingStatusChange,
      handleClose: () => setPendingStatusChange(),
      setError: () => setStatusError(true),
      setTicketsData,
      setOpenTicketsData
    })
  }

  useEffect(() => {
    setAllData(sortObjects(allData, sortBy, sortOrder));
    // eslint-disable-next-line
  }, [sortBy, sortOrder]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => trackPageView("Tickets", userData, azState), [userData?.userId, azState.reactGaInitializedState]);

  useEffect(() => {
    if (initialDataLoaded.current) {
      setQueryParameters({})
    }
    else {
      initialDataLoaded.current = true;
    }
    // eslint-disable-next-line
  }, [ticketsParams])

  useEffect(() => {
    if(!_.isEqual(prevParams.current, ticketsParams)) {
      userData?.tenant && handleFetchTickets();
      prevParams.current = ticketsParams;
    }
    // eslint-disable-next-line
  }, [userData?.tenant, userData?.userType, ticketsParams]);

  useEffect(() => {
    const filterTicketsData = () => {
      setPage(1);
      let filterParameters = getBaseParameters(ticketsParams, pageCount, 1, initialConfig, setInitialConfig);
      let filters = filterList;
      if (initialFilters) {
        filters = initialFilters;
        setOpenFilters(initialFilters)
        setInitialFilters();
      }
      filters.forEach(filter => {
        let name = Object.keys(filter)[0] // Filter name (i.e. Severity, Time)
        let filterName = filter[name].filter_name // Filter value string name (i.e. severity, created_date)
        let keys = Object.keys(filter[name].filters); // Filter values available (i.e. High, Critical)
        keys.forEach((key) => { // Check keys to see if they are turned on
          if (filter[name].filters[key] === true) { // filter is turned on
            if (filterName in filterParameters) { // already exists
              filterParameters[filterName].push(key.split(' ').join('_'))
            } else {
              filterParameters[filterName] = [key.split(' ').join('_')]
            }
          }
        })
      })
      if (!_.isEqual(filterParameters, ticketsParams)) {
        setOpenTicketsData({})
        setTicketsParams(filterParameters)
      }
    }
    filterTicketsData()
    // eslint-disable-next-line
  }, [filterList, pageCount])

  const onRowClick = async (event, data, refresh = false) => {
    let currentData = data.data || data
    expandedIndex === currentData.index ? setExpandedIndex(null) : setExpandedIndex(currentData.index || 0)
    let id = currentData.id || data;
    if (id === openTicketsData.id && !refresh && event !== 'dashboardrowclick') {
      setOpenTicketsData({})
      setExpandedIndex(null)
    } else {
      !refresh && setTicketsDetailsLoading(true)
      try {
        setOpenTicketsData(currentData)
      } catch (e) {
        captureSentryError(e, userData, 'setOpenTicketsData in SupportTicket.js');
      } finally {
        setTicketsDetailsLoading(false)
      }
    }
  }

  return (
      <div className={"tickets-page-container"}>
        {showCreateModal && (
          <CreateModal
            data={showCreateModal}
            handleClose={() => {
              setShowCreateModal(false);
            }}
            handleFetchTickets={resetAllFilters}
            setTicketsData={setTicketsData}
            setOpenTicketsData={setOpenTicketsData}
          />
        )}
        {pendingStatusChange && 
          <ConfirmModal
            message={`${pendingStatusChange.status === "Resolved" ? "Reopen" : "Resolve"} ticket ${pendingStatusChange.case_number}?`}
            confirmFunc={() => handleChangeStatus()}
            cancelFunc={() => {setPendingStatusChange(); setStatusError();}}
            modalErrors={statusError ? "Error changing status" : ""}
            rowLoading={statusLoading}
            className={"status-modal"}
          />}
        <div className={"header-row"}>
          <p className={"page-title"}>SUPPORT TICKETS</p>
          <div className="support-number">
            <span className="label">{"PHONE SUPPORT: "}</span>
            {"1-855-917-4981 x 2"}
          </div>
        </div>
        <div className={"top-container"}>
        </div>
          <PageFilters
            isReset={isReset}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            handleClose={() => {
              setExpandedIndex(null)
              setOpenTicketsData({})
            }}
            setExpandedIndex={setExpandedIndex}
            filterList={filterList}
            setOpenFilters={setOpenFilters}
            setParams={setTicketsParams}
            initialSearch={defaultConfig?.q}
            defaultDate={(defaultConfig?.q && !isReset) ? {} : defaultDate}
            searchParam={true}
            setSearchTerm={setSearchTerm}
          />
          <LoaderButton
            className="create-button"
            text={"CREATE"}
            onClick={() => setShowCreateModal(true)}
          />
          {
            !ticketsLoading &&
            <PaginationControls
              resetAllFilters={resetAllFilters}
              displayData={ticketsData}
              page={page}
              showDropdown={showDropdown}
              setPage={handleChangePage}
              toggleShowDropdown={toggleShowDropdown}
              setParams={setTicketsParams}
              params={ticketsParams}
              hideExport={true}
              setExpandedIndex={setExpandedIndex}
            />
          }
          {ticketsLoading ?
            <div className={"loader-container"}><Loader/></div>
            : fetchError ? <NotFound isError dataError />
            : !ticketsLoading && ticketsData.results.length === 0 ?
              <div>
                <p className={'line-filter'}/>
                <p className={'no-results-text'}>No results, try expanding your filter parameters.</p>
              </div>
              :
              <div className={"fixed-table-container"}>
                <div className={"ticket-table-container " + (expandedIndex !== null ? 'expanded' : '')}>
                  <Table page={"tickets " + (expandedIndex !== null ? 'expanded' : '')}>
                    <TableHeader
                      mainRow={mainRow}
                      className={"tickets"}
                      setSortFilters={setSortFilters}
                      sortFilters={sortFilters}
                      setParams={setTicketsParams}
                      params={ticketsParams}
                      sortFrontEnd={true}
                      sortBy={sortBy}
                      setSortBy={setSortBy}
                      sortOrder={sortOrder}
                      setSortOrder={setSortOrder}
                    />
                    {
                      allData.map((item, i) => <TicketRow
                        key={item.id}
                        rowNumber={i}
                        severities={severities}
                        page={"tickets " + (expandedIndex === i? 'expanded' : '')}
                        data={{...item, index: i}}
                        mapMainRow={mainRow}
                        statuses={statuses}
                        onClick={onRowClick}
                        setPendingStatusChange={setPendingStatusChange}
                        userData={userData}
                      />)
                    }
                  </Table>
                </div>
                {
                  (Object.keys(openTicketsData).length > 0 && !ticketsDetailsLoading) ?
                    <ExpandView
                      closeView={onRowClick}
                      severities={severities}
                      className={"expanded-tickets-container"}
                      data={openTicketsData}
                      statuses={statuses}
                      setUploadModal={setShowCreateModal}
                      setTicketsData={setTicketsData}
                      setOpenTicketsData={setOpenTicketsData}
                    />
                    : ticketsDetailsLoading &&
                    <div className={"expand-loader"}><Loader/></div>
                }
              </div>
          }
          <div className={"pagination-controls"}>
            <Pagination
              setPage={handleChangePage}
              page={page}
              total={ticketsData.total}
              />
          </div>
      </div>
  )
}
export default SupportTicket;
