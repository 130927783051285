const buildNode = (n) => {
  const labels = [...n.labels];
  if (n.properties.highly_connected_ip) labels.push("highly_connected_ip");
  if (n.properties.severity) labels.push(n.properties.severity);
  return {
    id: n.element_id,
    labels: labels,
    properties: n.properties,
  };
};

const buildEdge = (e) => ({
  id: `${e.start_element_id}-${e.end_element_id}`,
  source: e.start_element_id,
  target: e.end_element_id,
  properties: e.properties,
});

const addNode = (nodes, node) => !nodes.map(itm => itm.id).includes(node.id) && nodes.push(node);

export const toMotifFormat = (records) => {
  let nodes = [];
  let links = [];
  if(!records) return {nodes, links};

  records.forEach((element) => {
    if (element.isNode) {
      let builtNode = buildNode(element);
      addNode(nodes, builtNode);
    } else {
      let builtEdge = buildEdge(element);
      links.push(builtEdge);
    }
  });

  links.forEach(link => {
    const a = nodes.find(n => n.id === link.source);
    const b = nodes.find(n => n.id === link.target);
    !a.neighbors && (a.neighbors = []);
    !b.neighbors && (b.neighbors = []);
    a.neighbors.push(b);
    b.neighbors.push(a);

    !a.links && (a.links = []);
    !b.links && (b.links = []);
    a.links.push(link);
    b.links.push(link);
  });

  return { nodes, links };
};
