export const leftColumn = {
    "cloud": [],
    "endpoint": ["Severity", "Machine Domain", "Process ID", "Host ID"],
    "firewall": ["Severity", "Hostname", "Device Name", "Source City", "Source Region", "Source Country", "Source Reputation", "Destination City", "Destination Region", "Destination Country", "Destination Reputation", "Message"],
    "winlog": ["Event Data"],
    "identity": ["Source IP", "Source IP 2", "Source IP City", "Source IP Region", "Source IP Country", "Source IP Reputation", "Source IP Type", "Description", "Display Name", "Outcome Reason", "Device Type", "Authentication Details"],
    "iis": ["User Agent", "Query String"]
}

export const rightColumn = {
    "cloud": [],
    "endpoint": ["Tactic", "Description", "Pattern Disposition"],
    "firewall": [],
    "winlog": ["Message"],
    "identity": ["Device OS", "Device Browser", "Number of Failed Login Attempts", "Number of Successful Login Attempts", "Context"],
    "iis": ["Log File"]
}

export const transform = {
    "cloud": {
        "Event Details": "beautifyText"
    },
    "endpoint": {},
    "endpointdefender": {},
    "firewall": {},
    "winlog": {
        "Message": "beautifyText"
    },
    "identity": {},
    "iis": {}
}

export const config = {
    "cloud": {
        "Event Details": {
            "skipDisplayStringValue": true
        }
    },
    "endpoint": {},
    "endpointdefender": {},
    "firewall": {},
    "winlog": {
        "Message": {
            "skipDisplayStringValue": true
        }
    },
    "identity": {},
    "iis": {}
}

export const mainRow = {
    "cloud": {"firstRow": ["Service", "Action", "Username"], "secondRow":["Event Type" , "Source IP"]},
    "endpoint": {"firstRow": ["Hostname", "Username", "Crowdstrike Log Type"], "secondRow":["Source IP", "Command Line", "Action"]},
    "endpointdefender": {"firstRow": ["Hostname", "Investigation State", "Title"], "secondRow": ["Host ID", "First Seen", "Last Seen"]},
    "firewall": {"firstRow": ["Source IP", "Destination IP", "Action"], "secondRow": ["Source Port", "Destination Port", "Firewall Type"]},
    "winlog": {"firstRow": ["Hostname", "Action", "Outcome"], "secondRow": ["Username","Event ID", "Process Name"]},
    "identity": {"firstRow": ["Service", "Username", "Action"], "secondRow": ["Event Type", "User ID"]},
    "iis": {"firstRow": ["Host", "Source IP", "Method"], "secondRow": ["Response", "Port", "Page"]}
}
