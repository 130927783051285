import React, {useContext, useEffect, useState} from "react";
import UserData from "../../store/User/UserData";
import ServicePanels from "./ServicePanels";
import KeyMetrics from "../Dashboard/KeyMetrics";
import {getKeyMetrics} from "../../api/ApiClient";
import KP1 from "../Dashboard/config/icons/kpi-1.svg";
import KP2 from "../Dashboard/config/icons/kpi-2.svg";
import KP3 from "../Dashboard/config/icons/kpi-3.svg";
import KP4 from "../Dashboard/config/icons/kpi-4.svg";
import {captureSentryError} from "../../util/sentry";

const LeftDashboard = () => {
  const [userData] = useContext(UserData);
  const [keyMetricsData, setKeyMetricsData] = useState([])
  const [keyMetricsFetchError, setKeyMetricsFetchError] = useState(false)
  const [loadingKeyMetricsData, setLoadingKeyMetricsData] = useState(false)

  useEffect(() => {
    const getKeyMetricsData = async () => {
      setKeyMetricsFetchError(false);
      setLoadingKeyMetricsData(true)
      try {
        let keyMetricsResponse = await getKeyMetrics(userData?.tenant)
        let km = ['Block Rate', 'Noise Reduction', 'MTTFR (Critical)', 'Ransomware Defense']
        let keyMetrics = keyMetricsResponse?.filter(item => km.includes(item.name))
        keyMetrics?.map((item) => {
          if (item.name === 'MTTFR (Critical)') {
            item.stringValue = Math.round(item.value).toString() + 'm'
            item.value = item.value / 60
          } else {
            item.stringValue = Math.round(item.value).toString() + '%'
            item.value = item.value / 100
            if ((item.name === 'Noise Reduction') && (item.value < 0)) {
              item.stringValue = '- -'
            }
          }
          return item
        })
        setKeyMetricsData(keyMetrics)
      } catch (e) {
        captureSentryError(e, userData, 'getKeyMetrics')
        setKeyMetricsFetchError(true);
      } finally {
        setLoadingKeyMetricsData(false)
      }
    }
    userData?.tenant && getKeyMetricsData()
    // eslint-disable-next-line
  }, [userData?.tenant, userData?.userType])

  return (
    <div className={"left-dashboard"}>
      <KeyMetrics
        images={[KP1, KP2, KP3, KP4]}
        keyMetricsData={keyMetricsData}
        loadingKeyMetricsData={loadingKeyMetricsData}
        fetchError={keyMetricsFetchError}
        title={"AGGREGATED KEY METRICS"}
      />
      <ServicePanels/>
    </div>
  )
}
export default LeftDashboard
