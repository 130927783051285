export const leftColumn = [
  'Compliance Test Output', 'Risk', 'Compliance'
]

export const rightColumn = [
  'Remediation'
]

export const mainRow = {
  "Report Date": {"field": "report_date", "header": "TIME", "sort": true, "defaultSortValue": false, 'display': 'Report Date'},
  "Status": {"field": "Status", "header": "STATUS", "sort": true, "defaultSortValue": false},
  "Severity": {"field": "Severity", "header": "SEVERITY", "sort": true, "defaultSortValue": false},
  "Account ID": {"field": "account_id", "header": "ACCOUNT ID", "sort": true, "defaultSortValue": false, 'display': 'Account ID'},
  "Region": {"field": "Region", "header": "REGION", "sort": true, "defaultSortValue": false},
  "Service": {"field": "Service", "header": "SERVICE", "sort": true, "defaultSortValue": false},
  "Resource ID": {"field": "resource_id", "header": "RESOURCE ID", "sort": true, "defaultSortValue": false, 'display': 'Resource ID'},
  "Compliance Check": {"field": "compliance_check", "header": "COMPLIANCE CHECK", 'display': 'Compliance Check', "sort": true, "defaultSortValue": false}
}