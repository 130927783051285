import React from "react";
import {capitalize, formatTime} from "../../../util/format";
import Row from "../../../components/Table/Row";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import '../../../styles/components/Table/Row.scss';
import {mapIcons} from "../iconMap";
import {useNavigate} from "react-router-dom";
import ExpandedView from "../../Detections/ExpandedView";

const EndpointsRow = ({
                        data,
                        page,
                        expandAll,
                        index,
                        setExpandAll,
                        setExpandedIndexes,
                        expandedIndexes,
                        isExpanded,
                        mainRow,
                        leftColumn,
                        rightColumn,
                        setActiveTab
                      }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (!isExpanded) {
      setExpandedIndexes(prevExpandedIndexes => {
        return [...prevExpandedIndexes, index]
      })
      isExpanded = true
    } else {
      setExpandedIndexes(expandedIndexes.filter(x => x !== index))
      setExpandAll(expandAll.filter(x => x !== index))
      isExpanded = false
    }
  }

  const getColorType = (type) => {
    if (type === null) {
      return <QuestionMarkIcon fontSize="small" sx={{color: 'yellow'}}/>
    }
    return type ? <CheckIcon fontSize="small" sx={{color: '#D0F31A'}}/> :
      <CloseIcon fontSize="small" sx={{color: '#D64426'}}/>
  }

  const getTotalMissingUpdates = (missing_updates) => {
    let totalCount = 0
    Object.keys(missing_updates).map((key) => {
      let value = missing_updates[key]
      totalCount += value
      return key
    })
    return totalCount
  }

  const clickMissingUpdates = () => {
    setActiveTab('MISSING UPDATES')
    navigate('/connections/endpoints', {state: {hostname: data.hostname}})
  }

  return (
    <>
      <Row page={page + (isExpanded ? " no-bottom-border" : "")} onClick={handleClick} data={data}>
        <td className={"last_seen td-value"}>
          {isExpanded ?
            <div className={"expand-btn minus"}>-</div>
            : <div className={"expand-btn"}>+</div>
          }
          {formatTime(data.last_seen)}
        </td>
        <td className={"hostname td-value"}>
          {data.hostname || "N/A"}
        </td>
        <td className={"status td-value"}>
          {capitalize(data.status) || "N/A"}
        </td>
        <td className={"os_platform_name td-value"}>
          <img className={"threat-icon"} alt={'attack-icon'} src={mapIcons(data.os_platform_name)}/>
          <div className={"td-top"}/>
        </td>
        <td className={"os_version td-value"}>
          <div className={"td-top"}>{data.os_version || "N/A"} </div>
        </td>
        <td className={"firewall-container"}>
          <div className={"icon-container"}>
            <div className={"firewall-container"}>
              <div>{getColorType(data.firewall_enabled)}</div>
            </div>
          </div>
        </td>
        <td className={"encryption-container"}>
          <div className={"icon-container"}>
            <div className={"encryption-container"}>
              <div>{getColorType(data.encryption_enabled)}</div>
            </div>
          </div>
        </td>
        <td className={"right-row updates"}>
          <div className={"table-cell"} onClick={clickMissingUpdates}>
            <span className={"highlight-text"}>{getTotalMissingUpdates(data.missing_patch_count)}</span>
          </div>
        </td>
        <td className={"EDR td-value"}>
          {capitalize(data.edr_type)} {data.edr_version}
        </td>
        <td className={"Profiler td-value"}>
          {data.profiler_version}
        </td>
      </Row>
      {(expandedIndexes.includes(index) || expandAll.includes(index)) &&
      <ExpandedView
        isExpanded={isExpanded}
        data={data}
        statuses={data?.status}
        leftColumn={leftColumn[data.edr_type]}
        rightColumn={rightColumn[data.edr_type]}
        mainRow={mainRow}
      />
      }
    </>
  );
}

export default EndpointsRow;
