import React from "react";
import CircularGraph from "../../components/D3/CircularGraph/CircularGraph";
import Circle from "./config/images/circle.svg";

const ProgressHeader = ({data, setToggleRow, toggleRow}) => {
  const calculateColor = (value) => {
    return  value <= .25 ? '#D64526' : value > .25 && value <= .50 ? '#FFA415' : value > .50 && value <= .75 ? '#FFE133' :  '#cff319'
  }

  return (
    <div className={"progress-row"}>
      <span className={`expand-btn ${toggleRow}`} onClick={(e) => {
        e.stopPropagation();
        setToggleRow(!toggleRow)
      }}>{toggleRow ? '+' : '-'}</span>
      <p className={`circle-graph-title sub-title ${toggleRow}`}>Progress</p>
      <div className={"circle-container"} style={{backgroundImage: `url(${Circle}`}}>
        <CircularGraph
          innerRadius={33}
          outerRadius={35}
          xAxis={3}
          height={100}
          width={100}
          containerName={'maturity-assessment-progress'}
          data={data}
          color={toggleRow ? '#2a494e' : calculateColor(data.value)}
        />
      </div>
    </div>
  )
}

export default ProgressHeader