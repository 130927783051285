import "../../../styles/pages/Onboarding/OnboardingTable.scss";
import Tooltip from "../../../components/Tooltip/Tooltip";
import ConfirmDialogCell from "../../../components/Table/ConfirmDialogCell";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import {patchOnboardingUserAccess} from "../../../api/ApiClient";
import ToggleOff from "../icons/toggle_off.svg";
import ToggleOn from "../icons/toggle_on.svg";
import { Loader } from "../../../components/Loader/Loader";
import TrashIcon from "../../../assets/icons/trash-icon.svg";
import ResetPasswordIcon from "../../../assets/icons/reset-password.svg";
import Checkmark from "../../../assets/icons/checkmark_done.svg";
import ExpiredIcon from "../../../assets/icons/expired-red.svg";
import EditIcon from "../../../assets/icons/edit-icon.svg";
import { useRef, useState, useLayoutEffect } from "react";
import { formatTime } from "../../../util/format";
import { captureSentryError } from "../../../util/sentry";

const confirmDialogs = {
  delete: "Confirm delete?",
  edit: "Confirm edit?",
  resend: "Reset account?",
};

const portalUserRowButtonConfig = [
  { action: "edit", tooltip: "Edit", icon: EditIcon },
  {
    action: "resend",
    tooltip: "Reset account",
    icon: ResetPasswordIcon,
  },
  { action: "delete", tooltip: "Delete", icon: TrashIcon },
];

const nonPortalUserRowButtonConfig = [
  { action: "edit", tooltip: "Edit", icon: EditIcon },
  { action: "delete", tooltip: "Delete", icon: TrashIcon },
];

const statusIcons = {
  'active': Checkmark,
  'password reset required': ResetPasswordIcon,
  'expired': ExpiredIcon
}

const ContactsOnboardingRow = (props) => {
  const {
    selectedRows,
    setSelectedRows,
    rowError,
    rowLoading,
    resetPendingStages,
    pendingRow,
    setPendingRow,
    handleSubmit,
    userData = null,
    tableHeaders,
    row,
  } = props;
  const contact = useRef(row);
  const containerRef = useRef({});
  const textRef = useRef({});
  const [toolTipState, setToolTipState] = useState({});
  const [showAccessDialog, setShowAccessDialog] = useState(false)
  const [accessLoading, setAccessLoading] = useState(false)

  const iconButtonsDisabled = (button, row) =>
    (button.action === "reset" || button.action === "resend") && !row.userRole;

  const getButtonMapConfig = () => {
    if (row.userRole === 'Contact Only') {
      return nonPortalUserRowButtonConfig
    }
    if (row.tenant_created_with && userData?.tenant !== row.tenant_created_with) {
      return [{ action: "edit", tooltip: "Edit", icon: EditIcon }]
    }
    return portalUserRowButtonConfig
  }

  const getRowValue = (row, col, useTooltip=false) => {
    if (col === 'access') {
      return (
        showAccessStatus(row.userRole !== 'Deny', row['email'])
      )
    }
    if (col === 'userRole') {
      return row[col] ? row[col] : "Contact Only"
    }
    if (col === 'status' && !useTooltip) {
      let status = row[col]
      let statusIcon = statusIcons[status?.toLowerCase()]
      return <div className={`status-container ${status}`}>
        <img src={statusIcon} alt={status}/>
      </div>
    }
    if (col === 'lastLogin') {
      let lastLogin = row[col]
      if (lastLogin) {
        return formatTime(row[col])
      }
    }
    return row[col]
  }
  
  const handleAccessSubmit = async (access, email) => {
    setAccessLoading(true)
    try {
      let change_access = access ? 'allow' : 'deny'
      let params = {access: change_access, email: email}
      const onboardingUserAccessResponse = await patchOnboardingUserAccess(userData?.tenant, params)
      row.userRole = onboardingUserAccessResponse?.new_role
    } catch (e) {
      captureSentryError(e, userData, "patchOnboardingUserAccess API in ContactsOnboardingRow.js");
    } finally {
      setAccessLoading(false)
      setShowAccessDialog(false)
    }
  }

  const showAccessStatus = (access, email) => {
    return (
      <div className={access ? 'allow-access' : 'denied-access'} onClick={() => row.email !== userData.email && handleAccessSubmit(!access, email)}>{accessLoading ? <Loader className={'onboarding-access-loader'}/> : <img className={"access-toggle-icon"} alt={'access-toggle'} src={access ? ToggleOn : ToggleOff}/>}</div>
    )
  }

  const mapButtons = (b, i) => {
    return (
      <Tooltip
        content={`${b.tooltip} ${
          iconButtonsDisabled(b, row) ? " (portal users only)" : ""
        }`}
        className={`${b.action}-tooltip ${
          iconButtonsDisabled(b, row) && "disabled"
        }`}
        direction={"right"}
        key={i}
      >
        {b.action === "delete" && row.email === userData.email ? (
          <IconButton
            className={`table-icon ${b.action} disabled`}
            onClick={() => setPendingRow(row, b.action)}
            disabled={iconButtonsDisabled(b, row) || b.disabled}
          >
            <img src={b.icon} alt={b.tooltip} />
          </IconButton>
        ) : (
          <IconButton
            className={`table-icon ${b.action}`}
            onClick={() => setPendingRow(row, b.action)}
            disabled={iconButtonsDisabled(b, row) || b.disabled}
          >
            <img src={b.icon} alt={b.tooltip} />
          </IconButton>
        )}
      </Tooltip>
    );
  };

  useLayoutEffect(() => {
    let textSizeObserver = null;
    for (let header of Object.keys(tableHeaders)) {
      const key = `${contact.current.email}${header}`;
      textSizeObserver = new ResizeObserver(() => setTimeout(() => {
        const container = containerRef.current[key];
        const text = textRef.current[key];
        // use the tooltip component when the text size is over 80% which is threshold we set before hiding the overflow
        if (text?.scrollWidth > container?.clientWidth * 0.8) {
          setToolTipState((state) => ({ ...state, [key]: true }));
        } else {
          setToolTipState((state) => ({ ...state, [key]: false }));
        }
      }), 0);
      textSizeObserver.observe(textRef.current[key]);
      textSizeObserver.observe(containerRef.current[key]);
    }

    return () => {
      textSizeObserver.disconnect();
    };
  }, [tableHeaders]);

  return (
    <tr key={row.email + row.id}>
      <td className="checkbox-cell">
        <div className="checkbox-icon">
          <Checkbox
            key={row.email}
            className="custom-checkbox"
            checked={
              selectedRows.filter((r) => r.email === row.email).length > 0
            }
            onChange={(e) => {
              e.target.checked
                ? setSelectedRows((r) => [...r, row])
                : setSelectedRows((r) =>
                    r.filter((em) => em.email !== row.email)
                  );
            }}
          />
        </div>
      </td>
      {Object.keys(tableHeaders).map((col) => (
        <td
          key={`${row.email}${col}`}
          ref={(ref) => (containerRef.current[`${row.email}${col}`] = ref)}
        >
          {(toolTipState[`${row.email}${col}`] && col !== 'access') || col === 'status' ? (
            <Tooltip
              content={getRowValue(row, col, true)}
              type="contacts-onboarding"
              className="contacts-tooltip"
              direction={"right"}
            >
              <span
                className={`text ${col} contained`}
                ref={(ref) => (textRef.current[`${row.email}${col}`] = ref)}
              >
                {getRowValue(row, col)}
              </span>
            </Tooltip>
          ) : (
            <span
              className={col === 'access' ? `text ${col} ${showAccessDialog}` : `text ${col}`}
              ref={(ref) => (textRef.current[`${row.email}${col}`] = ref)}
            >
              {getRowValue(row, col)}
            </span>
          )}
        </td>
      ))}
      {pendingRow.row?.email === row.email && pendingRow.action !== "edit" ? (
        <ConfirmDialogCell
          message={confirmDialogs[pendingRow.action]}
          confirmFunc={handleSubmit}
          cancelFunc={resetPendingStages}
          rowError={rowError}
          rowLoading={rowLoading}
        />
      ) : (
        <td className="table-icon-container contact">
          {((!row.isInheritedUser && row.userRole !== 'Deny') || (userData?.tenant === row.tenant_created_with)) && getButtonMapConfig().map(mapButtons)}
        </td>
      )}
    </tr>
  );
};

export default ContactsOnboardingRow;
