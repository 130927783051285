import React, {useRef} from 'react';
import '../../../styles/pages/Vulnerabilities2/Overview/OverviewTable.scss';
import {useNavigate} from "react-router-dom";
import Tooltip from "../../../components/Tooltip/Tooltip";

const VulnerabilityTable = ({data = [], mapData, setCurrTab}) => {
  const titleTextRef = useRef();
  const navigate = useNavigate();

  const handleClick = (item) => {
    if (mapData.data === 'total_vulnerabilities') {
      const vulnState = item ? {state: {cve: item}} : {replace: true, state: null}
      setCurrTab('vulnerabilities')
      navigate('/risk-management/vulnerabilities2', vulnState)
    }
    else if (mapData.data === 'total_affected_assets') {
      const vulnState = item ? {state: {host: item}} : {replace: true, state: null}
      setCurrTab('assets')
      navigate('/risk-management/vulnerabilities2', vulnState)
    }
  }

  return (
    <div className={`vulnerability-table-container`}>
      <p className={"header"}>{mapData.subtitle}</p>
      {data.length > 0 ? <><table className="table-container">
        <thead>
        <tr className={"table-headers"}>
          {mapData.headers.map((key, index) =>
            <th key={index} className={index === 0 || key==="description" ? 'hidden' : key}>{key.toUpperCase().replace('_',' ')}</th>)}
        </tr>
        </thead>
        <tbody>
        {data?.map((item, index) => (
          <tr key={index} onClick={() => handleClick(mapData.data === 'total_affected_assets' ? item.host : item.cve)}>
            {mapData.headers.map((header) => (
              <td key={header}>
                {header === 'description' ?
                  <div className="title-container">
                    <Tooltip disableTimeout content={item[header]} className="description-tooltip" direction={"bottom"}>
                      <p ref={titleTextRef} className={header + ' text'}>{item[header]}</p>
                    </Tooltip>
                  </div> : <p className={header}>{item[header]}</p>}
              </td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>
      <div className={"see-all-btn"} onClick={() => handleClick(null)}>SEE ALL <span className={"carrot"}>{">>"}</span></div></> : <p className={"no-data"}>No Data</p>}    </div>
  )
}

export default VulnerabilityTable;
