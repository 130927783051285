import {Pagination} from "../index";
import GenericDropdown from "../Dropdown/GenericDropdown";
import Export from "../Export/Export"
import React, {useEffect, useRef} from "react";
import '../../styles/components/Table/PaginationControls.scss';
import {useOnClickOutside} from "../../util/clickOutside.js"
import _ from "lodash";
import ResetFilters from "./ResetFilters";

const PaginationControls = ({
                              className,
                              displayData,
                              page,
                              expandAll,
                              showDropdown,
                              sortFilters,
                              setSortFilters,
                              setExpandAll,
                              toggleShowDropdown,
                              setPage,
                              currentSearchConfig,
                              setParams,
                              params,
                              showSortDropdown,
                              setExpandedIndexes,
                              bauExportLoading,
                              resetAllFilters,
                              hideExport = false
                            }) => {

  const prevParams = useRef(params);
  useEffect(() => {
    const paramsChanged = !_.isEqual(prevParams.current, params);
    const pageParamDidNotChange = params?.page === prevParams.current?.page;
    if (paramsChanged && pageParamDidNotChange) {
      prevParams.current = params;
      setPage(1);
    }
  }, [params, setPage]);

  const handleSortResults = (name, item) => {
    let value = item.toLowerCase().split(' ').join('_')
    const filterValue = sortFilters[name].filters[item]
    if (filterValue === false) { // filter is clicked and highlighted
      setParams({...params, sort_by: value, order: 'desc'})
      sortFilters[name].filters = Object.fromEntries(Object.keys(sortFilters[name].filters).map((key) => [key, false]))
      setSortFilters(sortFilters)
      sortFilters[name].filters[item] = true
    } else { // filter is unclicked and not highlighted
      setParams({...params, sort_by: value, order: 'asc'})
      sortFilters[name].filters[item] = false
    }
    setSortFilters({...sortFilters})
  }

  const setOpenSort = (name, showDropdown, toggleShowDropdown) => showDropdown === '' ? toggleShowDropdown(name) : toggleShowDropdown('')

  const expandAllRows = () => {
    if (expandAll.length === 0) {
      setExpandAll([...Array(displayData.results.length).keys()])
    } else {
      setExpandedIndexes([])
      setExpandAll([])
    }
  }

  let domNode = useOnClickOutside(() => {
    toggleShowDropdown('')
  });

  let divClassName = ''
  if (className === 'log-search-table') {
    divClassName = ' log-search'
  }
  if (className === 'vulnerabilities-table') {
    divClassName = ' vulnerabilities'
  }
  return (
    <div className={(className ? className : '') + " pagination-controls"}>
      <div className={"pagination"}>
        <div className={"total-results"}><span
          className={"total"}>{displayData?.total}</span> {displayData?.total === 1 ? 'Result' : 'Results'}</div>
        {displayData?.results?.length > 0 &&
        <Pagination
          setPage={setPage}
          page={page}
          total={displayData.total}
        />}
        {displayData?.results?.length > 0 &&
        expandAll &&
        <p className={"expand-all"} onClick={() => expandAllRows()}>
          {expandAll.length > 0 ? 'Collapse All' : 'Expand All'}
        </p>}
      </div>
      <div className={"table-controls"} ref={domNode}>
        <ResetFilters onClick={resetAllFilters}/>
        {displayData?.results?.length > 0 ?
          <>
            {!hideExport && <Export
              currentSearchConfig={currentSearchConfig}
              toggleShowDropdown={toggleShowDropdown}
              setParams={setParams}
              params={params}
              bauExportLoading={bauExportLoading}
            />}
            {showSortDropdown &&
            <div
              className={showDropdown === 'Sort by' ? 'sort-by open' + divClassName : 'sort-by' + divClassName}
              ref={domNode}
            >
              <GenericDropdown
                className={"dropdown-sort dropdown-container"}
                selectCheckbox={handleSortResults}
                name={'Sort by'}
                data={sortFilters}
                isOpen={showDropdown === 'Sort by'}
                dropdownList={sortFilters['Sort by'].filters}
                showDropdown={showDropdown}
                toggleShowDropdown={toggleShowDropdown}
                setOpenSort={setOpenSort}
              />
            </div>}
          </> : null}
      </div>
    </div>
  )
}

export default PaginationControls;
