export const handleSortResults = (name, item, sortFilters, setSortFilters, params, setParams) => {
  let value = item.toLowerCase().split(' ').join('_')
  const filterValue = sortFilters[name].filters[item]
  if (filterValue === false) { // filter is clicked and highlighted
    setParams({...params, sort_by: value, order: 'desc'})
    sortFilters[name].filters = Object.fromEntries(Object.keys(sortFilters[name].filters).map((key) => [key, false]))
    setSortFilters(sortFilters)
    sortFilters[name].filters[item] = true
  } else { // filter is unclicked and not highlighted
    setParams({...params, sort_by: value, order: 'asc'})
    sortFilters[name].filters[item] = false
  }
  setSortFilters({...sortFilters})
}


export const setSortFilterValue = (sortFilters, mainRow, setSortFilters) => {
  Object.values(mainRow).forEach((item) => {
    if (item.sort) {
      sortFilters[item.field] = item.defaultSortValue
    }
  })
  setSortFilters({...sortFilters})
}
