import "../../../styles/pages/Onboarding/OnboardingTable.scss";
import React from "react";
import Tooltip from "../../../components/Tooltip/Tooltip";
import AscSorting from "../../../assets/icons/triangle-up.svg";
import DescSorting from "../../../assets/icons/triangle-down.svg";
import DenseTable from "../../../components/Table/DenseTable";
import ContactsOnboardingRow from "./ContactsOnboardingRow";

const tableHeaders = {
  firstname: "FIRST NAME",
  lastname: "LAST NAME",
  email: "EMAIL",
  mobilePhone: "PHONE",
  userRole: "ROLE",
  status: "STATUS",
  lastLogin: "LAST LOGIN",
  access: "ACCESS"
};

const ContactsOnboardingTable = (props) => {
  const {
    contactsUserData,
    selectedRows,
    setSelectedRows,
    rowError,
    rowLoading,
    dataLoading,
    resetPendingStages,
    pendingRow,
    setPendingRow,
    handleSubmit,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    userData=null
  } = props;

  const toggleSortOrder = () =>
    sortOrder === "asc" ? setSortOrder("desc") : setSortOrder("asc");

  
  const generateHeaderRowContent = (col) => (
    <div
      onClick={() => {
        sortBy === col ? toggleSortOrder() : setSortBy(col);
      }}
      className={"clickable" + (sortBy === col ? " active" : "")}
    >
      {tableHeaders[col].toUpperCase()}
      <span className="sort-icon">
        {sortBy === col && sortOrder === "asc" ? (
          <img className={"asc"} src={AscSorting} alt="" />
        ) : sortBy === col && sortOrder === "desc" ? (
          <img src={DescSorting} alt="" />
        ) : null}
      </span>
    </div>
  )

  const generateHeaderRow = () => (
    <>
      <td className="table-column icon" />
      {Object.keys(tableHeaders).map((col) => {
        return (
          <td className={`table-column ${col}`} key={col}>
            {col === 'access' ? 
              <Tooltip
                content={"Tenant Access Permission"}
                type="access-onboarding-header"
                className="title-tooltip"
                direction={"right"}
             >
              {tableHeaders[col].toUpperCase()}
            </Tooltip>
            : generateHeaderRowContent(col)}
          </td>
        );
      })}
      <td className="table-column buttons" />
    </>
  );

  const generateBodyRows = () =>
    contactsUserData.map((row) => (
      <ContactsOnboardingRow
        key={`${row.email}`}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        rowError={rowError}
        rowLoading={rowLoading}
        resetPendingStages={resetPendingStages}
        pendingRow={pendingRow}
        setPendingRow={setPendingRow}
        handleSubmit={handleSubmit}
        userData={userData}
        tableHeaders={tableHeaders}
        row={row}
      />
    ));

  return (
    <div className="onboarding-table contacts">
      <DenseTable
        loading={dataLoading}
        header={generateHeaderRow()}
        noDataMessage={"No users added yet."}
        body={generateBodyRows()}
      />
    </div>
  );
};

export default ContactsOnboardingTable;
