import React, {useContext, useEffect, useState} from 'react';
import '../../styles/pages/Connections/MobileConnection.scss';
import {abbreviateNumber} from "../../util/format";
import {mapIcons} from "./config/iconMap";
import ConnectionsLineChart from "./ConnectionsLineChart";
import UserData from "../../store/User/UserData";
import {Loader} from "../../components/Loader/Loader";
import {getMobileConnections} from "../../api/ApiClient";
import {tooltipMapping} from "../../config/tooltipTextMapping";
import Tooltip from "../../components/Tooltip/Tooltip";
import DisabledChart from "./DisabledChart";
import {getTrendDifference} from "./config/trend";
import MailButton from "../../components/MailButton/MailButton";
import { hasAdminAccess } from '../../util/userControl';
import NotFound from "../../navigation/NotFound";
import { captureSentryError } from '../../util/sentry';

const MobileConnection = ({onInvestigationClick, isMDRLite, createEmail}) => {
  const [userData] = useContext(UserData);
  const [connectionsData, setConnectionsData] = useState({})
  const [mobileLoading, setMobileLoading] = useState(false);
  const [remainingEndpoints, setRemainingEndpoints] = useState(0)
  const [icons, setIcons] = useState({})
  const [count, setCount] = useState(0);
  const [colors] = useState({})
  const [fetchError, setFetchError] = useState(false);
  const mapValues = {
    "Contracted": {field: 'contracted_endpoints'},
    "Events": {field: 'total_events'},
    "Investigations": {field: 'investigation_count'}
  }

  useEffect(() => {
    const getConnections = async () => {
      setFetchError(false);
      setMobileLoading(true)
      try {
        let connectionsResponse = await getMobileConnections(userData?.tenant)
        if (connectionsResponse.active_endpoints) {
          let totalActiveEndpoints = Object.values(connectionsResponse.active_endpoints).reduce((a, b) => a + b, 0)
          let abbreviatedEndpoints = abbreviateNumber(totalActiveEndpoints, 0)
          let contractedEndpoints = connectionsResponse.contracted_endpoints || 0
          setCount(abbreviatedEndpoints)
          setRemainingEndpoints(contractedEndpoints - totalActiveEndpoints)
          setIcons(connectionsResponse.active_endpoints)
        }
        setConnectionsData(connectionsResponse)
      } catch (e) {
        captureSentryError(e, userData, "getMobileConnections");
        setFetchError(true);
      } finally {
        setMobileLoading(false)
      }
    }

    userData?.tenant && !isMDRLite && getConnections()
    isMDRLite &&  setIcons({android: '', ios: ''})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.tenant, isMDRLite]);
  
  return (
    <>
      {!connectionsData.hasOwnProperty("error") &&
      <tr className={"connections-row mobile"}>
        {!mobileLoading ? 
        fetchError ? <NotFound isError dataError className="panel service" /> 
        : 
        <>
          <td className={"connection-type"}>
            <Tooltip content={tooltipMapping.serviceStatus.mobile} className={"title-tooltip"} direction={"right"}>
              <p className={"is-connected" + (isMDRLite ? ' disabled' : '')}>MOBILE</p>
            </Tooltip>
            {connectionsData.hasOwnProperty("error") && !isMDRLite && <p className={"error-text"}>No mobile hosts</p>}
            {connectionsData?.active_endpoints && <p className={"count" + (isMDRLite ? ' disabled' : '')}>
              {count}
            </p>}
            {isMDRLite && hasAdminAccess(userData?.role) &&
            <MailButton label={"ADD SERVICE »"} mailto={createEmail('mobile')}/>}
          </td>
          <td>
            <span className={"circle " + (!connectionsData?.status ? "grey" : connectionsData?.status)}/>
          </td>
          <td className={"service-icons" + (isMDRLite ? ' disabled' : '')}>
            {icons && Object.entries(icons).map((elem, i) =>
              <p key={i} className={"active-endpoint"}>
                <img alt={elem} src={mapIcons(elem[0].toLowerCase())}/><span className="service-count">{elem[1]}</span>
              </p>
            )}
            
          </td>
          <td>
            { remainingEndpoints < 0 &&
              getTrendDifference(remainingEndpoints)
            }
          </td>
          <td className={"connection-data"}>
            {!connectionsData.hasOwnProperty("error") &&
            Object.entries(mapValues).map((elem, index) => {
              let field = elem[1].field
              let value = connectionsData[field]
              if (elem[0] === "Contracted" && value === null) return ''
              let hasInvestigationLink = elem[0] === "Investigations" && value > 0
              return <div key={index} className={"display-values " + elem[0] + (hasInvestigationLink ? ' pointer' : '')}>
                <p className={"name"}>{elem[0]}</p>
                <p
                  className={"value " + (hasInvestigationLink ? ' link' : '')}
                  onClick={() => hasInvestigationLink && onInvestigationClick('Mobile')}
                >{!isMDRLite ? abbreviateNumber(value, 0) : ''}</p>
              </div>
            })}
          </td>
          <td className={"chart-container" + (isMDRLite ? ' disabled' : '')}>
            {connectionsData?.trend?.data && !isMDRLite ?
            <ConnectionsLineChart
              displayName={'mobile'}
              data={connectionsData.trend.data}
              colors={colors}
            /> : isMDRLite &&
              <DisabledChart
                data={[{name: 'Contracted', values: []},{name: 'Actual', values: []},{name: 'Disconnected', values: []}]}
                colors={{Contracted: 'green', Actual: 'orange', Disconnected: 'red'}}
              />}
          </td>
        </> : <td><Loader/></td>}
      </tr>}
    </>
  )
}

export default MobileConnection;