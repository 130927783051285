export const filterConfig = [
  {
    Priority: {
      filter_name: "priority",
      isOpen: true,
      filters: {
        'Critical': false, 'High': false, 'Medium': false, 'Low': false, 'Informational': false
      }
    },
  },
  {
    Status: {
      filter_name: "status",
      isOpen: true,
      filters: {'Waiting On Customer': false, 'Waiting On ActZero': false, 'Resolved': false, 'Closed': false, 'Re-Opened': false}
    }
  }
]

export const resetFilterConfig = {
    Priority: {
      filter_name: "priority",
      isOpen: true,
      filters: {
        'Critical': false, 'High': false, 'Medium': false, 'Low': false, 'Informational': false
      }
    },
    Status: {
      filter_name: "status",
      isOpen: true,
      filters: {'Waiting On Customer': false, 'Waiting On ActZero': false, 'Resolved': false, 'Closed': false, 'Re-Opened': false}
    }
}

export default filterConfig
