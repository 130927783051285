import React from 'react';
import Select from 'react-select'

const TenantDropdown = ({className, data, styles, defaultValue, onChange}) => {
  return (
    <div className={(className ? className : '') + " dropdown-checkbox-container"}>
      <div className={"dropdown-container"}>
        <Select
          id="combo-box-tenants"
          className={"tenant-selection"}
          styles={styles}
          options={data}
          value={defaultValue}
          onChange={(event) => onChange(event.name)}
        />
      </div>
    </div>
  )
}

export default TenantDropdown;
