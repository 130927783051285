import config from '../config'
import { fetchAuthSession } from 'aws-amplify/auth';
import {isIterable} from '../util/isIterable';

const API_URL = config.api_url;

async function getToken() {
  const session = await fetchAuthSession();
  if(window.Cypress) return "test_token";
  return session.tokens.idToken;
}

function buildUrlQuery(query) {
  let qry_string = '?'
  if (query.length === 0) {
    return ''
  }
  Object.keys(query).map(key => {
    let qryValue = query[key]
    if (typeof qryValue === 'string' || typeof qryValue === 'number') {
      qry_string += `${key}=${encodeURIComponent(qryValue)}&`
    } else if (qryValue !== null && isIterable(qryValue)) {
      qryValue.forEach((item) => {
        qry_string += `${key}=${encodeURIComponent(item)}&`
      })
    }
    return qry_string
  });
  return qry_string.slice(0, -1) //remove the last ampersand
}


const getFetchResponse = async(url, opts, isExport=false) => {
   const response = await fetch(url, opts);
  if (response.ok) {
    const promise = isExport ? response.text() : response.json()
    return await promise;
  } else if (response.status === 404) {
    return null
  } else if (response.headers.get('Content-Type') === 'application/json') {
    // Our API will return errors as JSON
    const {message} = await response.json();
    if (message) {
      throw new Error(message);
    }
  }
  throw new Error(response.statusText);
}

async function doFetch(method, path, body, query, isExport = false, fileType) {
  const token = await getToken();
  let headers = {
    Authorization: token
  }
  if (isExport) {
    headers['Accept'] = 'text/csv'
    if (query) {
      delete query.isExport
      delete query.size
      delete query.page
    }
  }

  const opts = {
    method: method,
    headers: headers
  };

  let url = `${API_URL}/${path}`;

  if (query) {
    url += buildUrlQuery(query)
  }

  if (body) {
    opts.headers['Content-Type'] = fileType ? fileType : 'application/json';
    opts.body = fileType ? body : JSON.stringify(body);
  }

  return getFetchResponse(url, opts, isExport)
}


const doFetchSalesforce = async(method, path, body, file1, file2) => {
    const token = await getToken();
    let headers = {
      Authorization: token
    }

    const opts = {
      method: method,
      headers: headers
    };

    let url = `${API_URL}/${path}`

    const formData = new FormData();
    formData.append('json_request', JSON.stringify(body));
    formData.append('certificate_file', file1);
    formData.append('private_key_file', file2);

      if (body) {
        opts.body = formData;
      }

      return getFetchResponse(url, opts)
}

export const getTenants = () => doFetch('GET', 'tenants');

export const getTenant = (tenant) => doFetch('GET', `tenants/${tenant}`);

export const postExportLogs = (search_body) => doFetch('POST', 'logs/export', search_body);

export const getExportedLogs = (tenant) => doFetch('GET', `logs/list-exports/${tenant}`);

export const postLogSearch = (search_body) => doFetch('POST', 'logs/search', search_body);

export const getLogSearchConfig = () => doFetch('GET', 'logs/search/config');

export const getDetections = (tenant, params, isExport = false) => {
  return doFetch('GET', `detections/${tenant}`, null, params, isExport);
}

export const getDetectionsTrends = (tenant, params) => doFetch('GET', `detections/${tenant}/trends`, null, params);
export const getDetectionsMetrics = (tenant) => doFetch('GET', `detections/${tenant}/metrics`);


export async function getProcessTreeData(tenant, detection_id) {
  return doFetch('GET', `detections/${tenant}/${detection_id}/tree`);
}

export async function getInvestigations(tenant, params, isExport = false) {
  return doFetch('GET', `investigations/${tenant}`, null, params, isExport);
}

export async function getInvestigationComments(tenant, case_id, search_body) {
  return doFetch('POST', `investigations/${tenant}/${case_id}/comment`, search_body);
}

export async function getInvestigation(tenant, case_id) {
  return doFetch('GET', `investigations/${tenant}/${case_id}`);
}

export async function getAction(tenant, detection_id, params) {
  return doFetch('GET', `investigations/${tenant}/actions/${detection_id}`, null, params);
}

export async function getActionStatus(tenant, detection_id) {
  return doFetch('GET', `investigations/${tenant}/actions/${detection_id}/status`);
}

export async function getVulnerabilitiesMetrics(tenant, params) {
  return doFetch('GET', `vulnerability/${tenant}/metrics`, null, params);
}

export async function getVulnerabilitiesTrends(tenant, params) {
  return doFetch('GET', `vulnerability/${tenant}/trends`, null, params);
}

export async function getVulnerabilitiesData(tenant, params, isExport = false) {
  return doFetch('GET', `vulnerability/${tenant}`, null, params, isExport);
}

export const postActionAndComment = async (tenant, id, body) => {
  return doFetch('POST', `vulnerability/${tenant}/${id}`, body);
}

export const updateMultipleActions = async (tenant, body) => {
  return doFetch('POST', `vulnerability/${tenant}/action`, body);
}

export const getFeatureFlag = (tenant, featureFlag, params) => doFetch('GET', `feature-flags/${featureFlag}/${tenant}`, null, params);

export const getKeyMetrics = async (tenant) => {
  return doFetch('GET', `metrics/${tenant}`);
}

export async function getRssFeed() {
  return doFetch('GET', 'threats/feed');
}

export async function getAnnouncements(tenant) {
  return doFetch('GET', `announcements/${tenant}`);
}

export async function readAnnouncements(tenant, readIds) {
  return doFetch('POST', `announcements/${tenant}/read`, null, readIds);
}

export async function getEndpoints(tenant, params, isExport = false) {
  return doFetch('GET', `endpoints/${tenant}/hosts`, null, params, isExport);
}

export async function getMissingUpdates(tenant, params, isExport = false) {
  return doFetch('GET', `endpoints/${tenant}/patches`, null, params, isExport);
}

export async function getMobileEndpoints(tenant, params, isExport = false) {
  return doFetch('GET', `endpoints/${tenant}/mobile`, null, params, isExport);
}

export const getMobileVulnerabilities = (tenant, params) => doFetch('GET', `endpoints/${tenant}/mobile/vulnerabilities`, null, params);

export async function getEndpointSummary(tenant, params) {
  return doFetch('GET', `endpoints/${tenant}/summary`, null, params);
}

export const getConnectionsPanelData = async (tenant) => doFetch('GET', `connections/${tenant}/summary`);

//connections apis
export const getEndPointConnections = (tenant) => doFetch('GET', `endpoints/${tenant}/status`)
export const getFirewallConnections = (tenant) => doFetch('GET', `connections/${tenant}/firewall/status`)
export const getMobileConnections = (tenant) => doFetch('GET', `endpoints/${tenant}/mobile/status`)
export const getLogConnections = (tenant) => doFetch('GET', `connections/${tenant}/log-management/status`)
export const getCloudConnections = (tenant) => doFetch('GET', `connections/${tenant}/cloud/status`)
export const getIdentityConnections = (tenant) => doFetch('GET', `connections/${tenant}/identity/status`)
export const getConnectionsModalData = (tenant, params, isExport = false) => doFetch('GET', `connections/${tenant}`, null, params, isExport)
export const getExportConnectionsData = (tenant) => doFetch('GET', `connections/${tenant}/export`)

//cloud hyegiene apis
export const getCloudHygiene = (tenant, params, isExport = false) => doFetch('GET', `cloud_hygiene/${tenant}`, null, params, isExport);
export const getCloudHygieneTrends = (tenant, params) => doFetch('GET', `cloud_hygiene/${tenant}/metrics`, null, params);

//file management
export async function getFiles(tenant) {
  return doFetch('GET', `files/${tenant}`);
}

export async function uploadFile(tenant, tenant_email, file, params) {
  const {upload_url} = await doFetch("GET", `files/${tenant}/upload`, null, params);

  await fetch(upload_url, {
    method: "PUT",
    body: file,
    headers: {
      "x-amz-meta-tags": params.tags,
      "x-amz-meta-creator-email": tenant_email,
    },
  });
}

export async function deleteFile(tenant, params) {
  return doFetch('DELETE', `files/${tenant}`, params);
}

//maturity assessment
export const getOverallMAData = (tenant, params) => doFetch('GET', `controls/${tenant}/summary`, null, params)
export const getMATableData = (tenant, params, isExport = false) => doFetch('GET', `controls/${tenant}`, null, params, isExport)
export const getMAExpandData = (tenant, control) => doFetch('GET', `controls/${tenant}/${control}`)
export const postMAComments = (tenant, control, body) => doFetch('POST', `controls/${tenant}/${control}`, body)

export const getMobileUsers = (tenant) => doFetch('GET', `onboarding/${tenant}/mobile/users`);

export async function addMobileUser(tenant, params, fileType) {
  return doFetch('POST', `onboarding/${tenant}/mobile/users`, params, null, null, fileType);
}

export async function editMobileUser(tenant, params) {
  return doFetch('PUT', `onboarding/${tenant}/mobile/users`, params);
}

export async function deleteMobileUser(tenant, params) {
  return doFetch('DELETE', `onboarding/${tenant}/mobile/users`, params);
}

export async function getOnboardingContacts(tenant, params) {
  return doFetch('GET', `onboarding/${tenant}/users`, null, params);
}

export async function addOnboardingContact(tenant, params, fileType) {
  return doFetch('POST', `onboarding/${tenant}/users`, params, null, null, fileType);
}

export const addOnboardingSalesforceConnector = (tenant, params, fileType) => {
    const file1 = fileType['certificate_file'];
    const file2 = fileType['private_key'];

    return doFetchSalesforce('POST', `onboarding/${tenant}/connectors`, params, file1, file2)
}


export const editOnboardingSalesforceConnector = (tenant, params, connectorId, fileType) => {
    const file1 = fileType['certificate_file'];
    const file2 = fileType['private_key'];

    return doFetchSalesforce('PATCH', `onboarding/${tenant}/connectors/${connectorId}`, params, file1, file2)
}

export async function editOnboardingContact(tenant, params) {
  return doFetch('PUT', `onboarding/${tenant}/users`, params);
}

export async function deleteOnboardingContact(tenant, params) {
  return doFetch('DELETE', `onboarding/${tenant}/users`, params);
}

export const patchOnboardingUserAccess = (tenant, params) => doFetch('PATCH', `onboarding/${tenant}/users/access`, params)

export async function getEndpointAgentData(tenant) {
  return doFetch('GET', `onboarding/${tenant}/endpoints`);
}

export async function getOnboardingConnectors(tenant) {
  return doFetch('GET', `onboarding/${tenant}/connectors`);
}

export async function addOnboardingConnector(tenant, params) {
  return doFetch('POST', `onboarding/${tenant}/connectors`, params);
}

export async function testOnboardingConnector(tenant, connectorId) {
  return doFetch('GET', `onboarding/${tenant}/connectors/test/${connectorId}`);
}

export async function editOnboardingConnector(tenant, params, connectorId) {
  return doFetch('PUT', `onboarding/${tenant}/connectors/${connectorId}`, params);
}

export async function deleteOnboardingConnector(tenant, connectorId) {
  return doFetch('DELETE', `onboarding/${tenant}/connectors/${connectorId}`);
}

export async function getTenantOnboardingData(tenant, isExport) {
  return doFetch('GET', `connections/${tenant}/onboard/summary`, null, null, isExport);
}
export const getTickets = (tenant, params, isExport = false) => doFetch('GET', `tickets/${tenant}`, null, params, isExport);
export const updateTenantData = (tenant, params, type) => doFetch(type, `onboarding/tenant/${tenant}`, params);

export const deleteTenantVM = (tenant, hostname) => doFetch('DELETE', `onboarding/tenant/${tenant}/vm/${hostname}`);
export const postNewTenant = (tenant, params, type) => doFetch(type, `onboarding/tenant/${tenant}`, params);
export const getCSPolicies = (tenant) => doFetch('GET', `onboarding/${tenant}/cs-policies`);
export const updateCSPolicies = (tenant, params) => doFetch('PATCH', `onboarding/${tenant}/cs-policies`, params);
export const postNewChildTenant = (tenant, params) => doFetch('POST', `onboarding/tenant/${tenant}/child`, params);

export const resendInviteOnboardingContact = (tenant, params) => doFetch('POST', `onboarding/${tenant}/users/resend-invite`, params);
export const getSalesForceAccounts = (tenant) => doFetch('GET', `onboarding/${tenant}/sfaccounts`)
export const getVulnerabilityScans = (tenant) => doFetch('GET', `onboarding/${tenant}/vulnerability-scans`);
export const addVulnerabilityScan = (tenant, params) => doFetch('POST', `onboarding/${tenant}/vulnerability-scans`, params);
export const editVulnerabilityScan = (tenant, params, id) => doFetch('PATCH', `onboarding/${tenant}/vulnerability-scans/${id}`, params);
export const deleteVulnerabilityScan = (tenant, id) => doFetch('DELETE', `onboarding/${tenant}/vulnerability-scans/${id}`);
export const getAvailableScanDays = (tenant) => doFetch('GET', `onboarding/${tenant}/vulnerability-scans/days`);

export const firewallOnboardingAPI = async (type, tenant, body) => doFetch(type, `onboarding/${tenant}/firewalls`, body);
export const editFirewallOnboarding = async (type, tenant, id, body) => doFetch(type, `onboarding/${tenant}/firewalls/${id}`, body);

export const createReport = async (tenant) => doFetch('POST', `reports/${tenant}`);

export const azkgGraphData = async (tenant, params) => doFetch('GET', `azkg/${tenant}`, null, params);
export const initiateLLMPreset = async (tenant, body) => doFetch('POST', `azkg/${tenant}/llm2`, body);
export const pollLLMPreset = async (tenant, body) => doFetch('POST', `azkg/${tenant}/fetch_s3`, body);
export const azkgLLMData = async (tenant, body) => doFetch('POST', `azkg/${tenant}/llm1`, body);

export const createTicket = (tenant, body) => doFetch('POST', `tickets/${tenant}`, body);
export const updateTicket = (tenant, body, caseId) => doFetch('PATCH', `tickets/${tenant}/${caseId}`, body);
export const deleteTicket = (tenant, caseId) => doFetch('DELETE', `tickets/${tenant}/${caseId}`);
export const getTicketComments = (tenant, caseId) => doFetch('GET', `tickets/${tenant}/${caseId}/comment`);
export const addTicketComment = (tenant, caseId, body) => doFetch('POST', `tickets/${tenant}/${caseId}/comment`, body);
export const getTicketAttachments = (tenant, caseId) => doFetch('GET', `tickets/${tenant}/${caseId}/attachments`);
export const addTicketAttachments = async (tenant, caseId, files, body, user_email) => {
  const response = await doFetch('POST', `tickets/${tenant}/${caseId}/attachments`, body)
  for (let i in files) {
    const upload_url = response.upload_urls.find(urlInfo => urlInfo.file_name === files[i].name).url;
    await fetch(upload_url, {
      method: "PUT",
      body: files[i],
      headers: {
        'x-amz-meta-creator-email': user_email,
        'content-type' : 'binary/octet-stream'
      },
    });
  }
}

export const getNetworkData = (tenant, params, isExport) => doFetch('GET', `network/${tenant}`, null, params, isExport);
export const getNetworkTrends = (tenant, params, isExport) => doFetch('GET', `network/${tenant}/trends`, null, params, isExport);
export const getNetworkMetrics = (tenant, isExport) => doFetch('GET', `network/${tenant}/metrics`,  null, null, isExport);

export const getTrends = (tenant, type) => doFetch('GET', `${type}/${tenant}/trends`);
export const getVulnerabilityMetrics = (tenant) => doFetch('GET', `vulnerabilities/${tenant}/metrics`);

export const getCSResponsePreference = (tenant) => doFetch('GET', `onboarding/${tenant}/cs-response-pref`);
export const updateCSResponsePreference = (tenant, body, id) => doFetch('PATCH', `onboarding/${tenant}/cs-response-pref/${id}`, body);

export const getChildTenantOfferings = (tenant) => doFetch('GET', `tenants/${tenant}/children-offerings`);

//new vulnerabilities page
export const getOverviewData = (tenant) => doFetch('GET', `vulnerabilities/${tenant}/overview`)
export const getVulnerabilitiesTable = (tenant, params, isExport = false) => doFetch('GET', `vulnerabilities/${tenant}/list-vulnerabilities`, null, params, isExport);
export const getVulnerabilitiesExpandedData = (tenant, id, params) => doFetch('GET', `vulnerabilities/${tenant}/list-vulnerabilities/${id}`, null, params);
export const getAssetsAndDetailsTable = (tenant, params) => doFetch('GET', `vulnerabilities/${tenant}/list-assets`, null, params);
export const getAssetsAndDetailsExpandedData = (tenant, id, params) => doFetch('GET', `vulnerabilities/${tenant}/list-assets/${id}`, null, params);
export const getAssetsAndDetailsPatchesData = (tenant, id, params) => doFetch('GET', `vulnerabilities/${tenant}/list-assets/${id}/missing-patches`, null, params);
export const getVulnerabilitiesExport = (tenant, params) => doFetch('GET', `vulnerabilities/${tenant}/list-vulnerabilities/export`, null, params)
export const getVulnerabilitiesExportList = (tenant, params) => doFetch('GET', `vulnerabilities/${tenant}/list-vulnerabilities/list-exports`, null, params)
export const postUpdateActionOrComment = (tenant, vulnerability_id, body) => doFetch('POST', `vulnerabilities/${tenant}/list-vulnerabilities/${vulnerability_id}/action`, body)
export const postUpdateAssetsActionOrComment = (tenant, asset_name, body) => doFetch('POST', `vulnerabilities/${tenant}/list-assets/${asset_name}/action`, body)

export const resetUserAccount = (tenant) => doFetch('POST', `onboarding/${tenant}/users/reset-account`);

export const initiateAIEResponse = (tenant, body) => doFetch('POST', `aie/${tenant}/adhoc`, body);
export const pollAIEResponse = (tenant, id) => doFetch('GET', `aie/${tenant}/session/${id}`);
export const getAIEDetectionInvestigation = (tenant, id) => doFetch('GET', `aie/${tenant}/detection/${id}`);
