import React, { useState, useEffect } from "react";
import { Loader } from "../../components/Loader/Loader";
import ResetAccount from "./ResetAccount";
import "../../styles/pages/Preferences/Preferences.scss";

const Preferences = ({ route }) => {
  const [currPage, setCurrPage] = useState("reset-account");
  const [loading] = useState();  
  const [sideMenuOptions, setSideMenuOptions] = useState([]);
  const setPage = (page) => {
    setCurrPage(page);
    window.history.pushState(null, "", `/preferences/${page}`);
  };

  useEffect(() => {
    let userOptions = ["reset-account"];
    setSideMenuOptions([]); // TODO: change this line when we have more options (only 2FA right now, so we hide the side menu)
    let defaultPage =
      route && userOptions.includes(route) ? route : userOptions[0];
    setPage(defaultPage);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={"preferences-container"}>
      <div className={"header-row"}>
        <p className={"page-title"}>PREFERENCES</p>
      </div>
      {loading ? (
        <div className="root-loader">
          <Loader />
        </div>
      ) : (
        sideMenuOptions.length ?
        <div className="side-bar">
          <ul className="side-options">
            {sideMenuOptions.map((opt) => (
              <li
                key={opt}
                onClick={() => setPage(opt)}
                className={`side-opt ${currPage === opt && "selected"}`}
              >
                {opt.split("-").join(" ")}
              </li>
            ))}
          </ul>
        </div> : <></>
      )}
    {currPage === "reset-account" && <ResetAccount />}
    </div>
  );
};

export default Preferences;
