export const filterConfig = [
    {
      Status: {
        filter_name: "status",
        isOpen: true,
        filters: {
          PASS: false, FAIL: true, WARNING: false, INFO: false
        }
      },
    },
    {
      Severity: {
        filter_name: "severity",
        isOpen: true,
        filters: {Critical: false, High: false, Medium: false, Low: false, Informational: false}
      }
    }
]

export const resetFilterConfig = {
    Status: {
      filter_name: "status",
      isOpen: true,
      filters: {PASS: false, FAIL: true, WARNING: false, INFO: false}
    },
    Severity: {
      filter_name: "severity",
      isOpen: true,
      filters: {
        Critical: false, High: false, Medium: false, Low: false, Informational: false
      }
    }
}

export const topIssuesFilterConfig = {
  Status: {
    filter_name: "status",
    isOpen: true,
    filters: {PASS: false, FAIL: true, WARNING: false, INFO: false}
  },
  Severity: {
    filter_name: "severity",
    isOpen: true,
    filters: {
      Critical: false, High: false, Medium: false, Low: false, Informational: false
    }
  }
}

export default filterConfig