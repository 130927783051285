import React, {useContext, useState, useLayoutEffect, useRef} from "react";
import {capitalize, formatTime} from "../../util/format";
import SeverityImage from "../../components/Table/SeverityImage";
import Row from "../../components/Table/Row";
import ExpandedView from "../Detections/ExpandedView";
import Dropdown from "../../components/Dropdown/Dropdown";
import ActionComment from "./ActionComment";
import {postActionAndComment} from "../../api/ApiClient";
import {actionTooltipConfig} from "./config/actionConfig";
import Tooltip from "../../components/Tooltip/Tooltip";
import {hasWriteAccess} from "../../util/userControl";
import UserData from "../../store/User/UserData";
import {Loader} from "../../components/Loader/Loader";
import Checkbox from "@mui/material/Checkbox";
import { captureSentryError } from "../../util/sentry";

const VulnerabilitiesRow = ({
                              data,
                              mapMainRow,
                              page,
                              severities,
                              statuses,
                              expandedIndexes,
                              setExpandedIndexes,
                              expandAll,
                              setExpandAll,
                              leftColumn,
                              rightColumn,
                              index,
                              getTableData,
                              selectedIDs,
                              selectActionCheckbox,
                            }) => {
  const [showDropdown, toggleShowDropdown] = useState(false)
  const [selectedAction, setSelectedAction] = useState(data.Action || 'New Risk')
  const [showComment, toggleShowComment] = useState(false)
  const [comment, setCurrentComment] = useState(data?.Comment || '')
  const vulnID = data['Vulnerability ID'] || ''
  const [userData] = useContext(UserData);
  const [existingComment, setExistingComment] = useState(data?.Comment || '')
  const [loading, setLoading] = useState(false);
  const [titleTooltip, showTitleTooltip] = useState(false);
  const titleContainerRef = useRef();
  const titleTextRef = useRef();
  const actionOptions = [
    {'Accept Risk': false},
    {'Defer Risk': false},
    {'In-Progress': false},
    {'Ignore': false},
  ]

  useLayoutEffect(()=> {
    const titleResizeObserver = new ResizeObserver(() => setTimeout(() => {
      if(titleTextRef.current?.offsetWidth > (titleContainerRef.current?.clientWidth * 0.75) - 5) showTitleTooltip(true) // css width is 75%
      else showTitleTooltip(false)
    }), 0);
    titleResizeObserver.observe(titleContainerRef.current);
    return () => titleResizeObserver.disconnect();
  }, [])

  const handleClick = (e) => {
    if (e.target.type !== 'checkbox') {
      if (!expandedIndexes.includes(index)) {
        setExpandedIndexes([...expandedIndexes, index])
        setExpandAll(expandAll.filter(x => x !== index))
      } else {
        setExpandedIndexes(expandedIndexes.filter(x => x !== index))
      }
    }
  }

  const openActionDropdown = (value, e) => {
    if (e) {
      e.stopPropagation();
      toggleShowDropdown(value)
    } else toggleShowDropdown(value)
  }

  const postAction = async (action) => {
    setLoading(true)
    try {
      const body = {action: action || selectedAction, comment: comment}
      await postActionAndComment(userData?.tenant, vulnID, body);
      setExistingComment(comment)
      toggleShowComment(false)
    } catch (e) {
      captureSentryError(e, userData, "postActionAndComment API in VulnerabilitiesRow.js");
    } finally {
      await getTableData()
      setLoading(false)
    }
  }

  const selectActionFromDropdown = async (value, e) => {
    e.stopPropagation();
    setSelectedAction(value)
    openActionDropdown(!showDropdown, e)
    await postAction(value)
  }

  const toggleCommentModal = (value, e) => {
    if(e) e.stopPropagation();
    if (value === true) {
      toggleShowComment(value)
    } else if (value === false || value === '') {
      setExistingComment(comment)
      toggleShowComment(false)
    }
  }

  return (
    <>
      <Row page={page} onClick={(e) => handleClick(e)} data={data}>
        <td className={"timestamp td-value"}>
          {expandedIndexes.includes(index) ?
            <div className={"expand-btn minus"}>-</div>
            : <div className={"expand-btn"}>+</div>
          }
          {formatTime(data.timestamp)}
        </td>
        <td className={"Family td-value"}>
          <Tooltip content={data.Family} className="vuln-family" direction={"bottom"}>
             <span className="text-contained" ref={titleTextRef}>
              {data.Family}
             </span>
          </Tooltip>
        </td>
        <td className={"Severity"}>
          <SeverityImage severities={severities} severity={data.severity}/>
        </td>
        {Object.values([mapMainRow['Source'], mapMainRow['Name'], mapMainRow['IP Address'], mapMainRow['CVE']])
          .map((item, index) => {
            let value = data[item.field]
            if (item.field === 'IP Address' && data['Port']) {
              value = `${data[item.field]}:${data['Port']}`
            }
            if (item.field === 'Report Source') {
              value = capitalize(data[item.field])
            }

            return (
              <td key={index} className={item.field + " td-value"}>
                {item.field === "Name" ? (
                  <div className="title-container" ref={titleContainerRef}>
                    {titleTooltip ? (
                      <Tooltip content={value} className="vuln-name" direction={"bottom"}>
                        <span className="text contained" ref={titleTextRef}>
                          {value}
                        </span>
                      </Tooltip>
                    ) : (
                      <span className="text" ref={titleTextRef}>
                        {value}
                      </span>
                    )}
                  </div>
                ) : (
                  <div>{value}</div>
                )}
              </td>
            );
          })}
        <td className={"Action td-value"}>
          <Checkbox
            key={index}
            className="custom-checkbox"
            checked={selectedIDs.includes(data['Vulnerability ID'])}
            onChange={(e) => selectActionCheckbox(e, data['Vulnerability ID'])}
          />
          <div className="dropdown-sort dropdown-container">
            {loading && <Loader/>}
            <Tooltip
              content={actionTooltipConfig[selectedAction] || ''}
              className={"title-tooltip " + showDropdown}
              direction={"bottom"}
              type={"action-value"}
            >
              {selectedAction === 'Risk Mitigated' || !hasWriteAccess(userData?.role) ?
                <p className={"risk-mitigated-title"}>{selectedAction}</p>
                : <Dropdown
                  className={"action-dropdown"}
                  name={selectedAction}
                  dropdownList={actionOptions}
                  selectItem={selectActionFromDropdown}
                  selectedItem={selectedAction}
                  showDropdown={showDropdown}
                  setShowDropdown={openActionDropdown}
                />}
            </Tooltip>
            <div
              onClick={(e) => toggleCommentModal(!showComment, e)}
              className={"open-comment"}
            >{(selectedAction === 'Risk Mitigated' || comment.length > 0 || !hasWriteAccess(userData?.role)) ? 'VIEW COMMENT' : 'ADD COMMENT'}
            </div>
          </div>
        </td>
      </Row>
      {showComment && <ActionComment
        loading={loading}
        readOnly={selectedAction === 'Risk Mitigated' || !hasWriteAccess(userData?.role)}
        existingComment={existingComment}
        setExistingComment={setExistingComment}
        close={toggleCommentModal}
        commentValue={comment}
        submitComment={postAction}
        setCurrentComment={setCurrentComment}
      />}
      {(expandedIndexes.includes(index) || expandAll.includes(index)) &&
      <ExpandedView
        source={data?.source}
        data={data}
        status={data.status}
        statuses={statuses}
        leftColumn={leftColumn}
        rightColumn={rightColumn}
      />}
    </>
  );
}

export default VulnerabilitiesRow;
