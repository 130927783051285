import React from "react";
import {formatTime} from "../../util/format";
import SeverityImage from "../../components/Table/SeverityImage";
import Row from "../../components/Table/Row";
import StatusBar from "../../components/Table/StatusBar";

const DashboardRow = ({data, page, severities, statuses, onClick}) => {
  return (
    <Row page={page} onClick={onClick} data={data}>
      <td>
        <div className={"left-row"}>
          <div>
            <p className={"date"}>{formatTime(data.created_date)}</p>
            <p className={"source"}>{data.source}</p>
          </div>
        </div>
      </td>
      <td className="severity-col">
        <SeverityImage severities={severities} severity={data.severity}/>
      </td>
      <td className="title-target-col">
        <p className={"value top"}>
          {data.title}
        </p>
        <p className={"value"}>
          {data.affected_resource}
        </p>
      </td>
      <td className="status-bar-col">
        {(data.hasOwnProperty('status') || data.hasOwnProperty('case_status')) && data.case_number &&
        <StatusBar statuses={statuses} selectedStatus={data.status || data['case_status']}/>
        }
      </td>
    </Row>
  );
};

export default DashboardRow;
