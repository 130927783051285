import React from 'react';
import '../../styles/components/Table/PaginationControls.scss';
import ReactPaginate from "react-paginate";

const Pagination = ({total, page, setPage, pageSize = 50}) => {

  const handlePageClick = ({selected: selectedPage}) => {
    let selected = selectedPage + 1
    setPage(selected);
  }

  return (
    <ReactPaginate
      forcePage={page - 1}
      previousLabel={"<"}
      nextLabel={">"}
      pageCount={Math.ceil(total / pageSize)}
      onPageChange={handlePageClick}
      marginPagesDisplayed={1}
      pageRangeDisplayed={2}
      containerClassName={"pagination"}
      disabledClassName={"pagination-disabled"}
      activeClassName={"pagination-active"}
    />
  )
}

export default Pagination;
