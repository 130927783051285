import React from 'react';
import TextField from '@mui/material/TextField';
import '../../styles/components/Dropdown/DatePickerComponent.scss';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';

const DatePickerComponent = ({
                               value,
                               handleChange,
                               title,
                               isOpen,
                               setIsOpen,
                               setIsSelected,
                               minDate,
                               maxDate,
                               type,
                               togglePageFilters,
                               invalidDateFormat,
                               setInvalidDateFormat,
                               newVulnerabilities = false,
                               handleNewVulnerabilitiesChange
                             }) => {
  const onChange = (newValue, keyboardInputValue, date_regex) => {
    keyboardInputValue !== undefined && setIsSelected('')
    if (keyboardInputValue !== undefined && !date_regex.test(keyboardInputValue)) {
      // Alert user
      setInvalidDateFormat(true)
    } else {
      (newValue < minDate || newValue > maxDate) ? setInvalidDateFormat(true) : setInvalidDateFormat(false)
      handleChange({time: newValue, text: ''})
    }
  };

  return (
    <div className={"date-picker-container"}>
      {title && <p>{title}</p>}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {type !== 'log-search' ?
          <DatePicker
            minDate={minDate}
            maxDate={maxDate}
            value={!!(['Current', 'Previous'].some(val => val === value.text) || (type?.includes('maturity-page') && value.time === '')) ? null
              : value.time} // Null time display for vulnerabilities page
            open={isOpen}
            onOpen={() => {
              setIsSelected('');
              setIsOpen(true);
              newVulnerabilities && handleNewVulnerabilitiesChange();
            }}
            onClose={() => setIsOpen(false)}
            closeOnSelect={false}
            onChange={(newValue, keyboardInputValue) => onChange(newValue, keyboardInputValue, /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/)}
            renderInput={(params) => <TextField variant="standard"
                                                inputProps={{className: `date-picker-validation`}} {...params}/>}
            InputProps={{
              disableUnderline: true,
              className: `date-picker-input ${invalidDateFormat}`
            }}
            disabled={togglePageFilters}
          /> :
          <DateTimePicker
            minDate={minDate}
            maxDate={maxDate}
            renderInput={(params) => <TextField variant="standard" {...params}/>}
            value={(type?.includes('maturity-page') && value.time === '') ? null : value.time}
            onChange={(newValue, keyboardInputValue) => onChange(newValue, keyboardInputValue, /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}\s(1[0-2]|0?[1-9]):([0-5]\d)\s(am|pm)$/)}
            InputProps={{
              disableUnderline: true,
              className: `date-picker-input ${invalidDateFormat}`
            }}
            open={isOpen}
            views={['year', 'day']}
            onOpen={() => {
              setIsSelected('');
              setIsOpen(true)
            }}
            onClose={() => setIsOpen(false)}
            closeOnSelect={false}
          />
        }
      </LocalizationProvider>
    </div>
  )
}

export default DatePickerComponent
