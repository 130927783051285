const dropdownStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#D0F31B' : '#fff',
    padding: 10,
    cursor: 'pointer',
    backgroundColor: '#14373f',
    border: state.isFocused ? "1px solid #018D8D" : "1px solid #002C34",
  }),
  control: () => ({
    marginTop: '5px',
    width: 200,
    color: '#D0F31B',
    display: 'flex',
    border: '1px solid #FAFBFB',
    cursor: 'pointer'
  }),
  singleValue: (provided) => {
    const color = '#D0F31B';
    return {...provided, color};
  },
  input: (provided) => {
    const color = '#fff';
    return {...provided, color};
  },
  menuList: base => ({
    ...base,
    padding: 0,
    marginTop: '-8px'
  })
}

export default dropdownStyles
