import React from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { Loader } from "../Loader/Loader";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IconButton from "@mui/material/IconButton";

const ConfirmDialogCell = ({message, confirmFunc, cancelFunc, rowError, rowLoading}) => {
  return (
    <td className="confirm-cell">
        {rowError && !rowLoading && (
          <div className="error row">
            <ErrorIcon className="err-icon" /> {rowError}
          </div>
        )}
        {rowLoading ? (
          <Loader />
        ) : (
          <div className="confirm-dialog">
            <span>{message}</span>
            <IconButton className="confirm" onClick={() => confirmFunc()}>
              <CheckCircleOutlineIcon className="color" />
            </IconButton>
            <IconButton className="cancel" onClick={() => cancelFunc()}>
              <HighlightOffIcon className="color" />
            </IconButton>
          </div>
        )}
      </td>
  );
};

export default ConfirmDialogCell;