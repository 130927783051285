import React from "react";
import "../../../../styles/pages/Onboarding/Cloud/AccountTable.scss";
import { Loader } from "../../../../components/Loader/Loader";
import ErrorIcon from "@mui/icons-material/Error";

const AccountTable = ({ rows, loading, dataError }) => (
  <table className="cloud-onboarding-table">
    <tbody>
      {loading ? (
        <tr className="no-data">
          <td>
            <Loader />
          </td>
        </tr>
      ) : dataError ? (
        <div className="error">
          <ErrorIcon className="err-icon" /> {dataError}
        </div>
      ) : rows?.length ? (
        rows
      ) : <p className={"no-accounts-text"}>"No accounts added yet"</p>}
    </tbody>
  </table>
);

export default AccountTable;
