import React, {useRef, useState} from "react";
import CaretDown from '../../assets/icons/caret-down.svg';
import {useOnClickOutside} from "../../util/clickOutside";

const presetMenu = [
  {label: "Show all detections", value: "all_detections"},
  {label: "Show all vulnerabilities", value: "all_vulnerabilities"},
  {label: "Show O365 logins from endpoints without EDR", value: "o365_login_no_edr"},
  {label: "Show endpoint detections", value: "endpoint_detections",},
  {label: "Show cloud and identity detections", value: "cloud_identity_detections"},
  {label: "Show the most highly connected IPs", value: "highly_connected_ips"},
  {label: "Show non-central network subgraphs", value: "non_central_network_subgraphs"},
  {label: "Show any RDP connections from endpoints without EDR", value: "rdp_endpoint_connections_no_edr"},
  {label: "Show any potentially unprotected endpoints", value: "unprotected_endpoints"},
];

const PresetDropdown = ({currValue, setValue, setSelectedByPreset}) => {
  const [openModal, setOpenModal] = useState(false)
  const hasSelectedValue = (currValue !== '' && currValue !== 'None')
  const ref = useRef();

  const onPresetSelect = (value) => {
    setSelectedByPreset(true)
    setValue(value)
    setOpenModal(false)
  }

  useOnClickOutside(() => setOpenModal(false), ref);

  return (
    <div className={"preset-container"} ref={ref}>
      <div onClick={() => setOpenModal(!openModal)} className={"label-container"}>
        <span className={hasSelectedValue ? 'label-selected' : ''}>
          Starting Points
        </span>
        {!hasSelectedValue && <img className={"caret"} src={CaretDown} alt="select"/>}
        {hasSelectedValue && <span className={"current-selection"}>{currValue.label}</span>}
      </div>
      {openModal === true && <div className={"preset-modal"}>
        <p className={"preset-item"} onClick={() => onPresetSelect('')}>None</p>
        {presetMenu.map((preset) => (
          <p onClick={() => onPresetSelect(preset)} className={"preset-item"} key={preset.value}>
            {preset.label}
          </p>
        ))}
      </div>}
    </div>
  );
};

export default PresetDropdown;
