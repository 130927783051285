import React, {useState} from "react";
import '../../styles/pages/Vulnerabilities/CommentModal.scss'
import {Loader} from "../../components/Loader/Loader";
import {Modal} from "../../components";

const CommentModal = ({type, submit, selectedIDs, resetModal, bulkComment, setBulkComment}) => {
  const [loading, setLoading] = useState(false)

  const submitComment = async () => {
    setLoading(true)
    await submit({vulnerability_ids: selectedIDs, comment: bulkComment})
    resetModal()
    setLoading(false)
  }

  return (
    <Modal className={"bulk-comment-container"} close={resetModal}>
      <p className="input-label">Bulk {type}</p>
      <textarea
        value={bulkComment || ""}
        className={`input-box notes`}
        onChange={(e) => setBulkComment(e.target.value)}
        placeholder={"Enter comment here"}
      />
      <div className={"flex comment-text"}>
        {type === 'Edit Comments' ? <p>EDITS TO THIS COMMENT WILL BE APPLIED TO ALL SELECTED VULNERABILITIES</p> :
          type === 'Add Comments' && <p>THIS COMMENT WILL BE ADDED TO ALL SELECTED VULNERABILITIES</p>}
        {loading ? <Loader/> : !loading && (type === 'Edit Comments' ?
          <div className="add-btn" onClick={() => submitComment()}>UPDATE</div> :
          type === 'Add Comments' && <div className="add-btn" onClick={() => submitComment()}>ADD</div>)}
      </div>
    </Modal>
  );
};
export default CommentModal;
