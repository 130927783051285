import React, {useContext, useEffect, useState, useRef} from 'react';
import GlobalState from "../../store/GlobalState/GlobalState";
import {Pagination} from "../../components";
import UserData from "../../store/User/UserData";
import '../../styles/pages/Detections/Detections.scss';
import {Loader} from "../../components/Loader/Loader";
import filterConfig, {
  emptyFilterConfig,
  resetFilterConfig,
  resetEmptyFilterConfig,
  filterConfigFromParent
} from "./config/filterconfig";
import LineChart from "../../components/D3/LineChart/LineChart";
import LineChartLegend from "../../components/D3/LineChart/Legend";
import {getDetections, getDetectionsTrends, getDetectionsMetrics} from "../../api/ApiClient";
import PaginationControls from "../../components/Table/PaginationControls";
import {severities} from "../../config/mappingData";
import PageFilters from "../../components/Filters/PageFilters";
import {filterData} from "../../util/format.js"
import {downloadFile} from "../../util/export"
import {leftColumn, mainRow, rightColumn} from "../../components/Mappings/Detections"
import {useLocation} from 'react-router-dom';
import DataList from "./DataList";
import moment from 'moment';
import Table from "../../components/Table/Table";
import TableHeader from "../../components/Table/TableHeader";
import DetectionsRow from "./DetectionsRow";
import {mapChartData} from "../../util/mapChartData";
import {setSortFilterValue} from "../../util/handleSortResults";
import {setResetFilter} from "../../util/handleResetFilter";
import {trackPageView} from "../../util/analytics";
import Tooltip from "../../components/Tooltip/Tooltip";
import {tooltipMapping} from "../../config/tooltipTextMapping";
import NotFound from "../../navigation/NotFound";
import { useSearchParams } from "react-router-dom"
import _ from 'lodash';
import { captureSentryError } from '../../util/sentry.js';

const Detections = () => {
  const location = useLocation() // Used to grab state from react router state
  const [queryParameters, setQueryParameters] = useSearchParams();
  const defaultID = queryParameters.get("id") || location.state?.id;
  const [userData] = useContext(UserData);
  const [pageCount] = useState(50)
  const [page, setPage] = useState(1)
  const today = new Date()
  const [selectedDate, setSelectedDate] = useState(defaultID ? '' : 'Last 30 Days')
  const defaultStartTime = new Date(new Date().setDate(today.getDate() - 30))
  const [defaultDate, setDefaultDate] = useState(defaultID ? null : {
    text: 'Last 30 Days',
    time: defaultStartTime
  })
  const [detectionsData, setDetectionsData] = useState({results: [], total: 0})
  let [sortFilters, setSortFilters] = useState([])
  const [filterList, setOpenFilters] = useState(defaultID ? emptyFilterConfig : localStorage?.onParentClick ?
    filterConfigFromParent : filterConfig)
  const [detectionsLoading, setDetectionsLoading] = useState(false)
  const [detectionsTrendLoading, setDetectionsTrendLoading] = useState(true)
  const [detectionsMetrics, setDetectionsMetrics] = useState({})
  const [mappedGraphData, setMappedGraphData] = useState([])
  const [mappedSourceGraphData, setMappedSourceGraphData] = useState([])
  const [showDropdown, toggleShowDropdown] = useState('')
  const [expandAll, setExpandAll] = useState([])
  const [detectionsParams, setDetectionsParams] = useState({})
  const [detectionsTrendsParams, setDetectionsTrendsParams] = useState({})
  const [axisData, setAxisData] = useState({x: {min: 0, max: 0}, y: {max: 0}})
  const [axisSourceData, setAxisSourceData] = useState({x: {min: 0, max: 0}, y: {max: 0}})
  const [toggleMetricsFilter, setToggleMetricsFilter] = useState(false)
  const statuses = [{'Re-Opened': '#FFE133'}, {'Waiting_On_ActZero': '#d0f31b'}, {'Waiting_On_Customer': '#FFA415'}, {'Resolved': '#018D8D'}, {'Merged': '#018D8D'}, {'Closed': '#018D8D'}]
  const [expandedIndexes, setExpandedIndexes] = useState(location.state?.expandIndex === undefined ? [] : [location.state?.expandIndex]);
  const [initialConfig, setInitialConfig] = useState(
    defaultID ? 
    {page: 1, size: pageCount, q: defaultID } 
    : {start_time: moment(defaultStartTime).format(), end_time: moment(today).format(), page: 1, size: pageCount})
  const [azState] = useContext(GlobalState);
  let [allData, setAllData] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [isReset, setIsReset] = useState(false)
  const [fetchError, setFetchError] = useState(false);
  const initialDataLoaded = useRef(false);
  const prevParams = useRef({});

  const lineData = {
    lineOpacity: "1",
    lineOpacityHover: "1",
    otherLinesOpacityHover: "0.3",
    lineStroke: "3px",
    lineStrokeHover: "5px"
  };

  const colors = {
    Critical: "#D64526",
    High: "#FFA415",
    Medium: "#FFE133",
    Low: "#638185",
    Informational: "#066666"
  };

  const sourceColors = {
    Endpoint: "#D0F31A",
    Cloud: "#028D8D",
    Network: "#FFE133",
    Mobile: "#638185",
    Identity: "#826738"
  };

  const severitiesValues = [
    {name: 'Critical', values: []},
    {name: 'High', values: []},
    {name: 'Medium', values: []},
    {name: 'Low', values: []},
    {name: 'Informational', values: []}
  ]

  const sourceValues = [
    {name: 'Endpoint', values: []},
    {name: 'Cloud', values: []},
    {name: 'Network', values: []},
    {name: 'Mobile', values: []},
    {name: 'Identity', values: []}
  ]

  useEffect(() => {
    queryParameters.get("id") && !initialDataLoaded.current && setExpandedIndexes([0]);
  }, [queryParameters])

  const resetAllFilters = async () => {
    setIsReset(true)
    handleClose()
    let config = {
      start_time: moment(defaultStartTime).format(),
      end_time: moment(today).format(),
      page: 1,
      size: pageCount,
      severity: ['Critical', 'High']
    }
    setDetectionsParams(config)
    setInitialConfig(config)
    setResetFilter(filterConfig, resetFilterConfig, setOpenFilters)
    setSortFilterValue([], mainRow, setSortFilters)
    setDefaultDate({
      text: 'Last 30 days',
      time: defaultStartTime
    })
    setDetectionsTrendsParams({"page": 1, "size": 50, "severity": ["Critical", "High"]})
    window.history.replaceState('', location.state?.id)
    setSelectedDate('Last 30 Days')
    setIsReset(false)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => trackPageView("Detections", userData, azState), [userData?.userId, azState.reactGaInitializedState]);

  useEffect(() => {
    const getDetectionsTrendData = async () => {
      setDetectionsTrendLoading(true)
      try {
        let detectionsTrendResponse = await getDetectionsTrends(userData?.tenant, detectionsTrendsParams)
        let detectionsMetricsResponse = await getDetectionsMetrics(userData?.tenant)
        mapChartData(detectionsTrendResponse.monthly_trend_by_severity, setAxisData, setMappedGraphData, severitiesValues, [])
        mapChartData(detectionsTrendResponse.monthly_trend_by_source, setAxisSourceData, setMappedSourceGraphData, sourceValues, [])
        setDetectionsMetrics(detectionsMetricsResponse)
      } catch (e) {
        captureSentryError(e, userData, 'getDetectionsTrendResponse API in Detections.js');
      } finally {
        setDetectionsTrendLoading(false)
      }
    }
    userData?.tenant && Object.keys(detectionsTrendsParams).length > 0 && getDetectionsTrendData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.tenant, userData?.userType, detectionsTrendsParams]);

  useEffect(() => {
    const getDetectionData = async () => {
      setFetchError(false);
      let isExport = detectionsParams.isExport || false
      if (isExport) {
        setDetectionsParams({...detectionsParams, isExport: false})
        setExportLoading(true)
      } else setDetectionsLoading(true)
      try {
        const detectionsResponse = await getDetections(userData?.tenant, detectionsParams, isExport)
        const mapResponseData = detectionsResponse?.results.map(obj =>
          obj["Identity Subsource"] === "Active Directory" && obj["Detection Type"].startsWith("ad-")
            ? { ...obj, "Detection Type": obj["Detection Type"].replace(/^ad-/, 'AD-') }
            : obj
        );
        if (isExport) {
          downloadFile(detectionsResponse)
          setExportLoading(false)
        } else {
          setDetectionsData(detectionsResponse)
          setAllData(mapResponseData)
        }
        document.getElementsByTagName("body")[0]?.scrollTo(0, azState?.scrollYPosition)
      } catch (e) {
        captureSentryError(e, userData, 'getDetectionsResponse API in Detections.js');
        setFetchError(true);
      } finally {
        localStorage.removeItem('onParentClick');
        setDetectionsLoading(false)
      }
    }
    if(!_.isEqual(prevParams.current, detectionsParams)) {
      userData?.tenant && Object.keys(detectionsParams).length > 0 && getDetectionData()
      prevParams.current = detectionsParams;
    }
    // eslint-disable-next-line
  }, [userData?.tenant, userData?.userType, detectionsParams]);

  const handleChangePage = async (currPage) => {
    setPage(currPage)
    setDetectionsParams({...detectionsParams, page: currPage})
  };

  useEffect(() => {
    setPage(1);
    setExpandedIndexes([]);
    filterData(filterList, detectionsParams, pageCount, 1, setDetectionsParams, initialConfig, setInitialConfig)
    filterData(filterList, detectionsTrendsParams, pageCount, 1, setDetectionsTrendsParams)
    // eslint-disable-next-line
  }, [filterList, pageCount])


  const handleClose = () => {
    setExpandAll([])
    setExpandedIndexes([])
  }

  useEffect(() => {
    setSortFilterValue([], mainRow, setSortFilters)
    // So filters don't hide searched detection
    defaultID && setResetFilter(filterConfig, resetEmptyFilterConfig, setOpenFilters)
  }, [defaultID])

  useEffect(() => {
    if (initialDataLoaded.current) {
      setQueryParameters({})
    }
    else {
      initialDataLoaded.current = true;
    }
    // eslint-disable-next-line
  }, [detectionsParams])

  return (
    <div className={"detections-page-container all-page-container"}>
      <div className={"header-row"}>
        <Tooltip content={tooltipMapping.detections.title} className={"title-tooltip"} direction={"right"}>
          <p className={"page-title"}>DETECTIONS</p>
        </Tooltip>
      </div>
      {detectionsTrendLoading ?
        <div className={"loader-container"}><Loader/></div>
        :
        <div className={`top-container ${toggleMetricsFilter}`} onClick={() => setToggleMetricsFilter(false)}>
          <div className={"graph-container" + (mappedGraphData.length === 0 ? ' no-data' : '')}>
            <div className={`title-and-legend ${toggleMetricsFilter}`}>
                <span className={`expand-btn ${toggleMetricsFilter}`} onClick={(e) => {
                  e.stopPropagation();
                  setToggleMetricsFilter(!toggleMetricsFilter)
                }}>{toggleMetricsFilter ? '+' : '-'}</span>
              <p className={`graph-title  ${toggleMetricsFilter}`}>TOTAL BY SEVERITY</p>
            </div>
            <LineChartLegend
              colors={colors}
              data={mappedGraphData}
            />
            {mappedGraphData.length > 0 ?
              <LineChart
                width={550}
                plotRadius={2}
                yAxisTicks={4}
                xAxisTicks={mappedGraphData[0].values.length}
                lineData={lineData}
                data={mappedGraphData}
                className={"severity-line-chart"}
                axis={axisData}
                widthOffset={.7}
                height={115}
                marginLeft={50}
                colors={colors}
              /> : <p className={"no-data"}>No Data</p>
            }
          </div>
          <div className={"graph-container right" + (mappedSourceGraphData.length === 0 ? ' no-data' : '')}>
            <div className={`title-and-legend right ${toggleMetricsFilter}`}>
              <p className={`graph-title right ${toggleMetricsFilter}`}>TOTAL BY SOURCE</p>
            </div>
            <LineChartLegend
              colors={sourceColors}
              position={'right'}
              data={mappedSourceGraphData}
            />
            {mappedSourceGraphData.length > 0 ?
              <LineChart
                width={550}
                plotRadius={2}
                yAxisTicks={4}
                xAxisTicks={mappedSourceGraphData[0].values.length}
                lineData={lineData}
                data={mappedSourceGraphData}
                className={"source-line-chart"}
                axis={axisSourceData}
                widthOffset={.7}
                height={115}
                marginLeft={50}
                colors={sourceColors}
                position={'right'}
              /> : <p className={"no-data right"}>No Data</p>
            }
          </div>
          <DataList data={detectionsMetrics} metricsExpanded={toggleMetricsFilter}/> 
        </div>}
      <PageFilters
        isReset={isReset}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        handleClose={handleClose}
        filterList={filterList}
        setOpenFilters={setOpenFilters}
        setParams={setDetectionsParams}
        setMetricParams={!isReset ? setDetectionsTrendsParams : null}
        setExpandAll={setExpandAll}
        initialSearch={defaultID}
        collapsible={true}
        defaultDate={(defaultID && !isReset) ? {} : defaultDate}
      />
      {
        !detectionsLoading &&
        <PaginationControls
          resetAllFilters={resetAllFilters}
          setExpandAll={setExpandAll}
          displayData={detectionsData}
          page={page}
          setPage={handleChangePage}
          expandAll={expandAll}
          showDropdown={showDropdown}
          toggleShowDropdown={toggleShowDropdown}
          setParams={setDetectionsParams}
          params={detectionsParams}
          showSortDropdown={false}
          setExpandedIndexes={setExpandedIndexes}
          expandedIndexes={expandedIndexes}
          bauExportLoading={exportLoading}
        />
      }
      {detectionsLoading ?
        <div className={"loader-container"}><Loader/></div>
        : fetchError ? <NotFound isError dataError /> 
        : !detectionsLoading && detectionsData.results.length === 0 ?
          <div>
            <p className={'line-filter'}/>
            <p className={'no-results-text'}>No results, try expanding your filter parameters.</p>
          </div>
          :
          <div>
            <div className="fixed-table-container">
              <Table page={"detections"}>
                <TableHeader
                  mainRow={mainRow}
                  className={"detections"}
                  setSortFilters={setSortFilters}
                  sortFilters={sortFilters}
                  setParams={setDetectionsParams}
                  params={detectionsParams}
                />
                {
                  allData.map((item, i) => <DetectionsRow
                    key={i}
                    severities={severities}
                    page={"detections"}
                    data={item}
                    mapMainRow={mainRow}
                    mapLeftColumn={leftColumn}
                    mapRightColumn={rightColumn}
                    statuses={statuses}
                    onClick={async () => {
                      
                    }}
                    index={i}
                    setExpandedIndexes={setExpandedIndexes}
                    expandedIndexes={expandedIndexes}
                    setExpandAll={setExpandAll}
                    expandAll={expandAll}
                    isExpanded={(expandedIndexes?.includes(i) || expandAll?.includes(i))}
                    setQueryParameters={setQueryParameters}
                  />)
                }
              </Table>
            </div>
            <div className={"pagination-controls"}>
              <Pagination
                setPage={handleChangePage}
                page={page}
                total={detectionsData.total}
              />
            </div>
          </div>
      }
    </div>
  )
}

export default Detections;
