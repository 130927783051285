import React from "react";
import LoaderIcon from '../../assets/icons/ajax-loader.svg';
import './Loader.scss';

export const Loader = ({className}) => {
  return (
    <div className={`page-loading d-flex justify-content-center relative-loader ${className}`}>
      <img src={LoaderIcon} alt="loading"/>
    </div>
  )
}
