import React, { useContext, useEffect, useState, useRef } from "react";
import "../../styles/pages/KnowledgeGraph/KnowledgeGraph.scss";
import ForceDirectionGraph from "../../components/D3/ForceDirectionGraph/ForceDirectionGraph3D";
import GraphControls from "./GraphControls";
import GraphFilters from "./GraphFilters";
import { azkgGraphData, azkgLLMData, pollLLMPreset } from "../../api/ApiClient";
import { toMotifFormat } from "./config/convertData";
import UserData from "../../store/User/UserData";
import { getDefaultFilters, categorizeFilters } from "./config/filterConfig";
import { captureSentryError } from "../../util/sentry";
import ErrorRobot from "../../assets/icons/error-robot.svg";
import { CssLoader } from "../../components/Loader/CssLoader";

const KnowledgeGraph = () => {
  const [userData] = useContext(UserData);
  const [data, setData] = useState({ nodes: [], links: [] });
  const [selectedTenant, setSelectedTenant] = useState(userData?.tenant);
  const [loading, setLoading] = useState(true);
  const [startTime, setStartTime] = useState(
    userData?.tenant === "hackedbox"
      ? 1675231200
      : new Date(new Date().setDate(new Date().getDate() - 30)).getTime() / 1000
  );
  const [endTime, setEndTime] = useState(
    userData?.tenant === "hackedbox" ? 1677650400 : Date.now() / 1000
  );
  const [selectedFilters, setSelectedFilters] = useState(() =>
    getDefaultFilters()
  );
  const [selectedPreset, setSelectedPreset] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedByPreset, setSelectedByPreset] = useState(false);
  const [graphError, setGraphError] = useState(false);
  const [dimensions, setDimensions] = useState(2); // 2D or 3D graph
  const latestRequestId = useRef(0);

  const getFilters = (updatedFilters) => {
    let filters = selectedFilters;
    if (updatedFilters) filters = updatedFilters;
    let categorizedFilters = { start_time: startTime, end_time: endTime };
    if (selectedPreset?.value) {
      categorizedFilters = {
        ...categorizedFilters,
        preset_query: selectedPreset?.value,
      };
    } else {
      filters = categorizeFilters(filters);
      categorizedFilters = { ...categorizedFilters, ...filters };
    }
    if (selectedByPreset) {
      setSelectedByPreset(false);
      let tempSelectedFilters = {
        ...Object.keys(selectedFilters).reduce(
          (reduced, key) => ({
            ...reduced,
            [key]: true,
          }),
          {}
        ),
      };
      setSelectedFilters({ ...tempSelectedFilters });
    } else {
      setSelectedPreset("");
    }
    return categorizedFilters;
  };

  const fetchAndFormatData = async (updatedFilters) => {
    const requestId = ++latestRequestId.current;
    setLoading(true);
    try {
      let filters = getFilters(updatedFilters);
      const results = await azkgGraphData(selectedTenant, {
        ...filters,
        size: 1000,
      });
      const convertedData = toMotifFormat(results?.body);
      const seenLinks = new Map();
      convertedData.links = convertedData.links.filter(
        ({ id }) => !seenLinks.has(id) && seenLinks.set(id, true)
      );
      if (requestId === latestRequestId.current) setData(convertedData);
    } catch (e) {
      captureSentryError(e, userData, "ZeroIn GET graph data");
    } finally {
      if (requestId === latestRequestId.current) setLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedTenant) setData({});
    selectedTenant && fetchAndFormatData();
    // eslint-disable-next-line
  }, [selectedTenant, startTime, endTime, selectedPreset]);

  useEffect(() => {
    if (userData?.tenant !== selectedTenant) {
      setSelectedTenant(userData?.tenant);
    }
    // eslint-disable-next-line
  }, [userData?.tenant]);

  const getLLMData = async () => {
    setLoading(true);
    try {
      const results = await azkgLLMData(selectedTenant, {
        question: searchTerm,
      });
      const response = await pollSummaryData(results);
      if (!response.includes("Unable to create valid cypher query")) {
        setData(toMotifFormat(JSON.parse(response)));
      }
    } catch (e) {
      captureSentryError(e, userData, "/llm1 getLLMData");
    } finally {
      setLoading(false);
    }
  };

  const pollSummaryData = async (pollingId) => {
    try {
      const results = await pollLLMPreset(userData?.tenant, pollingId);
      if (results.answer) {
        return results.answer;
      } else {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        return pollSummaryData(pollingId);
      }
    } catch (e) {
      setGraphError(true);
      captureSentryError(e, userData, "fetch_s3 pollSummaryData");
    }
  };

  const handleDimensionSwitch = () => {
    setLoading(true);
    setDimensions(dimensions === 3 ? 2 : 3);
    data.nodes.forEach((node) => {
      if (node.fx) {
        delete node.fx;
        delete node.fy;
        delete node.fz;
      }
    });
    setData(data);
    setTimeout(() => setLoading(false), 10); // triggers re-render of data and d3 forces
  };

  return (
    <div className={"knowledge-graph-container"}>
      <div className={"fdg-container"}>
        {graphError === true ? (
          <img src={ErrorRobot} className="robot error-img" alt="Error" />
        ) : loading ? (
          <div className="loader-container">
            <CssLoader className={"loader-custom relative"} />
          </div>
        ) : (
          <ForceDirectionGraph
            data={data}
            dimensions={dimensions}
            setDimensions={handleDimensionSwitch}
          />
        )}
        <GraphControls
          setEndTime={setEndTime}
          setStartTime={setStartTime}
          tenant={selectedTenant}
        />
      </div>
      <GraphFilters
        setSelectedByPreset={setSelectedByPreset}
        fetchAndFormatData={fetchAndFormatData}
        selectedTenant={selectedTenant}
        loading={loading}
        setSelectedFilters={setSelectedFilters}
        selectedFilters={selectedFilters}
        selectedPreset={selectedPreset}
        setSelectedPreset={setSelectedPreset}
        getLLMData={getLLMData}
        setSearchTerm={setSearchTerm}
      />
    </div>
  );
};

export default KnowledgeGraph;
