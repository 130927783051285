import React from "react";
import "../../styles/components/Modal/OptionsModal.scss";
import Modal from "./Modal";
import LoaderButton from "../Button/LoaderButton";
import ErrorIcon from "@mui/icons-material/Error";
import Checkbox from "@mui/material/Checkbox";
import { Loader } from "../Loader/Loader";

const OptionsModal = ({
  closeFunction,
  header,
  description,
  className,
  successMessage,
  submitFunction,
  loading,
  error,
  options,
  buttonText = "SUBMIT",
  selection,
  setSelection,
  formLabel,
  fetchLoading,
}) => {
  return (
    <div className="options-modal-container">
      <Modal
        close={() => closeFunction()}
        className={`options-modal ${className}`}
      >
        <div className="modal-header">{header}</div>

        <div className="modal-content">
          {fetchLoading ? (
            <Loader className="modal-loader"/>
          ) : successMessage ? (
            <div className="success-content">{successMessage}</div>
          ) : (
            <>
              <div className={"description"}>{description}</div>
              <div className="radio-form">
                <div className="form-label">{formLabel}</div>
                <div className="options-container">
                  {options.map((option) => (
                    <div className="option">
                      <Checkbox
                        key={option.name}
                        className="custom-checkbox"
                        checked={selection.includes(option.name)}
                        onChange={(e) => {
                          setSelection((prev) => {
                            if (prev.includes(option.name)) {
                              return prev.filter((el) => el !== option.name);
                            } else {
                              return [...prev, option.name];
                            }
                          });
                        }}
                      />
                      <div className="label">{option.value}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="footer">
                {error && (
                  <div className={`submit-error`}>
                    <ErrorIcon className="err-icon" />
                    {"An error has occurred."}
                  </div>
                )}
                <LoaderButton
                  loading={loading}
                  onClick={() => submitFunction(selection)}
                  text={buttonText}
                  className="save-btn"
                />
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default OptionsModal;
