import React from "react";
import VirtualMachineTable from "./VirtualMachineTable";
import VirtualMachineInfo from "./VirtualMachineHeader";

const VirtualMachine = () => {
  return (
    <div className="virtual-machine-container">
      <p className={"onboarding-title contacts"}>VIRTUAL MACHINE</p>
      <VirtualMachineInfo/>
      <VirtualMachineTable/>
    </div>);
};

export default VirtualMachine;
