import React, {useContext, useEffect, useState} from "react";
import FrameworkFilters from "./FrameworkFilters";
import PageFilters from "../../components/Filters/PageFilters";
import {getOverallMAData} from "../../api/ApiClient";
import UserData from "../../store/User/UserData";
import {Loader} from "../../components/Loader/Loader";
import ProgressHeader from "./ProgressHeader";
import '../../styles/pages/MaturityAssessment/Filters.scss';
import { captureSentryError } from "../../util/sentry";

const Filters = ({
                   handleClose,
                   filterList,
                   setExpandAll,
                   selectCheckbox,
                   setTableParams,
                   frameworkOpenFilters,
                   selectFramework,
                   loadingNote,
                   selectedDate,
                   setSelectedDate,
                   isReset,
                   defaultDate
                 }) => {
  const [userData] = useContext(UserData);
  const [loading, setLoading] = useState(false)
  const [filterData, setFilterData] = useState([])
  const [overall, setOverall] = useState({})
  const [toggleRow, setToggleRow] = useState(false)

  useEffect(() => {
    const getFilterData = async () => {
      setLoading(true)
      let params = {framework: []}
      Object.entries(frameworkOpenFilters[0].FRAMEWORK.filters).forEach(item => {
        item[1] === true && params.framework.push(item[0])
      })
      try {
        let response = await getOverallMAData(userData?.tenant, params)
        let overallValue = Math.round(response.overall.percent_completed)
        setOverall({name: 'maturity-assessment', value: overallValue / 100, stringValue: overallValue + '%'})
        setFilterData(response)
      } catch (e) {
        captureSentryError(e, userData, "getOverallMAData API in Filters.js");
      } finally {
        setLoading(false)
      }
    }
    (userData?.tenant && !loadingNote) && getFilterData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.tenant, frameworkOpenFilters, loadingNote])

  return (
    <div className={`maturity-assessment top-container ${toggleRow}`}>
      {Object.keys(overall).length > 0 &&
      <ProgressHeader data={overall} setToggleRow={setToggleRow} toggleRow={toggleRow}/>}
      <div className={"filters-container"}>
        <div className={"framework-filters"}>
          {!loading ? filterData?.frameworks?.length > 0 && frameworkOpenFilters.map((filter, i) =>
            <FrameworkFilters
              key={i}
              data={filterData.frameworks}
              filter={filter}
              selectCheckbox={selectFramework}
            />) : <Loader/>}
        </div>
        <PageFilters
          type={"maturity-page"}
          isReset={isReset}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          className={"maturity-assessment"}
          handleClose={handleClose}
          filterList={filterList}
          setOpenFilters={selectCheckbox}
          setParams={setTableParams}
          setExpandAll={setExpandAll}
          defaultDate={defaultDate}
        />
      </div>
    </div>
  )
}

export default Filters
