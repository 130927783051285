import React from "react";
import Checkmark from "../../../assets/icons/checkmark_done.svg";
import CloseIcon from "@mui/icons-material/Close";

export const tableHeaders = {
  name: "NAME",
  endpointsCount: "ENDPOINTS",
  contractedCount: "CONTRACTED",
  cloudCount: "CLOUD",
  mobileCount: "MOBILE",
  identityCount: "IDENTITY",
  logVolume: "LOG VOLUME",
  evm: "EVM",
  deviceControl: "DEVICE CONTROL",
  onboardingStatus: "ONBOARDING",
};

export const getEnabledIcon = (enabled) =>
  enabled ? (
    <img className="tenants-checkmark" src={Checkmark} alt="Yes" />
  ) : (
    <CloseIcon className="tenants-close-icon" />
  );