import "../../../styles/pages/Onboarding/VirtualMachine/VirtualMachineTable.scss";
import React, {useState} from "react";
import {hasWriteAccess} from "../../../util/userControl";
import IconButton from "@mui/material/IconButton";
import TrashIcon from "../../../assets/icons/trash-icon.svg";
import ConfirmDialogCell from "../../../components/Table/ConfirmDialogCell";

const VirtualMachineTableRow = ({data, setTableData, userData, handleDeleteVM}) => {
  const [pendingDelete, setPendingDelete] = useState()
  const tableHeaders = {
    hostname: "HOSTNAME",
    ip_address: "LOCAL IP",
    public_ip_address: "EXTERNAL HOST IP",
    vm_roles: "TYPE",
    hypervisor_version: "HYPERVISOR",
  };

  const mapType = {
    cspm: 'CSPM', log_collector: 'Log Collector', vulnerability_scanning: 'Vulnerability Scanning'
  }

    return (
        <>
          {data.map((row) =>
              <tr>
                {Object.keys(tableHeaders).map((col) =>
                    <td key={`${row.email}${col}`}>{col === 'vm_roles' ? row[col].map(item => mapType[item]).join(', ')
                        : row[col]}</td>)}
                {pendingDelete?.hostname === row.hostname &&
                pendingDelete?.ip_address === row.ip_address &&
                pendingDelete?.public_ip_address === row.public_ip_address ? (
                    <ConfirmDialogCell
                        message={"Confirm?"}
                        confirmFunc={() => handleDeleteVM(row)}
                        cancelFunc={setPendingDelete}
                    />
                ) : (
                    <td className="icon-container">
                      {hasWriteAccess(userData?.role) && <IconButton
                          onClick={() =>
                              setPendingDelete({
                                hostname: row.hostname,
                                ip_address: row.ip_address,
                                public_ip_address: row.public_ip_address
                              })
                          }
                      >
                        <img src={TrashIcon} alt="Delete" className="delete-btn"/>
                      </IconButton>}
                    </td>
                )}
              </tr>
          )}
        </>
    );
  };

export default VirtualMachineTableRow;