import {agentLogos} from "./config";
import DownloadIcon from "../../../assets/icons/download-icon-highlight.svg";
import React from "react";
import "../../../styles/pages/Onboarding/Endpoints/AgentsList.scss";

const AgentList = ({
                     setCurrentModal,
                     endpointAgentData,
                     currentModal,
                     expandModal,
                     downloadModalRef,
                     setExpandModal
                   }) => {
  return (
    <div className="agents-list">
      <span className="section-label">Download endpoint agent</span>
      {agentLogos.map((os, i) => (
        <div
          className={`download-btn ${os.name}`}
          key={i}
          onClick={os.isModal ? () => setCurrentModal((prevState) => prevState === os.name ? null : os.name) : null}
        >{os.isModal ? (<img src={os.svg} alt={`View ${os.name} agent list`} className={`icon ${os.name}`}/>) :
          (<a href={endpointAgentData?.download_urls[os.name][0].link} target="_blank" rel="noreferrer">
            <img src={os.svg} alt={`View ${os.name} agent list`} className={`icon ${os.name}`}/>
          </a>)}
          <img src={DownloadIcon} alt="" className="download-icon"/>
        </div>
      ))}
      {currentModal && (
        <div className={`download-versions-modal ${expandModal ? "expanded" : ""}`} ref={downloadModalRef}>
          <div className="download-options">
            <div className="modal-header">
              {`${currentModal.charAt(0).toUpperCase() + currentModal.slice(1)} endpoint agent download`}
            </div>
            <ul>
              {endpointAgentData?.download_urls[currentModal].slice(0, !expandModal ? 5 :
                endpointAgentData?.download_urls[currentModal].length).map((v, i) =>
                <li className="download-option" key={i}>
                  <a href={v.link} target="_blank" rel="noreferrer">
                    <div className="os-name">{v.name}</div>
                    <img src={DownloadIcon} alt="" className="download-icon-small"/>
                  </a>
                </li>
              )}
            </ul>
          </div>
          {!expandModal ? <div className="more-btn" onClick={() => setExpandModal(true)}>{"MORE »"}</div> :
            <div className="more-btn" onClick={() => setExpandModal(false)}>{"« LESS"}</div>}
        </div>
      )}
    </div>
  )
}

export default AgentList
