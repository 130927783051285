import React, {useEffect, useRef} from "react";
import "../../../../styles/pages/Onboarding/Cloud/AddServiceModal.scss";
import {useOnClickOutside} from "../../../../util/clickOutside";

const AddCloudModal = ({otherTabs, setSelectedTabs, close, title}) => {
  const ref = useRef()

  useOnClickOutside(() => close(false), ref)

  useEffect(() => {
    otherTabs.length === 0 && close(false)
  }, [otherTabs, close])

  return (
    <div className={"add-service-modal"} ref={ref}>
      <p className={"add-service-title"}>{title}</p>
      {otherTabs.map((tab, i) =>
        <p
          className={"pointer"}
          onClick={() => setSelectedTabs(tab, tab.display)}
          key={i}
        >{tab.display}</p>
      )}
    </div>
  )
};

export default AddCloudModal;
