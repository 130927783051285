export const leftColumn = {
    "Endpoint-CrowdStrike": {},
    "Endpoint-Defender": {
        "Detection Details": {
            "Detection Time": {"field": "Detection Time", "transform": "formatTime"},
            "Detection ID": {"field": "Detection ID"},
            "Tactic": {"field": "Tactic"},
            "Technique": {"field": "Technique"},
            "Detection Type": {"field": "Detection Type"},
            "Description": {"field": "Description"}
        },
        "Host Details": {
            "Hostname": {"field": "Hostname"},
            "Username": {"field": "Username"},
            "MachineID": {"field": "MachineID"}
        }
    },
    "Endpoint-SentinelOne": {
        "Detection Details": {
            "Detection Time": {"field": "Detection Time", "transform": "formatTime"},
            "Mitigation Status": {"field": "Mitigation Status", "transform": "capitalizeAndFormat"},
            "Classification": {"field": "Classification"},
            "Threat Indicators": {"field": "Indicators", "transform": "formatIndicators", "display": "Threat Indicators"},
        },
        "Host Details": {
            "Hostname": {"field": "Hostname"},
            "Username": {"field": "Username"},
            "Local IP": {"field": "Local IP"},
            "Local IP Version": {"field": "Local IP Version"},
            "Endpoint Type": {"field": "Endpoint Type", "transform": "capitalizeAndFormat"},
            "OS Name": {"field": "OS Name"},
            "Agent Version": {"field": "Agent Version"},
            "Machine Network Status": {"field": "Machine Network Status", "transform": "capitalizeAndFormat"},
            "Agent ID": {"field": "Agent ID"}
        }
    },
    "Network": {"": {
            "Detection Time": {"field": "Detection Time", "transform": "formatTime"},
            "Detection ID": {"field": "Detection ID"},
            "Firewall": {"field": "Firewall"},
            "Source IP": {"field": "Source IP"},
            "Source City": {"field": "Source City"},
            "Source Country": {"field": "Source Country", "transform": "countryCodeToName"},
            "Destination Country": {"field": "Destination Country", "transform": "countryCodeToName"},
            "Source Reputation": {"field": "Source Reputation"},
            "Client IP": {"field": "Client IP"},
            "Distinct IPs Count": {"field": "Distinct IPs Count"},
            "Top Source IP List": {"field": "Source IP List", "display": "Top Source IP List", "transform": "formatPythonLists"},
            "Top Destination IP List": {"field": "Destination IP List", "display": "Top Destination IP List", "transform": "formatPythonLists"},
            "Hostname": {"field": "Hostname"},
            "Log file": {"field": "Log file"}
        }},
    "Cloud-AWS": {"": {
            "Cloud Subsource": {"field": "Cloud Subsource"},
            "Detection Time": {"field": "Detection Time", "transform": "formatTime"},
            "Detection ID": {"field": "Detection ID"},
            "Detection Type": {"field": "Detection Type"},
            "AWS Region": {"field": "AWS Region"},
            "AWS Account Id": {"field": "AWS Account Id"},
            "Event Name": {"field": "Event Name"}
        }},
    "Cloud-GuardDuty": {"": {
            "Cloud Subsource": {"field": "Cloud Subsource"},
            "Detection Time": {"field": "Detection Time", "transform": "formatTime"},
            "Detection ID": {"field": "Detection ID"},
            "Detection Type": {"field": "Detection Type"},
            "AWS Region": {"field": "AWS Region"},
            "AWS Account Id": {"field": "AWS Account Id"}
        }},
    "Cloud-O365": {"": {
            "Cloud Subsource": {"field": "Cloud Subsource"},
            "Service": {"field": "Service"},
            "Detection Time": {"field": "Detection Time", "transform": "formatTime"},
            "Detection ID": {"field": "Detection ID"},
            "Detection Type": {"field": "Detection Type"},
            "Detection Details": {"field": "Detection Details"},
            "Username": {"field": "Username"}
        }},
    "Cloud-GSuite": {"": {
            "Cloud Subsource": {"field": "Cloud Subsource"},
            "Detection Time": {"field": "Detection Time", "transform": "formatTime"},
            "Detection ID": {"field": "Detection ID"},
            "Detection Type": {"field": "Detection Type"},
            "Event Name": {"field": "Event Name"},
            "Username": {"field": "Username"}
        }},
    "Cloud-Salesforce": {"": {
            "Cloud Subsource": {"field": "Cloud Subsource"},
            "Detection Time": {"field": "Detection Time", "transform": "formatTime"},
            "Detection ID": {"field": "Detection ID"},
            "Detection Type": {"field": "Detection Type"},
            "Login Type": {"field": "Login Type"},
            "Message Type": {"field": "Message Type"},
            "Salesforce Username": {"field": "Salesforce Username"}
        }},
    "Identity-Okta": {"": {
            "Identity Subsource": {"field": "Identity Subsource"},
            "Detection Time": {"field": "Detection Time", "transform": "formatTime"},
            "Detection ID": {"field": "Detection ID"},
            "Detection Type": {"field": "Detection Type"},
            "Detection Details": {"field": "Detection Details"},
            "Device OS": {"field": "Device OS"},
            "Device Browser": {"field": "Device Browser"},
            "Number of Failed Login Attempts": {"field": "Number of Failed Login Attempts"},
            "Number of Successful Login Attempts": {"field": "Number of Successful Login Attempts"},
            "Authentication Details": {"field": "Authentication Details"}
        }},
    "Identity-Active Directory":{"" :{
            "Identity Subsource": {"field": "Identity Subsource"},
            "Detection Time": {"field": "timestamp", "transform": "formatTime", display: "Detection Time"},
            "Detection ID": {"field": "Detection ID"},
            "Detection Type": {"field": "Detection Type"},
            "Hostname":{"field": "Hostname"},
            "Computer Name": {"field": "Computer Name"},
            "Username": {"field": 'Username'},
        }},
    "Mobile": {"": {
            "Detection Time": {"field": "Detection Time", "transform": "formatTime"},
            "Detection ID": {"field": "Detection ID"},
            "Detection Type": {"field": "Detection Type"},
            "Threat Name": {"field": "Threat Name"}
        }}
}

export const rightColumn = {
    "Endpoint-SentinelOne": {
        "File Details": {
            "File Path": {"field": "File Path"},
            "File Name": {"field": "File Name"},
            "File Size": {"field": "File Size"},
            "File Extension Type": {"field": "File Extension Type"},
            "SHA1": {"field": "SHA1"},
            "MD5": {"field": "MD5"}
        },
        "Other Details": {
            "Scan Start Time": {"field": "Scan Start Time"},
            "Scan End Time": {"field": "Scan End Time"},
            "Detection ID": {"field": "Detection ID"},
            "Malicious Process Arguements": {"field": "Malicious Process Arguements"},
            "Reboot Required": {"field": "Reboot Required"},
            "Agent IpV6": {"field": "Agent IpV6"},
            "Agent IpV4": {"field": "Agent IpV4"},
            "External IP": {"field": "External IP"},
            "Detection Engines": {"field": "Detection Engines", "transform": "formatPythonLists", "skipStringify": true}
        }
    },
    "Endpoint-CrowdStrike": {
        "Detection Details": {
            "Detection Time": {"field": "Detection Time", "transform": "formatTime"},
            "Detection ID": {"field": "Detection ID"},
            "Severity": {"field": "Severity"},
            "Tactic": {"field": "Tactic"},
            "Technique": {"field": "Technique"},
            "Objective": {"field": "Objective"},
            "Detection Type": {"field": "Detection Type"},
            "Description": {"field": "Description"}},
        "Host Details": {
            "Local IP": {"field": "Local IP"},
            "Hostname": {"field": "Hostname"},
            "ComputerName": {"field": "ComputerName"},
            "MAC Address": {"field": "MAC Address"},
            "OS Detail": {"field": "OS Detail"},
            "Machine Domain": {"field": "Machine Domain"},
            "Username": {"field": "Username"},
            "Agent ID": {"field": "Agent ID"}},
        "File Details": {
            "SHA256": {"field": "SHA256"},
            "MD5": {"field": "MD5"},
            "File Name": {"field": "File Name"},
            "File Path": {"field": "File Path"},
            "File Quarantined": {"field": "File Quarantined"}},
        "Other Details": {
            "EventType": {"field": "EventType"},
            "EventUUID": {"field": "EventUUID"},
            "Event Score": {"field": "Event Score"},
            "DetectId": {"field": "DetectId"},
            "ExternalApiType": {"field": "ExternalApiType"}
        }},
    "Endpoint-Defender": {
        "File Details": {
            "Evidence": {"field": "Evidence"}
        },
        "Other Details": {
            "DetectId": {"field": "Detection ID"},
            "Detection URL": {"field": "Detection URL"},
            "Threat Info": {"field": "Threat Info"},
            "Resolved Time": {"field": "Resolved Time", "transform": "formatTime"},
            "Alert Creation Time": {"field": "Alert Creation Time", "transform": "formatTime"},
            "Last Event Time": {"field": "Last Event Time", "transform": "formatTime"},
            "First Event Time": {"field": "First Event Time", "transform": "formatTime"}
        }
    },
    "Network": {"": {
            "Destination IP": {"field": "Destination IP"},
            "Destination City": {"field": "Destination City"},
            "Destination Reputation": {"field": "Destination Reputation"},
            "Method": {"field": "Method"},
            "URI": {"field": "URI"},
            "Query String": {"field": "Query String"},
            "Response Code": {"field": "Response Code"},
            "Referrer": {"field": "Referrer"},
            "User Agent": {"field": "User Agent"},
            "IP list": {"field": "IP list"},
            "Origin Country": {"field": "Origin Country"},
            "Conections Count": {"field": "Connections Count"},
            "Connections Count Percentile": {"field": "Connections Count Percentile", "display": "Connections Count Score"},
            "Bytes Received": {"field": "Bytes Received", "transform": "abbreviateData"},
            "Bytes Received Percentile": {"field": "Bytes Received Percentile", "display": "Bytes Received Score"},
            "Bytes Sent": {"field": "Bytes Sent", "transform": "abbreviateData"},
            "Bytes Sent Percentile": {"field": "Bytes Sent Percentile", "display": "Bytes Sent Score"},
            "Aggregation Start Time": {"field": "Aggregation Start Time", "transform": "formatTime", "display": "Aggregation Start Time"},
            "Aggregation End Time": {"field": "Aggregation End Time", "transform": "formatTime", "display": "Aggregation End Time"},
        }},
    "Cloud-AWS": {"": {
            "Source IP": {"field": "Source IP"},
            "Source City": {"field": "Source City"},
            "Source Country": {"field": "Source Country"},
            "Source Reputation": {"field": "Source Reputation"},
            "User Identity": {"field": "User Identity"},
            "IAM Type": {"field": "IAM Type"}
        }},
    "Cloud-GuardDuty": {"": {
            "Description": {"field": "Description"},
            "Resources": {"field": "Resources"}
        }},
    "Cloud-O365": {"": {
            "Source IP": {"field": "Source IP"},
            "Source City": {"field": "Source City"},
            "Source Country": {"field": "Source Country"},
            "Source Reputation": {"field": "Source Reputation"},
            "24 Hour IP Address Analytics": {"field": "24 Hour IP Address Analytics"},
            "24 Hour IP Address Analytics Summary": {"field": "24 Hour IP Address Analytics Summary"},
            "24 Hour Exchange Analytics ": {"field": "24 Hour Exchange Analytics"},
            "24 Hour Exchange Analytics Summary": {"field": "24 Hour Exchange Analytics Summary"},
            "24 Hour Login Analytics": {"field": "24 Hour Login Analytics"},
            "24 Hour Login Analytics Summary": {"field": "24 Hour Login Analytics Summary"},
            "IDs Locked": {"field": "IDs Locked"},
            "Unique IPs": {"field": "Unique IPs"},
            "Detections": {"field": "Detections"},
            "Login Failures": {"field": "Login Failures"},
            "Login Successes": {"field": "Login Successes"},
            "Unique Countries": {"field": "Unique Countries"},
            "Unique IP Successes": {"field": "Unique IP Successes"},
            "Unique Country Successes": {"field": "Unique Country Successes"},
            "Invalid Usernames/Passwords": {"field": "Invalid Usernames/Passwords"}
        }},
    "Cloud-GSuite": {"": {
            "Source IP": {"field": "Source IP"},
            "Source City": {"field": "Source City"},
            "Source Country": {"field": "Source Country"},
            "Source Reputation": {"field": "Source Reputation"}
        }},
    "Cloud-Salesforce": {"": {
            "Source IP": {"field": "Source IP"},
            "Source City": {"field": "Source City"},
            "Source Country": {"field": "Source Country"},
            "Source Reputation": {"field": "Source Reputation"},
            "Source IP Username": {"field": "Source IP Username"},
            "Salesforce User ID": {"field": "Salesforce User ID"}
        }},
    "Identity-Okta": {"": {
            "Source IP": {"field": "Source IP"},
            "Source City": {"field": "Source City"},
            "Source Region": {"field": "Source Region"},
            "Source Country": {"field": "Source Country"},
            "Source Reputation": {"field": "Source Reputation"},
            "Source Type": {"field": "Source Type"},
            "Device Type": {"field": "Device Type"},
            "Context": {"field": "Context"}
        }},
    "Identity-Active Directory":{"" :{
            "Winlog Keywords": {"field": "Winlog Keywords"},
            "Detection Details": {"field": "Detection Details"},
        }},
    "Mobile": {"": {
            "Email": {"field": "Email"},
            "First Name": {"field": "First Name"},
            "Last Name": {"field": "Last Name"},
            "Phone Type": {"field": "Phone Type"},
            "OS": {"field": "OS"}
        }}
}

export const mainRow = {
    "Detection Time": {"field": "Detection Time", "header": "TIME", "sort": true, "defaultSortValue": false},
    "Source": {"field": "Source", "header": "SOURCE", "sort": true, "defaultSortValue": false},
    "Severity": {"field": "Severity", "header": "SEVERITY", "sort": true, "defaultSortValue": false},
    "Detection Type": {"field": "Detection Type", "header": "DETECTION TYPE", "sort": true, "defaultSortValue": false, "transform": "capitalize"},
    "Target": {"field": "Affected Resource", "header": "TARGET"},
    "Action": {"field": "Action", "header": "ACTION", "sort": true, "defaultSortValue": false},
    "Case Number": {"field": "Case Number", "navigate": "investigations", "header": "CASE NUMBER", "sort": true, "defaultSortValue": false}// eslint-disable-line no-template-curly-in-string
}
