import React, {useContext, useEffect, useState, useRef} from 'react';
import GlobalState from "../../store/GlobalState/GlobalState";
import UserData from "../../store/User/UserData";
import '../../styles/pages/Dashboard/Dashboard.scss';
import {getKeyMetrics, getRssFeed} from "../../api/ApiClient";
import {severities} from "../../config/mappingData";
import {getInvestigationData} from "../../util/getInvestigationsData";
import GenericDropdown from "../../components/Dropdown/GenericDropdown";
import {handleSortResults} from '../../util/handleSortResults';
import {Loader} from "../../components/Loader/Loader";
import KeyMetrics from "./KeyMetrics";
import {formatTimeFilters} from "../../util/format";
import {useNavigate} from "react-router-dom";
import Table from "../../components/Table/Table";
import DashboardRow from "./DashboardRow";
import VulnerabilitiesOverTime from "./VulnerabilitiesOverTime";
import ConnectionsPanel from "./ConnectionsPanel";
import {trackPageView} from "../../util/analytics";
import Tooltip from "../../components/Tooltip/Tooltip";
import {tooltipMapping} from "../../config/tooltipTextMapping";
import {filterConfig} from '../Investigations/config/filterConfig';
import _ from 'lodash';
import ControlsPanel from "./ControlsPanel";
import NotFound from "../../navigation/NotFound";
import KP1 from "./config/icons/kpi-1.svg";
import KP2 from "./config/icons/kpi-2.svg";
import KP3 from "./config/icons/kpi-3.svg";
import KP4 from "./config/icons/kpi-4.svg";
import NoAlertsPanel from "./NoAlertsPanel";
import { captureSentryError } from '../../util/sentry';

const Dashboard = () => {
  const [azState] = useContext(GlobalState);
  const [userData] = useContext(UserData);
  const statuses = [{'Re-Opened': '#FFE133'}, {'Waiting_On_ActZero': '#d0f31b'}, {'Waiting_On_Customer': '#FFA415'}, {'Resolved': '#018D8D'}, {'Merged': '#018D8D'}, {'Closed': '#018D8D'}]
  const [dashboardLoading, setDashboardLoading] = useState(false)
  const [threatsPanelLoading, setThreatsPanelLoading] = useState(false)
  const [sortFilters, setSortFilters] = useState({
    'Sort by': {
      filters: {
        Timestamp: false,
        Source: false,
        Severity: false
      }
    }
  })
  const [params, setParams] = useState({page: 1, size: 50})
  const [criticalThreats, setCriticalThreats] = useState(0)
  const [showDropdown, toggleShowDropdown] = useState('')
  const [dashboardData, setDashboardData] = useState({
    results: [],
    count: 0
  })
  let [allData, setAllData] = useState([])
  const navigate = useNavigate();
  let [rssData, setRssData] = useState([]);
  const [loadingKeyMetricsData, setLoadingKeyMetricsData] = useState(false)
  const isMDRLite = userData?.tenantConfig?.is_mdr_lite;
  const isMobileOnly = userData?.tenantConfig?.is_mobile_edr_only;
  const [keyMetricsData, setKeyMetricsData] = useState([])
  const [images, setImages] = useState([]);
  const [noAlertsData, setNoAlertsData] = useState([]);
  const [rssFetchError, setRSSFetchError] = useState(false);
  const [tableFetchError, setTableFetchError] = useState(false);
  const [keyMetricsFetchError, setKeyMetricsFetchError] = useState(false)
  const [connectionsFetchError, setConnectionsFetchError] = useState(false)
  const [controlsFetchError, setControlsFetchError] = useState(false)
  const inputRef = useRef({});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => trackPageView("Dashboard", userData, azState), [userData?.userId, azState.reactGaInitializedState]);

  useEffect(() => {
    if (!('investigationsInit' in inputRef.current) || (inputRef.current['investigationsInitFilters'] !== sortFilters)) {
      inputRef.current['investigationsInit'] = true
      inputRef.current['investigationsInitFilters'] = sortFilters
      userData?.tenant && Object.keys(params).length > 0 && getInvestigationData(setDashboardLoading, setDashboardData, setTableFetchError, userData, params, setAllData)
    }
  }, [userData, sortFilters, params]);

  useEffect(() => {
    const criticalParams = {
      page: 1,
      size: 50,
      severity: 'Critical',
      status: 'Waiting_On_Customer',
      start_time: formatTimeFilters(2592000).start_time,
      end_time: formatTimeFilters(2592000).end_time
    }
    if (!('investigationsCriticalInit' in inputRef.current)){
      inputRef.current['investigationsCriticalInit'] = true
      userData?.tenant && getInvestigationData(setDashboardLoading, setCriticalThreats, () => {}, userData, criticalParams)
    }
    const getRssData = async () => {
      setRSSFetchError(false);
      setThreatsPanelLoading(true);
      try {
        if (!('rssInit' in inputRef.current)){
          inputRef.current['rssInit'] = true
          let data = await getRssFeed();
          setRssData(data);
        }
      } catch(e){
        setRSSFetchError(true);
      }
      setThreatsPanelLoading(false);
    }
    getRssData()
  }, [userData])

  useEffect(() => {
    const getKeyMetricsData = async () => {
      setKeyMetricsFetchError(false);
      setLoadingKeyMetricsData(true)
      try {
        let keyMetricsResponse = await getKeyMetrics(userData?.tenant)
        let bgImages = [KP1, KP2, KP3, KP4]
        if ((isMDRLite || isMobileOnly) && bgImages.length > 3) {
          bgImages.splice(2, 1)
          keyMetricsResponse = keyMetricsResponse?.filter(item => item.name !== 'Ransomware Defense')
        }
        let km = ['Block Rate', 'Noise Reduction', 'MTTFR (Critical)', 'Ransomware Defense']
        let keyMetrics = keyMetricsResponse?.filter(item => km.includes(item.name))
        let noAlerts = keyMetricsResponse?.filter(item => !km.includes(item.name))
        keyMetrics?.map((item) => {
          if (item.name === 'MTTFR (Critical)') {
            item.stringValue = Math.round(item.value).toString() + 'm'
            item.value = item.value / 60
          } else {
            item.stringValue = Math.round(item.value).toString() + '%'
            item.value = item.value / 100
            if((item.name === 'Noise Reduction') && (item.value < 0)){
              item.stringValue = '- -'
            }
          }
          return item
        })
        setImages(bgImages)
        setNoAlertsData(noAlerts.reverse())
        setKeyMetricsData(keyMetrics)
      } catch (e) {
        captureSentryError(e, userData, 'getKeyMetrics API in Dashboard.js');
        setKeyMetricsFetchError(true);
      } finally {
        setLoadingKeyMetricsData(false)
      }
    }
    userData?.tenant && isMDRLite !== undefined && getKeyMetricsData()
    // eslint-disable-next-line
  }, [userData?.tenant, userData?.userType, isMDRLite, isMobileOnly])

  const onRowClick = (e, props) => {
    navigate("/activity/investigations", {
      state: {
        filters: {investigations: {initialConfig: {page: 1, size: 50, q: props.data.case_number}}},
        case: {id: props.data.case_id}
      }
    });
  };

  const onCriticalThreatsClick = () => {
    const alertFilterConfig = [...filterConfig];
    _.set(alertFilterConfig, '[0].Severity.filters.Critical', true);
    _.set(alertFilterConfig, '[2].Status.filters["Waiting On Customer"]', true);
    navigate('/activity/investigations', {state: {filters: {investigations: {initialFilters: alertFilterConfig}}}})
  }

  const setOpenSort = (name, showDropdown, toggleShowDropdown) => showDropdown === '' ? toggleShowDropdown(name) : toggleShowDropdown('')

  return (
    <div className={"dashboard-container"}>
      <div className={"left-dashboard"}>
        <KeyMetrics
          images={images}
          keyMetricsData={keyMetricsData}
          loadingKeyMetricsData={loadingKeyMetricsData}
          fetchError={keyMetricsFetchError}
          title={"KEY METRICS"}
        />
        {
          !loadingKeyMetricsData &&
          (noAlertsData.filter(item => item.name === 'Investigations' && (item.value === 0 || item.value === null)).length > 0
            ||userData?.tenant?.toLowerCase().includes('hackedbox')) &&
          <NoAlertsPanel
            data={noAlertsData}
          />
        }
        {!dashboardLoading && dashboardData?.results.length > 0 ?
          <div className={"dashboard-controls"}>
            <div className={"dashboard-info"}>
              <p className={"label"}>INVESTIGATIONS
                <span className={"result-count"}>{dashboardData?.total}</span>
              </p>
              <div className={"critical-threats"}>
                {criticalThreats.total > 0 && <p onClick={() => onCriticalThreatsClick()}>
                  <b>{criticalThreats.total} critical</b> waiting for you »</p>}
              </div>
            </div>
            <div className={showDropdown ? 'sort-by open' : 'sort-by'}>
              <GenericDropdown
                className={"dropdown-sort dropdown-container"}
                selectCheckbox={handleSortResults}
                name={'Sort by'}
                data={sortFilters}
                isOpen={showDropdown === 'Sort by'}
                dropdownList={sortFilters['Sort by'].filters}
                showDropdown={showDropdown}
                toggleShowDropdown={toggleShowDropdown}
                sortFilters={sortFilters}
                setSortFilters={setSortFilters}
                params={params}
                setParams={setParams}
                setOpenSort={setOpenSort}
              />
            </div>
          </div> :
          <div className={"dashboard-controls"}>
            <p className={"label"}>INVESTIGATIONS</p>
          </div>
        }
        <div>
          {dashboardLoading ? <Loader/> :
            tableFetchError ? <NotFound isError dataError className={"panel"}/> :
              !dashboardLoading && dashboardData?.results.length > 0 ?
                <div className={"dashboard-table-container"}>
                  <Table page={"dashboard"}>
                    {
                      allData.map((item, i) => <DashboardRow
                        key={i}
                        severities={severities}
                        page={"dashboard"}
                        data={item}
                        statuses={statuses}
                        onClick={onRowClick}
                      />)
                    }
                  </Table>
                </div> :
                <div>
                  <p className={'line-filter'}/>
                  <p className={'no-results-text'}>No results.</p>
                </div>
          }
        </div>
      </div>

      <div className={"right-dashboard"}>
        <VulnerabilitiesOverTime/>
        <div className={"connections-and-controls right-item"}>
          {connectionsFetchError && controlsFetchError ? (
            <NotFound isError dataError className={"panel"} />
          ) : (
            <>
              <ConnectionsPanel setFetchError={setConnectionsFetchError} />
              <ControlsPanel setFetchError={setControlsFetchError} />
            </>
          )}
        </div>
        {rssFetchError ? <NotFound isError dataError className={"panel"}/> :
          <div className={"critical-threats-list right-item"}>
            <Tooltip content={tooltipMapping.dashboard.criticalThreats} direction="bottom">
              <div className={"critical-threats-list-title"}> CRITICAL EMERGENT THREATS</div>
            </Tooltip>
            {threatsPanelLoading ? <Loader/> : !threatsPanelLoading && <div>
              {rssData['results'] && Object.values(rssData['results'].slice(-5)).map((item, index) => {
                return (<div key={index} className={"critical-threat-list-items"}>
                  <a className={"clickable-link"}
                     target="_blank" rel="noreferrer"
                     key={"a" + index}
                     href={item['link']}>{item['title']}
                  </a>
                </div>)
              })} </div>}
          </div>
        }
      </div>
    </div>
  )
}

export default Dashboard;
