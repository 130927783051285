import React, {useEffect, useState, useContext} from "react";
import "../../../styles/pages/Onboarding/Tenants/NewTenant.scss";
import UserData from "../../../store/User/UserData";
import {getSalesForceAccounts, postNewTenant} from "../../../api/ApiClient";
import LoaderIcon from "../../../assets/icons/ajax-loader.svg";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Select from 'react-select';
import dropdownStyles from "./config/dropdownstyles";
import {Loader} from "../../../components/Loader/Loader";
import { captureSentryError } from "../../../util/sentry";

const NewTenant = ({account={}}) => {
  const [userData] = useContext(UserData);
  const [salesForceLoading, setSalesForceLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [submitComplete, setSubmitComplete] = useState(false)
  const [salesForceData, setSalesForceData] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(account);
  const [submitError, setSubmitError] = useState("")

  useEffect(() => {
    const getSalesForceAccountList = async () => {
      setSalesForceLoading(true)
      try {
        let salesforceAccounts = await getSalesForceAccounts(userData?.tenant);
        if (salesforceAccounts?.total > 0) {
          let salesforceOptions = []
          salesforceAccounts?.results?.map((account) => {
            salesforceOptions.push({value: account, label: account.account_name})
            return account
          });
          setSalesForceData(salesforceOptions);
        }
      } catch (e) {
        captureSentryError(e, userData, "getSalesForceAccounts API in NewTenant.js");
      } finally {
        setSalesForceLoading(false)
      }
    }
    userData?.tenant && getSalesForceAccountList();
    // eslint-disable-next-line
  }, [userData?.tenant]);

  const submitTenant = async () => {
    setSubmitError("")
    setSubmitComplete(false)
    if (selectedAccount?.value) {
      setSubmitLoading(true)
      try {
        await postNewTenant(userData?.tenant, selectedAccount.value, "POST");
        setSubmitComplete(true)
        setTimeout(() => {
          setSubmitComplete(false);
        }, 2000);
      } catch (e) {
        setSubmitError(e?.message);
        captureSentryError(e, userData, "postNewTenant API in NewTenant.js");
      } finally {
        setSubmitLoading(false)
      }
    } else {
      setSubmitError("Please select an account")
    }
  }

  return (
    <div className="tenant-onboarding-container">
      <div className={"header-row"}>
        <h3 className={"page-title"}>CREATE NEW TENANT</h3>
      </div>
      <div className={"dropdown-container"}>
        <div className={"new-tenant-container"}>
          {selectedAccount?.value?.tenant_id && 
            <div className={"tenant-name-container"}>
              <div className={"tenant-name-title"}>Tenant Name</div>
              <div className={"tenant-name-value"}>{selectedAccount?.value?.tenant_id}</div>
            </div>
          }
          <div className={"salesforce-container"}>
            <div className={"salesforce-title"}>Salesforce Account</div>
            {salesForceLoading ? <Loader/> : 
              <Select
                id="combo-box-tenants"
                className={"tenant-account-selection"}
                classNamePrefix={"react-select"}
                options={salesForceData}
                styles={dropdownStyles}
                onChange={(event) => {setSelectedAccount(event); setSubmitError("")}}
              />
            }
          </div>
          {submitError && !submitLoading && (
            <div className={"message-container error"}>
              <ErrorIcon className={"message-icon error"}/> <span className={"message-text error"}>{submitError}</span>
            </div>
          )}
          <div className={`message-container success ${submitComplete ? 'shown' : 'hidden'}`}>
              <CheckCircle className={"message-icon success"}/><span className={"message-text success"}> Tenant creation request submitted successfully. It will be ready for onboarding in approximately 15 minutes.</span>
          </div>
          {submitLoading ? <div className={"submit-loader"}><img src={LoaderIcon} alt="loading"/></div> : 
            <button
              className={"create-tenant-button"}
              type="submit"
              value="Submit"
              onClick={() => submitTenant()}>SUBMIT
            </button>
          }
        </div>
      </div>
    </div>)
};

export default NewTenant;