import React, { useState } from "react";
import '../../styles/components/Table/Row.scss';
import '../../styles/components/Table/StatusBar.scss';
import Tooltip from "../../components/Tooltip/Tooltip";
import FailStatusIcon from '../../assets/icons/fail-status.svg';
import PassStatusIcon from '../../assets/icons/pass-status.svg';
import WarnStatusIcon from '../../assets/icons/warning-status.svg';
import InfoStatusIcon from '../../assets/icons/info-status.svg';
import { formatTime } from "../../util/format";
import { formatField } from "../../util/transform.js"
import SeverityImage from "../../components/Table/SeverityImage";
import Row from "../../components/Table/Row";
import ExpandedView from "./ExpandedView";

const HygieneRow = ({ data, mapMainRow, page, severities, onClick, setExpandedIndexes, expandedIndexes, setExpandAll, expandAll, isExpanded, index }) => {
  const [hasTooltip, setHasTooltip] = useState([])

  const handleClick = async () => {
    if (!isExpanded) {
      setExpandedIndexes(prevExpandedIndexes => {
        return [...prevExpandedIndexes, index]
      })
      isExpanded = true
    } else if (expandAll.includes(index)) {
      setExpandAll(expandAll.filter(x => x !== index))
    } else {
      setExpandedIndexes(expandedIndexes.filter(x => x !== index))
      setExpandAll(expandAll.filter(x => x !== index))
      isExpanded = false
    }
    if (data.case_id) {
      await onClick(data.case_id)
    }
  }

  const grabStatusIcon = (status) => {
    switch (status) {
      case 'pass':
        return PassStatusIcon;
      case 'fail':
        return FailStatusIcon;
      case 'warning':
        return WarnStatusIcon;
      case 'info':
        return InfoStatusIcon;
      default:
        return;
    }
  }

  return (
    <>
      <Row page={page} onClick={handleClick} data={data} isExpanded={isExpanded}>
        <td className={"report_date td-value"}>
          {isExpanded ?
            <div className={"expand-btn minus"}>-</div>
            : <div className={"expand-btn"}>+</div>
          }
          {formatTime(data['Report Date'])}
        </td>
        {data['Status'] && <td className={"status td-value"}>
          <img className={`tooltip-value status ${data['Status']}`} src={grabStatusIcon(data['Status'].toLowerCase())} alt={data['Status'].toLowerCase()} />
        </td>}
        <td className={"Severity"}>
          <SeverityImage severities={severities} severity={data['severity']} />
        </td>
        {Object.values([mapMainRow['Account ID'], mapMainRow['Region'], mapMainRow['Service'], mapMainRow['Resource ID'], mapMainRow['Compliance Check']]).map((item, index) => {
          let { value } = formatField(data, item, index)
          return <td key={index} className={item.field.replace(/\s+/g, '-').toLowerCase() + " td-value"}>
            {hasTooltip.some(tooltip => tooltip === item.field) && !isExpanded ?
              <Tooltip content={value} direction={"center"} className={'large-tooltip'}><span className={`tooltip-value ${item.field}`}>{value}</span></Tooltip>
              : <span className={isExpanded ? 'expanded' : `tooltip-value ${item.field}`} ref={ref => {
                if (!ref) return
                const isOverflowing = ref.scrollWidth > ref.clientWidth
                if (isOverflowing) {
                  setHasTooltip(prevTooltips => [...prevTooltips, item.field])
                }
              }}>{value}</span>
            }
          </td>
        })}
      </Row>
      {isExpanded &&
        <ExpandedView
          data={data}
        />
      }
    </>
  );
};

export default HygieneRow;