import React from "react";
import "../../styles/components/Tab/Tab.scss";
import parse from "html-react-parser";

const Tab = ({ data, onClick, selected }) => (
  <div
    className={"tab " + data?.configName + (selected ? " selected" : "")}
    onClick={onClick}
  >
    <span className={"tab-text"}>{parse(data?.display)}</span>
  </div>
);

export default Tab;
