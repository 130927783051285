import React, {useRef} from "react";
import '../../styles/components/Modal/Modal.scss';
import CloseIcon from "@mui/icons-material/Close";
import {useOnClickOutside} from "../../util/clickOutside";

const Modal = ({children, close, className, handleClose}) => {
  const ref = useRef()
  useOnClickOutside(() => close(''), ref)

  return (
    <div className={className + " modal-container"} ref={ref}>
      <div className={"close-button-container"}>
        <CloseIcon
          className={"modal-close-icon"}
          fontSize="small"
          onClick={(e) => handleClose ? handleClose(e) : close(e)}
        />
      </div>
      <div className={"modal-data"}>{children}</div>
    </div>
  );
};

export default Modal;
