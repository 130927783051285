import React, {useEffect, useState} from "react";
import '../../styles/pages/LogSearch/ExpandedView.scss';
import {displayStringValue} from "../../util/format.js"
import { transformValueWithFunction } from "../../util/transform.js";
import {leftColumn, rightColumn, transform, config} from "../../components/Mappings/LogSearch"

const ExpandedView = ({className, data, logSource}) => {
  const [leftExpandedData, setLeftExpandedData] = useState({})
  const [rightExpandedData, setRightExpandedData] = useState({})

  useEffect(() => {
    if (data) {
      let tempLeft = {}
      let tempRight = {}
      let extraFields = {}
      Object.values(data).map((item) => { // Allocate data to the correct side of expanded panel
        let tempValue = item.value;
        if (transform[logSource] && transform[logSource][item.name]) {
            tempValue = transformValueWithFunction(item.value, transform[logSource][item.name])
        }

        if (leftColumn[logSource].includes(item.name)) {
          tempLeft[item.name] = {value: tempValue, config: config[logSource][item.name] || {}}
        } else if (rightColumn[logSource].includes(item.name)) {
          tempRight[item.name] = {value: tempValue, config: config[logSource][item.name] || {}}
        } else {
          extraFields[item.name] = {value: tempValue, config: config[logSource][item.name] || {}}
        }
        return item
      })
      if (logSource === 'cloud') { // Doesn't have any expanded mapping
        let extraFieldKeys = Object.keys(extraFields)
        extraFieldKeys.map((item, index) => {
          if (index <= extraFieldKeys.length / 2) { // Put first half of the fields on the left side
            tempLeft[item] = extraFields[item]
          }
          if (index > extraFieldKeys.length / 2) { // Other half of the fields on the right side
            tempRight[item] = extraFields[item]
          }
          return item
        })
      } else {
        tempRight = Object.assign({}, tempRight, extraFields);
      }
      setLeftExpandedData(tempLeft)
      setRightExpandedData(tempRight)
    }
  }, [data, logSource]);
  
  return (
    <div className={(className ? className : '') + " labeled-expanded-view-container"}>
      <table className={"left-container " + (Object.keys(rightExpandedData).length > 0 ? 'border' : '')}>
      <div className={"left-table"}>
        <tbody>
          {Object.entries(leftExpandedData).map((entry, index) => {
            let [key, value] = entry
            value = value || 'N/A'
            let displayName = key.replace('_', ' ')
            return <tr className={"expanded-item"} key={index}>
                  <td className={"expanded-item-title"}>{displayName}</td>
                  <td className={"expanded-item-value"}>{value.config.skipDisplayStringValue ? value.value : displayStringValue(value.value)}</td>
                </tr>
          })}
        </tbody>
      </div>
      </table>
      <table className={"right-container"}>
        <div className={"right-table"}>
          <tbody className={"right-body"}>
            {Object.entries(rightExpandedData).map((entry, index) => {
              let [key, value] = entry
              value = value || 'N/A'
              let displayName = key.replace('_', ' ')
              return <tr key={index} className={"expanded-item right"}>
                  <td className={"expanded-item-title"}>{displayName}</td>
                  <td className={"expanded-item-value"}>{value.config.skipDisplayStringValue ? value.value : displayStringValue(value.value)}</td>
                </tr>
            })}
            </tbody>
        </div>
      </table>
    </div>
  )
}
export default ExpandedView;
