import React, { useContext, useState, useEffect } from "react";
import UserData from "../../../../store/User/UserData";
import { captureSentryError } from "../../../../util/sentry";
import OptionsModal from "../../../../components/Modal/OptionsModal";
import { csOptionsModalConfig } from "../config/config";
import {
  getCSResponsePreference,
  updateCSResponsePreference,
} from "../../../../api/ApiClient";

const CrowdStrikeOptionsModal = ({ closeFunction }) => {
  const [userData] = useContext(UserData);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [selection, setSelection] = useState([]);
  const [connectorId, setConnectorId] = useState([]);

  useEffect(() => {
    userData.tenant && setCSPreference();
    // eslint-disable-next-line
  }, [userData]);

  const setCSPreference = async () => {
    try {
      setFetchLoading(true);
      setSubmitError("");
      const results = await getCSResponsePreference(userData.tenant);
      const connector = results[0];
      setConnectorId(connector?.id);
      const defaultSelection = [];
      connector?.response_preference?.workstation === "AUTO_RESPONSE" &&
        defaultSelection.push("workstation");
      connector?.response_preference?.server === "AUTO_RESPONSE" &&
        defaultSelection.push("server");
      setSelection(defaultSelection);
    } catch (e) {
      captureSentryError(e, userData, "updateCSPolicyOptions");
      setSubmitError(true);
    } finally {
      setFetchLoading(false);
    }
  };

  const handleSubmit = async (selection) => {
    try {
      setSubmitLoading(true);
      setSubmitError("");
      const serverSelection = selection.includes("server")
        ? "AUTO_RESPONSE"
        : "ON_DEMAND_RESPONSE";
      const workstationSelection = selection.includes("workstation")
        ? "AUTO_RESPONSE"
        : "ON_DEMAND_RESPONSE";
      await updateCSResponsePreference(
        userData.tenant,
        {
          server: serverSelection,
          workstation: workstationSelection,
        },
        connectorId
      );
      closeFunction();
    } catch (e) {
      captureSentryError(e, userData, "updateCSPolicyOptions");
      setSubmitError(true);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <OptionsModal
      fetchLoading={fetchLoading}
      closeFunction={closeFunction}
      {...csOptionsModalConfig}
      className="cs-options-modal"
      loading={submitLoading}
      error={submitError}
      submitFunction={handleSubmit}
      selection={selection}
      setSelection={setSelection}
    />
  );
};

export default CrowdStrikeOptionsModal;
