import React from 'react';
import '../../styles/pages/Vulnerabilities/TopVulnerabilities.scss';

const TopVulnerabilities = ({title, data, type, handleClick}) => {
  return (
    <div className={"top-vulns"}>
      <p className={"title"}>{title}</p>
      <table>
        <tbody>
        {
          data && data.map((item, index) => {
            let name = Object.keys(item)[0]
            return (
              <tr className={"top-item"} key={index}>
                <td className={"top-amount"}>{item[name]}</td>
                <td className={`top-name ${type}`} onClick={(e) => handleClick(e, title)}>{name}</td>
              </tr>)
          })
        }
        </tbody>
      </table>
    </div>
  )
}

export default TopVulnerabilities;
