import React, {useContext, useEffect, useState} from "react";
import '../../styles/components/Modal/Modal.scss';
import {Modal} from "../../components";
import Table from "../../components/Table/Table";
import TableHeaderWrapper from "../../components/Table/TableHeaderWrapper";
import {getConnectionsModalData} from "../../api/ApiClient";
import UserData from "../../store/User/UserData";
import {abbreviateNumber, formatTime} from "../../util/format";
import {Loader} from "../../components/Loader/Loader";
import NotFound from "../../navigation/NotFound";
import { captureSentryError } from "../../util/sentry";

const AccountsModal = ({close, name, values}) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [userData] = useContext(UserData);
  const [fetchError, setFetchError] = useState(false);
  const accountsTableHeader = {
    "ACCOUNT ID": {"sort": false},
    "STATUS": {"sort": false},
    "LAST SEEN": {"sort": false},
    "TOTAL EVENTS": {"sort": false},
  }

  const mapNames = {
    "aws": 'AWS',
    "gsuite": 'GSuite',
    "o365": 'Office 365',
    "salesforce": 'Salesforce'
  }

  useEffect(() => {
    const getConnections = async () => {
      setFetchError(false);
      setLoading(true)
      try {
        let response = await getConnectionsModalData(userData?.tenant, {type: name})
        if(!response.total) response.results[name] = values?.active_connections?.map(() => {return {account_id : "N/A", status: "red", last_seen: "N/A", total_events: "N/A"}}); // temporary bug fix, until RD-3069 is addressed
        setData(response.results[name])
      } catch (e) {
        captureSentryError(e, userData, "getConnectionsModalData API in AccountsModal.js");
        setFetchError(true);
      } finally {
        setLoading(false)
      }
    }
    getConnections()
  }, [userData, userData?.tenant, userData?.userType, name, values]);

  return (
    <Modal close={close}>
      <p className={"modal-title"}>{mapNames[name]} Accounts</p>
      {!loading ?
        fetchError ? <NotFound isError dataError className="panel modal" /> 
        :
        <Table>
          <TableHeaderWrapper>
            {Object.keys(accountsTableHeader).map((item, i) =>
              <th className={"header-item"} key={i}>{item}</th>
            )}
          </TableHeaderWrapper>
          {data?.length > 0 && data.map((item, i) =>
            <tr key={i} className={"list-row"}>
              <td className={"list-item"}>{item.account_id}</td>
              <td className={"list-item status"}><span className={"circle " + (item.status)}/></td>
              <td className={"list-item"}>{!isNaN(item.last_seen) ? formatTime(item.last_seen) : item.last_seen}</td>
              <td className={"list-item"}>{abbreviateNumber(item.total_events)}</td>
            </tr>
          )}
        </Table> : <Loader/>}
    </Modal>
  );
};

export default AccountsModal;
