import React from "react";
import InvestigationsBySeverity from "./InvestigationsBySeverity";
import VulnerabilityTrends from "./VulnerabilityTrends";
import DetectionsBySeverity from "./DetectionsBySeverity";

const RightDashboard = () => {
  return (
    <div className={"right-dashboard"}>
      <InvestigationsBySeverity/>
      <hr className={"panel-divider"}/>
      <DetectionsBySeverity/>
      <hr className={"panel-divider"}/>
      <VulnerabilityTrends/>
    </div>
  )
}
export default RightDashboard;
