import React from "react";
import '../../styles/components/Table/TableHeaderWrapper.scss';

const TableHeaderWrapper = (props) => {
  return (
    <tr className={props.page + ' table-header-wrapper'}>
      {props.children}
    </tr>
  );
}

export default TableHeaderWrapper;
