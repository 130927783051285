import React, { useState, useContext } from "react";
import "../../styles/pages/Preferences/ResetAccount.scss";
import ErrorIcon from "@mui/icons-material/Error";
import { captureSentryError } from "../../util/sentry";
import UserData from "../../store/User/UserData";
import ConfirmModal from "../../components/Modal/ConfirmModal";
import { resetUserAccount } from "../../api/ApiClient";
import { handleResetAccountSignOut } from "../../util/handleSignOut";

const ResetAccount = () => {
  const [userData] = useContext(UserData);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState("");
  const [showConfirmResetModal, setShowConfirmResetModal] = useState(false);

  const handleResetAccount = async () => {
    setSubmitLoading(true);
    setError("");
    try {
      const results = await resetUserAccount(userData?.tenant);
      if (!results.success)
        setError(results?.error || "Error resetting account");
      else {
        handleResetAccountSignOut();
      }
    } catch (e) {
      setError(e.error || "Error resetting account");
      captureSentryError(e, userData, "reset account in preferences");
    } finally {
      setSubmitLoading(false);
    }
  };

  const resetModal = () => {
    setShowConfirmResetModal(false);
  };

  return (
    <div className={"MFA-settings-container"}>
      {showConfirmResetModal && (
        <ConfirmModal
          message={`Are you sure you want to reset your account?`}
          confirmFunc={() => handleResetAccount()}
          cancelFunc={resetModal}
          modalErrors={error}
          rowLoading={submitLoading}
        />
      )}
      <div className="header">Reset Account</div>
      <div className="App-2FA-container">
        <div className="subheader">
          {
            "Clicking the button below will reset your password and multi-factor authentication. This will sign you out of the portal, and send a new invite to your email."
          }
        </div>
        <div className="confirm-btn-container">
          <button
            className="confirm-btn"
            onClick={() => setShowConfirmResetModal(true)}
          >
            Reset account
          </button>
          {error && (
            <div className="error-msg app">
              <ErrorIcon className="err-icon" /> {error}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetAccount;
