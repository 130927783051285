import React, {useContext, useEffect, useState} from "react";
import '../../../styles/pages/Vulnerabilities2/AssetsWithVulnerabilities/ExpandedView.scss';
import Tab from "../../../components/Tab/Tab";
import {ExpandedTableList} from "../ExpandedTable";
import {Pagination} from "../../../components";
import {actionOptions, tables, tabs, updateAsset, vulnerabilitiesTableData} from "../config/assetswithvulnerabilitiesconfig";
import UserData from "../../../store/User/UserData";
import {getAssetsAndDetailsExpandedData, getAssetsAndDetailsPatchesData,} from "../../../api/ApiClient";
import {captureSentryError} from "../../../util/sentry";
import {Loader} from "../../../components/Loader/Loader";
import {ExpandedViewTable} from "./ExpandedViewTable";
import ActionModal from "../ActionModal";
import Dropdown from "../../../components/Dropdown/Dropdown";
import CommentModal from "../CommentModal";

const ExpandedView = ({data, index, setParentTab, action}) => {
  const [userData,] = useContext(UserData);
  const [currTab, setCurrTab] = useState('vulnerabilities');
  const [page, setPage] = useState(1);
  const [loading, setIsLoading] = useState(true)
  const [tableData, setTableData] = useState({})
  const [loadingTable, setLoadingTable] = useState(false)
  const [selectAllCheckboxes, setSelectAllCheckboxes] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedGlobalAction, setSelectedGlobalAction] = useState('Global Actions')
  const [openDropdown, setOpenDropdown] = useState(false);
  const [loadingActionUpdate, setLoadingActionUpdate] = useState(false)
  const [params, setParams] = useState({page: page, size: 25, sort_by: 'severity', order: 'desc'})
  const [globalComment, setGlobalComment] = useState('')
  const [loadingGlobalComments, setLoadingGlobalComments] = useState(false)
  const [openCommentModal, setOpenCommentModal] = useState(false)

  const selectActionFromDropdown = (value) => {
    setSelectedGlobalAction(value);
    setOpenDropdown(false);
    if (value.includes('Comments')) setOpenCommentModal(true)
  };

  const getData = async (onPageChange) => {
    setIsLoading(onPageChange !== true);
    if (onPageChange === true) {
      setLoadingTable(true);
    }
    try {
      let newParams = {...params, page: page}
      if (action) newParams.action = action
      if (newParams.sort_by === 'action_taken') newParams.sort_by = 'action'
      if (data?.endpoint_id) newParams.endpoint_id = data.endpoint_id

      let response = currTab === 'vulnerabilities' ? await getAssetsAndDetailsExpandedData(userData.tenant, encodeURIComponent(data?.asset_name), newParams)
        : await getAssetsAndDetailsPatchesData(userData.tenant, encodeURIComponent(data?.asset_name), newParams)
      let formatData = currTab === 'patches' ?
        {...tableData, vulnerabilities: response} : response
      setTableData(formatData)
    } catch (e) {
      let apiName = currTab === 'vulnerabilities' ? 'getAssetsAndDetailsExpandedData' : 'getAssetsAndDetailsPatchesData'
      captureSentryError(e, userData, apiName)
    } finally {
      setIsLoading(false)
      setLoadingTable(false)
    }
  }

  useEffect(() => {
    (userData.tenant && loading === true) ? getData() : getData(true)
    // eslint-disable-next-line
  }, [userData.tenant, page, currTab, params]);

  const sortTable = (value) => {
    const newParams = {
      ...params,
      sort_by: value,
      order: params.sort_by === value && params.order === 'asc' ? 'desc' : 'asc',
    };
    setParams(newParams);
  };

  const submitActionComment = async (comment) => {
    let asset_name = data?.asset_name || ''
    setLoadingGlobalComments(true);
    try {
      await updateAsset(selectedGlobalAction, 0, selectedCheckboxes, selectActionFromDropdown, asset_name,
        userData, setLoadingGlobalComments, comment);
    } catch (e) {
      captureSentryError(e, userData, "postUpdateAssetsActionOrComment add global comment");
    } finally {
      setLoadingGlobalComments(false);
      setSelectedGlobalAction('Global Actions')
      setOpenCommentModal(false)
      await getData(true)
    }
  }

  return (
    <div className={"flex expanded-container"} key={index}>
      {loading ? <Loader/> : <>
        <div className={"vulnerability-data-table"}>
          {tables.map((table, index) =>
            <ExpandedTableList key={index} data={tableData?.details} labels={table?.labels} title={table?.title}/>
          )}
        </div>
        {selectedGlobalAction !== 'Global Actions' && (!openCommentModal ?
          <ActionModal
            setSelectedGlobalAction={setSelectedGlobalAction}
            className={"update-action-modal"}
            count={selectedCheckboxes.length}
            setLoadingActionUpdate={setLoadingActionUpdate}
            loadingActionUpdate={loadingActionUpdate}
            setOpenCommentModal={setOpenCommentModal}
          /> : <CommentModal
            existingComment={tableData?.vulnerabilities?.results
              .filter(item => item.vulnerability_id === selectedCheckboxes[0])[0].comment || ''}
            selectedCheckboxes={selectedCheckboxes}
            type={selectedGlobalAction}
            setSelectedGlobalAction={setSelectedGlobalAction}
            cancel={() => {setSelectedGlobalAction('Global Actions');setOpenCommentModal(false)}}
            setGlobalComment={setGlobalComment}
            globalComment={globalComment}
            submit={submitActionComment}
            loading={loadingGlobalComments}
            setOpenCommentModal={setOpenCommentModal}
          />)}
        <div className={"assets-and-details"}>
          <div className={"tab-container"}>
            {tabs.map((tab) =>
              <Tab
                onClick={() => {setCurrTab(tab.configName);setPage(1);}}
                key={tab.configName}
                data={tab}
                selected={tab.configName === currTab}
              />
            )}
          </div>
          <div className={"table-container"}>
            {loadingTable ? <Loader/> : tableData?.vulnerabilities?.results.length > 0 ? <>
              <div className={"flex assets-pagination"}>
                <p className={"results"}>{tableData?.vulnerabilities?.total.toString() + ' Results'}</p>
                {tableData?.vulnerabilities?.total > 25 &&
                <Pagination setPage={setPage} page={page} total={tableData?.vulnerabilities?.total} pageSize={25}/>}
                {currTab !== 'patches' && <Dropdown
                  className={"global-action-dropdown vulnerabilities-tab " +
                  (selectedCheckboxes.length === 0 && !selectAllCheckboxes ? "disabled" : "")
                  }
                  name={"Global Actions"}
                  dropdownList={actionOptions}
                  selectItem={(value) =>
                    (selectedCheckboxes.length === 0 && !selectAllCheckboxes) ? null : selectActionFromDropdown(value)
                  }
                  selectedItem={selectedGlobalAction}
                  showDropdown={openDropdown === true}
                  setShowDropdown={setOpenDropdown}
                />}
              </div>
              <ExpandedViewTable
                page={"assets"}
                setCurrTab={setParentTab}
                className={currTab}
                headers={vulnerabilitiesTableData[currTab].headers}
                data={tableData?.vulnerabilities?.results}
                asset_name={tableData?.details['Asset Name']}
                selectAllCheckboxes={selectAllCheckboxes}
                setSelectAllCheckboxes={setSelectAllCheckboxes}
                selectedCheckboxes={selectedCheckboxes}
                setSelectedCheckboxes={setSelectedCheckboxes}
                sortTable={sortTable}
                params={params}
                assetName={data?.asset_name}
              />
              {tableData?.vulnerabilities?.total > 25 &&
              <Pagination setPage={setPage} page={page} total={tableData?.vulnerabilities?.total} pageSize={25}/>}
            </> : <p>No Data</p>}
          </div>
        </div>
      </>}
    </div>
  );
};

export default ExpandedView;
