import config from "../config";
import { setUpTOTP, fetchUserAttributes } from "aws-amplify/auth";

export const setAuthRegistration = async ({
  setAccountName,
  setTotpToken,
  setQrCodeURL,
  setQrLoading,
  setQrError,
}) => {
  try {
    const output = await setUpTOTP();
    const cognitoUserInfo = await fetchUserAttributes();
    setAccountName(cognitoUserInfo.email);
    const totp = output.sharedSecret;
    setTotpToken(totp);
    let qrcodeTotpUrl =
      "otpauth://totp/" +
      cognitoUserInfo.email +
      "?secret=" +
      totp +
      "&issuer=ActZero.ai";
    if (config.env !== "prod") {
      const envCapitalized =
        config.env.charAt(0).toUpperCase() + config.env.slice(1);
      qrcodeTotpUrl = qrcodeTotpUrl + "(" + envCapitalized + ")";
    }
    setQrCodeURL(qrcodeTotpUrl);
    setQrLoading(false);
  } catch (e) {
    setQrError(true);
  }
};
