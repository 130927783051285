import {getFeatureFlag} from "../api/ApiClient";
import {captureSentryError} from "./sentry";

export const getFeatureFlagAccess = async (setAzState, userData, flagName, name) => {
  try {
    const flag = await getFeatureFlag(userData?.tenant, flagName)
    setAzState(state => ({...state, flags: {...state?.flags, [name]: flag?.value}}))
  } catch (e) {
    captureSentryError(e, userData, `get ${flagName} feature flag getFeatureFlagAccess`)
  }
}
