import React, {useState} from 'react';
import { serviceUnits, tooltipContent } from './config/serviceUnits';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '../../../components/Tooltip/Tooltip';
import TooltipIcon from "../../../assets/icons/tooltip.svg";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dropdown from '../../../components/Dropdown/Dropdown';

const ServicesComponent = ({inputData, setInputData, missingSelections, setMissingSelections, isSelected, setIsSelected}) => {
  const [showDropdown, setShowDropdown] = useState('')

  const tableData = [
    { text: 'Managed Detection and Response (MDR)', tooltip: true, unitsText: 'Endpoints per selection', units: serviceUnits['endpoints'] },
    { text: 'Enhanced Vulnerability Management', tooltip: true, unitsText: 'Number of endpoints' },
    { text: 'Device Control', tooltip: true, unitsText: 'Number of endpoints' },
    { text: 'Mobile MDR', tooltip: true, unitsText: 'Number of devices', units: serviceUnits['endpoints'] },
    { text: 'Log Management', tooltip: true, unitsText: 'GB per day', storageText: "Stored for",  units: serviceUnits['volume'], storageUnits: serviceUnits['duration'] },
    { text: 'Log Storage', tooltip: true, unitsText: 'GB per day', storageText: "Stored for", units: serviceUnits['volume'], storageUnits: serviceUnits['duration'] },
    { text: 'vCISO', tooltip: true, enabled: false, subText: "VIRTUAL CHIEF INFORMATION SECURITY OFFICER" },
  ];

  const handleDropdownSelection = (item, value, subType='') => {
    let newRowState = inputData[item]
    if (item === 'Managed Detection and Response (MDR)') {
      if (subType === 'defender') {
        newRowState.defender_selection = value
      } else {
        newRowState.crowdstrike_selection = value
        if (inputData['Enhanced Vulnerability Management'].enabled) inputData['Enhanced Vulnerability Management'].selection = value
        if (inputData['Device Control'].enabled) inputData['Device Control'].selection = value
      }
    } else if (item.includes('Log') && subType === 'storage') { 
      newRowState.storageSelection = value
    } else {
      newRowState.selection = value
    }
    if (item === 'Enhanced Vulnerability Management') {
      inputData['Managed Detection and Response (MDR)'].crowdstrike_selection = value
      if (inputData['Device Control'].enabled) {inputData['Device Control'].selection = value}
    } 
    if (item === 'Device Control') {
      inputData['Managed Detection and Response (MDR)'].crowdstrike_selection = value
      if (inputData['Enhanced Vulnerability Management'].enabled) {inputData['Enhanced Vulnerability Management'].selection = value}
    }
    setInputData(prevState => {prevState[item] = newRowState; return prevState});
    setShowDropdown('');
  }

  const handleCheckboxSelection = (e, item, subType="") => {
    let checked = e.target.checked
    let newRowState = inputData[item]
    let selectionName = item
    if (item === 'Managed Detection and Response (MDR)') {
      if (subType === 'defender') {
        newRowState.defender_enabled = checked
        selectionName = `${item}-defender`
      } else {
        newRowState.crowdstrike_enabled = checked
        selectionName = `${item}-cs`
      }
    } else {
      newRowState.enabled = checked
    }
    if (checked) {
      setIsSelected(prevState => {return [...prevState, selectionName]})
    } else {
      setIsSelected(prevState => {
        let removeCheck = [selectionName]
        if (subType === "cs") {
          removeCheck = [...removeCheck, "Device Control", "Enhanced Vulnerability Management"]
        }
        return prevState.filter(selection => removeCheck.indexOf(selection) === -1)
      })
    }
    setInputData(prevState => {
      if (checked) {
        if (item === 'Enhanced Vulnerability Management') prevState['Enhanced Vulnerability Management'].selection = prevState['Managed Detection and Response (MDR)'].crowdstrike_selection
        if (item === 'Device Control') prevState['Device Control'].selection = prevState['Managed Detection and Response (MDR)'].crowdstrike_selection
      } else {
        if (subType === 'cs') {
          prevState['Managed Detection and Response (MDR)'].crowdstrike_selection = ""
          prevState['Enhanced Vulnerability Management'] = {enabled: false, selection: ""}
          prevState['Device Control'] = {enabled: false, selection: ""} 
        }
        else if (subType === 'defender') {
          prevState['Managed Detection and Response (MDR)'].defender_selection = ""
        } else if (item.includes('Log')) {
          prevState[item].storageSelection = ""
          prevState[item].selection = ""
        }
        newRowState.selection = ""
      } 
      return prevState
      });
  }

  const grabDropdown = (rowField, rowDefaultField, units, rowText, subText) => {
    return (
      <Dropdown
        name={rowField || rowDefaultField}
        dropdownList={units}
        selectItem={(value, e) => {handleDropdownSelection(rowText, value, `${subText.replace("-", "")}`)}}
        selectedItem={rowField}
        showDropdown={showDropdown === `${rowText}${subText}`}
        setShowDropdown={(value) => {setShowDropdown(value ? `${rowText}${subText}` : ''); setMissingSelections(value ? missingSelections.filter(selection => selection !== `${rowText}${subText}`) : missingSelections)}}
        useList={true}
        className={rowText}
        caretColor={getCaretColor(isSelected.filter(selection => selection === `${rowText}${subText.replace('-storage', '')}`).length > 0, `${rowText}${subText}`)}
      />
    )
  }

  const grabCheckbox = (rowText, subText, label, tooltip=false, subTitle=null) => {
    let canSelect = canSelectService(rowText)
    return (
      <>
      <FormGroup>
        <FormControlLabel control={<Checkbox checked={isSelected.filter(selection => selection === `${rowText}${subText}`).length > 0} disabled={!canSelect} onChange={(e) => {handleCheckboxSelection(e, rowText, `${subText.replace("-", "")}`)}}/>} label={label}/>
      </FormGroup>
      {subTitle &&
        <span className={"service-sub-text"}>{subTitle}</span>
      }
      {tooltip && <Tooltip
        content={tooltipContent[rowText]}
        type="child-tenant-tooltip"
        className="large-tooltip"
        direction={"right"}
      >
        <img
          className="tooltip-icon"
          src={TooltipIcon}
          alt={label}
        />
      </Tooltip>}
    </>
    )
  }

  const isSelectedOption = (selections, selected) => {
    return selections?.some(selection => selection === selected)
  }

  const canSelectService = (text) => {
    if (text === "Enhanced Vulnerability Management" || text === "Device Control") {
      return inputData['Managed Detection and Response (MDR)'].crowdstrike_enabled
    } else if (text === "Log Storage") {
      return !inputData['Log Management'].enabled
    } else if (text === "Log Management") {
      return !inputData['Log Storage'].enabled
    }
    return true
  }

  const getCaretColor = (active, text) => {
    if (active && missingSelections.filter(selection => selection === text).length > 0) {
      return "error"
    }
    if (active) {
      return ""
    }
    return "disabled"
  }

  return (
    <table className={"service-table"}>
      <thead>
        <tr className={"tr-headers"}>
          <th></th>
          <th className={"th-units-header"}>UNITS</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((row, index) => (
          <tr key={index}>
            <td>
              {row.text === 'Managed Detection and Response (MDR)' ? 
              <>
                {row.text}
                <Tooltip
                  content={tooltipContent[row.text]}
                  type="mdr-tenant-tooltip"
                  className="large-tooltip"
                  direction={"right"}
                >
                  <img
                    className="tooltip-icon"
                    src={TooltipIcon}
                    alt={row.text}
                  />
                </Tooltip>
                <tr key={'crowdstrike'}>
                  <td className={"mdr-service-row"}>
                  {grabCheckbox(row.text, '-cs', 'Crowdstrike Falcon')}
                  </td>
                </tr>
                <tr key={'defender'}>
                  <td className={"mdr-service-row"}>
                  {grabCheckbox(row.text, '-defender', 'Microsoft Defender for Endpoint')}
                  </td>
                </tr>
              </>
              : 
              <div className={"service-selection"}>
                {grabCheckbox(row.text, '', row.text, true, row.subText)}
              </div>}
            </td>
            <td>
              <div className={`dropdowns-container${row.text.includes('Log') ? " log" : ""}`}>
                {row.units && row.text !== 'Managed Detection and Response (MDR)' && <div className={`td-units-column ${row.text.includes('Log')}${isSelectedOption(isSelected, row.text) ? " selected" : " unselected"}${isSelectedOption(missingSelections, row.text) ? " service-error" : ""}`}>
                {grabDropdown(inputData[row.text].selection, row.unitsText, row.units, row.text, "")}
                </div>
                }

                {(row.text === 'Device Control' || row.text === 'Enhanced Vulnerability Management') && <div className={`td-units-column ${row.text.includes('Log')}${isSelectedOption(isSelected, row.text) ? " selected" : " unselected"}${isSelectedOption(missingSelections, row.text) ? " service-error" : ""}`}>
                  <span className={"edr-offerrings"}>{inputData[row.text].enabled ? inputData['Managed Detection and Response (MDR)'].crowdstrike_selection : row.unitsText}</span>
                </div>
                }

                {row.text.includes('Log') &&
                  <div className={`td-units-column log-dropdown${isSelectedOption(isSelected, row.text) ? " selected" : " unselected"}${isSelectedOption(missingSelections, `${row.text}-storage`) ? " service-error" : ""}`}>
                {grabDropdown(inputData[row.text].storageSelection, row.storageText, row.storageUnits, row.text, "-storage")}
                </div>}
              </div>

              {row.text === 'Managed Detection and Response (MDR)' &&
              <div className={`mdr-dropdown-container`}>
                <div className={`td-units-column cs-dropdown${isSelectedOption(isSelected, `${row.text}-cs`) ? " selected" : " unselected"}${isSelectedOption(missingSelections, `${row.text}-cs`) ? " service-error" : ""}`}>
                {grabDropdown(inputData[row.text].crowdstrike_selection, row.unitsText, row.units, row.text, "-cs")}
                </div>
                <div className={`td-units-column defender-dropdown${isSelectedOption(isSelected, `${row.text}-defender`) ? " selected" : " unselected"}${isSelectedOption(missingSelections, `${row.text}-defender`) ? " service-error" : ""}`}>
                {grabDropdown(inputData[row.text].defender_selection, row.unitsText, row.units, row.text, "-defender")}
                </div>
              </div>
              }
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ServicesComponent;





