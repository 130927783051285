import React, {useContext, useEffect, useRef, useState} from 'react';
import UserData from "../../store/User/UserData";
import '../../styles/pages/MaturityAssessment/ExpandedView.scss'
import {getMAExpandData, postMAComments} from "../../api/ApiClient";
import {Loader} from "../../components/Loader/Loader";
import moment from "moment";
import Dropdown from "../../components/Dropdown/Dropdown";
import Button from "../../components/Button/Button";
import {KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight} from "@mui/icons-material";
import {Modal} from "../../components";
import {hasWriteAccess} from "../../util/userControl"
import CloseIcon from "../../config/icons/close-icon.svg";
import NotFound from "../../navigation/NotFound";
import { captureSentryError } from '../../util/sentry';

const Comment = ({item, index}) =>
  <div key={index} className={"comment-container"}>
    <div className={"author-info"}>
      <p>NOTES BY</p>
      <p className={"author"}>{item?.author.toUpperCase()}</p>
      <p>{moment(item?.timestamp * 1000).format("MM-DD-YYYY")}</p>
    </div>
    <div className={"comment-info"}>
      <p className={"comment"}>{item?.notes}</p>
      {item?.status && <p className={"greyed"}>STATUS CHANGE:
        <span className={"status"}> {item?.status.toUpperCase()}</span>
      </p>}
    </div>
  </div>

const ExpandView = ({className, control, statusColors, close, loadingNote, setLoadingNote, getTableData}) => {
  const [userData] = useContext(UserData);
  let divRef = useRef()
  const statuses = [{'Incomplete': false},
    {'In Progress': false},
    {'Ready for Review': false},
    {'Completed': false},
    {'Not Applicable': false}]
  const [currentComment, setCurrentComment] = useState('')
  const [data, setData] = useState({})
  const [showDropdown, setShowDropdown] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState('')
  const [showAuditModal, setShowAuditModal] = useState(false)
  const [fetchError, setFetchError] = useState(false);

  const getStatus = () => statusColors.filter(status => Object.keys(status)[0] === data.status)[0] || 'grey'

  const resetModal = () => setShowAuditModal(false);

  const getData = async () => {
    setFetchError(false);
    setLoadingNote(true)
    try {
      let response = await getMAExpandData(userData?.tenant, control)
      setSelectedStatus(response?.status)
      if (response?.history.length > 0) {
        let history = response?.history
        history.map((a, i) => {
          let checkLast = (index) => {
            if (index > history.length - 1) return
            if (a.status === history[i - index]?.status) {
              delete a.status
            } else if (history[i - index]?.status === undefined) {
              checkLast(index + 1)
            }
          }
          checkLast(1)
          return a
        })
      }
      if (response?.status === 'Reviewed') {
        response.status = 'Ready for Review'
      }
      setData(response)
      divRef?.current?.scrollIntoView({behavior: 'smooth', block: 'start'})
    } catch (e) {
      captureSentryError(e, userData, 'getMAExpandData API in MaturityAssessment/ExpandedView.js');
      setFetchError(true);
    } finally {
      setLoadingNote(false)
    }
  }

  const addComment = async () => {
    setLoadingNote(true)
    try {
      const body = {"status": selectedStatus, "notes": currentComment, "author": userData?.email}
      setCurrentComment('')
      await postMAComments(userData?.tenant, control, body)
      getData()
      getTableData()
    } catch (e) {
      captureSentryError(e, userData, 'postMAComments API in MaturityAssessment/ExpandedView.js');
    } finally {
      setLoadingNote(false)
    }
  }

  useEffect(() => {
    userData?.tenant && control && getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.tenant, control, userData?.userType])

  const showString = (isRansomware, isCloudATO) => {
    if (isRansomware && isCloudATO) {
      return 'Ransomware, CloudATO'
    } else {
      return isRansomware ? 'Ransomware' : isCloudATO ? 'Cloud ATO' : ''
    }
  }

  return (
    <div ref={divRef} className={className}>
      {!loadingNote && fetchError ? <NotFound isError dataError />
      : <>
      {!loadingNote && <div className={"expanded-header"}>
        <div className={"date-ticket"}>
          {data.timestamp && <p className={"date"}>{moment.unix(data.timestamp).format("MM-DD-YY")}</p>}
          <p className={"ticket"}>{`${data.control}`}</p>
        </div>
        {data?.status && <p className={"status"} style={{color: getStatus()[data?.status]}}>
          {data?.status?.replace(/[^a-zA-Z]/g, " ").toUpperCase()}
        </p>}
        <img onClick={() => close('')} className={"close-icon pointer"} src={CloseIcon} alt={"close"}/>
      </div>}
      {!loadingNote && <div>
        <p className={"expanded-label description"}>{data?.description && 'CONTROL DESCRIPTION'}</p>
        <p className={"description"}>{data?.description}</p>
      </div>}
      {!loadingNote && <div className={"control-data" + ((data?.history && data?.history.length > 0) || hasWriteAccess(userData?.role) ? ' border-bottom' : '')}>
        <div>
          <p className={"expanded-label"}>{data?.service && 'TOOL CATEGORY'}</p>
          <p>{data?.service && data?.service}</p>
          <p className={"expanded-label"}>{data?.hygiene_category && 'HYGIENE'}</p>
          <p>{data?.hygiene_category && data?.hygiene_category}</p>
        </div>
        <div>
          <p className={"expanded-label"}>{(data?.ransomware || data?.cloud_ato) && 'ACTZERO SERVICE CATEGORY'}</p>
          <p>{showString(data?.ransomware, data?.cloud_ato)}</p>
          <p className={"expanded-label"}>{data?.coverage && 'AZ COVERAGE'}</p>
          <p>{data?.coverage && data?.coverage}</p>
        </div>
      </div>}

      {!loadingNote ? data?.history?.map((comment, index) => <Comment item={comment} key={index}/>) : <Loader/>}
      {hasWriteAccess(userData?.role) && <div className={"notes-container"}>
        <div className={"comment-header"}>
          <p className={"notes-title"}>NOTES</p>
          <Dropdown
            name={(data?.status === selectedStatus && !showDropdown) ? "Status" : showDropdown ? '' : selectedStatus}
            dropdownList={statuses}
            selectItem={setSelectedStatus}
            selectedItem={selectedStatus}
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
          />
        </div>
        <div className={"textarea-container"}>
          <textarea
            value={currentComment}
            className={"add-comment-input"}
            onChange={(e) => setCurrentComment(e.target.value)}
          />
        </div>
        <div className={"expand-footer-btns"}>
          <p className={"pointer audit-btn"} onClick={() => setShowAuditModal(!showAuditModal)}>
            Audit Guidance
            {showAuditModal ?
              <KeyboardDoubleArrowLeft className={'arrow'}/> : <KeyboardDoubleArrowRight className={"arrow"}/>}
          </p>
          <Button className={"update-button"} disabled={false} text={'UPDATE'} onClick={addComment}/>
        </div>
      </div>}
      {showAuditModal && <Modal close={resetModal}>
        <p className={"audit-header"}>{data?.control}</p>
        <p className={"audit-text"}>{data?.audit_guidance}</p>
      </Modal>}
      </>}
    </div>
  );
}

export default ExpandView;
