import React, { useState, useEffect } from "react";
import BellIcon from "../assets/icons/bell.svg";
import BellIconClear from "../assets/icons/bell-no-number.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Divider from "@mui/material/Divider";
import { readAnnouncements } from "../api/ApiClient";
import parse from "html-react-parser";
import "../styles/navigation/Announcements.scss";
import { Modal } from "../components";
import { captureSentryError } from "../util/sentry";

export function AnnouncementModal(props) {
  const { setOpen, contentList, setContent, userData } = props;
  const [currPage, setCurrPage] = useState(0);
  const [viewedPages, setViewedPages] = useState([]);

  useEffect(() => {
    if (!viewedPages.includes(currPage) && !contentList[currPage]?.read)
      setViewedPages((pgs) => {
        return [...pgs, currPage];
      });
    // eslint-disable-next-line
  }, [currPage]);

  const handleClose = async () => {
    try {
      setOpen(false);
      const readIds = viewedPages
        .filter((pg) => !contentList[pg]?.is_read)
        .map((pg) => contentList[pg]?.id);
      setContent(
        contentList.map((announcement) => ({
          ...announcement,
          is_read: readIds.includes(announcement.id) || announcement.is_read,
        }))
      );
      const submitIds = { announcement_ids: readIds };
      await readAnnouncements(userData.tenant, submitIds);
    } catch (e) {
      captureSentryError(e, userData, "postAnnouncements API");
    }
  };

  return (
    <div className="announcement-modal-container">
      <Modal close={() => handleClose()}>
        <div className="modal-content">
          <div className="modal-top">
            <div className="title-text">
              {contentList && contentList[currPage]?.title}
            </div>
            <div className="announcement-pages">
              {currPage + 1} / {contentList.length}
            </div>
          </div>
          <div className="modal-body">
            <Divider variant="middle" className="divider" />
            {currPage > 0 && (
              <div
                aria-label="Previous"
                onClick={() => setCurrPage(currPage - 1)}
                className="arrow back"
              >
                <ArrowBackIosNewIcon />
              </div>
            )}
            <div className="body-text">
              {contentList && contentList[currPage]?.body
                ? parse(contentList[currPage]?.body)
                : ""}
            </div>
            {currPage < contentList.length - 1 && (
              <div
                onClick={() => setCurrPage(currPage + 1)}
                className="arrow forward"
              >
                <ArrowForwardIosIcon />
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export const AnnouncementBtn = ({ count }) => (
  <div className="announcement-btn-container">
    {!count ? (
      <div className="announcement-btn-clear">
        <img alt="Announcements" src={BellIconClear} />
      </div>
    ) : (
      <div className="announcement-btn">
        <span
          className={"announcement-count " + (count > 9 ? "double-digit" : "")}
        >
          {count}
        </span>
        <img alt="Announcements" src={BellIcon} />
      </div>
    )}
  </div>
);
