import "../../../styles/pages/Onboarding/VirtualMachine/VirtualMachineTable.scss";
import React, {useContext, useEffect, useState} from "react";
import UserData from "../../../store/User/UserData";
import VirtualMachineTableHeader from "./VirtualMachineTableHeader";
import DenseTable from "../../../components/Table/DenseTable";
import {getTenant, deleteTenantVM} from "../../../api/ApiClient";
import VirtualMachineTableRow from "./VirtualMachineTableRow";
import {sortObjects} from "../../../util/sortObjects";
import NotFound from "../../../navigation/NotFound";
import {trackClick} from "../../../util/analytics";
import { captureSentryError } from "../../../util/sentry";

const VirtualMachineTable = () => {
  const [userData] = useContext(UserData);
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const [sortBy, setSortBy] = useState();
  const [sortOrder, setSortOrder] = useState("asc");
  const [fetchError, setFetchError] = useState(false)
  useEffect(() => {
    setTableData(sortObjects(tableData, sortBy, sortOrder));
    // eslint-disable-next-line
  }, [sortBy, sortOrder]);


  useEffect(() => {
    userData?.tenant && getTenantData()
    // eslint-disable-next-line
  }, [userData?.tenant])


  const getTenantData = async () => {
      setFetchError(false);
      setLoading(true)
      try {
        let tenantVMData = await getTenant(userData?.tenant);
        setTableData(tenantVMData?.vm_tracking)
      } catch (aggregateError) {
        captureSentryError(aggregateError, userData, "getTenant API in VirtualMachineTable.js");
        setFetchError(true);
      } finally {
        setLoading(false)
      }
    };

  const handleDeleteVM = async (row) => {
    setLoading(true)
    try {
      await deleteTenantVM(userData.tenant, row.hostname);
      trackClick(userData, `Deleted VM hostname: ${row.hostname}`)
      await getTenantData()
    } catch (e) {
      captureSentryError(e, userData, "deleteTenantVM API in VirtualMachineTable.js");
      return {success: false, error: e.message};
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <>
      {!loading && fetchError ?
        <NotFound isError dataError className="panel modal"/> :
        <DenseTable
          className={"virtual-machine"}
          loading={loading}
          header={<VirtualMachineTableHeader
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
          />}
          noDataMessage={"No VMs added yet."}
          body={<VirtualMachineTableRow
              handleDeleteVM={handleDeleteVM}
              userData={userData}
              data={tableData}/>}
        />}
    </>
  );
}

export default VirtualMachineTable;
