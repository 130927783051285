import React, { useEffect, useState } from "react";
import { useOnClickOutside } from "../../util/clickOutside";
import { GenericRadio } from "../../components";
import { UncheckedBox } from "../../util/icons";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { KeyboardArrowDown } from "@mui/icons-material";

export const LogSearchDropdown = ({
  options,
  setPostLogSearchConfig,
  type,
  field,
}) => {
  const [selection, setSelection] = useState(["All"]);
  const [showDropdown, setShowDropdown] = useState(false);
  let domNode = useOnClickOutside(() => {
    setShowDropdown(false);
  });

  const handleSetSelection = (e) => {
    const name = e.target.value || e.target.innerText;
    setSelection((prev) => {
      let newValue = !prev.includes(name)
        ? [...prev, name]
        : prev.filter((x) => x !== name);
      return (name === "All" && e.target.checked) || !newValue.length
        ? ["All"]
        : newValue.filter((x) => x !== "All");
    });
  };

  useEffect(() => {
    setPostLogSearchConfig((prev) => {
      let config = prev.log_sources.find(
        (item) => item.type.toLowerCase() === type
      );
      config.fields[field] = selection
        .filter((e) => e !== "All")
        .join(",");

      if (!config.fields[field].length) {
        delete config.fields[field];
      }
      return prev;
    });
    //eslint-disable-next-line
  }, [selection]);

  return (
    <>
      {showDropdown ? (
        <div className={"ls-row log-search-dropdown-container"} ref={domNode}>
          <GenericRadio
            handleView={handleSetSelection}
            className={
              "customize-container dropdown-checkbox log-source-options log-search-dropdown"
            }
            selected={selection}
            handleChange={handleSetSelection}
            options={options?.map(function (e) {
              return { name: e, obj: e };
            })}
            icon={<UncheckedBox />}
            checkedIcon={<CheckBoxIcon className={"checked-box-icon"} />}
          />
        </div>
      ) : (
        <div
          className={"ls-row closed-dropbox log-search-input ls-dropdown-label"}
        >
          <div
            className={"ls-dropdown"}
            onClick={() => setShowDropdown(true)}
          >
            <p className={"ls-service-view"}>
              {selection.map((u) => u).join(", ")}
            </p>
            <KeyboardArrowDown
              className={"arrow-icon arrow-dark-color"}
              onClick={() => setShowDropdown(false)}
            />
          </div>
        </div>
      )}
    </>
  );
};
