import React from "react";
import {Modal} from "../../components";
import Button from "../../components/Button/Button";
import "../../styles/pages/Vulnerabilities2/Vulnerabilities/ActionComment.scss";
import {Loader} from "../../components/Loader/Loader";
import EditPencil from './config/icons/pencil.svg';
import CloseIcon from "@mui/icons-material/Close";
import { formatTime } from "../../util/format";

const ActionComment = ({
                         setCurrentComment,
                         commentValue = '',
                         commentLastUpdated,
                         close,
                         existingComment = '',
                         submitComment,
                         setExistingComment,
                         loading = false,
                         index,
                         commentError
                       }) => (
  <Modal close={close} className="vulnerabilities new-comment-modal" noOutsideClick>
    <CloseIcon className="comment-close-icon" fontSize="small" onClick={e => close(false, e)}/>
    {loading ? 
      <Loader />
     : commentError ? 
      <div className="error-message">
        Failed to add comment. Please try again.
      </div>
     : existingComment !== "" ? 
      <div>
        <div className="modal-title">
          <div className="add-comment-title">Comment</div>
          {commentLastUpdated && <span className="comment-time"><span className="time-label">Last updated</span>{formatTime(commentLastUpdated)}</span>}
          <div className="edit-pencil">
            <img
              src={EditPencil}
              alt="edit comment"
              onClick={() => setExistingComment('', index)}
            />
          </div>
        </div>
        <p className="comment-text">{existingComment}</p>
      </div>
      : <React.Fragment>
        <div className="modal-title add-comment-title">Add comment</div>
        <textarea
          placeholder="Enter comment here"
          value={existingComment === '' ? commentValue : commentValue}
          className="add-comment-input"
          onChange={e => setCurrentComment(e.target.value)}
        />
        <Button
          className="update-button-comment"
          disabled={false}
          text="ADD"
          onClick={() => submitComment(commentValue, index)}
        />
      </React.Fragment>
    }
  </Modal>
);

export default ActionComment;
