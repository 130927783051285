import DefaultType from './icons/default-type.svg';
import MacType from './icons/apple-type.svg';
import LinuxType from './icons/linux-type.svg';
import WindowsType from './icons/windows-type.svg';

export const iconMap = {
  "mac": MacType,
  "windows": WindowsType,
  "linux": LinuxType,
  "default": DefaultType
}

export const mapIcons = (os_type) => {
  let source = os_type.toLowerCase()
  switch(source) {
    case 'mac':
      return iconMap['mac'];
    case 'windows':
        return iconMap['windows'];
    case 'linux':
      return iconMap['linux'];
    default:
      return iconMap['default'];
  }

}
