import React from "react";
import ZoomInIcon from "../../assets/icons/zoomin.svg";
import ZoomOutIcon from "../../assets/icons/zoomout.svg";
import '../../styles/components/Graph/ZoomButtons.scss';

const ZoomButtons = ({handleZoomButton}) => {
  const zoomButtonConfig = [
    {
      value: "zoom-in",
      icon: ZoomInIcon,
    },
    {
      value: "zoom-out",
      icon: ZoomOutIcon,
    },
  ];

  return (
    <div className="zoom-container">
      {zoomButtonConfig.map((button) => (
        <div
          className={`zoom-button ${button.value}`}
          onClick={() => handleZoomButton(button.value)}
          key={button.value}
        >
          <img src={button.icon} alt={button.value} className="icon" />
        </div>
      ))}
    </div>
  );
};

export default ZoomButtons;
