import React, {useContext, useEffect, useState} from "react";
import "../../../styles/pages/Onboarding/Cloud/CloudOnboarding.scss";
import CloudOnboardingContent from "./CloudOnboardingContent.js";
import {cloudInputConfigs, customElements, defaultInputValues, instructionLinks} from "./config";
import {getOnboardingConnectors, testOnboardingConnector} from "../../../api/ApiClient";
import UserData from "../../../store/User/UserData";
import Tab from "./components/Tab";
import AddCloudModal from "./components/AddCloudModal";
import NoCloudTabs from "./components/NoCloudTabs";
import {Loader} from "../../../components/Loader/Loader";
import { captureSentryError } from "../../../util/sentry.js";
import { useLocation } from 'react-router-dom';
import { defaultTabMap, defaultTabOptions } from "./config";

const CloudOnboarding = () => {
  const [accountData, setAccountData] = useState();
  const [fetchError, setFetchError] = useState("");
  const [loading, setLoading] = useState(false);
  const [userData] = useContext(UserData);
  const [currTab, setCurrTab] = useState("");
  const [openAddService, setOpenAddService] = useState(false);
  const [selectedTabs, setSelectedTabs] = useState([]);
  const [allTabs, setAllTabs] = useState(defaultTabOptions);
  const location = useLocation();

  useEffect(() => {
    userData.tenant && fetchData();
    // eslint-disable-next-line
  }, [userData?.tenant]);

  const fetchData = async (value) => {
    setFetchError(false);
    value !== 'fromOnClick' && setLoading(true);
    let results
    try {
      let data = await getOnboardingConnectors(userData.tenant);
      results = data?.results
      let newTabs = []
      let removeTab = allTabs;
      results.forEach(item => {
        allTabs.forEach(tab => {
          if (tab.configName === item.connector_type && !newTabs.includes(tab)) {
            newTabs.push(tab)
            removeTab = removeTab.filter((x) => x.display !== tab.display)
          }
        })
      })
      if (newTabs.length > 0) {
        setCurrTab(newTabs[0].configName)
        setSelectedTabs(newTabs)
        setAllTabs(removeTab)
      }
      setAccountData(results);
      if(location.state) setDefaultTab(newTabs, removeTab);
    } catch (e) {
      setFetchError(e);
      captureSentryError(e, userData, "getOnboardingConnectors API in CloudOnboarding.js");
    }
    setLoading(false);
    return results
  }

  const setDefaultTab = (existingTabs, removeTab) => {
    const tab = defaultTabMap[location.state.cloudType];
    const data = defaultTabOptions.find((t) => t.configName === tab);
    if (!existingTabs.find((t) => t.configName === tab)) {
      setSelectedTabs([...existingTabs, data]);
      setCurrTab(data.configName);
      setAllTabs(removeTab.filter((x) => x.display !== data.display));
    } else {
      setCurrTab(tab);
    }
  };

  const testAccountHandler = async (connectorId, setErrors) => {
    setErrors((prevState) => {
      return {...prevState, test: ""};
    });
    try {
      return await testOnboardingConnector(userData.tenant, connectorId); // displays checkmark or error
    } catch (e) {
      captureSentryError(e, userData, "testOnboardingConnector API in CloudOnboarding.js");
      return {success: false, error: e.message};
    }
  };

  const addCloudTab = (data, name) => {
    setAllTabs([...allTabs.filter((x) => x.display !== name)])
    setSelectedTabs([...selectedTabs, data])
    setCurrTab(data.configName)
  }

  const removeCloudTab = (data) => {
    setSelectedTabs(prevState => {
      const newState = prevState.filter(t => t.configName !== data.configName);
      currTab === data.configName && newState[0] && setCurrTab(newState[0].configName);
      return newState;
    });
    setAllTabs(prevState => [...prevState, data].sort((a, b) => {
        const tabOrder = defaultTabOptions.map(t => t.configName);
        return tabOrder.indexOf(a.configName) - tabOrder.indexOf(b.configName);
      }
    ));
  }

  const setInitialTab = (data) => {
    setCurrTab(data.configName)
    addCloudTab(data, data.display)
  }

  const selectTab = (data) => {
    setCurrTab(data.configName)
  }

  return (
    <div className={"cloud-onboarding-container"}>
      <div className="onboarding-title">CLOUD INTEGRATIONS</div>
      {selectedTabs.length > 0 && !loading ? <>
        <div className={"cloud-tabs-container"}>
          {selectedTabs.map(tab =>
            <Tab
              onClick={selectTab}
              key={tab.configName}
              data={tab}
              selected={currTab === tab.configName}
              showCloseIcon={!accountData?.filter((account) => account.connector_type === tab.configName).length}
              onClose={removeCloudTab}
            />
          )}
          <div className={"cloud-tab " + (currTab === 'add service' ? ' selected' : '') + 'add-service'}>
            <span onClick={() => setOpenAddService(!openAddService)}>+ ADD ADDITIONAL SERVICE »</span>
          </div>
          {openAddService &&
          <AddCloudModal
            title={"+ ADD ADDITIONAL SERVICE »"}
            otherTabs={allTabs}
            setSelectedTabs={addCloudTab}
            selectedTabs={selectedTabs}
            close={setOpenAddService}
          />}
        </div>
        <div className="cloud-content-container">
          <CloudOnboardingContent
            config={cloudInputConfigs[currTab]}
            fetchData={fetchData}
            accountData={accountData?.filter(account => account.connector_type === currTab)}
            connectorType={currTab}
            loading={loading}
            fetchError={fetchError}
            setAccountData={setAccountData}
            instructionsLink={instructionLinks[currTab]}
            defaultInputValues={defaultInputValues[currTab]}
            customElements={customElements[currTab]}
            testAccountHandler={testAccountHandler}
          />
        </div>
      </> : selectedTabs.length === 0 && !loading ?
        <NoCloudTabs
          onClick={setInitialTab}
          allTabs={defaultTabOptions}
          setOpenAddService={setOpenAddService}
          openAddService={openAddService}
          addCloudTab={addCloudTab}
          selectedTabs={selectedTabs}
        /> : loading && <Loader/>}
    </div>
  );
};

export default CloudOnboarding;
