import React from "react";
import { instructionLinks, instructions } from "../config";

export function CloudOnboardingInstructions({ connectorType }) {
  return (
    <div className="cloud-instructions">
      <span className="instructions-title">ADD AN INTEGRATION</span>
      <div className="info-text">
        {instructions[connectorType].text}{" "}
        <a
          href={instructionLinks[connectorType]}
          target="_blank"
          rel="noreferrer"
          className="link instructions"
        >
          {"FULL INSTRUCTIONS »"}
        </a>
      </div>

      <div className="info-sub">
        {"Please fill in all fields below. After adding, click the"}{" "}
        <span>{"TEST"}</span> {"button to verify the integration."}
      </div>
      <div>
        {
          "If you encounter any issues and are unable to verify your integration, please contact "
        }
        <a href={"mailto: support@actzero.ai"} className="link">
          {"support@actzero.ai."}
        </a>
      </div>
    </div>
  );
}
