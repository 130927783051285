import "../../../styles/pages/Onboarding/Contacts/ContactsOnboarding.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  addOnboardingContact,
  getOnboardingContacts,
} from "../../../api/ApiClient";
import UserData from "../../../store/User/UserData";
import GlobalState from "../../../store/GlobalState/GlobalState";
import { trackPageView } from "../../../util/analytics";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UploadModal from "../UploadModal";
import { Loader } from "../../../components/Loader/Loader";
import ErrorIcon from "@mui/icons-material/Error";
import ContactsOnboardingTable from "./ContactsOnboardingTable";
import ConfirmModal from "../../../components/Modal/ConfirmModal";
import SampleCSV from "../../../assets/files/Sample_CSV_Format_Contacts_Onboarding.csv";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Pagination } from "../../../components";
import Modal from "../../../components/Modal/Modal";
import {
  handleAddUser,
  handleDeleteUsers,
  handleEditUser,
  handleResendInvite,
} from "./util";
import {
  addInputsConfig,
  addUserCheckboxConfig,
  editUserCheckboxConfig,
  defaultInputs,
  pageSize,
  requiredParameters,
} from "./config";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Tooltip from "../../../components/Tooltip/Tooltip";
import NotFound from "../../../navigation/NotFound";
import { tooltipMapping } from "../../../config/tooltipTextMapping";
import { captureSentryError } from "../../../util/sentry";

const ContactsOnboarding = ({ selectedChildTenant }) => {
  const [azState] = useContext(GlobalState);
  const [userData] = useContext(UserData);
  const [contactsUserData, setContactsUserData] = useState([]);
  const [dataTotal, setDataTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [addUserInputs, setAddUserInputs] = useState(defaultInputs);
  const [editUserInputs, setEditUserInputs] = useState(defaultInputs);
  const [actionError, setActionError] = useState();
  const [addError, setAddError] = useState();
  const [editError, setEditError] = useState();
  const [rowError, setRowError] = useState();
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [rowLoading, setRowLoading] = useState(false);
  const [showConfirmResendModal, setShowConfirmResendModal] = useState(false);
  const [modalErrors, setModalErrors] = useState([]);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("email");
  const [sortOrder, setSortOrder] = useState("asc");
  const [pendingRow, setPendingRow] = useState(() => ({
    row: {},
    action: null,
  }));
  const roleEscalationDefaulted = useRef(false);
  const [fetchError, setFetchError] = useState(false);
  const [totpEnabledUsers, setTotpEnabledUsers] = useState([]);
  const actionMenuConfig = [
    { label: "Reset account", function: setShowConfirmResendModal },
    { label: "Delete", function: setShowConfirmDeleteModal },
  ];
  const defaultCheckboxMapping = {
    Admin: { escalationType: "Primary", contactType: ["Technical"] },
    "Power User": { escalationType: "Primary", contactType: ["Technical"] },
    "Portal User": { escalationType: "Secondary", contactType: ["Technical"] },
    "Contact Only": { escalationType: "Secondary", contactType: ["Business"] },
  };

  useEffect(
    () => trackPageView("Contacts Onboarding", userData, azState),
    // eslint-disable-next-line
    [userData?.userId, azState.reactGaInitializedState]
  );

  useEffect(() => {
    userData.tenant && getContactsUserData();
    // eslint-disable-next-line
  }, [userData?.tenant, sortBy, sortOrder, page, selectedChildTenant]);

  async function getContactsUserData() {
    try {
      setFetchError(false);
      setLoading(true);
      const { results, total } = await getOnboardingContacts(
        !selectedChildTenant
          ? userData?.tenant
          : selectedChildTenant.id
          ? selectedChildTenant.id
          : selectedChildTenant,
        {
          order: sortOrder,
          page: page,
          size: pageSize,
          sort_by: sortBy,
        }
      );
      const totpUsers = results
        .filter((user) => user.mfaSetting === "totp")
        .map((user) => user.email);
      setTotpEnabledUsers(totpUsers);
      setContactsUserData(results);
      setDataTotal(total);
      setLoading(false);
    } catch (e) {
      captureSentryError(
        e,
        userData,
        "getOnboardingContacts API in ContactsOnboarding.js"
      );
      setFetchError(true);
    }
  }

  const handleSubmit = () => {
    const functionMap = {
      delete: deleteUsers,
      resend: resendInvite,
    };
    functionMap[pendingRow.action]();
  };

  const handlePendingRow = (row, action) => {
    if (action === "edit") setEditUserInputs(row);
    setPendingRow({ row: row, action: action });
  };

  const resetPendingStage = () => {
    setRowError();
    setPendingRow({ row: {}, action: null });
  };

  const handleActionMenu = (setModal) => {
    resetPendingStage();
    selectedRows.length && setModal(true);
    setShowActionMenu(false);
  };

  const resetModal = () => {
    setShowConfirmDeleteModal();
    setShowConfirmResendModal();
    setPendingRow({
      row: {},
      action: null,
    });
    setEditUserInputs(defaultInputs);
    setEditError();
    setModalErrors([]);
  };

  const resendInvite = (isMultiple) =>
    handleResendInvite(
      isMultiple,
      setRowLoading,
      selectedRows,
      userData,
      selectedChildTenant === null ? userData?.tenant : selectedChildTenant,
      setModalErrors,
      setSelectedRows,
      pendingRow,
      setRowError,
      resetPendingStage,
      setActionError,
      resetModal
    );

  const deleteUsers = (isMultiple) =>
    handleDeleteUsers(
      isMultiple,
      setRowLoading,
      selectedRows,
      userData,
      selectedChildTenant === null ? userData?.tenant : selectedChildTenant,
      setModalErrors,
      setSelectedRows,
      setShowConfirmDeleteModal,
      getContactsUserData,
      pendingRow,
      setRowError,
      resetPendingStage,
      setContactsUserData,
      setActionError,
      setTotpEnabledUsers
    );

  const handleCheckboxSelection = (menu, opt, isMulti, func) => {
    if (menu === "escalationType") roleEscalationDefaulted.current = true;
    func((prevState) => {
      let copy = { ...prevState };
      if (menu === "userRole") {
        copy = setDefaultCheckboxes(copy, opt);
      }
      if (isMulti) {
        if (!copy[menu]) copy[menu] = [];
        copy[menu] = copy[menu].includes(opt)
          ? [...copy[menu]].filter((s) => s !== opt)
          : [...copy[menu], opt];
      } else {
        copy[menu] = opt;
      }
      return copy;
    });
  };

  const addUserCheckbox = (currentVal, option, isMulti) => {
    if (isMulti)
      return (typeof currentVal === "boolean" && currentVal) ||
        (currentVal && currentVal.includes(option)) ? (
        <CheckBoxIcon />
      ) : (
        <CheckBoxOutlineBlankIcon />
      );
    else
      return (typeof currentVal === "string" &&
        currentVal.toLowerCase() === option.toLowerCase()) ||
        (typeof currentVal === "boolean" && currentVal) ? (
        <RadioButtonCheckedIcon className="radio" />
      ) : (
        <RadioButtonUncheckedIcon className="radio" />
      );
  };

  const setDefaultCheckboxes = (data, role) => {
    data.escalationType = defaultCheckboxMapping[role].escalationType;
    data.contactType = defaultCheckboxMapping[role].contactType;
    return data;
  };

  const userInputForm = (isEdit) => {
    const inputSetControl = isEdit ? editUserInputs : addUserInputs;
    const inputValControl = isEdit ? setEditUserInputs : setAddUserInputs;
    const checkboxes = isEdit ? editUserCheckboxConfig : addUserCheckboxConfig;
    const showApp2FA =
      isEdit && totpEnabledUsers.includes(inputSetControl?.email);
    return (
      <div>
        {addInputsConfig
          .filter((input) => !(input.column === "email" && isEdit))
          .map((input) => (
            <span
              className={`${
                requiredParameters[input.column] ||
                (input.column === "mobilePhone" &&
                  (inputSetControl.userRole === "Admin" ||
                    inputSetControl.userRole === "Read Only" ||
                    inputSetControl.userRole === "Read Write"))
                  ? "required"
                  : ""
              }`}
              key={input.placeholder}
            >
              {input.column === "mobilePhone" ? (
                <PhoneInput
                  className={`${
                    input.className ? input.className : input.column
                  }`}
                  placeholder={input.placeholder}
                  value={inputSetControl[input.column]}
                  onChange={(value) => {
                    let copy = { ...inputSetControl };
                    copy[input.column] = value;
                    inputValControl(copy);
                  }}
                />
              ) : (
                <input
                  placeholder={input.placeholder}
                  className={`${
                    input.className ? input.className : input.column
                  }`}
                  value={inputSetControl[input.column]}
                  onChange={(e) => {
                    let copy = { ...inputSetControl };
                    copy[input.column] = e.target.value;
                    inputValControl(copy);
                  }}
                  maxLength={input.column === "jobTitle" ? "25" : ""}
                />
              )}
            </span>
          ))}
        <div className={`add-user-checkboxes ${isEdit && "editing"}`}>
          {checkboxes.map((d) => {
            return (
              <div className={`selections-container`} key={d.label}>
                <div className="selections-label">{d.label}</div>
                <ul className="checkbox-options">
                  {d.options.map((opt) => (
                    <li
                      onClick={() =>
                        handleCheckboxSelection(
                          d.value,
                          opt,
                          d.multiselect,
                          inputValControl,
                          inputSetControl
                        )
                      }
                      key={opt}
                      className={`checkbox-option ${
                        !showApp2FA && isEdit && opt === "totp"
                          ? "disabled"
                          : ""
                      }`}
                    >
                      <div className="checkbox-icon">
                        {addUserCheckbox(
                          inputSetControl[d.value],
                          opt,
                          d.multiselect,
                          d.label
                        )}
                      </div>
                      {tooltipMapping?.onboarding?.checkboxes[opt] ? (
                        <Tooltip
                          content={tooltipMapping?.onboarding?.checkboxes[opt]}
                          direction="right"
                          className={"options-tooltip"}
                        >
                          {" "}
                          <span className="label">
                            {opt === "totp" ? "App 2FA" : opt}
                          </span>{" "}
                        </Tooltip>
                      ) : (
                        opt
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="contacts-onboarding-content">
      {showConfirmResendModal && (
        <ConfirmModal
          message={`Reset account of ${selectedRows.length} user${
            selectedRows.length > 1 ? "s" : ""
          }?`}
          confirmFunc={() => resendInvite(true)}
          cancelFunc={resetModal}
          modalErrors={modalErrors}
          rowLoading={rowLoading}
        />
      )}
      {showConfirmDeleteModal && (
        <ConfirmModal
          message={`Delete ${selectedRows.length} user${
            selectedRows.length > 1 ? "s" : ""
          }?`}
          confirmFunc={() => deleteUsers(true)}
          cancelFunc={resetModal}
          modalErrors={modalErrors}
          rowLoading={rowLoading}
        />
      )}
      {pendingRow.action === "edit" && (
        <div className="edit-modal">
          <Modal close={resetModal}>
            <span className="edit-header">{`Edit person: ${pendingRow.row.email}`}</span>
            <div className="edit-modal-content">
              <div className="edit-form-container">{userInputForm(true)}</div>
              {rowLoading ? (
                <Loader />
              ) : (
                <IconButton
                  className="submit-btn"
                  onClick={() =>
                    handleEditUser(
                      setRowLoading,
                      setEditError,
                      setPendingRow,
                      editUserInputs,
                      userData,
                      selectedChildTenant === null
                        ? userData?.tenant
                        : selectedChildTenant,
                      setContactsUserData,
                      resetPendingStage,
                      setTotpEnabledUsers
                    )
                  }
                >
                  <div className="files-button">{"SUBMIT"}</div>
                </IconButton>
              )}
            </div>
            {editError && (
              <div className="error edit">
                <ErrorIcon className="err-icon" /> {editError}
              </div>
            )}
          </Modal>
        </div>
      )}
      <UploadModal
        isOpen={showUploadModal}
        setOpen={setShowUploadModal}
        userData={userData}
        uploadFunction={addOnboardingContact}
        refreshData={getContactsUserData}
        sampleCSV={SampleCSV}
      />
      <div className="onboarding-title contacts">PEOPLE</div>
      {fetchError ? (
        <NotFound isError dataError />
      ) : (
        <>
          <div className="form-container">
            <div className="input-container">
              <div className="label">ADD NEW PERSON +</div>
              {userInputForm()}
            </div>
            <div className="add-btn-container">
              <IconButton
                onClick={() =>
                  handleAddUser(
                    userData,
                    selectedChildTenant === null
                      ? userData.tenant
                      : selectedChildTenant,
                    setContactsUserData,
                    setAddError,
                    setIsUploading,
                    addUserInputs,
                    setAddUserInputs
                  )
                }
              >
                {isUploading ? <Loader /> : <div className="add-btn">ADD</div>}
              </IconButton>
              {addError && (
                <div className="error add">
                  <ErrorIcon className="err-icon" /> {addError}
                </div>
              )}
            </div>
          </div>
          <div className="actions-container">
            <span
              className="selection"
              onClick={() => setShowActionMenu(!showActionMenu)}
            >
              Actions <KeyboardArrowDownIcon className="down-arrow" />
            </span>
            {showActionMenu && (
              <ul
                className="options"
                onMouseLeave={() => setShowActionMenu(false)}
              >
                {actionMenuConfig.map((a) => (
                  <li
                    className={`option ${a.disabled ? "disabled" : ""}`}
                    onClick={
                      !a.disabled ? () => handleActionMenu(a.function) : null
                    }
                    key={a.label}
                  >
                    {a.label}
                  </li>
                ))}
              </ul>
            )}
            {actionError && (
              <div className="error action">
                <ErrorIcon className="err-icon" /> {actionError}
              </div>
            )}
          </div>
          <ContactsOnboardingTable
            contactsUserData={contactsUserData}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            pendingRow={pendingRow}
            setPendingRow={handlePendingRow}
            handleSubmit={handleSubmit}
            resetPendingStages={resetPendingStage}
            dataLoading={loading}
            rowLoading={rowLoading}
            rowError={rowError}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            sortBy={sortBy}
            setSortBy={setSortBy}
            userData={userData}
          />
          <div className={"pagination-controls"}>
            <Pagination
              setPage={setPage}
              page={page}
              total={dataTotal}
              pageSize={pageSize}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ContactsOnboarding;
