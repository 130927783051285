import "../../../styles/pages/Onboarding/VirtualMachine/VirtualMachineInfo.scss";
import React, {useContext, useState} from "react";
import UserData from "../../../store/User/UserData";
import CopyModal from "./CopyModal";
import {useOnClickOutside} from "../../../util/clickOutside";

const VirtualMachineInfo = () => {
  const [userData] = useContext(UserData);
  const [showId, setShowId] = useState(false)
  const [stringCopied, setStringCopied] = useState(false)
  const activationCodeRef = useOnClickOutside(() => setShowId());

  return (
    <>
      <div>
        <p className={"vm-description"}>
          <div className={"flex"}>
          <span>
            Configure a virtual machine (VM) to activate log collection and vulnerability scanning within your network environment.
          </span>
            <p ref={activationCodeRef}>
          <span
            className="link activation-code"
            onClick={() => {
              setShowId(!showId);
              setStringCopied(false);
            }}>Activation Code »</span>
              {showId && <CopyModal
                title={"Activation Code:"}
                setStringCopied={setStringCopied}
                stringCopied={stringCopied}
                value={userData?.tenantConfig?.actzero_id}
              />}
            </p>
          </div>
          <a
            href={"https://docs.actzero.ai/install-vm/"}
            target="_blank"
            rel="noreferrer"
            className="link instructions"
          >{"FULL INSTRUCTIONS »"}</a>
        </p>
      </div>
    </>);
};

export default VirtualMachineInfo;
