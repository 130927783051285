import * as d3ss from "d3-simple-slider";
import * as d3 from "d3";
import React, {useEffect, useRef, useState} from "react";
import '../../../styles/components/D3/TimeSlider/TimeSlider.scss';
import {resize} from "../../../util/resize";

const TimeSlider = ({setTime, name, title, tenant, width, marginLeft}) => {
  const chartRef = useRef(null);
  const [chartWidth, setChartWidth] = useState({width: width});

  useEffect(() => {
    const handleResize = () => {
      resize(marginLeft, chartRef, setChartWidth);
    };

    handleResize(); // Initial call to set width on component mount

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, [marginLeft]);

  useEffect(() => {
    // hardcode February 2023 for hackedbox tenant demo data
    let minTimestamp = tenant === "hackedbox" ? 1675231200000 : new Date(new Date().setDate(new Date().getDate() - 30)).getTime();
    let maxTimestamp = tenant === "hackedbox" ? 1677650400000 : new Date().getTime(); // Get current timestamp

    d3.select(`.svg.${name}`).remove();

    let slider = d3ss
      .sliderHorizontal()
      .min(new Date(minTimestamp))
      .max(new Date(maxTimestamp))
      .default(name === 'end' ? maxTimestamp : minTimestamp)
      .step(1)
      .width(chartWidth.width)
      .displayValue(true)
      .displayFormat(d3.utcFormat('%d %b'))
      .tickFormat(d3.utcFormat("%d"))
      .ticks(10)
      .on('onchange', val => d3.select(`.time-value.${name}`).text(new Date(val).toUTCString().slice(0, -3)))
      .on("end", d => setTime(d.getTime() / 1000));

    d3.select(`.slider.${name}`)
      .append('svg')
      .attr('class', `svg ${name}`)
      .attr('width', chartWidth.width + "px")
      .attr('height', 100)
      .append('g')
      .attr('transform', 'translate(30,30)')
      .call(slider);
    // eslint-disable-next-line
  }, [name, setTime, tenant, chartWidth.width]);

  return (
    <div className="slider-container" ref={chartRef}>
      <div className={"slider-info"}>
        <p className={"slider-title"}>{title}</p>
        <p className={"time-value " + name}/>
      </div>
      <div className={"slider " + name}/>
    </div>
  );
};

export default TimeSlider;
