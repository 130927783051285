import React, {useState, useLayoutEffect, useRef} from "react";
import {formatTime} from "../../../util/format";
import Row from "../../../components/Table/Row";
import '../../../styles/components/Table/Row.scss';
import SeverityImage from "../../../components/Table/SeverityImage";
import {severities} from "../../../config/mappingData";
import Linkify from "linkify-react";

const UpdatesRow = ({data, page, expandAll, index, setExpandAll, setExpandedIndexes, expandedIndexes, isExpanded}) => {
  const [titleOverflowing, setTitleOverflowing] = useState(true);
  const [descriptionOverflowing, setDescriptionOverflowing] = useState(true);
  const titleContainerRef = useRef();
  const titleTextRef = useRef();
  const descriptionContainerRef = useRef();
  const descriptionTextRef = useRef();

  // Layout effect to determine if title or description is overflowing; if so, add expand button
  useLayoutEffect(() => {
    const titleResizeObserver = new ResizeObserver(() => setTimeout(() => {
      if (
        titleTextRef.current?.offsetWidth >=
        titleContainerRef.current?.clientWidth - 2 // padding is 1px on both sides
      )
        setTitleOverflowing(true); 
      else setTitleOverflowing(false);
    }), 0);
    const descriptionResizeObserver = new ResizeObserver(() => setTimeout(() => {
      if (
        descriptionTextRef.current?.offsetWidth >=
        descriptionContainerRef.current?.clientWidth - 2 // padding is 1px on both sides
      )
        setDescriptionOverflowing(true);
      else setDescriptionOverflowing(false);
    }), 0);
    titleResizeObserver.observe(titleContainerRef.current);
    descriptionResizeObserver.observe(descriptionContainerRef.current);
    return () => {
      titleResizeObserver.disconnect();
      descriptionResizeObserver.disconnect();
    };
  }, []);

  const handleClick = async () => {
    if(!isExpanded) {
      setExpandedIndexes(prevExpandedIndexes => {return [...prevExpandedIndexes, index]})
      isExpanded = true
    } else {
      setExpandedIndexes(expandedIndexes.filter(x => x !== index))
      setExpandAll(expandAll.filter(x => x !== index))
      isExpanded = false
    }
  }

  return (
    <>
      <Row
        page={
          !titleOverflowing && !descriptionOverflowing
            ? `${page} not-clickable`
            : page
        }
        isExpanded={isExpanded}
        onClick={(titleOverflowing || descriptionOverflowing) && handleClick}
        data={data}
      >        
        <td className={"report_date td-value"}>
          {(titleOverflowing || descriptionOverflowing)  && 
            <>
              {isExpanded ?
                <div className={"expand-btn minus"}>-</div>
                : <div className={"expand-btn"}>+</div>
              }
            </>
          }
          {formatTime(data.report_date)}
        </td>
        <td className={"Source td-value"}>
          {data.hostname || "N/A"}
        </td>
        <td className={isExpanded + " Severity"}>
          <SeverityImage severities={severities} severity={data.severity || "N/A"}/>
        </td>
        <td className={"td-value Title"} ref={titleContainerRef}>
          <div className={`td-top ${isExpanded}`} ref={titleTextRef}>{data.title || "N/A"}</div>
        </td>
        <td className={"td-value Description"} ref={descriptionContainerRef}>
          <div className={`td-top ${isExpanded}`} ref={descriptionTextRef}> 
            <Linkify options={{className: "description-link", defaultProtocol: "https", target: "_blank"}}>
              {data.description || "N/A"}
            </Linkify>
          </div>
        </td>
      </Row>
     </>
  );
}

export default UpdatesRow;
