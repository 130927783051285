import {Dropdown as BootstrapDropdown} from 'react-bootstrap';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import UserData from "../../store/User/UserData";
import {Loader} from "../Loader/Loader";
import React, {useContext, useState} from "react";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {getExportedLogs, postExportLogs} from "../../api/ApiClient";
import '../../styles/components/Export/Export.scss';
import {useOnClickOutside} from "../../util/clickOutside.js"
import Tooltip from "../Tooltip/Tooltip";
import {tooltipMapping} from "../../config/tooltipTextMapping";
import { captureSentryError } from '../../util/sentry.js';

const Export = ({
                  className,
                  currentSearchConfig,
                  setParams,
                  params,
                  bauExportLoading
                }) => {
  const [userData] = useContext(UserData);
  const [pastExportsLoading, setPastExportsLoading] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)
  const [showExportDropdown, toggleShowExportDropdown] = useState(false)
  const [showPastExportedFiles, setShowPastExports] = useState(false)
  const [pastExportedFiles, setPastExportedFiles] = useState([])


  const toggleExport = () => {
    if (pastExportedFiles.length === 0) {
      // Call list exported files endpoint
      refreshExportedFiles()
    }
    if (showExportDropdown) {
      toggleShowExportDropdown(false)
    } else {
      toggleShowExportDropdown(true)
    }
    setShowPastExports(false)
  }

  const handleBAUExport = () => {
    setParams({...params, isExport: true})
  }

  const onExport = async () => {
    //Send search to export endpoint
    if (exportLoading) {
      return
    }
    setExportLoading(true)
    try {
      let variables = currentSearchConfig
      if (variables.hasOwnProperty('date_searched')) { //past search
        delete variables['date_searched']
      }
      await postExportLogs(variables)
    } catch (e) {
      captureSentryError(e, userData, "getExportedLogSearch");
    } finally {
      setTimeout(() => {
        setExportLoading(false)
      }, 300000);
    }
  }

  const refreshExportedFiles = async () => {
    // Call list exported files endpoint
    setPastExportsLoading(true)
    try {
      const listExportedLogsResponse = await getExportedLogs(userData?.tenant)
      let pastExportedFiles = listExportedLogsResponse.map((item, i) => {
        return <BootstrapDropdown.Item key={i} href={item.url}>{item.file_name}</BootstrapDropdown.Item>
      })
      setPastExportedFiles(pastExportedFiles)
    } catch (e) {
      captureSentryError(e, userData, "listExportedSearches");
    } finally {
      setPastExportsLoading(false)
    }
  }

  let domNode = useOnClickOutside(() => {
    toggleShowExportDropdown(false)
    setShowPastExports(false)
  });

  return (
    <div className={(currentSearchConfig ? 'export ' : ' export-bau ') + (className ? className : '')}>
      {currentSearchConfig ? <div className={'export-container'} ref={domNode}>
          <BootstrapDropdown
            className={"export-button"}
            autoClose='outside'
            drop="down">
            <div className={"export-toggle-container"}>
              <Tooltip content={tooltipMapping.logSearch.export} className={"export-logs"} direction={"left"}>
                <button className={"export-toggle"} onClick={() => toggleExport()}>Export</button>
              </Tooltip>
              {showExportDropdown || showPastExportedFiles ?
                <KeyboardDoubleArrowLeftIcon className={'arrow-down-export'} onClick={() => toggleExport()}/>
                :
                <KeyboardDoubleArrowRightIcon className={"arrow-up-export"} onClick={() => toggleExport()}/>
              }
            </div>
            {showPastExportedFiles && showExportDropdown ?
              <BootstrapDropdown.Menu
                className={`export-menu past-files ` + (showPastExportedFiles && pastExportedFiles.length > 0 ? 'true' : '')}
                show={showPastExportedFiles && showExportDropdown}
              >
                {pastExportedFiles}
                {pastExportedFiles.length > 0 ? <BootstrapDropdown.Divider/> : null}
                {pastExportsLoading ?
                  <div className={"loader-container"}><Loader/></div> :
                  <BootstrapDropdown.Item
                    className={'export-loading ' + pastExportsLoading}
                    disabled={pastExportsLoading}
                    onClick={() => refreshExportedFiles()}>
                    <i>Refresh...</i>
                  </BootstrapDropdown.Item>
                }
              </BootstrapDropdown.Menu>
              :
              <BootstrapDropdown.Menu className={"export-menu " + showExportDropdown} show={!showPastExportedFiles}>
                <BootstrapDropdown.Item
                  as={ButtonGroup}
                  className={'export-loading ' + exportLoading}
                  disabled={exportLoading}
                  onClick={() => onExport()}>
                  Export All
                </BootstrapDropdown.Item>
                <BootstrapDropdown.Divider/>
                <BootstrapDropdown.Item
                  as={ButtonGroup}
                  className={'export-loading'}
                  onClick={() => setShowPastExports(true)}
                >Past Exports
                </BootstrapDropdown.Item>
              </BootstrapDropdown.Menu>
            }
          </BootstrapDropdown>
        </div> :
        bauExportLoading 
        ? <Loader classname="bau-export-loader"/>
        : <button
          download="filename.csv"
          className={"export-button-bau"}
          onClick={() => handleBAUExport()}>Export</button>}
    </div>)
}

export default Export;