import React, {useEffect, useState} from "react";
import moment from "moment";
import LineChartLegend from "../../components/D3/LineChart/Legend";
import LineChart from "../../components/D3/LineChart/LineChart";

const ConnectionsLineChart = ({data, colors, displayName, numberFormat, contractedAmount}) => {
  const [axisData, setAxisData] = useState({x: {min: 0, max: 0}, y: {max: 0}})
  const [mappedGraphData, setMappedGraphData] = useState([])
  const lineData = {
    lineOpacity: "1",
    lineOpacityHover: "1",
    otherLinesOpacityHover: "0.3",
    lineStroke: "3px",
    lineStrokeHover: "5px"
  };

  useEffect(() => {
    const mapGraphData = (data) => {
      let names = Object.keys(data)
      let values;
      let yMax = 0
      let yMin = 0
      let xMax = 0
      let xMin = 0
      let formattedData = []
      names.forEach(name => {
        const iso = moment(data[name][data[name].length - 1][0] * 1000).toISOString()
        xMin = new Date(iso.slice(0, -1))
        values = data[name].map(value => {
          const isoStr = moment(value[0] * 1000).toISOString()
          value[0] = new Date(isoStr.slice(0, -1))
          if (value[1] > yMax) yMax = value[1]
          if (value[1] < yMin) yMax = value[1]
          if (Date.parse(value[0]) < Date.parse(xMin)) xMin = value[0]
          if (Date.parse(value[0]) > Date.parse(xMax)) xMax = value[0]
          return {date: value[0], value: value[1]}
        })
        formattedData.push({name: name, values: [...values]})
      })
      setMappedGraphData(formattedData)
      if (contractedAmount !== undefined) yMax += 0.5
      if (contractedAmount !== undefined) yMin -= 0.5
      setAxisData({x: {min: xMin, max: xMax}, y: {max: yMax, min: yMin}})
    }
    data && mapGraphData(data)
  }, [data, displayName, colors, contractedAmount])

  return (
    <div>
      {mappedGraphData.length > 0 &&
      <div>
        <div className={"legend-container"}>
          <LineChartLegend
            data={mappedGraphData}
            colors={colors}
            rectPosition={200}
            textPosition={215}
          />
        </div>
        <LineChart
          numberFormat={numberFormat}
          contractedAmount={contractedAmount}
          width={630}
          plotRadius={2}
          yAxisTicks={5}
          xAxisTicks={mappedGraphData[0].values.length}
          lineData={lineData}
          data={mappedGraphData}
          className={`${displayName}-line-chart`}
          axis={axisData}
          widthOffset={.6}
          height={150}
          marginLeft={50}
          colors={colors}
          timeFormat={"%d"}
        />
      </div>
      }
    </div>
  );
};

export default ConnectionsLineChart;
