import React from "react";
import Row from "../../../components/Table/Row";
import '../../../styles/pages/Vulnerabilities2/AssetsWithVulnerabilities/AssetsWithVulnerabilitiesTable.scss';
import ExpandedView from "./ExpandedView";

const AssetsWithVulnerabilitiesRow = ({
                                        data,
                                        page,
                                        mapMainRow,
                                        expandedIndexes,
                                        setExpandedIndexes,
                                        expandAll,
                                        setExpandAll,
                                        index,
                                        setCurrTab,
                                        action
                                      }) => {

  const isExpanded = expandedIndexes?.includes(index) || expandAll?.includes(index)

  const handleClick = (e) => {
    if (e.target.type !== 'checkbox') {
      if (!expandedIndexes.includes(index)) {
        setExpandedIndexes([...expandedIndexes, index])
        setExpandAll(expandAll.filter(x => x !== index))
      } else {
        setExpandedIndexes(expandedIndexes.filter(x => x !== index))
      }
    }
  }

  return (
    <>
      <Row key={index + page} page={page} onClick={(e) => handleClick(e)} data={data} className={isExpanded ? 'selected' : ''}>
        <td className={"Time td-value"}>
          {isExpanded ? <div className={"expand-btn minus"}>-</div> : <div className={"expand-btn"}>+</div>}
        </td>
        {Object.values([mapMainRow['Asset Name'], mapMainRow['IP Address'], mapMainRow['Type'],
          mapMainRow['Vulnerabilities'], mapMainRow['Missing Patches']])
          .map((item, index) => {
            let value = data[item?.field] || ''
              return <td key={index} className={item.field + " td-value"}><div>{value}</div></td>
          })}
      </Row>
      {isExpanded && <ExpandedView action={action} data={data} key={index} setParentTab={setCurrTab}/>}
    </>
  );
};

export default AssetsWithVulnerabilitiesRow;
