import "../../../styles/pages/Onboarding/Firewall/AddFirewallForm.scss";
import React, {useContext, useEffect, useState} from "react";
import {addFirewallConfig, requiredFirewallParameters, vendors} from './config';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {editFirewallOnboarding, firewallOnboardingAPI} from "../../../api/ApiClient";
import UserData from "../../../store/User/UserData";
import {Loader} from "../../../components/Loader/Loader";
import Tooltip from "../../../components/Tooltip/Tooltip";
import TooltipIcon from "../../../assets/icons/tooltip.svg";
import { captureSentryError } from "../../../util/sentry";

const AddFirewallForm = ({existingData, getData, resetModal}) => {
  const [userData] = useContext(UserData);
  const [inputValues, setInputValues] = useState({});
  const [showDropdown, setShowDropdown] = useState(false)
  const [vendor, setVendor] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  useEffect(() => {
    const mapExistingData = () => {
      let mappedData = {
        type: existingData?.type,
        ip: existingData?.ip,
        hostname: existingData?.hostname,
        notes: existingData?.notes
      }
      let type = vendors.filter(x => x[existingData?.type])[0][existingData?.type]
      setVendor(type)
      setInputValues(mappedData)
    }
    existingData && mapExistingData()
  }, [existingData])

  const verifyFields = () => {
    let errorMessage = ''
    Object.keys(requiredFirewallParameters).some(item => {
      if (inputValues[item] === '' || !Object.keys(inputValues).includes(item))
        return errorMessage = {[item]: requiredFirewallParameters[item]}
      if (item === 'ip') {
        const regexExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
        let isValidIP = regexExp.test(inputValues[item])
        if (!isValidIP) return errorMessage = {[item]: requiredFirewallParameters[item]}
      }
      return errorMessage = ''
    })
    setError(errorMessage)
    return errorMessage
  }

  const postNewFirewall = async () => {
    if (verifyFields() === '') {
      setLoading(true)
      try {
        await firewallOnboardingAPI("POST", userData?.tenant, inputValues)
        await getData()
        if(resetModal) resetModal()
      } catch (e) {
        captureSentryError(e, userData, "POST firewallOnboardingAPI API in AddFirewallForm.js");
      } finally {
        setLoading(false)
        setInputValues({})
        setVendor(null)
      }
    }
  }

  useEffect(() => {
    setError('')
  }, [inputValues])

  const updateFirewall = async (id) => {
    setLoading(true)
    try {
      await editFirewallOnboarding("PATCH", userData?.tenant, id, inputValues)
      await getData()
    } catch (e) {
      captureSentryError(e, userData, "PATCH firewallOnboardingAPI API in AddFirewallForm.js");
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={"firewall-form"}>
      <div>
        {!existingData && <div>
          <p className={"firewall-form-title"}>ADD A NETWORK DEVICE</p>
          <p className={"help-text"}>
            Enter the relevant info below for each network device that will be forwarding logs to ActZero
          </p>
        </div>}
        <div className={"flex vendor-dropdown"}>
          <p className={!existingData ? "dropdown-label add" : "dropdown-label"}>Vendor</p>
          <div className="actions-container">
          <span
            className="selection flex"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            {vendor || "Vendor name"} <KeyboardArrowDownIcon className="down-arrow"/>
            {error?.type && <p className={"error-msg"}>{error?.type}</p>}
          </span>
            {showDropdown && (
              <ul
                className="options vendor-options"
                onMouseLeave={() => setShowDropdown(false)}
              >{vendors.map((x, i) => <li
                  className="option"
                  onClick={() => {
                    setInputValues({...inputValues, type: Object.keys(x)[0]})
                    setVendor(Object.values(x)[0])
                    setShowDropdown(false)
                  }}
                  key={i}
                >{Object.values(x)[0]}</li>
              )}
              </ul>
            )}
          </div>
        </div>
        <table>
          <tbody>
          {addFirewallConfig.map((input) => (
            <tr key={input.value}>
              <td className={`label-cell ${input.label.toLowerCase()}`}>
                <span className="input-label">{input.label}</span>
                {input.tooltip && (
                  <span className="input-tooltip-container">
                    <Tooltip
                      content={input.tooltip}
                      className={"input-tooltip"}
                      direction={"right"}
                      disableTimeout
                    >
                      <img
                        className="tooltip-icon"
                        src={TooltipIcon}
                        alt="?"
                      />
                      </Tooltip>
                    </span>
                )}
              </td>
              <td className={"flex"}>
                {input.label !== 'Notes' ? <input
                    key={`${input.value}-input`}
                    className={`input-box`}
                    type={"text"}
                    value={inputValues[input.value] ? inputValues[input.value] : ""}
                    placeholder={""}
                    onChange={(e) => setInputValues({...inputValues, [input.value]: e.target.value})}
                  /> :
                  <textarea
                    value={inputValues.notes || ""}
                    className={`input-box notes`}
                    onChange={(e) => setInputValues({...inputValues, notes: e.target.value})}
                  />}
                {error[input.value] && <p className={"error-msg"}>{error[input.value]}</p>}
                {input.label === 'Notes' && (loading ? <Loader/> : !loading && existingData ?
                  <div
                    className={error === '' ? 'add-btn' : 'add-btn red-button'}
                    onClick={() => updateFirewall(existingData?._id)}
                  >UPDATE</div> :
                  <div
                    className={error === '' ? 'add-btn' : 'add-btn red-button'}
                    onClick={() => postNewFirewall()}
                  >ADD</div>)}
              </td>
            </tr>
          ))}</tbody>
        </table>
      </div>
    </div>
  );
};

export default AddFirewallForm;
