import React, {useContext, useEffect, useState, useRef} from 'react';
import PaginationControls from "../../components/Table/PaginationControls";
import {Pagination} from "../../components";
import {severities} from "../../config/mappingData";
import GlobalState from "../../store/GlobalState/GlobalState";
import UserData from "../../store/User/UserData";
import {getInvestigation} from "../../api/ApiClient";
import PageFilters from "../../components/Filters/PageFilters";
import filterConfig, {resetFilterConfig} from "./config/filterConfig";
import {Loader} from "../../components/Loader/Loader";
import '../../styles/pages/Investigations/Investigations.scss';
import ExpandView from "./ExpandView";
import _ from 'lodash';
import moment from 'moment';
import {getInvestigationData} from "../../util/getInvestigationsData";
import {formatTimeFilters, getBaseParameters} from "../../util/format.js"
import {mainRow} from "../../components/Mappings/Investigations"
import {useLocation} from 'react-router-dom';
import Table from "../../components/Table/Table";
import TableHeader from "../../components/Table/TableHeader";
import InvestigationsRow from "./InvestigationsRow";
import {setSortFilterValue} from "../../util/handleSortResults";
import {setResetFilter} from "../../util/handleResetFilter";
import {trackPageView} from "../../util/analytics";
import Tooltip from "../../components/Tooltip/Tooltip";
import {tooltipMapping} from "../../config/tooltipTextMapping";
import { useSearchParams } from "react-router-dom"
import NotFound from "../../navigation/NotFound";
import { captureSentryError } from '../../util/sentry.js';

const Investigations = () => {
  const [azState] = useContext(GlobalState);
  const [userData] = useContext(UserData);
  const location = useLocation()
  const [queryParameters, setQueryParameters] = useSearchParams();
  const defaultConfig = queryParameters.get("case") ? {page: 1, size: 50, q: queryParameters.get("case")} : location.state?.filters?.investigations?.initialConfig;
  const [pageCount] = useState(50)
  const [page, setPage] = useState(1)
  const today = new Date()
  const defaultStartTime = new Date(new Date().setDate(today.getDate() - 30))
  const [defaultDate, setDefaultDate] = useState(defaultConfig ? {text: null, time: null} : {
    text: 'Last 30 Days',
    time: defaultStartTime
  })
  const [selectedDate, setSelectedDate] = useState(defaultConfig ? '' : defaultDate.text)
  const [investigationsLoading, setInvestigationsLoading] = useState(false)
  const [investigationDetailsLoading, setInvestigationDetailsLoading] = useState(false)
  const [investigationsData, setInvestigationsData] = useState({results: [], total: 0})
  let [sortFilters, setSortFilters] = useState([])
  const [showDropdown, toggleShowDropdown] = useState('')
  let [filterList, setOpenFilters] = useState(filterConfig)
  const [investigationsParams, setInvestigationsParams] = useState({})
  const statuses = [
    {'Re-Opened': '#FFE133'},
    {'Waiting_On_ActZero': '#d0f31b'},
    {'Waiting_On_Customer': '#FFA415'},
    {'Resolved': '#018D8D'},
    {'Merged': '#018D8D'},
    {'Closed': '#018D8D'}
  ]
  const [openInvestigationData, setOpenInvestigationData] = useState({})
  const [criticalThreats, setCriticalThreats] = useState(0)
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [initialConfig, setInitialConfig] = useState(
      defaultConfig ||
      {start_time: moment(defaultStartTime).format(), end_time: moment(today).format(), page: 1, size: pageCount})
  let [allData, setAllData] = useState([])
  const [initialFilters, setInitialFilters] = useState(
    location.state?.filters?.investigations?.initialFilters && location.state?.filters?.investigations?.initialFilters)
  const [exportLoading, setExportLoading] = useState(false);
  const [isReset, setIsReset] = useState(false)
  const [fetchError, setFetchError] = useState(false);
  const initialDataLoaded = useRef(false);
  const prevParams = useRef({});

  const resetAllFilters = async () => {
    setIsReset(true)
    let config = {
      start_time: moment(defaultStartTime).format(),
      end_time: moment(today).format(),
      page: 1,
      size: pageCount
    }
    setInvestigationsParams(config)
    setExpandedIndex(null)
    setOpenInvestigationData({})
    window.history.replaceState('', location?.state?.filters?.investigations?.initialConfig?.q)
  }

  useEffect(() => {
    if (userData?.tenant) {
      if (!isReset) {
        if(!_.isEqual(investigationsParams, prevParams.current)){
          Object.keys(investigationsParams).length > 0 && getInvestigationData(setInvestigationsLoading, setInvestigationsData, setFetchError, userData, investigationsParams, setAllData, setExportLoading, setInvestigationsParams, azState?.scrollYPosition)
          prevParams.current = investigationsParams;
        }
      } else {
        setSortFilterValue([], mainRow, setSortFilters)
        setResetFilter(filterConfig, resetFilterConfig, setOpenFilters)
        setDefaultDate({
          text: 'default',
          time: defaultStartTime
        })
        setSelectedDate('Last 30 Days')
        getInvestigationData(setInvestigationsLoading, setInvestigationsData, setFetchError, userData, investigationsParams, setAllData, setExportLoading, setInvestigationsParams, azState?.scrollYPosition)
        !investigationsLoading && setIsReset(false)
      }
    }
    // eslint-disable-next-line
  }, [userData?.tenant, userData?.userType, investigationsParams]);

  const handleChangePage = async (currPage) => {
    setPage(currPage)
    setInvestigationsParams({...investigationsParams, page: currPage})
  };

// eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => trackPageView("Investigations", userData, azState), [userData?.userId, azState.reactGaInitializedState]);

  useEffect(() => {
    setSortFilterValue([], mainRow, setSortFilters)
    setResetFilter(filterConfig, resetFilterConfig, setOpenFilters)
    if (location?.state?.filters?.investigations?.initialConfig?.source) {
      let name = location?.state?.filters?.investigations?.initialConfig?.source[0]
      filterList.map(filter => {
        let filterName = Object.keys(filter)[0]
        if (filterName === 'Source') {
          filter.Source.filters[name] = true
        }
        if (filterName === 'Cloud') {
          let types = location?.state?.filters?.investigations?.initialConfig?.type
          if (types) filter.Cloud.filters[types[0]] = true
        }
        return filter
      })
      setOpenFilters([...filterList])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.filters?.investigations?.initialConfig])

  useEffect(() => {
    const filterInvestigationsData = () => {
      setPage(1);
      let filterParameters = getBaseParameters(investigationsParams, pageCount, 1, initialConfig, setInitialConfig);
      let filters = filterList;
      if (initialFilters) {
        filters = initialFilters;
        setOpenFilters(initialFilters)
        setInitialFilters();
      }
      filters.forEach(filter => {
        let name = Object.keys(filter)[0] // Filter name (i.e. Severity, Time)
        let filterName = filter[name].filter_name // Filter value string name (i.e. severity, created_date)
        let keys = Object.keys(filter[name].filters); // Filter values available (i.e. High, Critical)
        keys.forEach((key) => { // Check keys to see if they are turned on
          if (filter[name].filters[key] === true) { // filter is turned on
            if (filterName in filterParameters) { // already exists
              filterParameters[filterName].push(key.split(' ').join('_'))
            } else {
              filterParameters[filterName] = [key.split(' ').join('_')]
            }
          }
        })
      })
      if (!_.isEqual(filterParameters, investigationsParams)) {
        setOpenInvestigationData({})
        setInvestigationsParams(filterParameters)
      }
    }
    filterInvestigationsData()
    // eslint-disable-next-line
  }, [filterList, pageCount])

  const onRowClick = async (event, data, refresh = false) => {
    let currentData = data.data || data
    if (!expandedIndex && expandedIndex !== 0) {
      setExpandedIndex(currentData.index)
    } else if (expandedIndex === currentData.index && !refresh) {
      setExpandedIndex(null)
    } else {
      setExpandedIndex(currentData.index)
    }
    let id = currentData.case_id || data;
    if (id === openInvestigationData.case_id && !refresh && event !== 'dashboardrowclick') {
      setOpenInvestigationData({})
      setQueryParameters({})
    } else {
      !refresh && setInvestigationDetailsLoading(true)
      try {
        let investigationData = await getInvestigation(userData?.tenant, id)
        setOpenInvestigationData(investigationData.investigation)
      } catch (e) {
        captureSentryError(e, userData, 'getInvestigation API in Investigations.js');
      } finally {
        setInvestigationDetailsLoading(false)
      }
    }
  }

  const setInitialOpenCase = (caseId) => {
    onRowClick('dashboardrowclick', caseId)
    setExpandedIndex(0);
  }

  useEffect(() => {
    if (queryParameters.get("case") && !initialDataLoaded.current) setInitialOpenCase(queryParameters.get("case"));
    else if (location.state?.case?.id) setInitialOpenCase(location.state?.case?.id);

    initialDataLoaded.current = true;
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(initialDataLoaded.current) setQueryParameters({})
    else initialDataLoaded.current = true;
    // eslint-disable-next-line
  }, [investigationsParams])

  useEffect(() => {
    let criticalDataRange = formatTimeFilters(2592000)
    const criticalParams = {
      page: 1,
      size: 50,
      severity: 'Critical',
      status: 'Waiting_On_Customer',
      start_time: criticalDataRange.start_time,
      end_time: criticalDataRange.end_time
    }
    
    userData?.tenant && getInvestigationData(() => {
    }, setCriticalThreats, () => {}, userData, criticalParams)
    // eslint-disable-next-line
  }, [])

  return (
    <div className={"investigations-page-container all-page-container"}>
      <div className={"header-row"}>
        <Tooltip content={tooltipMapping.investigations.title} className={"title-tooltip"} direction={"right"}>
          <p className={"page-title"}>INVESTIGATIONS</p>
        </Tooltip>
        <span className={"threats-msg-container"}>
          {criticalThreats.total > 1 ?
            <p className="threats-msg"><b>There are {criticalThreats.total} critical investigations</b> waiting for a
              response from you »</p>
            : criticalThreats.total === 1 &&
            <p className="threats-msg"><b>There is 1 critical investigation</b> waiting for a response from you »</p>}
        </span>
      </div>
      <div className={"top-container"}>
      </div>
      <PageFilters
        isReset={isReset}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        handleClose={() => {
          setExpandedIndex(null)
          setOpenInvestigationData({})
        }}
        filterList={filterList}
        setOpenFilters={setOpenFilters}
        setParams={setInvestigationsParams}
        initialSearch={defaultConfig?.q}
        defaultDate={(defaultConfig?.q && !isReset) ? {} : defaultDate}
      />
      {
        !investigationsLoading &&
        <PaginationControls
          resetAllFilters={resetAllFilters}
          displayData={investigationsData}
          page={page}
          showDropdown={showDropdown}
          setPage={handleChangePage}
          toggleShowDropdown={toggleShowDropdown}
          setParams={setInvestigationsParams}
          params={investigationsParams}
          bauExportLoading={exportLoading}
        />
      }
      {investigationsLoading ?
        <div className={"loader-container"}><Loader/></div>
        : fetchError ? <NotFound isError dataError />
        : !investigationsLoading && investigationsData.results.length === 0 ?
          <div>
            <p className={'line-filter'}/>
            <p className={'no-results-text'}>No results, try expanding your filter parameters.</p>
          </div>
          :
          <div>
            <div className={"fixed-table-container investigations-table-container"}>
              <Table page={"investigations " + (expandedIndex !== null ? 'expanded' : '')}>
                <TableHeader
                  mainRow={mainRow}
                  className={"investigations"}
                  setSortFilters={setSortFilters}
                  sortFilters={sortFilters}
                  setParams={setInvestigationsParams}
                  params={investigationsParams}
                />
                {
                  allData.map((item, i) => <InvestigationsRow
                    key={i}
                    rowNumber={i}
                    severities={severities}
                    page={"investigations " + (expandedIndex === i ? 'expanded' : '')}
                    data={{...item, index: i}}
                    mapMainRow={mainRow}
                    statuses={statuses}
                    onClick={onRowClick}
                  />)
                }
              </Table>
              {
                (Object.keys(openInvestigationData).length > 0 && !investigationDetailsLoading && expandedIndex !== null) ?
                  <ExpandView
                    closeView={onRowClick}
                    severities={severities}
                    className={"expanded-investigations-container"}
                    data={{...openInvestigationData, index: expandedIndex}}
                    statuses={statuses}
                  />
                  : investigationDetailsLoading &&
                  <div className={"expand-loader"}><Loader/></div>
              }
            </div>
            <div className={"pagination-controls"}>
              <Pagination
                setPage={handleChangePage}
                page={page}
                total={investigationsData.total}
              />
            </div>
          </div>}
    </div>
  )
}

export default Investigations;
