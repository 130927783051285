import React from "react";
import '../../styles/pages/MaturityAssessment/MaturityAssessmentRow.scss';
import Row from "../../components/Table/Row";
import StatusBar from "../../components/Table/StatusBar";
import {iconMapCategory, iconMapFramework} from "./config/iconmap";

const MaturityAssessmentRow = ({data, page, statuses, onRowClick}) => {
    let Unknown = iconMapCategory['Unknown']
    const categoryIcon = iconMapCategory[data.category.replace(/[^A-Z0-9]/ig, "")]

  return (
      <>
        <Row page={page} onClick={onRowClick} data={data}>
          <td className={"control column td-value"}>
            <img className={`icon ${data.framework}`} alt={data.framework} src={iconMapFramework[data.framework]}/>
            <div className="framework-container">
              <div className={"framework"}>{data.framework}</div>
              <div className="framework-name">{data.name}</div>
            </div>
          </td>
          <td className={"td-value category limit-text-lines"}>
            <p className={"greyed"}>{data.category}</p>
          </td>
          <td className={"td-value tool limit-text-lines"}>
            <p className={"greyed"}>{data.tool}</p>
          </td>
          <td className={"td-value icon"}>
            <img className={"icon category"} alt={data.category} src={categoryIcon ? categoryIcon : Unknown}/>
          </td>
          <td className={"td-value name limit-text-lines"}>
            <p className="name-text">{data.short_name}</p>
          </td>
          <td className={"td-value difficulty"}>
            <p className={"greyed"}>{data.difficulty}</p>
          </td>
          <td className={"td-value status"}>
            {data.hasOwnProperty('status') &&
            <StatusBar statuses={statuses} selectedStatus={data.status}/>}
          </td>
        </Row>
      </>
    );
  }
;

export default MaturityAssessmentRow;
