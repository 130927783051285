import React, { useState, useRef } from "react";
import "../../../styles/pages/Network/NetworkMetrics.scss";
import Dropdown from "../../../components/Dropdown/Dropdown";

const NetworkDropdown = ({ config, filters, setFilters }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const initialSet = useRef(false);
  const { options, className, value, name } = config;
  const selectedItem = Object.keys(options).find(
    (key) => options[key] === filters[value]
  );
  return (
    <div className={`network-dropdown-container ${className}`}>
      <Dropdown
        className={`network-dropdown ${className}`}
        name={initialSet.current ? selectedItem : name}
        showDropdown={showDropdown}
        dropdownList={Object.keys(options)}
        setShowDropdown={setShowDropdown}
        selectedItem={selectedItem}
        selectItem={(item) => {
          initialSet.current = true;
          setFilters({ ...filters, [value]: options[item] });
          setShowDropdown();
        }}
        useList
      />
    </div>
  );
};
export default NetworkDropdown;
