import React from "react";
import "../../styles/pages/Vulnerabilities2/Vulnerabilities/ActionComment.scss";
import Radio from "../../components/Radio/Radio";
import BetaIcon from "../../assets/icons/beta2.svg";
import Tooltip from "../../components/Tooltip/Tooltip";
import {vulnToggleTooltip} from "./config/tooltips";
import TooltipIcon from "../../assets/icons/tooltip.svg";

const TogglePage = ({onClick, defaultChecked}) => (
  <div className={"flex vuln-toggle"}>
    <Radio leftLabel={'1.0'} rightLabel={'2.0'} onClick={onClick} defaultChecked={defaultChecked}/>
    <img className="beta-icon" src={BetaIcon} alt="beta"/>
    <Tooltip
      disableTimeout
      content={
        <>
          <div>{vulnToggleTooltip}</div>
          <div>Click{" "}<img className="tooltip-icon-small" src={TooltipIcon} alt="Instructions"/>{" "}to find more
            information on the new Vulnerabilities page.
          </div>
        </>
      }
      className={"title-tooltip"} direction={"left"}>
      <a className={"pointer"} href={"https://docs.actzero.ai/risk_mgmt_vulnerabilities_2/"} target="_blank" rel="noreferrer">
        <img className="tooltip-icon" src={TooltipIcon} alt="Instructions"/>
      </a>
    </Tooltip>
  </div>
);

export default TogglePage;
