import "../../../styles/pages/Onboarding/VirtualMachine/VirtualMachineTable.scss";
import React from "react";
import AscSorting from "../../../assets/icons/triangle-up.svg";
import DescSorting from "../../../assets/icons/triangle-down.svg";

const FirewallTableHeader = ({sortOrder, setSortOrder, setSortBy, sortBy}) => {
  const tableHeaders = {
    "":"",
    hostname: "NAME",
    ip_address: "MANAGEMENT ADDRESS",
    vendor: "VENDOR",
    notes: "NOTES",
  };

  const toggleSortOrder = () => sortOrder === "asc" ? setSortOrder("desc") : setSortOrder("asc");

  return (
    <>
      {Object.keys(tableHeaders).map((col) =>
        <td className={`table-column ${col}`} key={col}>
          <div
            onClick={() => sortBy === col ? toggleSortOrder() : setSortBy(col)}
            className={"clickable" + (sortBy === col ? " active" : "")}
          >
            {tableHeaders[col].toUpperCase()}
            <span className="sort-icon">
                {sortBy === col && sortOrder === "asc" ? <img className={"asc"} src={AscSorting} alt=""/> :
                  sortBy === col && sortOrder === "desc" && <img src={DescSorting} alt=""/>}
              </span>
          </div>
        </td>
      )}
      <td className="table-column buttons" />
    </>
  );
};

export default FirewallTableHeader;
