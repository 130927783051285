import React from "react";
import "../../../../styles/pages/Onboarding/Cloud/NoCloudTabs.scss";
import Tab from "./Tab";
import AddCloudModal from "./AddCloudModal";

const NoCloudTabs = ({onClick, allTabs, currTab, setOpenAddService, openAddService, selectedTabs}) => {
  return (
    <div className={"no-cloud-container"}>
      {openAddService &&
      <AddCloudModal
        title={"+ ADD SERVICE »"}
        otherTabs={allTabs}
        setSelectedTabs={onClick}
        selectedTabs={selectedTabs}
        close={setOpenAddService}
      />}
      <div
        className={"cloud-tab add-service"}
      >
        <span onClick={() => setOpenAddService(!openAddService)}>+ ADD SERVICE »</span>
      </div>
      <p>Add your cloud integrations here - start by selecting a cloud service above</p>
      <div className={"no-cloud-tabs-container"}>
        {allTabs.map((tab, i) =>
          <Tab onClick={onClick} key={i} data={tab} selected={currTab === tab.configName}/>
        )}
      </div>
    </div>
  )
};

export default NoCloudTabs;
