import "../styles/pages/Dashboard/NotFound.scss";
import NotFoundRobot from "../assets/icons/not-found-robot.svg";
import ErrorRobot from "../assets/icons/error-robot.svg";

const NotFound = ({ isError, noText, className, dataError }) => {
  return (
    <div className={`not-found-container ${noText && "no-text"} ${className}`}>
      <div className = "not-found-content">
        {isError ? (
          <img src={ErrorRobot} className="robot error-img" alt="Error" />
        ) : (
          <img src={NotFoundRobot} className="robot lost-img" alt="Not Found" />
        )}
        <div className={"not-found-text"}>
          <div className="header-msg">
            {dataError
              ? "An error has occurred while loading this data"
              : isError 
              ? "It appears an error has occurred"
              : "Sorry, we can't find that page"}
          </div>
          <div className="contact-msg">
            {"For help, please contact "}
            <a href={"mailto: support@actzero.ai"} className="link">
              {"support@actzero.ai."}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
