import React from "react";
import PolicyOptions from "./PolicyOptions";

const PolicyOptionsContainer = ({
  windowsPolicies,
  setWindowsPolicies,
  macPolicies,
  setMacPolicies,
  linuxPolicies,
  setLinuxPolicies,
  csPolicyOptions,
  serverGroupID,
  workstationGroupID,
}) => {
  const functionMap = {
    Windows: { value: windowsPolicies, setValue: setWindowsPolicies },
    Mac: { value: macPolicies, setValue: setMacPolicies },
    Linux: { value: linuxPolicies, setValue: setLinuxPolicies },
  };
  if (!workstationGroupID) {
    delete functionMap.Mac;
  }
  return (
    <div className="cs-options-container">
      {Object.keys(functionMap).map((osName) => {
        return (
          <div className="modal-options-os" key={osName}>
            <PolicyOptions
              className={`${osName}`}
              osName={osName}
              optionsList={csPolicyOptions[osName]}
              selectedItem={functionMap[osName].value}
              selectItem={(e) => functionMap[osName].setValue(e)}
              serverGroupID={serverGroupID}
              workstationGroupID={workstationGroupID}
            />
          </div>
        );
      })}
    </div>
  );
};

export default PolicyOptionsContainer;
