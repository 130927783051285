import React, { useContext, useEffect, useState } from "react";
import "../../styles/pages/SupportTicket/ExpandedView.scss";
import UserData from "../../store/User/UserData";
import { formatTime } from "../../util/format";
import CloseIcon from "../../config/icons/close-icon.svg";
import Border from "../../config/icons/dossier-border.svg";
import Grid from "@mui/material/Grid";
import DocumentIcon from "../../assets/icons/document-icon.svg";
import { Loader } from "../../components/Loader/Loader";
import { hasWriteAccess } from "../../util/userControl";
import {
  getTicketComments,
  addTicketComment,
  getTicketAttachments,
} from "../../api/ApiClient";
import LoaderButton from "../../components/Button/LoaderButton";
import { getTickets } from "../../api/ApiClient";
import { captureSentryError } from "../../util/sentry";

const Comment = ({ item, index }) => (
  <div key={index}>
    <hr />
    <div className={"row comment-fields"}>
      <div className={"comment-info"}>
        <div className={"comment-label-value-author"}>
          <div className={"comment-label-value"}>{"RESPONSE BY"}</div>
          <div className={"comment-author"}>{item.author?.toUpperCase()}</div>
        </div>
        <div className={"comment-time"}>{formatTime(item.timestamp)}</div>
      </div>
      <p key={index} className={"comment"}>
        {item.body}
      </p>
    </div>
  </div>
);

const Status = ({ item, index }) => {
  return (
    <div key={index}>
      <hr />
      <div className={"row comment-fields status"}>
        <div className={"status-info"}>
          <div className={"status-label"}>STATUS CHANGE: </div>{" "}
          <div className={"status-value"}>
            {" "}
            {item.status?.toUpperCase().replaceAll("_", " ")}
          </div>{" "}
          <div className={"status-timestamp"}>
            {" "}
            {formatTime(item.timestamp)}{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

const convertToUnix = (date) =>
  typeof date === "string" || date instanceof String
    ? Math.floor(new Date(date).getTime() / 1000)
    : date;

const getCommentsAndStatus = (data) => {
  let commentAndStatus =
    data.comments.length > 0
      ? data.comments.concat(data.status_history)
      : data.status_history;
  commentAndStatus.length > 0 &&
    commentAndStatus.sort((a, b) => {
      a = convertToUnix(a.timestamp);
      b = convertToUnix(b.timestamp);
      return a - b;
    });
  return commentAndStatus;
};

const ExpandView = ({
  className,
  data,
  statuses,
  closeView,
  setUploadModal,
  setTicketsData,
  setOpenTicketsData,
}) => {
  const [userData] = useContext(UserData);
  const [currentComment, setCurrentComment] = useState("");
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [attachmentsLoading, setAttachmentsLoading] = useState(false);
  const [commentAndStatus, setCommentsAndStatus] = useState([]);
  const [attachmentsData, setAttachmentsData] = useState([]);
  const statusName = data.status;
  const statusColor =
    statuses.find((st) => st[statusName])[statusName] || "#018D8D";
  const editPermissions =
    hasWriteAccess(userData?.role) &&
    !["Closed", "Merged", "Resolved"].includes(data.status);

  const getComments = async () => {
    try {
      setCommentsLoading(true);
      let comments = await getTicketComments(userData?.tenant, data.id);
      let formattedComments = getCommentsAndStatus(comments);
      setCommentsAndStatus(formattedComments);
    } catch (e) {
      captureSentryError(e, userData, "getTicketComments");
    } finally {
      setCommentsLoading(false);
    }
  };

  const getAttachments = async () => {
    try {
      setAttachmentsData([])
      setAttachmentsLoading(true);
      const results = await getTicketAttachments(userData?.tenant, data.id);
      setAttachmentsData(results);
    } catch (e) {
      captureSentryError(e, userData, "getTicketAttachments");
    } finally {
      setAttachmentsLoading(false);
    }
  };

  useEffect(() => {
    if (userData.tenant) {
      getComments();
      getAttachments();
    }
    // eslint-disable-next-line
  }, [userData.tenant, data]);

  const addTicketsComment = async () => {
    setCommentsLoading(true);
    try {
      let params = { comment: currentComment };
      await addTicketComment(userData?.tenant, data.id, params);
      getComments();

      const ticketsResponse = await getTickets(userData?.tenant);
      const updatedObject = ticketsResponse.results.find(
        (t) => t.id === data.id
      );
      setTicketsData((prevData) => {
        const prevObject = prevData.results.findIndex((t) => t.id === data.id);
        prevData.results[prevObject] = updatedObject;
        return { ...prevData };
      });
      setOpenTicketsData((prevOpen) =>
        Object.keys(prevOpen).length ? updatedObject : prevOpen
      );
    } catch (e) {
      captureSentryError(e, userData, "addTicketComment");
    } finally {
      setCommentsLoading(false);
    }
  };

  return (
    <div className={className}>
      <div className={"tickets-header"}>
        <div className={"expanded-header"}>
          <div className={"date-ticket"}>
            <p className={"date"}>{formatTime(data.created_date)}</p>
            <p className={"ticket"}>{`${data.case_number}`}</p>
          </div>
          <div className="assignee">
            <span className="label">
              {data.assigned_to !== "Support Unassigned" && "ASSIGNED TO "}
            </span>
            {data.assigned_to.toUpperCase()}
          </div>
          <div>
            <img
              onClick={() => closeView("event", data)}
              className={"close-icon"}
              src={CloseIcon}
              alt={"close"}
            />
            <p className={"status"} style={{ color: statusColor }}>
              {statusName.replace(/[^a-zA-Z]/g, " ").toUpperCase()}
            </p>
          </div>
        </div>
        <div
          className={"header-border"}
          style={{ backgroundImage: `url(${Border})` }}
        />
      </div>
      <div className={"description"}>
        <hr />
        <div className={"row description"}>
          <p>{data.description}</p>
        </div>
        <Grid>
          <div className={"attachments"}>
            {attachmentsLoading ? (
              <Loader />
            ) : (
              attachmentsData.map((item, i) => (
                <a
                  className={"clickable-link"}
                  target="_blank"
                  rel="noreferrer"
                  href={item.url}
                  key={item.url}
                >
                  <div className={"file-icon-attachment"} key={i}>
                    <div className={"file-icon"}>
                      <img src={DocumentIcon} alt="File" />
                    </div>
                    <div className={"file-name"}>{item.file_name}</div>
                  </div>
                </a>
              ))
            )}
          </div>
        </Grid>
        {editPermissions && !commentsLoading && (
          <label className="upload-text" onClick={() => setUploadModal(data)}>
            ADD ATTACHMENTS »
          </label>
        )}
        {!commentAndStatus.length ? <hr className="comment-divider" /> : <></>}
      </div>
      {commentsLoading ? (
        <div className={"loader-container"}>
          <Loader />
        </div>
      ) : (
        <div className={"comments"}>
          {commentAndStatus.map((item, index) =>
            item.status ? (
              <Status
                key={index}
                item={item}
                index={index}
                statuses={statuses}
              />
            ) : (
              <Comment key={index} item={item} index={index} />
            )
          )}
          {editPermissions && (
            <div>
              {commentAndStatus.length ? <hr /> : <></>}
              <div className={"user-comment"}>
                <div className="form-title">Add Comment</div>
                <form>
                  <textarea
                    className={"add-comment-input"}
                    onChange={(e) => setCurrentComment(e.target.value)}
                  />
                </form>
                <LoaderButton
                  className={"comment-submit-button"}
                  type="submit"
                  text="UPDATE"
                  onClick={addTicketsComment}
                >
                  UPDATE
                </LoaderButton>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ExpandView;
