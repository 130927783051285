import { components } from 'react-select';

export const SingleValue = (props) => {
  const { label, offset } = props.getValue()[0];

  return (
    <components.SingleValue {...props}>
      <span>{label}</span> <span style={{ "paddingLeft": 5 }}>{offset}</span>
    </components.SingleValue>
  );
};

export const Option = (props) => {
  const { label, offset } = props.data;

  return (
    <components.Option {...props}>
      <span>{label}</span> <span style={{ "paddingLeft": 5 }}>{offset}</span>
    </components.Option>
  );
};