import React from "react";
import {formatTime} from "../../util/format";
import Row from "../../components/Table/Row";
import '../../styles/components/Table/Row.scss';
import {mapIcons} from "../../config/iconMapping";

const MobileRow = ({data, page, onVulnerabilityClick}) => {
  return (
    <>
      <Row page={page} onClick={async () => {
      }} data={data}>
        <td className={"last_seen td-value"}>
          {formatTime(data.last_seen)}
        </td>
        <td className={"Email td-value"}>
          <div className={"td-oversized"}>{data.email || "N/A"}</div>
        </td>
        <td className={"Name td-value"}>
          <div className={"td-oversized"}>{`${data.first_name} ${data.last_name}` || "N/A"}</div>
        </td>
        <td className={"status td-value"}>
          {data.status || "N/A"}
        </td>
        <td className={"Vulnerabilities td-value"}>
          <span className={`vulnerabilities-value ${data.max_severity} ${data?.vulnerabilities_count > 0 ? "clickable" : ""}`} onClick={() => {data?.vulnerabilities_count > 0 && onVulnerabilityClick(data)}}>{data.vulnerabilities_count || 0}</span>
        </td>
        <td className={"os_type td-value icon " + (data?.os_type || '')}>
          {<img className={"icon"} alt={data?.os_type} src={mapIcons(data?.os_type, '', 0)}/> || "N/A"}
        </td>
        <td className={"os_version td-value"}>
          {data.os_version || "N/A"}
        </td>
        <td className={"phone_model td-value"}>
          <div className={"td-oversized"}>{data.phone_model_name || data.phone_model_version || "N/A"}</div>
        </td>
      </Row>
    </>
  );
}

export default MobileRow;
