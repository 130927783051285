import React, {useContext, useEffect, useState} from 'react';
import PageFilters from "../../../components/Filters/PageFilters";
import VulnerabilitiesTable from "../VulnerabilitiesTable";
import {filterConfig, tableHeader} from "../config/vulnerabilitytabconfig";
import VulnerabilitiesRow from "./VulnerabilitiesRow";
import UserData from "../../../store/User/UserData";
import {getVulnerabilitiesTable} from "../../../api/ApiClient";
import {captureSentryError} from "../../../util/sentry";
import {Loader} from "../../../components/Loader/Loader";
import {filterData} from "../../../util/format";
import {useLocation, useNavigate} from "react-router-dom";
import {setSortFilterValue} from "../../../util/handleSortResults";
import {setResetFilter} from "../../../util/handleResetFilter";
import {resetFilterConfig} from "../config/vulnerabilitytabconfig";
import {useDebounce} from "../../../util/debounce";

const Vulnerability = ({setCurrTab}) => {
  const [userData] = useContext(UserData);
  const location = useLocation();
  const navigate = useNavigate();
  const [isReset, setIsReset] = useState(false)
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const defaultParams = {page: 1, size: 50}
  const [params, setParams] = useState(defaultParams)
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  let [filterList, setOpenFilters] = useState(filterConfig)
  const [page, setPage] = useState(1)
  let [sortFilters, setSortFilters] = useState([])
  const debouncedParams = useDebounce(params, 1000, setIsLoading);

  useEffect(() => {
    filterData(filterList, params, 50, page, setParams, {}, ()=>{})
    // eslint-disable-next-line
  }, [filterList, page, debouncedParams])

  useEffect(()=>{
    //reset filters on tab change
    resetFilters()
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    const getVulnerabilitiesData = async () => {
      setIsLoading(true)
      try {
        if (location.state?.cve) {
          params.vulnerability_id = location.state.cve
          delete location.state.cve
        }
        setExpandedIndexes([])
        let vulnerabilitiesTableResponse = await getVulnerabilitiesTable(userData.tenant, debouncedParams);
        vulnerabilitiesTableResponse.results = vulnerabilitiesTableResponse.results.map(item => {
          item.exploit = item.exploit ? 'Yes' : 'No';
          return item;
        });
        setTableData(vulnerabilitiesTableResponse)
      } catch (e) {
        captureSentryError(e, userData, 'getVulnerabilitiesTable')
      } finally {
        setIsLoading(false)
        setIsReset(false)
      }
    }

    userData.tenant && getVulnerabilitiesData();
    // eslint-disable-next-line
  }, [userData.tenant, debouncedParams]);


  const resetFilters = () => {
    setIsReset(true)
    setPage(1)
    setSortFilterValue([], tableHeader, setSortFilters)
    setExpandedIndexes([])
    setResetFilter(filterConfig, resetFilterConfig, setOpenFilters)
    navigate('/risk-management/vulnerabilities2', { replace: true, state: null })
    setParams(defaultParams)
  }

  return (
    <div className={"vulnerability-container fixed-table-container"}>
      <PageFilters
        type={"vulnerabilities"}
        isReset={isReset}
        filterList={filterList}
        className={"vulnerabilities-filters-new"}
        defaultDate={{text: 'none'}}
        handleClose={() => setExpandedIndexes(null)}
        setOpenFilters={setOpenFilters}
        setParams={setParams}
        collapsible={true}
      />
      {!isLoading ?
        <VulnerabilitiesTable
          setSortFilters={setSortFilters}
          sortFilters={sortFilters}
          resetFilters={resetFilters}
          page={page}
          setPage={setPage}
          expandedIndexes={expandedIndexes}
          setExpandedIndexes={setExpandedIndexes}
          tableData={tableData}
          className={"new-vuln-table"}
          TableRow={VulnerabilitiesRow}
          tableHeader={tableHeader}
          params={params}
          setCurrTab={setCurrTab}
          setParams={setParams}
          exportType={'vulnerabilities'}
        /> : <Loader/>}
    </div>
  );
}

export default Vulnerability;
