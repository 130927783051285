import React, {useContext, useEffect, useState} from "react";
import {getTrends} from "../../api/ApiClient";
import {captureSentryError} from "../../util/sentry";
import UserData from "../../store/User/UserData";
import {Loader} from "../../components/Loader/Loader";
import {mapChartData} from "../../util/mapChartData";
import {colors, detectionSeveritiesValues} from "./config/graph-config";
import '../../styles/pages/MultiTenantDashboard/DetectionsBySeverity.scss';
import MultiTenantLineChart from "./components/MultiTenantLineChart";
import DetectionsIcon from "../../assets/icons/detectionicon.svg";
import {onTenantClick} from "./config/helper";

const DetectionsBySeverity = () => {
  const [detectionsGraphData, setDetectionsGraphData] = useState([])
  const [axisData, setAxisData] = useState({x: {min: 0, max: 0}, y: {max: 0}})
  const [userData] = useContext(UserData);
  const [loading, setLoading] = useState(false);
  const [detectionsData, setDetectionsData] = useState([])

  useEffect(() => {
    const getTrendData = async () => {
      setLoading(true)
      try {
        const data = await getTrends(userData?.tenant, 'detections')
        mapChartData(data.monthly_trend_by_severity, setAxisData, setDetectionsGraphData, detectionSeveritiesValues, [])
        setDetectionsData(data?.top_tenants_by_detections)
      } catch (e) {
        captureSentryError(e, userData, 'getTrends detections trends')
      } finally {
        setLoading(false)
      }
    }
    userData?.tenant && getTrendData()
    // eslint-disable-next-line
  }, [userData?.tenant, userData?.userType])

  return (
    <div className={"multi-tenant-panel detections"}>
      {loading ? <Loader/> : detectionsGraphData?.length > 0 &&
        <div className={"graph-container" + (detectionsGraphData?.length === 0 ? ' no-data' : '')}>
          <div className={`title-and-legend detections`}>
            <p className={`graph-title`}>DETECTIONS BY SEVERITY
              <span className={"sub-title"}> OVER TIME, AGGREGATED</span>
            </p>
          </div>
          <div className={"flex detections-panel"}>
            <MultiTenantLineChart
              className={"detections-trends"}
              axisData={axisData}
              colors={colors}
              mappedGraphData={detectionsGraphData}
              width={450}
              widthOffset={.7}
              icon={DetectionsIcon}
            />
            <div className={"panel-tables investigations"}>
              <p className={"title"}>HIGHEST NUMBER OF DETECTIONS</p>
              {detectionsData?.map((item, i) => {
                return <p className={"detection-value"} key={i}>
                  <span
                    className={"label"}
                    onClick={() => onTenantClick(item.tenant_id, '/activity/detections', true)}
                  >
                    {item.friendly_name}
                  </span>
                  <span className={"value"}>{item.count}</span>
                </p>
              })}
            </div>
          </div>
        </div>}
    </div>
  )
}
export default DetectionsBySeverity;
