import "../../../styles/pages/Onboarding/OnboardingTable.scss";
import React, { useState, useEffect } from "react";
import AscSorting from "../../../assets/icons/triangle-up.svg";
import DescSorting from "../../../assets/icons/triangle-down.svg";
import EditIcon from "../../../assets/icons/edit-icon.svg";
import DenseTable from "../../../components/Table/DenseTable";
import TrashIcon from "../../../assets/icons/trash-icon.svg";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import ResendEmail from "../../../assets/icons/resend-email.svg";
import Checkmark from "../../../assets/icons/checkmark_done.svg";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { sortObjects } from "../../../util/sortObjects";
import ConfirmDialogCell from "../../../components/Table/ConfirmDialogCell";

const tableHeaders = {
  first_name: "FIRST NAME",
  last_name: "LAST NAME",
  email: "EMAIL",
};

const confirmDialogs = {
  delete: "Confirm delete?",
  edit: "Confirm edit?",
  resend: "Resend invite?",
};

const rowButtonConfig = [
  {action: "edit", tooltip: "Edit", icon: EditIcon},
  {action: "resend", tooltip: "Resend invite", icon: ResendEmail},
  {action: "delete", tooltip: "Delete", icon: TrashIcon}
]

const MobileOnboardingTable = (props) => {
  const {
    mobileUserData,
    selectedRows,
    setSelectedRows,
    mapInputRefs,
    setMobileUserData,
    rowError,
    rowLoading,
    dataLoading,
    resetPendingStages,
    pendingRow,
    setPendingRow,
    handleSubmit,
  } = props;

  const [sortBy, setSortBy] = useState();
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    setMobileUserData(sortObjects(mobileUserData, sortBy, sortOrder));
    // eslint-disable-next-line
  }, [sortBy, sortOrder]);

  const toggleSortOrder = () => sortOrder === "asc" ? setSortOrder("desc") : setSortOrder("asc");

  const generateHeaderRow = () => (
    <>
      <td className="table-column icon" />
      {Object.keys(tableHeaders).map((col) => {
        return (
          <td className={`table-column ${col}`} key={col}>
            <div
              onClick={() => sortBy === col ? toggleSortOrder() : setSortBy(col)}
              className={"clickable" + (sortBy === col ? " active" : "")}
            >
              {tableHeaders[col].toUpperCase()}
              <span className="sort-icon">
                {sortBy === col && sortOrder === "asc" ? (
                  <img className={"asc"} src={AscSorting} alt="" />
                ) : sortBy === col && sortOrder === "desc" ? (
                  <img src={DescSorting} alt="" />
                ) : null}
              </span>
            </div>
          </td>
        );
      })}
      <td className="agent-installed">AGENT INSTALLED</td>
      <td className="table-column buttons" />
    </>
  );

  const generateBodyRows = () =>
    mobileUserData.map((row) => (
      <tr key={row.email + row.id}>
        <td className="checkbox-cell">
          <div className="checkbox-icon">
            <Checkbox
              key={row.email}
              className="custom-checkbox"
              checked={
                selectedRows.filter((r) => r.email === row.email).length > 0
              }
              onChange={(e) => {
                e.target.checked
                  ? setSelectedRows((r) => [...r, row])
                  : setSelectedRows((r) =>
                      r.filter((em) => em.email !== row.email)
                    );
              }}
            />
          </div>
        </td>
        {Object.keys(tableHeaders).map((col) => {
          return pendingRow.row.email === row.email &&
            pendingRow.action === "edit" &&
            col !== "email" ? (
            <td key={`${row.email}${col}`}>
              <input
                defaultValue={pendingRow.row[col]}
                className={`edit-input ${col}`}
                ref={mapInputRefs[col]}
              />
            </td>
          ) : (
            <td key={`${row.email}${col}`}>{row[col]}</td>
          );
        })}
        <td>
          {row["active_endpoints_count"] && row["active_endpoints_count"] > 0 &&
          (!rowError || pendingRow.row.email !== row.email) ? (
            <><img className="table-checkmark" src={Checkmark} alt="Yes" /><span className={"table-checkmark-text"}>{row["active_endpoints_count"] > 1 && `(${row["active_endpoints_count"]})`}</span></>
          ) : (
            (!rowError || pendingRow.row.email !== row.email) && (
              <CloseIcon className="close-icon" fontSize="small" />
            )
          )}
        </td>
        {pendingRow.row?.email === row.email ? (
          <ConfirmDialogCell 
            message={confirmDialogs[pendingRow.action]}
            confirmFunc={handleSubmit}
            cancelFunc={resetPendingStages}
            rowError={rowError}
            rowLoading={rowLoading}
          />
        ) : (
          <td className="table-icon-container">
            {rowButtonConfig.map((b, i) => {
              return(
              <Tooltip
                content={b.tooltip}
                className={`${b.action}-tooltip`}
                direction={"right"}
                key={i}
              >
                <IconButton
                  className={`table-icon ${b.action}`}
                  onClick={() => setPendingRow(row, b.action)}
                >
                  <img src={b.icon} alt={b.tooltip} />
                </IconButton>
              </Tooltip>
              )
            })}
          </td>
        )}
      </tr>
    ));

  return (
    <div className="onboarding-table mobile">
      <DenseTable
        loading={dataLoading}
        header={generateHeaderRow()}
        noDataMessage={"No users added yet."}
        body={generateBodyRows()}
      />
    </div>
  );
};

export default MobileOnboardingTable;
