import React, { useState, useEffect } from "react";
import "../../../styles/pages/Network/TopCountriesTable.scss";
import { abbreviateNumber } from "../../../util/format";
import { countryTableConfig } from "../config/config";
import { sortTopCountries } from "../util";
import AscSorting from "../../../assets/icons/triangle-up.svg";
import DescSorting from "../../../assets/icons/triangle-down.svg";

const TopCountriesTable = ({
  topCountriesData,
  allCountries,
  setTopCountries,
}) => {
  const [sortBy, setSortBy] = useState("inbound");
  const [sortOrder, setSortOrder] = useState("desc");

  useEffect(() => {
    setTopCountries(sortTopCountries(allCountries, sortBy, sortOrder));
    // eslint-disable-next-line
  }, [sortBy, sortOrder]);

  const handleSort = (value) => {
    value === sortBy
      ? setSortOrder((prev) => (prev === "desc" ? "asc" : "desc"))
      : setSortBy(value);
  };

  return (
    <div className={"top-countries-container"}>
      <div className={"countries-title"}>{"TOP COUNTRIES"}</div>
      {!topCountriesData.length ? (
        <div className={"no-data"}>{"No data"}</div>
      ) : (
        <table>
          <tbody>
            <tr className={"country-headers"}>
              {countryTableConfig.map((item) => (
                <td
                  className={"country-header"}
                  key={item.header}
                  onClick={() => handleSort(item.value)}
                >
                  {sortBy === item.value && sortOrder === "asc" ? (
                    <img className={"asc"} src={AscSorting} alt="" />
                  ) : sortBy === item.value && sortOrder === "desc" ? (
                    <img src={DescSorting} alt="" />
                  ) : null}
                  {item.header}
                </td>
              ))}
            </tr>
            {topCountriesData.map((country) => {
              return (
                <tr className={"country-row"} key={country.country}>
                  {countryTableConfig.map((item) => (
                    <td
                      className={`countries-amount ${item.value}`}
                      key={item.value}
                    >
                      {abbreviateNumber(country[item.value])}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TopCountriesTable;
