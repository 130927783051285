import * as Sentry from "@sentry/react";

export const captureSentryError = (e, userData, apiName) => {
  let userGroup = userData?.userType ? userData?.userType : "no group";
  Sentry.setContext(apiName, {
    userGroup: userGroup,
    tenantWithAccess: userData?.tenant,
    message: `${apiName} : ${e?.message}`,
    url: window.location.href,
    error: e,
  });
  Sentry.captureException(e);
};
