export const modules = [
  { label: "Reputation", value: "reputation" },
  { label: "GeoIP", value: "geoip" },
  { label: "Whois", value: "whois" },
  { label: "Is Known Endpoint", value: "is_known_endpoint" },
  { label: "Is IP in Network", value: "is_ip_in_network" },
];

export const startPoints = [
  { label: "IP Investigation", values: ["geoip", "reputation", "whois", "is_known_endpoint", "is_ip_in_network"] },
];
