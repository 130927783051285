export const setResetFilter = (filterConfig, resetFilterConfig, setOpenFilters) => {
  filterConfig.forEach(filter => {
     let name = Object.keys(filter)[0]
     let keys = Object.keys(filter[name].filters);
      keys.forEach((key) => {
        filter[name].filters[key] = resetFilterConfig[name].filters[key]
      })
  })
  setOpenFilters(filterConfig)
}

export const setResetFilterToFalse = (filterConfig, setOpenFilters) => {
  filterConfig.forEach(filter => {
    let name = Object.keys(filter)[0]
    let keys = Object.keys(filter[name].filters);
    keys.forEach((key) => {
      filter[name].filters[key] = false
    })
  })
  setOpenFilters(filterConfig)
}
