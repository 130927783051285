export const tooltipMapping = {
  dashboard: {
    title: "",
    criticalThreats: "ActZero curated list of high risk vulnerabilities used in current attacker campaigns with the potential to cause significant harm to organizations.",
    vulnerabilitiesOverTime: "Status of the most recent vulnerability scan, with trend over the last 12 months.",
    vulnerabilitiesChange: "Percentage change in the vulnerabilities found in comparison with the previous scan.",
    connections: "Health status re service connectivity to the ActZero platform.",
    controls: "Controls that AZ has direct visibility into are listed in smaller circles and their completion count. For complete details go to maturity assessment page.",
    keyMetrics: {
      'Block Rate': 'Percentage of detections blocked in the last 30 days. (Note: Blocking is applicable to endpoints and may not apply to network and cloud.',
      'Noise Reduction': 'Percentage of detections filtered in the last 30 days that do not merit alerting (low severity, informational)',
      'MTTFR (Critical)': 'Average time from when a critical detection occurs until the SOC responds for the last 30 days (Note: Global metric across all ActZero customers).',
      'Ransomware Defense': 'Percentage of the controls from the ‘Blueprint for Ransomware Defense’ framework currently implemented.'
    }
  },
  detections: {
    title: "Security related events generated by aggregating telemetry across domains (network, endpoint, cloud, mobile, identity)."
  },
  investigations: {
    title: "Alert Notifications with an associated ticket generated on automated or manual analysis of a detection."
  },
  vulnerabilities: {
    title: "Network Vulnerability scan results, executed on monthly basis.",
    action: "Track the progress of vulnerability handling and stay updated on their status. Important ones to know are, \n'New Risk' represents vulnerabilities identified during the scan that are yet to be addressed. \n'Risk Mitigated' refers to vulnerabilities that were previously identified and unable to be found during subsequent scans.",
    confidence: "Indicates the certainty of identifying a host based on its NetBIOS or DNS name, MAC address, and IP address history. Exact matches result in high confidence, partial matches yield moderate to low confidence, and no match produces no confidence."
  },
  logSearch: {
    title: "Search event logs and filter results across multiple event sources for the last 30 days.",
    logSource: "Select a log source to expose more attributes to search. Attributes vary by the type of log source selected.",
    export: "Export current search results (max 1M logs). Allow 5 minutes between exports for report to run (shows as grayed out). Download a compressed export file via ‘Past Exports’ Note: Time Stamp will be in epoch seconds, convertible to local time using macros.",
    searchButton: 'Max limit: Last 10,000 log records. If required, further narrow search by reducing the time period.',
    logConfig: "Searches are case sensitive and wildcard searches are done using an asterisk (*)"
  },
  endpoints: {
    title: "List of endpoints that are seen by ActZero in your environment.",
    missing: "List of missing updates per endpoint.",
    mobile: "List of mobile hosts that are seen by ActZero in your environment."
  },
  mobile: {
    title: "List of mobile hosts that are seen by ActZero in your environment."
  },
  serviceStatus: {
    title: "Verify the visibility of your infrastructure and other objects. If the connections you manage appear to be down, check if your settings are correct. If this doesn't solve the problem, get in touch with us for support.",
    endpoint: "Status of the connection with Crowdstrike cloud & total number of endpoints seen by ActZero.",
    firewall: "Status & list of network IPs seen by ActZero",
    cloud: "Overall and individual status of cloud connectors seen by ActZero",
    log: "Volume of log data ingested per day in GB per 30 days by ActZero.",
    mobile: "Status of the connection with Zimperium cloud & total number of mobile endpoints seen by ActZero.",
    identity: "Status of connection with your okta account and total number and associated account counts",
    child_offerings: (
      <div>
        <div>
          1. Total number of customers who have purchased the host-based
          vulnerability scanning module
        </div>
        <div>
          2. Total number of customers who have purchased the device control module
          to monitor and control portable media devices
        </div>
      </div>
    ),
  },
  hygiene: {
    title: "Cloud Hygine tooltip content"
  },
  onboarding: {
    mobileCSV: "Upload a CSV file to add multiple users. It must contain ONLY 3 columns: first_name, last_name and email.",
    contactsCSV: "Upload a CSV file to add multiple users. Click UPLOAD to view a sample CSV format.",
    checkboxes: {
      "Admin": "User can access all components under the selected tenant with read and write permissions.",
      "Portal User": "Can do read operations only. All forms & tenant administration would be hidden.",
      "Power User": "User can do read and write operations across the portal. Tenant administration like onboarding new users will not be allowed.",
      "Contact Only": "Does not have portal access but will be captured as a point of contact. Eg: For billing purposes.",
      "Primary": "Refers to the initial contact point.",
      "Secondary": "Refers to the additional points of contact if primary contact is not reachable.",
      "Business": "Individuals responsible for non-technical aspects like account management, billing and general inquiries.",
      "Technical": "Individuals skilled in addressing technical issues and providing specialized support.",
      "totp": "App 2FA can be enabled only by the user from the preferences page."
    },
    tenantsPage: "View the onboarding status of your tenants"
  }
}
