export const addFirewallConfig = [
  {
    label: "Name",
    value: "hostname",
    tooltip:
      "Please provide a distinct name or identifier for your firewall listed. For example, use a hostname or a location-specific name.",
  }, {
    label: "Management address",
    value: "ip",
  },
  {
    label: "Notes",
    value: "notes",
  }
]

export const vendors = [
  { "barracuda": 'Barracuda' },
  { "checkpoint": 'Checkpoint' },
  { "cisco-asa": 'Cisco ASA' },
  { "ips_fire_power": 'Cisco Firepower' },
  { "fortinet": 'Fortinet' },
  { "juniper": 'Juniper' },
  { "meraki": 'Meraki' },
  { "paloalto": 'Palo Alto' },
  { "pfsense": 'pfSense' },
  { "sonicwall": 'SonicWall' },
  { "sophos": 'Sophos' },
  { "ubiquiti": 'Ubiquiti' },
  { "versa_networks_fw": 'Versa Networks FW' },
  { "vmware_nsx_edge_firewall": 'VMware NSX edge' },
  { "watchguard": 'WatchGuard' },
  { "zscaler_zia_fw": 'Zscaler ZIA FW' },
  { "zyxel": 'Zyxel' },
  {"cyberoam": 'Cyber Roam'}
]

export const requiredFirewallParameters = {
  type: "Please select a vendor to continue",
  hostname: "Please enter a name to continue",
  ip: "Please enter a valid ip to continue"
};
