export const leftColumn = {}
export const rightColumn = {}

export const mainRow = {
	"Time": {"field": "created_date", "header": "TIME", "sort": true, "defaultSortValue": false},
	"Source": {"field": "Source", "header": "SOURCE", "sort": true, "defaultSortValue": false},
	"Severity": {"field": "Severity", "header": "SEVERITY", "sort": true, "defaultSortValue": false},
	"title": {"field": "title", "header": "TITLE", "sort": true, "defaultSortValue": false},
	"affected_resource": {"field": "affected_resource", "header": "TARGET"},
	"Status": {"field": "Status", "header": "STATUS", "sort": true, "defaultSortValue": false}
}
