import React from 'react';
import '../../styles/pages/Connections/Connections.scss';
import LineChartLegend from "../../components/D3/LineChart/Legend";

const DisabledChart = ({data, colors}) => {
  return (
    <div>
      <LineChartLegend
        data={data}
        colors={colors}
        rectPosition={200}
        textPosition={215}
      />
      <div className={"disabled-chart"}/>
    </div>
  )
}

export default DisabledChart;
