import React, {useEffect, useState} from "react";
import {formatTime} from "../../util/format";
import Row from "../../components/Table/Row";
import '../../styles/components/Table/Row.scss';
import {mainRow} from "../../components/Mappings/LogSearch"
import ExpandedView from "./ExpandedView";

const LogSearchRow = ({data, page, expandAll, index, setExpandAll, setExpandedIndexes, expandedIndexes, isExpanded}) => {
  const [firstRowData, setFirstRowData] = useState({})
  const [secondRowData, setSecondRowData] = useState({})
  const logSource = data.log_source.toLowerCase()
  const logOrigin = data.source?.toLowerCase()
  let mapping = mainRow[logSource]
  // we figure out if there is a more specific mapping by combining the log_source and source fields.
  // Right now, the general mapping types takes precedence as they are more common.
  if (logOrigin != null && !!mainRow[logSource + logOrigin]) {
    mapping = mainRow[logSource + logOrigin]
  }

  useEffect(() => {
    const timeFields = ["First Seen", "Last Seen"]
    if (data) {
      let tempFirstRow = {}
      let tempSecondRow = {}
      Object.values(data?.display).forEach((item) => {
        if (mapping['firstRow'].includes(item.name)) {
          tempFirstRow[item.name] = timeFields.includes(item.name) ? formatTime(item.value) : item.value;
        }
        if (mapping['secondRow'].includes(item.name)) {
          tempSecondRow[item.name] = timeFields.includes(item.name) ? formatTime(item.value) : item.value;
        }
      })
      setFirstRowData(tempFirstRow)
      setSecondRowData(tempSecondRow)
    }
  }, [data, logSource, mapping]);
  
  const handleClick = async () => {
    if(!isExpanded) {
      setExpandedIndexes(prevExpandedIndexes => {return [...prevExpandedIndexes, index]})
      isExpanded = true
    } else {
      setExpandedIndexes(expandedIndexes.filter(x => x !== index))
      setExpandAll(expandAll.filter(x => x !== index))
      isExpanded = false
    }
  }

  return (
    <>
      <Row page={`${page} ${isExpanded}`} onClick={() => handleClick()} data={data}>
        <td className={"log-search-table-row"}>
        {isExpanded ?
            <span className={"expand-btn minus"}>-</span>
           : <span className={"expand-btn"}>+</span>
          }
          <table>
            <tbody>
              <tr>
                <td className={"log-search-table-cell"}>Time<span className={`log-search-table-cell value ${isExpanded}`}>{formatTime(data.timestamp)}</span></td>
                {Object.values(mapping["firstRow"]).map((key, i) => {
                  return <td className={"log-search-table-cell"} key={i}>{key}<span className={`log-search-table-cell value ${isExpanded}`}><div className={!isExpanded ? "apply-ellipsis" : ""}>{firstRowData[key] || 'N/A'}</div></span></td>
                })}
              </tr>
              <tr>
                <td className={"log-search-table-cell"}>Source<span className={`log-search-table-cell value ${isExpanded}`}>{data.log_source}</span></td>
                {Object.values(mapping["secondRow"]).map((key, i) => {
                  return <td className={"log-search-table-cell right"} key={i}>{key}<span className={`log-search-table-cell value ${isExpanded}`}><div className={!isExpanded ? "apply-ellipsis" : ""}>{secondRowData[key] || 'N/A'}</div></span></td>
                })}
              </tr>
            </tbody>
          </table>
        </td>
      </Row>
      {(expandedIndexes.includes(index) || expandAll.includes(index)) &&
        <ExpandedView
          data={data?.expand}
          logSource={logSource}
        />}
    </>
  );
}

export default LogSearchRow;
