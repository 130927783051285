import LineChartLegend from "../../../components/D3/LineChart/Legend";
import LineChart from "../../../components/D3/LineChart/LineChart";
import React, {useEffect, useState} from "react";
import '../../../styles/pages/Vulnerabilities2/Overview/OverviewGraph.scss';
import {mapChartData} from "../../../util/mapChartData";
import {lineData, severityColors, severitiesValues} from "../config/overviewconfig";
import Tooltip from "../../../components/Tooltip/Tooltip";
import {totalBySeverityTooltip} from "../config/tooltips";

const VulnerabilityGraph = ({ className, data }) => {
  const [axisData, setAxisData] = useState({ x: { min: 0, max: 0 }, y: { max: 46, min: 0 } });
  const [mappedGraphData, setMappedGraphData] = useState([]);

  useEffect(() => {
    const resetValues = severitiesValues.map(item => ({...item, values: []}));
    mapChartData(data, setAxisData, setMappedGraphData, resetValues, []);
  }, [data]);

  return (
    <div className={`${className} vuln-graph-container` + (mappedGraphData.length === 0 ? ' no-data' : '')}>
      <Tooltip
        content={totalBySeverityTooltip}
        type={"vuln-tabs"}
        className={"title-tooltip"}
        direction={"right"}
        disableTimeout
      >
        <div className={`graph-title`}>TOTAL BY SEVERITY <span className="graph-subtitle">OVER TIME</span></div>
      </Tooltip>
      {mappedGraphData.length > 0 && <LineChartLegend
        colors={severityColors}
        rectPosition={400}
        textPosition={415}
        data={mappedGraphData}
      />}
      {mappedGraphData.length > 0 ?
        <LineChart
          width={750}
          plotRadius={2}
          yAxisTicks={4}
          xAxisTicks={mappedGraphData[0].values.length}
          lineData={lineData}
          data={mappedGraphData}
          className={"vulns-two-line-chart "}
          axis={axisData}
          widthOffset={.55}
          height={180}
          marginLeft={50}
          colors={severityColors}
        /> : <p className={"no-data"}>No Data</p>}
    </div>
  );
}

export default VulnerabilityGraph;
