export const csOptionsModalConfig = {
  header: "CrowdStrike Options",
  formLabel: "Automatic Response",
  description: (
    <div className="description">
      <div>
        Activate automatic Host Quarantine (network isolation) for endpoints
        under scrutiny for specific CrowdStrike detections that are{" "}
        <b>
          <i>Critical.</i>
        </b>
      </div>
      <div>
        <i>
          Note: Currently there are no options in the portal to exclude specific
          workstations or servers from isolation.
        </i>
      </div>
    </div>
  ),
  options: [
    {
      value: "Workstation",
      name: "workstation",
      isMulti: true,
    },
    {
      value: "Server",
      name: "server",
      isMulti: true,
    },
  ],
};

export const getSuccessMessage = (selection) => {
  const serverSelected = selection.includes("server");
  const workstationSelected = selection.includes("server");

  return `Automatic response is now ${
    serverSelected ? "enabled" : "disabled"
  } for servers and ${
    workstationSelected ? "enabled" : "disabled"
  } for workstations.`;
};
