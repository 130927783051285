import {
  nodeConfig,
  idMapping,
} from "../../../pages/KnowledgeGraph/config/filterConfig";
import * as THREE from "three";
import * as TWEEN from "@tweenjs/tween.js";
import { CSS3DObject } from "three/addons/renderers/CSS3DRenderer.js";

const maxLabelLength = 25;
const nodeSize = 130;
const textureLoader = new THREE.TextureLoader();

const generateIconSprite = (icon, size) => {
  const iconTexture = textureLoader.load(icon);
  iconTexture.colorSpace = THREE.SRGBColorSpace;
  
  const material = new THREE.SpriteMaterial({
    map: iconTexture,
    depthTest: false,
    forceSinglePass: true,
    vertexColors: true,
  });

  const sprite = new THREE.Sprite(material);
  sprite.scale.set(size, size, 1);

  return sprite;
};

export const renderNodes = ({ data }) => {
  const iconMap = {};

  nodeConfig.forEach((item) => {
    // load the icon svg
    const size =
      item.value === "highly_connected_ip" ? nodeSize * 1.4 : nodeSize;

    iconMap[item.value] = {
      icon: generateIconSprite(item.icon, size),
      outlined: generateIconSprite(item.outlinedIcon, size),
      highlighted: generateIconSprite(item.highlightedIcon, size),
    };
  });

  data.nodes.forEach((node) => {
    const name =
      node.labels[node.labels.length - 1] === "highly_connected_ip"
        ? "highly_connected_ip"
        : node.labels[0].toLowerCase();
    const iconKey = node.properties.Severity
      ? `${name}-${node.properties.Severity}`
      : name;

    const nodeEl = document.createElement("div");
    let text = node.properties[idMapping[node.labels[0]]] || "";
    if (text.length > maxLabelLength)
      text = text.slice(0, maxLabelLength) + "...";
    nodeEl.textContent = text;
    nodeEl.style.color = "white";
    nodeEl.className = `node-text ${iconKey}`;
    const label = new CSS3DObject(nodeEl);

    node.icons = {
      icon: iconMap[iconKey].icon.clone(),
      hoverIcon: iconMap[iconKey].highlighted.clone(),
      selectIcon: iconMap[iconKey].outlined.clone(),
      label: label,
    };
  });
};

export const handleDataClick = ({
  data,
  graph,
  dimensions,
  setSelectedData,
  setHoverData,
}) => {
  setSelectedData(data);
  setHoverData();
  let averages;

  // if data is a link, calculate the center of link for camera target
  if (!data.x)
    averages = {
      x: (data.source.x + data.target.x) / 2,
      y: (data.source.y + data.target.y) / 2,
    };

  if (dimensions === 3) {
    const transitionTime = 3000;
    // If data is a link
    if (!data.x) {
      averages.z = (data.source.z + data.target.z) / 2;
      const distance = 1200; // Aim at node from outside it
      const distRatio =
        1 + distance / Math.hypot(averages.x, averages.y, averages.z);
      graph.cameraPosition(
        {
          x: averages.x * distRatio,
          y: averages.y * distRatio,
          z: ((data.source.z + data.target.z) / 2) * distRatio,
        }, // new position
        averages, // lookAt ({ x, y })
        transitionTime // ms transition duration
      );
    } else {
      // data is a node
      const distance = 1000;
      const distRatio = 1 + distance / Math.hypot(data.x, data.y, data.z);
      graph.cameraPosition(
        {
          x: data.x * distRatio,
          y: data.y * distRatio,
          z: data.z * distRatio,
        },
        data,
        transitionTime
      );
    }
  } else {
    // custom camera animation for 2D "hover" view
    const transitionTime = 1000;
    const camera = graph.camera();
    const camPos = camera.position;
    let target;

    // If data is a link
    if (!data.x) {
      target = { x: averages.x, y: averages.y };
      // setHighlightNodes([data.source, data.target]);
    } else target = { x: data.x, y: data.y };

    const camLookAt = Object.assign(
      new THREE.Vector3(0, 0, -1000)
        .applyQuaternion(camera.quaternion)
        .add(camera.position)
    );

    const setCameraPos = (dataCoords) => {
      camera.position.set(dataCoords.x, dataCoords.y);
    };

    const setLookAt = (dataCoords) => {
      const lookAtVect = new THREE.Vector3(dataCoords.x, dataCoords.y);
      const controls = graph.controls();
      controls.target = lookAtVect;
    };

    new TWEEN.Tween(camPos)
      .to(target, transitionTime)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(setCameraPos)
      .start();

    new TWEEN.Tween(camLookAt)
      .to(target, transitionTime)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(setLookAt)
      .start();
  }
};
