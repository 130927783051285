export const colors = {
  Critical: "#D64526",
  High: "#FFA415",
  Medium: "#FFE133",
  Low: "#638185",
  Informational: "#066666"
};

export const lineData = {
  lineOpacity: "1",
  lineOpacityHover: "1",
  otherLinesOpacityHover: "0.3",
  lineStroke: "3px",
  lineStrokeHover: "5px"
};

export const severitiesValues = [
  {name: 'Critical', values: []},
  {name: 'High', values: []},
  {name: 'Medium', values: []},
  {name: 'Low', values: []},
  {name: 'Informational', values: []}
]

export const detectionSeveritiesValues = [
  {name: 'Critical', values: []},
  {name: 'High', values: []},
  {name: 'Medium', values: []},
  {name: 'Low', values: []},
  {name: 'Informational', values: []}
]

export const tooltipMap = {
  'Escape rate': 'Escape Rate refers to the percentage of previously resolved vulnerabilities that reappear in the latest scan. This metric highlights the effectiveness of past fixes and the need for better security practices.',
  'Burn rate': 'Burn Rate is the percentage of vulnerabilities that have been successfully addressed compared to how they are being discovered.',
  'Arrival rate': 'Arrival Rate refers to the frequency at which new vulnerabilities are discovered or reported in the infrastructure. A higher rate may indicate increasing threats. Monitoring this rate helps in assessing the security posture and the effectiveness of detection tools.',
}

export  const vulnGraphValues = [
  {name: 'Escape rate', values: []},
  {name: 'Burn rate', values: []},
  {name: 'Arrival rate', values: []},
  {name: 'Survival rate', values: []},
]