import GoogleAuthenticator from "../../assets/icons/google-authenticator.svg";
import Duo from "../../assets/icons/duo.svg";
import MicrosoftAuthenticator from "../../assets/icons/microsoft-authenticator.svg";
import OktaVerify  from "../../assets/icons/okta-verify.png";

export const formFields = {
    signIn: {
      username: {
        placeholder: "samplemail@email.com",
        label: "Email address",
        isRequired: true,
      },
      password: {
        placeholder: "Password",
        isRequired: true,
      },
    },
    resetPassword: {
      username: {
        placeholder: "samplemail@email.com",
        label: "Email address",
        isRequired: true,
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: "Enter code",
        label: "Verification code",
        isRequired: true,
        type: "text"
      },
      confirm_password: {
        placeholder: "Confirm New Password",
        isRequired: true,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        placeholder: "Enter code",
        label: "Verification code",
        autoFocus: "autoFocus",
        isRequired: true,
        type: "text"
      },
    },
    setupTOTP: {
      confirmation_code: {
        placeholder: "Enter code",
        label: "Verification code",
        autoFocus: "autoFocus",
        isRequired: true,
        type: "text"
      },
      QR: {
        totpIssuer: 'ActZero.ai',
        totpUsername: '',
      },
    },
    forceNewPassword: {
      password: {
        placeholder: "New Password",
        isRequired: true,
      },
      confirm_password: {
        placeholder: "Confirm New Password",
        isRequired: true,
      },
    },
};

export const headers = {
  forgotPassword: "Reset your password",
  confirmResetPassword: "Reset your password",
  signIn: "Sign in to ActZero",
  confirmSignIn: "Enter Verification Code",
  forceNewPassword: "Create New Password",
  setupTOTP: "Setup Authentication",
  verifyUser: "Verify User",
  confirmVerifyUser: "Verify User",
  setup: "",
  signUp: "Sign Up",
  confirmSignUp: "Confirm Sign Up"
};

export const errors = {
  invalidCode: "Invalid verification code provided, please try again.",
  attemptLimit: "Attempt limit exceeded, please try again after some time.",
  userNotFound: "Incorrect username or password.",
  invalidPasswordFormat: "Password must contain capital letter, number, and symbol. Minimum 8 characters."
};

export const i18n = {
  "Sign in": "Login",
  "Change Password": "Submit",
  "Code": "Enter Code",
  "Confirm": "Confirm 2FA",
  "Back to Sign In": "Back to Login"
};

export const applicationIcons = [
  {
    label: "Google Authenticator",
    className: "google",
    img: GoogleAuthenticator,
    width: "70px",
  },
  { label: "Duo", className: "duo", img: Duo, width: "90px" },
  {
    label: "Microsoft Authenticator",
    className: "microsoft",
    img: MicrosoftAuthenticator,
    width: "70px",
  },
  {
    label: "Okta Verify",
    className: "okta-verify",
    img: OktaVerify,
    width: "53px",
  },
];